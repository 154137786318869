import { Injectable, inject } from "@angular/core";
import { db } from "src/app/db.service";
import { DataService } from "../data.service";
import { from, map, of, defer, merge } from "rxjs";
import { liveQuery } from "dexie";

@Injectable({
  providedIn: "root",
})
export class SellsService {
  _dataService = inject(DataService);
  getTotals(startDate: Date, endDate: Date) {
    const localData = this._dataService.getLS("sellsTotals");
    const localObservable = defer(() => of(localData));
    const dexieObservable = from(
      liveQuery(() => this.getTotalsHelper(endDate))
    ).pipe(
      map((data) => {
        const result: { sells; returns; residue } = {
          sells: data.reduce(
            (acc, value) =>
              value.date >= startDate && value.quantity > 0
                ? acc + value.quantity * value.price
                : acc,
            0
          ),
          returns: data.reduce(
            (acc, value) =>
              value.date >= startDate && value.quantity < 0
                ? acc + value.quantity * value.price
                : acc,
            0
          ),
          residue: data.reduce(
            (acc, value) =>
              value.date < startDate ? acc + value.quantity * value.price : acc,
            0
          ),
        };
        this._dataService.setLS("sellsTotals", result);
        return result;
      })
    );
    return merge(localObservable, dexieObservable);
  }

  async getTotalsHelper(endDate) {
    const result = await db.sells.where("date").belowOrEqual(endDate).toArray();
    return result;
  }
}
