<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">{{ data.header ? data.header : "Sil" }}</div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="onNoClick()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>
<div class="px-5 pt-0">
  <div
    mat-dialog-content
    class="d-flex justify-content-center"
    style="
      min-height: 50px;
      padding-top: 5px;
      display: flex;
      align-items: center;
    "
  >
    <h5 style="text-align: center; white-space: pre-wrap;">
      {{ data.body ? data.body : data }}
      <span
        style="
          font-weight: 400;
          display: block;
          text-align: center;
          margin-top: 0.5rem;
        "
        >{{ data.footer ? data.footer : "silinsin?" }}</span
      >
    </h5>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-center mt-3 mb-4">
    <button
      class="btn w-50 btn-outline-secondary py-2"
      (click)="onNoClick()"
      style="border-top-right-radius: 0px; border-bottom-right-radius: 0px"
      cdkFocusInitial
    >
      Yox
    </button>
    <button
      class="btn w-50 btn-outline-secondary py-2"
      (click)="onYesClick()"
      style="border-top-left-radius: 0px; border-bottom-left-radius: 0px"
    >
      Hə
    </button>
  </div>
</div>
