<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    {{
      dialogData.stockEntryType == "Stock Entry"
        ? "Anbar Girişi"
        : "Anbar Çıxış"
    }}
    <span *ngIf="dialogData.data?.tId && !copyMode" class="transactionNo"
      ><span class="pcOnly" style="margin-right: -5px">Qaimə</span
      >{{ transactionNo }}</span
    >
  </div>
  <div class="ui-titlecontrols" *ngIf="dialogData.data?.tId && !copyMode">
    <button
      class="ui-btn operations"
      (click)="showContextMenu = !showContextMenu"
    >
      <i
        class="material-symbols-outlined"
        style="color: white; font-weight: 500"
        >more_horiz</i
      >
    </button>
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
  <div
    class="context-menu filterSettings"
    *ngIf="showContextMenu"
    [style.right.px]="45"
    [style.top.px]="36"
  >
    <div class="filterItems">
      <button
        (click)="editMode = true; copyMode = false; showContextMenu = false"
        [disabled]="editMode && !copyMode"
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      >
        <span class="material-symbols-outlined"> edit </span>
        <span class="w-100">Düzəliş et</span>
      </button>
      <button
        (click)="editMode = true; copyMode = true; showContextMenu = false"
        [disabled]="editMode && copyMode"
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      >
        <span class="material-symbols-outlined"> content_copy </span>
        <span class="w-100">Kopyala</span>
      </button>
    </div>
  </div>
</div>

<div appFocusNextOnEnter>
  <div class="container-fluid">
    <div class="row px-3 py-3" style="background-color: #f6f6f6">
      <div
        class="col-md-3 col-sm-12"
        [ngClass]="{ 'col-md-4': !transactionNo || editMode }"
      >
        <!-- <label class="form-label" for="contact">Tərəf Müqabil</label> -->
        <div class="input-group">
          <div class="input-group-text">
            <i class="material-icons">person</i>
          </div>
          <ng-select
            #customerInput
            [items]="_dataService.customers()"
            bindLabel="name"
            bindValue="id"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="_customersService.selectedCustomerId"
            name="customerId"
            class="form-control"
            placeholder="Tərəf Müqabil seçin"
            [disabled]="dialogData.data && !editMode"
            [searchFn]="_dataService.customSearchFn"
            cdkFocusInitial
          >
          </ng-select>
          <div
            *ngIf="!dialogData?.data"
            class="input-group-text inputGroupButtons cardHover"
            style="cursor: pointer"
          >
            <a
              matTooltip="Yeni Müştəri"
              matTooltipClass="line-broken-tooltip"
              (click)="newCustomerDialog()"
            >
              <mat-icon class="material-icons">add</mat-icon>
            </a>
          </div>
        </div>
      </div>

      <div
        class="col-md-3 col-sm-12 mt-smm-5"
        [ngClass]="{ 'col-md-4': !transactionNo || editMode }"
      >
        <!-- <label class="form-label" for="contact">Anbar</label> -->
        <div class="input-group">
          <div class="input-group-text">
            <i class="material-icons">inventory</i>
          </div>
          <ng-select
            [items]="_dataService.stockGroups()"
            bindLabel="name"
            bindValue="id"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="selectedStockGroupId"
            [disabled]="dialogData.data && !editMode"
            name="stockGroupId"
            class="form-control"
            (change)="getMaterialSums()"
          >
          </ng-select>
          <!-- <div class="input-group-text inputGroupButtons" style="cursor: pointer;">
                    <a (click)="newCustomerDialog()" ><mat-icon class="material-icons">add</mat-icon></a>
                </div> -->
        </div>
      </div>

      <div
        class="col-md-3 col-sm-12 mt-smm-5"
        [ngClass]="{ 'col-md-4': !transactionNo || editMode }"
      >
        <!-- <label class="form-label" for="date">Tarix</label> -->
        <div class="input-group">
          <div
            class="input-group-text"
            style="
              padding: 0px;
              width: 49px;
              height: 38px;
              display: flex;
              justify-content: center;
            "
          >
            <mat-datepicker-toggle [for]="myDatepicker">
              <mat-icon
                style="color: #0f4c7d; line-height: 21px"
                matDatepickerToggleIcon
                >calendar_month</mat-icon
              >
            </mat-datepicker-toggle>
          </div>
          <input
            [(ngModel)]="date.value"
            [value]="date.value"
            [matDatepicker]="myDatepicker"
            class="form-control"
            id="date"
            ngModel
            name="date"
            (click)="myDatepicker.open()"
            [disabled]="dialogData.data && !editMode"
            style="
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            "
          />

          <mat-datepicker #myDatepicker></mat-datepicker>
        </div>
      </div>

      <div
        *ngIf="transactionNo && !editMode"
        class="col-md-3 col-sm-12 mt-smm-5"
      >
        <div class="input-group">
          <div class="input-group-text">
            <span class="manat fw-500">₼</span>
          </div>
          <input
            type="text"
            placeholder="Qiymət"
            class="form-control"
            min="0"
            [ngModel]="sum | number | removeComma"
            (ngModelChange)="sum = $event"
            name="priceSum"
            readonly
          />
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <form #inv="ngForm" (keydown.enter)="$event.preventDefault()">
      <div class="container" style="overflow-x: hidden">
        <!-- PRODUCTS-->
        <div class="row pb-3 pt-2 px-3">
          <div class="col-12 d-flex flex-column">
            <div *ngIf="materialsToAdd?.length">
              <div ngModelGroup="products">
                <div
                  class="materialsTable mobileMaterialsList materialsTableHeader"
                  [ngClass]="{
                    materialsTableWithDel:
                      materialsToAdd.length > 1 &&
                      !_dataService.mobileDevice &&
                      (!dialogData.transactionId || editMode)
                  }"
                  *ngIf="!_dataService.mobileDevice"
                >
                  <div class="form-label">Material</div>
                  <div class="form-label">Miqdar</div>
                  <div class="form-label">Qiymət</div>
                  <div class="form-label">Məbləğ</div>
                </div>
                <div
                  ngModelGroup="{{ i }}"
                  *ngFor="
                    let product of materialsToAdd;
                    let i = index;
                    trackBy: _dataService.trackByFn
                  "
                  class="mt-2 materialsTable mobileMaterialsList gap-mobile"
                  [ngClass]="{
                    materialsTableWithDel:
                      materialsToAdd.length > 1 &&
                      !_dataService.mobileDevice &&
                      (!dialogData.data || editMode)
                  }"
                >
                  <div>
                    <div class="input-group">
                      <div class="input-group-text fw-500 pcOnly">
                        <span *ngIf="materialsToAdd.length > 1">{{
                          i + 1
                        }}</span>
                        <mat-icon *ngIf="materialsToAdd.length == 1"
                          >sell</mat-icon
                        >
                      </div>
                      <ng-select
                        #input
                        [items]="_dataService.materials()"
                        bindLabel="name"
                        bindValue="id"
                        appendTo="body"
                        loadingText="Yüklənir .."
                        notFoundText="Tapılmadı"
                        [(ngModel)]="product.id"
                        name="materialId"
                        class="form-control"
                        [searchFn]="_dataService.customSearchFn"
                        placeholder="Material seçin"
                        (change)="getMaterialDetails(product.id, i)"
                        [disabled]="dialogData.data && !editMode"
                        required
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <div>
                            <span>{{ item.name }}</span>
                          </div>
                          <small
                            ><b>Qalıq:</b>
                            {{ item.totalQuantity ? item.totalQuantity : 0 }}
                            {{ item.unit ? item.unit : "ədəd" }}</small
                          >
                        </ng-template>
                      </ng-select>
                      <!-- <div
                        *ngIf="!dialogData.data || editMode"
                        class="input-group-text inputGroupButtons cardHover"
                        style="cursor: pointer"
                      >
                        <a
                          matTooltip="Yeni Material"
                          matTooltipClass="line-broken-tooltip"
                          (click)="newMaterialDialog(i)"
                        >
                          <mat-icon class="material-icons">add</mat-icon>
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        [(ngModel)]="product.quantity"
                        (ngModelChange)="
                          product.quantity = _dataService.fixInputValue(
                            product.quantity
                          )
                        "
                        (keypress)="_dataService.getNumericsOnly($event)"
                        (focus)="product.quantity = null"
                        (focusout)="
                          product.quantity = _dataService.calculateInput(
                            product.quantity
                          );
                          calculateMaterialPrice(product, i)
                        "
                        type="text"
                        placeholder="Miqdar"
                        class="form-control"
                        autocomplete="off"
                        name="quantity"
                        tabindex="1"
                        [disabled]="dialogData.data && !editMode"
                        required
                      />
                      <div class="input-group-text">
                        <span style="font-weight: 500">{{
                          product.unit ? (product.unit | slice : 0 : 2) : "əd"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        [(ngModel)]="product.sellPrice"
                        (ngModelChange)="
                          product.sellPrice = _dataService.fixInputValue(
                            product.sellPrice
                          )
                        "
                        (keypress)="_dataService.getNumericsOnly($event)"
                        (focus)="product.sellPrice = null"
                        (focusout)="
                          product.sellPrice = _dataService.calculateInput(
                            product.sellPrice
                          );
                          calculateMaterialPrice(product, i)
                        "
                        type="text"
                        placeholder="Qiymət"
                        class="form-control"
                        autocomplete="off"
                        name="price"
                        [disabled]="dialogData.data && !editMode"
                        required
                      />
                      <div class="input-group-text">
                        <span class="manat fw-500">₼</span>
                      </div>
                    </div>
                  </div>
                  <div class="priceTotal">
                    <div class="input-group">
                      <input
                        [(ngModel)]="product.sum"
                        (ngModelChange)="
                          product.sum = _dataService.fixInputValue(product.sum)
                        "
                        (keypress)="_dataService.getNumericsOnly($event)"
                        (focus)="product.sum = null"
                        (focusout)="
                          product.sum = _dataService.calculateInput(
                            product.sum
                          );
                          calculateMaterialPrice(product, i, 'sum')
                        "
                        type="text"
                        placeholder="Məbləğ"
                        class="form-control"
                        autocomplete="off"
                        name="mebleg"
                        [disabled]="dialogData.data && !editMode"
                        tabindex="-1"
                      />
                      <div class="input-group-text">
                        <span class="manat fw-500">₼</span>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="materialsToAdd.length > 1">
                    <div class="deleteColumn btnDeleteContainer">
                      <button
                        *ngIf="
                          materialsToAdd.length > 1 &&
                          (!dialogData.data || editMode)
                        "
                        matTooltip="Materialı sil"
                        [matTooltipPosition]="'right'"
                        matTooltipClass="line-broken-tooltip roundedTooltip"
                        class="btn btn-outline-primary btnDelete"
                        (click)="
                          removeProduct(i, $event);
                          calculateMaterialPrice(inv.value, i)
                        "
                      >
                        <mat-icon class="material-icons">clear</mat-icon>
                      </button>
                    </div>
                  </ng-container>
                  @if(i!=materialsToAdd.length-1) {
                  <hr class="mobileOnly w-100 grid-span-2-mobile mt-0" />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- PRICE STUFF-->
        <div
          class="row px-3"
          style="padding: 0.5rem 0rem 1rem; border-top: 1px solid #ececec"
          *ngIf="!transactionNo || editMode"
        >
          <div class="col-md-1 d-flex align-items-end">
            <button
              *ngIf="!dialogData.data || editMode"
              class="btn btn-outline-secondary"
              style="
                background: #e9ecef;
                border: 1px solid #cecece !important;
                color: rgb(44, 76, 102);
                width: 50px;
                height: 90%;
                line-height: 10px;
              "
              (click)="nextProduct()"
              matTooltip="Yeni Material 
                  Qısayol: Shift + N"
              matTooltipClass="line-broken-tooltip"
              [matTooltipPosition]="'right'"
            >
              <mat-icon class="material-icons">add</mat-icon>
            </button>
          </div>

          <div
            *ngIf="!transactionNo || editMode"
            class="col"
            [ngClass]="{
              'col-md-4': priceReceived != 0
            }"
            style="padding-left: 0px !important"
          >
            <label class="form-label" for="priceSum">Toplam Qiymət</label>
            <div class="input-group">
              <div class="input-group-text manat fw-500">₼</div>
              <input
                type="text"
                id="priceSum"
                placeholder="Qiymət"
                class="form-control"
                min="0"
                [ngModel]="sum | number | removeComma"
                (ngModelChange)="sum = $event"
                name="priceSum"
                readonly
              />
            </div>
          </div>

          <div
            *ngIf="!dialogData.data || editMode"
            class="col"
            [ngClass]="{
              'col-md-4': priceReceived != 0
            }"
          >
            <label class="form-label" for="priceReceived">Ödənən Məbləğ</label>
            <div class="input-group">
              <div class="input-group-text manat fw-500">₼</div>
              <input
                type="text"
                id="priceReceived"
                placeholder="Ödənən Məbləğ"
                class="form-control"
                min="0"
                [(ngModel)]="priceReceived"
                (ngModelChange)="residue = sum - priceReceived"
                (keypress)="_dataService.getNumericsOnly($event)"
                (focus)="priceReceived ? priceReceived : (priceReceived = '')"
                (focusout)="
                  priceReceived.length > 0
                    ? priceReceived
                    : (priceReceived = 0);
                  priceReceived != 0
                    ? (priceReceived =
                        _dataService.calculateInput(priceReceived))
                    : (priceReceived = 0);
                  residue = sum - priceReceived
                "
                name="priceReceived"
              />
            </div>
          </div>

          <div
            *ngIf="(!dialogData.data || editMode) && priceReceived != 0"
            class="col-3"
          >
            <label class="form-label" for="residue">Qalıq</label>
            <div class="input-group">
              <div class="input-group-text manat fw-500">₼</div>
              <input
                type="text"
                id="residue"
                placeholder="Qalıq"
                class="form-control"
                min="0"
                [ngModel]="residue | number | removeComma"
                (ngModelChange)="residue = $event"
                name="residue"
                readonly
              />
            </div>
          </div>
        </div>

        <!-- DETAILS-->
        <div class="row px-3 pt-1 pb-3">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-text pcOnly">
                <mat-icon>info_outline</mat-icon>
              </div>
              <textarea
                placeholder="Məlumat"
                class="form-control"
                id="details"
                rows="2"
                name="details"
                [(ngModel)]="transactionDetails"
                [disabled]="dialogData.data && !editMode"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>

<div
  class="d-grid"
  style="
    background-color: #f6f6f6;
    border-top: 1px solid #ececec;
    padding: 0.75rem 1.25rem;
  "
>
  <button
    #btnAdd
    *ngIf="!dialogData?.data || editMode"
    class="btn btn-outline-primary btnDelete w-100 d-flex justify-content-center align-items-center padding-block-5"
    (click)="addNewIstehsal(inv.value, btnAdd)"
    [disabled]="!inv.valid || !inv.dirty"
  >
    <span style="font-size: 1.1rem">{{
      dialogData?.data && !copyMode ? "Yenilə" : "Əlavə et"
    }}</span>
    <div *ngIf="dataLoading" class="lds-dual-ring"></div>
  </button>
  @if(user && !editMode) {
  <span
    class="userName"
    style="
      margin: 0px 0px;
      color: #606060;
      position: absolute;
      right: 25px;
      bottom: 3px;
    "
    ><span class="material-symbols-outlined"> person </span>{{ user }}</span
  >
  }
</div>
