<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    <span style="text-wrap: nowrap"> Anbarlar Arası Köçürmə </span>
    <div class="clipText ml-auto" *ngIf="dialogData.data">
      <span class="transactionNo"
        ><span class="pcOnly" style="margin-right: -5px">Qaimə</span
        >{{ transactionNo }}</span
      >
    </div>
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<div appFocusNextOnEnter>
  <form #inv="ngForm">
    <div class="container">
      <div class="row g-2 pb-3 pt-2 px-2">
        <div class="col-6">
          <label class="form-label" for="bankGroup">Anbardan</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">inventory</i>
            </div>
            <ng-select
              class="form-control"
              [items]="cashBox"
              bindLabel="name"
              bindValue="id"
              placeholder="Çıxış Anbarı"
              [(ngModel)]="stockGroupIdFrom"
              [searchable]="false"
              [clearable]="false"
              (change)="this.getMaterialSums()"
              name="stockGroupIdFrom"
              required
            >
            </ng-select>
          </div>
        </div>

        <div class="col-6">
          <label class="form-label" for="bankGroup">Anbara</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">inventory</i>
            </div>
            <ng-select
              class="form-control"
              [items]="cashBox"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="stockGroupIdTo"
              placeholder="Giriş Anbarı"
              [searchable]="false"
              [clearable]="false"
              name="stockGroupIdTo"
              required
            >
            </ng-select>
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label" for="date">Tarix</label>
          <div class="input-group">
            <div
              class="input-group-text"
              style="
                padding: 0px;
                width: 49px;
                height: 38px;
                display: flex;
                justify-content: center;
              "
            >
              <mat-datepicker-toggle [for]="myDatepicker">
                <mat-icon
                  style="color: #0f4c7d; line-height: 21px"
                  matDatepickerToggleIcon
                  >calendar_month</mat-icon
                >
              </mat-datepicker-toggle>
            </div>
            <input
              [(ngModel)]="date.value"
              [value]="date.value"
              [matDatepicker]="myDatepicker"
              class="form-control"
              id="date"
              ngModel
              name="date"
              (click)="myDatepicker.open()"
              style="
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              "
            />

            <mat-datepicker #myDatepicker></mat-datepicker>
          </div>
        </div>

        <div class="col-6">
          <label class="form-label" for="details">Məlumat</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <mat-icon>info_outline</mat-icon>
            </div>
            <textarea
              placeholder="Məlumat"
              class="form-control"
              id="details"
              rows="1"
              name="details"
              [(ngModel)]="details"
            ></textarea>
          </div>
        </div>

        <div class="col-12 d-flex flex-column">
          <div *ngIf="materialsToAdd?.length">
            <div ngModelGroup="products">
              <div
                class="materialsTable materialsTableHeader"
                [ngClass]="{
                  materialsTableWithDel:
                    materialsToAdd.length > 1 && !_dataService.mobileDevice
                }"
                *ngIf="!_dataService.mobileDevice"
              >
                <div class="form-label">Material</div>
                <div class="form-label">Miqdar</div>
              </div>
              <div
                ngModelGroup="{{ i }}"
                *ngFor="
                  let product of materialsToAdd;
                  let i = index;
                  trackBy: _dataService.trackByFn
                "
                class="mt-2 materialsTable"
                [ngClass]="{
                  materialsTableWithDel:
                    materialsToAdd.length > 1 &&
                    !_dataService.mobileDevice &&
                    !transactionNo
                }"
              >
                <div>
                  <div class="input-group">
                    <div class="input-group-text">
                      <mat-icon>sell</mat-icon>
                    </div>
                    <ng-select
                      #input
                      [items]="_dataService.materials()"
                      bindLabel="name"
                      bindValue="id"
                      loadingText="Yüklənir .."
                      notFoundText="Tapılmadı"
                      [(ngModel)]="product.id"
                      name="materialId"
                      class="form-control"
                      [searchFn]="_dataService.customSearchFn"
                      placeholder="Material seçin"
                      (change)="getMaterialDetails(product.id, i)"
                      required
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div>
                          <span>{{ item.name }}</span>
                        </div>
                        <small
                          ><b>Qalıq:</b>
                          {{ item.totalQuantity ? item.totalQuantity : 0 }}
                          {{ item.unit ? item.unit : "ədəd" }}</small
                        >
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div>
                  <div class="input-group">
                    <input
                      [(ngModel)]="product.quantity"
                      type="text"
                      placeholder="Miqdar"
                      class="form-control"
                      autocomplete="off"
                      name="quantity"
                      tabindex="1"
                      (focusout)="
                        product.quantity = _dataService.calculateInput(
                          product.quantity
                        )
                      "
                      required
                    />
                    <div class="input-group-text">
                      <span style="font-weight: 500">{{
                        product.unit ? (product.unit | slice : 0 : 2) : "əd"
                      }}</span>
                    </div>
                  </div>
                </div>
                <ng-container
                  *ngIf="materialsToAdd.length > 1 && !transactionNo"
                >
                  <div class="deleteColumn">
                    <button
                      *ngIf="materialsToAdd.length > 1"
                      matTooltip="Materialı sil"
                      [matTooltipPosition]="'right'"
                      matTooltipClass="line-broken-tooltip roundedTooltip"
                      class="btn btn-outline-primary btnDelete"
                      (click)="
                        removeProduct(i, $event);
                      "
                    >
                      <mat-icon class="material-icons">clear</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="d-grid mt-2"
  style="
    background-color: #f6f6f6;
    border-top: 1px solid #ececec;
    padding: 0.75rem 1.25rem;
  "
>
  <div class="btn-group" *ngIf="!transactionNo">
    <button
      *ngIf="!dialogData.data"
      class="btn btn-outline-primary btnDelete padding-block-5"
      style="
        background: #e6e6e6;
        border: 0px;
        color: #2c4c66;
        width: 55px;
        line-height: 10px;
        z-index: 99;
      "
      (click)="nextProduct()"
      matTooltip="Digər Material"
      matTooltipClass="line-broken-tooltip roundedTooltip"
      [matTooltipPosition]="'right'"
    >
      <mat-icon class="material-icons">add</mat-icon>
    </button>
    <button
      class="btn btn-outline-primary btnDelete padding-block-5 w-100"
      [disabled]="!inv.valid || stockGroupIdFrom == stockGroupIdTo"
      (click)="addTransferOrder(inv.value, $event)"
    >
      Əlavə et
    </button>
  </div>
  @if(user) {
  <span
    class="userName"
    style="
      margin: 0px 0px;
      color: #606060;
      position: absolute;
      right: 25px;
      bottom: 3px;
    "
    ><span class="material-symbols-outlined"> person </span>{{ user }}</span
  >
  }
</div>
