<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    {{ dialogData ? "Material" : "Yeni Material" }}
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<form #inv="ngForm">
  <div class="container">
    <div class="row g-2 pb-3 pt-2 px-2">
      <div class="col-12">
        <label class="form-label" for="name" *ngIf="!showOperations">Adı</label>
        <div class="input-group" [ngClass]="{ 'mt-2': showOperations }">
          <div class="input-group-text pcOnly">
            <i class="material-icons">book</i>
          </div>
          <input
            [(ngModel)]="name"
            type="text"
            placeholder="Adı"
            class="form-control"
            autocomplete="off"
            name="name"
            required
            [readonly]="showOperations"
          />
        </div>
      </div>

      @if(dialogData && showOperations) {
      <div class="col-md-6">
        <label class="form-label" for="stockGroups" *ngIf="!showOperations"
          >Anbar</label
        >
        <div class="input-group" [ngClass]="{ 'mt-2': showOperations }">
          <div class="input-group-text">
            <i class="material-icons">inventory</i>
          </div>
          <ng-select
            [items]="_dataService.stockGroups()"
            bindLabel="name"
            bindValue="id"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="selectedStockGroupId"
            (change)="selectedStockGroupIdSig.set(selectedStockGroupId)"
            name="stockGroups"
            class="form-control"
            placeholder="Anbar"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-md-6 d-flex">
        <div class="input-group" [ngClass]="{ 'mt-2': showOperations }">
          <div class="input-group-text">
            <div
              class="box"
              (click)="showProduction.set(!showProduction())"
              style="cursor: pointer"
            >
              <mat-icon
                style="color: var(--primary-background)"
                *ngIf="showProduction()"
                >check</mat-icon
              >
            </div>
          </div>
          <input
            type="select"
            placeholder="İstehsalat"
            class="form-control"
            autocomplete="off"
            name="production"
            (click)="showProduction.set(!showProduction())"
            readonly
            style="cursor: pointer"
          />
        </div>
      </div>
      }

      <div class="col-6" *ngIf="!showOperations">
        <label class="form-label" for="name"
          >Qiyməti {{ unit ? "(" + unit + "/₼)" : "(ədəd/₼)" }}</label
        >
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <span style="font-weight: 600">₼</span>
          </div>
          <input
            [(ngModel)]="price"
            type="number"
            placeholder="Qiyməti"
            class="form-control"
            autocomplete="off"
            name="price"
            required
          />
        </div>
      </div>

      <div class="col-6" *ngIf="!showOperations">
        <label class="form-label" for="unit">Vahid</label>
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <i class="material-icons">token</i>
          </div>
          <ng-select
            [items]="_dataService.units"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="unit"
            name="unit"
            class="form-control"
            placeholder="Vahid seçin"
            required
          >
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <!-- OPERATIONS -->
  <div *ngIf="showOperations" class="d-grid pb-2">
    <div class="container">
      <div class="row g-2 px-2">
        <ag-grid-angular
          style="
            width: 100%;
            height: 274px;
            border-radius: 6px;
            overflow: hidden;
            margin-top: 0.5rem;
          "
          class="ag-theme-alpine"
          [rowData]="agGridData$"
          [columnDefs]="columnDefs"
          [localeText]="_dataService.agGridLocale"
          (gridReady)="onGridReady($event)"
          [getRowId]="getRowId"
          [cacheQuickFilter]="true"
          (rowClicked)="
            _dataService.transactionDialog($event.data.transactionId)
          "
        >
        </ag-grid-angular>
      </div>
    </div>
  </div>
  <!-- END OPERATIONS -->

  <div class="d-grid mt-2 py-3" style="background-color: #f6f6f6">
    <div class="container">
      <div class="row g-2 px-2">
        <div class="col" *ngIf="dialogData">
          <button
            class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex align-items-center justify-content-center"
            (click)="materialOperations($event)"
          >
            {{ showOperations ? "Bağla" : "Əməliyyatlar" }}
            <mat-icon
              *ngIf="!dataLoading"
              style="font-size: 21px; vertical-align: inherit"
            >
              {{
                showOperations
                  ? "keyboard_double_arrow_down"
                  : "keyboard_double_arrow_up"
              }}
            </mat-icon>
            <div *ngIf="dataLoading" class="lds-dual-ring"></div>
          </button>
        </div>
        @if(!showOperations) {
        <div class="col">
          <button
            class="btn btn-outline-primary btnDelete padding-block-5 w-100"
            [disabled]="!inv.valid || !inv.dirty"
            (click)="addMaterial(inv.value, $event)"
          >
            {{ dialogData ? "Yenilə" : "Əlavə et" }}
          </button>
        </div>
        }
      </div>
    </div>
  </div>
</form>
