<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    {{ dialogData ? "İstehsal Qaiməsi Nº" + dialogData.tId : "Yeni İstehsal" }}
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<mat-dialog-content appFocusNextOnEnter class="mb-2">
  <form #inv="ngForm">
    <div class="container">
      <div class="row px-3 py-3" style="background-color: #f6f6f6">
        <div class="col-md-8 col-sm-12">
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">import_contacts</i>
            </div>

            <ng-select
              #productInput
              [items]="_dataService.products()"
              bindLabel="name"
              bindValue="id"
              groupBy="category"
              [loading]="dataLoading"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="_productService.selectedProductId"
              name="productId"
              class="form-control"
              [searchFn]="_dataService.customSearchFn"
              placeholder="Məhsul seçin"
              [disabled]="dialogData && !inv.dirty"
              required
              cdkFocusInitial
              (change)="productSelected()"
            >
            </ng-select>

            <div
              class="input-group-text inputGroupButtons cardHover"
              *ngIf="_productService.selectedProductId"
              style="cursor: pointer"
            >
              <a
                matTooltip="Məhsul haqqında"
                matTooltipClass="line-broken-tooltip"
                (click)="
                  _productService.ProductDetailsDialog(
                    _productService.selectedProductId
                  )
                "
              >
                <mat-icon>info_outline</mat-icon>
              </a>
            </div>

            <div
              *ngIf="!dialogData && _authService.loggedInUserValue.role == 1"
              class="input-group-text inputGroupButtons cardHover"
              style="cursor: pointer"
            >
              <a
                matTooltip="Yeni Məhsul Yarat"
                matTooltipClass="line-broken-tooltip"
                (click)="_productService.ProductDetailsDialog()"
              >
                <mat-icon>add</mat-icon>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-4 mt-smm-5">
          <div class="input-group">
            <div
              class="input-group-text"
              style="
                padding: 0px;
                width: 49px;
                height: 38px;
                display: flex;
                justify-content: center;
              "
            >
              <mat-datepicker-toggle [for]="myDatepicker">
                <mat-icon
                  style="color: #0f4c7d; line-height: 21px"
                  matDatepickerToggleIcon
                  >calendar_month</mat-icon
                >
              </mat-datepicker-toggle>
            </div>
            <input
              [(ngModel)]="date.value"
              [value]="date.value"
              [matDatepicker]="myDatepicker"
              class="form-control"
              id="date"
              ngModel
              name="date"
              tabindex="-1"
              (click)="myDatepicker.open()"
              style="
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              "
            />
            <mat-datepicker #myDatepicker></mat-datepicker>
          </div>
        </div>

        <div
          [ngClass]="{ 'd-none': dialogData }"
          class="col-md-8 col-sm-12 mt-3"
        >
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">science</i>
            </div>

            <ng-select
              [items]="_dataService.formulaGroups()"
              bindLabel="name"
              bindValue="id"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="selectedFormulaGroupId"
              name="formulaGroupId"
              class="form-control"
              appendTo="body"
              placeholder="Formul"
              [searchFn]="_dataService.customSearchFn"
              tabindex="0"
            >
            </ng-select>
            <div
              class="input-group-text inputGroupButtons cardHover"
              *ngIf="inv.value.formulaGroupId"
              style="cursor: pointer"
            >
              <a
                matTooltip="Formul haqqında"
                matTooltipClass="line-broken-tooltip"
                (click)="newFormulaDialog(selectedFormulaGroupId)"
              >
                <mat-icon class="material-icons">info_outlined</mat-icon>
              </a>
            </div>
          </div>
        </div>

        <div
          [ngClass]="{ 'd-none': dialogData }"
          class="col-md-4 mt-smm-5 mt-3"
        >
          <!-- <label class="form-label" for="contact">Çıxış Anbarı</label> -->
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">inventory</i>
            </div>
            <ng-select
              [items]="_dataService.stockGroups()"
              bindLabel="name"
              bindValue="id"
              appendTo="body"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="selectedStockGroupId"
              name="stockGroupId"
              class="form-control"
            >
            </ng-select>
            <!-- <div class="input-group-text inputGroupButtons" style="cursor: pointer;">
                      <a (click)="newCustomerDialog()" ><mat-icon class="material-icons">add</mat-icon></a>
                  </div> -->
          </div>
        </div>
        <!-- <div [ngClass]="{ 'd-none': dialogData }" class="col-md-4 mt-3">
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">bolt</i>
            </div>
            <select
              class="form-control"
              name="production"
              ngModel="İstehsalat"
              tabindex="-1"
            >
              <option value="İstehsalat" selected>İstehsalat</option>
              <option value="Hazır Məhsul">Hazır Məhsul</option>
            </select>
          </div>
        </div> -->
      </div>

      <div class="row px-3 py-2">
        <div class="col">
          <label class="form-label" for="name">Ədəd</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">tag</i>
            </div>
            <input
              type="number"
              placeholder="Ədəd"
              class="form-control"
              autocomplete="off"
              min="0"
              [(ngModel)]="quantity"
              name="quantity"
              required
            />
          </div>
        </div>

        <div class="col">
          <label class="form-label" for="contact">Ağırlıq (kg/m)</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">monitor_weight</i>
            </div>
            <input
              type="number"
              placeholder="{{
                weightPlaceholder ? weightPlaceholder : 'Ağırlıq'
              }}"
              class="form-control"
              autocomplete="off"
              min="0"
              [(ngModel)]="weight"
              name="weight"
              (focus)="weight = ''"
              (focusout)="weight == '' ? (weight = weightPlaceholder) : weight"
              required
            />
          </div>
        </div>

        <div *ngIf="showWidth || width" class="col">
          <label class="form-label" for="email">En (m)</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">straighten</i>
            </div>
            <input
              type="number"
              placeholder="{{ widthPlaceholder ? widthPlaceholder : 'En' }}"
              class="form-control"
              autocomplete="off"
              min="0"
              [(ngModel)]="width"
              name="width"
              (focus)="width = ''"
              (focusout)="width == '' ? (width = widthPlaceholder) : width"
              required
            />
          </div>
        </div>

        <div class="col">
          <label class="form-label" for="email">Uzunluq (m)</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">straighten</i>
            </div>
            <input
              type="number"
              placeholder="{{
                lengthPlaceholder ? lengthPlaceholder : 'Uzunluq'
              }}"
              class="form-control"
              autocomplete="off"
              min="0"
              [(ngModel)]="length"
              name="length"
              (focus)="length = ''"
              (focusout)="length == '' ? (length = lengthPlaceholder) : length"
              required
            />
          </div>
        </div>
      </div>

      <div class="row px-3 pb-3">
        <div class="col-md-8">
          <label class="form-label" for="contact">Operator</label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">person</i>
            </div>
            <!-- <input type="text" placeholder="Operator" class="form-control" autocomplete="off"
                                [(ngModel)]="data.personnel" name="personnel"> -->
            <ng-select
              [items]="personnel()"
              bindLabel="name"
              bindValue="id"
              [loading]="dataLoading"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="selectedPersonnelId"
              name="personnelId"
              class="form-control panelUp"
              [searchFn]="_dataService.customSearchFn"
              placeholder="Operator seçin"
            >
            </ng-select>
          </div>
        </div>

        <div class="col-md-4">
          <label class="form-label" for="email">Avadanlıq</label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">microwave</i>
            </div>
            <input
              type="number"
              placeholder="Avadanlıq"
              class="form-control"
              autocomplete="off"
              min="1"
              [(ngModel)]="machineNo"
              name="machineNo"
            />
          </div>
        </div>

        @if(user) {
        <span class="userName"
          ><span class="material-symbols-outlined"> person </span
          >{{ user }}</span
        >
        }

        <div
          *ngIf="formulaTableData.data.length"
          class="mat-elevation-z1 mt-3 px-0 mt-smm-5"
          style="border-radius: 6px"
        >
          <table
            mat-table
            #matTable
            [dataSource]="formulaTableData"
            class="w-100"
          >
            <ng-container matColumnDef="material">
              <th mat-header-cell *matHeaderCellDef>Məhsul</th>
              <td mat-cell *matCellDef="let element">
                {{ element.materialId.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef>Miqdar, kg</th>
              <td mat-cell *matCellDef="let element">
                {{ -element.quantity | number : "1.2-2" | removeComma }}
              </td>
            </ng-container>

            <ng-container *ngIf="dialogData.formulaGroupId" matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef>Formul</th>
              <td mat-cell *matCellDef="let element">
                {{
                  (-element.quantity / weight / quantity / length) *
                    formulaTotalQuantity
                    | number
                    | removeComma
                }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="formulaDisplayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="
                let row;
                columns: formulaDisplayedColumns;
                let i = dataIndex
              "
              style="cursor: pointer"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div
  style="
    background-color: #f6f6f6;
    border-top: 1px solid #ececec;
    padding-inline: 8px;
  "
>
  <div *ngIf="!dialogData" class="d-grid py-2" style="padding-inline: 14px">
    <button
      *ngIf="!dialogData"
      class="btn btn-outline-secondary"
      [disabled]="!inv.valid"
      (click)="addNewIstehsal(inv.value, $event)"
      style="padding-block: 10px; border: 1px solid gainsboro; font-size: 16px"
    >
      Əlavə et
    </button>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div
        *ngIf="
          !formulaTableData.data.length &&
          dialogData &&
          dialogData.formulaGroupId
        "
        class="col py-2"
      >
        <button
          #btnDetails
          *ngIf="dialogData && dialogData.formulaGroupId"
          class="btn btn-block btn-outline-secondary w-100 py-2 d-flex justify-content-center align-items-center"
          (click)="getProductionFormula(btnDetails)"
          style="
            padding-block: 10px;
            border: 1px solid gainsboro;
            font-size: 16px;
          "
        >
          <mat-icon
            *ngIf="!dataLoading"
            style="font-size: 21px; line-height: 23px; vertical-align: inherit"
          >
            science
          </mat-icon>
          <span>
            {{
              dialogData.formulaGroupId ? selectedFormulaName : "Formul"
            }}</span
          >

          <div *ngIf="dataLoading" class="lds-dual-ring"></div>
        </button>
      </div>

      <!-- UPDATE TRANSACTION -->
      <div
        *ngIf="
          dialogData &&
          (_authService.loggedInUserValue.role == 1 ||
            dialogData.dateAdded == dateNow)
        "
        class="col py-2"
      >
        <button
          *ngIf="dialogData"
          class="btn btn-block btn-outline-secondary w-100 h-100 py-2 d-flex justify-content-center align-items-center"
          [disabled]="!inv.dirty || !inv.valid"
          (click)="addNewIstehsal(inv.value, $event, true)"
          style="
            padding-block: 10px;
            border: 1px solid gainsboro;
            font-size: 16px;
          "
        >
          <mat-icon
            style="
              font-size: 21px;
              line-height: 23px;
              vertical-align: inherit;
              padding-right: 10px;
            "
            >autorenew</mat-icon
          >
          <span> Qaiməni yenilə</span>

          <div *ngIf="dataLoading" class="lds-dual-ring"></div>
        </button>
      </div>

      <!-- ADD NEW TRANSACTION -->
      <div *ngIf="dialogData" class="col py-2">
        <button
          *ngIf="dialogData"
          class="btn btn-block btn-outline-secondary w-100 h-100 py-2 d-flex justify-content-center align-items-center"
          (click)="addNewIstehsal(inv.value, $event)"
          [disabled]="!inv.valid || !inv.dirty"
          style="
            padding-block: 10px;
            border: 1px solid gainsboro;
            font-size: 16px;
          "
        >
          <mat-icon
            style="
              font-size: 21px;
              vertical-align: inherit;
              padding-right: 10px;
            "
            >loupe</mat-icon
          >
          <span> Yeni qaimə əlavə et</span>

          <div *ngIf="dataLoading" class="lds-dual-ring"></div>
        </button>
      </div>
    </div>
  </div>
</div>
