<app-navbar
  [headerText]="'İstehsal'"
  [buttons]="navButtons"
  [dataLoading]="dataLoading()"
></app-navbar>

<!-- CARDS -->
<div class="container-fluid px-3 mt-6 noHammerSwipe">
  <div
    [ngClass]="{ 'd-none': !showSummary }"
    class="widgetsContainer mb-2 pt-2"
    #widgetsContent
    (wheel)="onWheel($event)"
    style="
      display: flex;
      gap: 1em;
      overflow-x: scroll;
      margin-top: -0.5rem !important;
    "
  >
    <div
      #card
      (click)="_productService.ProductDetailsDialog(sum.productId)"
      class="cards cardHover"
      *ngFor="let sum of groupSumDataFiltered"
    >
      <div class="d-flex justify-content-between">
        <h4>{{ sum.name }}</h4>
      </div>

      <table style="text-align: center">
        <tr class="thead">
          <th colspan="2">İstehsal</th>
          <th>Satılan</th>
          <th>Qalan</th>
        </tr>
        <tr class="summaries">
          <td>
            <span class="productionQuantity">{{
              sum.totalLength | number : "1.0-0" | commaToSpace
            }}</span
            ><span class="cardSummaryTitle">{{
              sum.unit ? sum.unit : "ədəd"
            }}</span>
          </td>
          <td>
            <span class="productionQuantity">{{
              sum.totalWeight | number : "1.0-0" | commaToSpace
            }}</span
            ><span class="cardSummaryTitle">kg</span>
          </td>
          <td>
            <span class="productionQuantity">{{
              sum.sells | number : "1.0-0" | commaToSpace
            }}</span
            ><span class="cardSummaryTitle">{{
              sum.unit ? sum.unit : "ədəd"
            }}</span>
          </td>
          <td>
            <span class="productionQuantity">{{
              sum.totalLength - sum.sells | number : "1.0-0" | commaToSpace
            }}</span
            ><span class="cardSummaryTitle">{{
              sum.unit ? sum.unit : "ədəd"
            }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!-- SEARCH -->
<div class="container-fluid px-3">
  <div class="row">
    <div id="targetTable" class="col-md-6">
      <div class="input-group" style="position: relative">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          type="search"
          name="search"
          class="form-control cardHover"
          (keyup)="applyFilter(searchInput.value)"
          (search)="applyFilter(searchInput.value)"
          placeholder="Axtar"
          #searchInput
        />
        <div class="input-group-append d-flex">
          <div
            (click)="showColumnSettings = !showColumnSettings"
            matTooltip="Göstərilən sütunları seç"
            matTooltipClass="line-broken-tooltip"
            matTooltipPosition="right"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              mat-stroked-button
              style="
                font-size: 1.5rem;
                line-height: 1.2;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              view_column
            </mat-icon>
          </div>
          <div
            *ngIf="showSummary"
            (click)="toggleGraphs()"
            matTooltip="Xülasələri {{ xulaseTooltip }}"
            matTooltipClass="line-broken-tooltip"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              style="
                font-size: 1.7rem;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              unfold_more
            </mat-icon>
          </div>
          <div
            (click)="
              showSummary = !showSummary;
              _dataService.setLS('showProductionSummary', showSummary)
            "
            matTooltip="Xülasələri {{ showSummary ? 'gizlə' : 'göstər' }}"
            matTooltipClass="line-broken-tooltip"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              mat-stroked-button
              style="
                font-size: 1.5rem;
                line-height: 1.2;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              toc
            </mat-icon>
          </div>
        </div>

        <!-- FILTERS -->
        <ng-container *ngIf="showColumnSettings">
          <div class="mt-2 tableColumnsSelectorContainer">
            <div
              class="px-3 py-1"
              style="
                background-color: var(--primary-background);
                color: white;
                display: flex;
                font-family: 'Mulish';
                font-weight: 600;
                font-size: 18px;
                grid-column: span 2;
              "
            >
              <span style="padding-left: 23px">Göstərilən sütünlar</span>
            </div>
            @for (item of columnDefs; track item.field) {
            <div
              *ngIf="item.field != 'tId'"
              class="d-flex gap-2 align-items-center cardHover px-3 py-2"
              (click)="item.hide = !item.hide; updateCols()"
              style="cursor: pointer; white-space: nowrap; padding: 5px 15px"
            >
              <div class="box">
                <mat-icon
                  style="color: var(--primary-background)"
                  *ngIf="!item.hide"
                  >check</mat-icon
                >
              </div>
              {{ item.headerName }}
            </div>

            }
          </div>
        </ng-container>
      </div>
      <div
        class="overlay"
        style="
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1000;
          transition-duration: 0.2s;
          background-color: rgb(0 0 0 / 15%);
        "
        *ngIf="showColumnSettings || showUsedMaterials"
        (click)="showColumnSettings = false; showUsedMaterials = false"
      ></div>
    </div>
    <div class="col-md-6 mt-smm-6" style="position: relative">
      <div
        class="summaryContainer cardHover"
        (click)="
          getUsedMaterialsForProduction();
          showUsedMaterials = !showUsedMaterials
        "
        style="position: relative; cursor: pointer"
      >
        <span class="summaryElementContainer"
          ><span class="summaryType">Toplam</span>
          <span
            >{{ totalKG | number : "1.0-0" | commaToSpace }}
            <span class="manat">kg</span></span
          ></span
        >
      </div>
      <div
        *ngIf="showUsedMaterials"
        class="usedMaterials"
        style="border-radius: 4px; overflow: hidden; z-index: 1001"
      >
        <div
          class="px-3 py-1"
          style="
            background-color: var(--primary-background);
            color: white;
            display: flex;
            font-family: 'Mulish';
            font-weight: 600;
            font-size: 18px;
            grid-column: span 2;
          "
        >
          <span style="padding-inline: 23px"
            >Dövr Ərzində İstifadə Olunan Materiallar</span
          >
        </div>
        <div style="margin: 0">
          <div
            style="
              height: 300px;
              overflow-y: scroll;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
            "
          >
            @if(usedMaterials.length>0){
            <div class="pl-3 header">Məhsul</div>
            <div class="pl-3 header">Qiymət</div>
            <div class="pl-3 header">Toplam KG</div>
            <div class="pl-3 header">Toplam AZN</div>
            } @else {
            <h4
              style="
                grid-column: span 4;
                place-content: center;
                text-align: center;
                font-family: Mulish;
              "
            >
              Yüklənir..
            </h4>
            }

            <ng-container *ngFor="let item of usedMaterials">
              <div
                class="flex-1 tableContent"
                (click)="_stockService.materialDialog(item.materialId)"
              >
                {{ item.materialName }}
              </div>
              <div class="flex-1 tableContent"  (click)="_stockService.materialDialog(item.materialId)">
                {{ item.unitPrice | number : "1.2-2" | commaToSpace }}
                <span class="manat">₼</span>
              </div>
              <div class="flex-1 tableContent"  (click)="_stockService.materialDialog(item.materialId)">
                {{ -item.sum | number : "1.0-0" | commaToSpace }} kg
              </div>
              <div class="flex-1 tableContent"  (click)="_stockService.materialDialog(item.materialId)">
                {{ -item.totalPrice | number : "1.0-0" | commaToSpace }}
                <span class="manat">₼</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TABLE -->
<div class="container-fluid px-3 mt-3">
  <ag-grid-angular
    style="width: 100%; border-radius: 6px; overflow: hidden"
    [ngStyle]="
      showSummary
        ? { height: 'calc(100vh - 280px)' }
        : { height: 'calc(100vh - 134px)' }
    "
    class="ag-theme-alpine cardHover"
    [rowData]="agGridData$ | async"
    [columnDefs]="columnDefs"
    [localeText]="_dataService.agGridLocale"
    [animateRows]="false"
    (gridReady)="onGridReady($event)"
    [cacheQuickFilter]="true"
    (rowClicked)="openAddIstehsalDialog($event.data, $event)"
  >
  </ag-grid-angular>
</div>
