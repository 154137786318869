<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    <span style="text-wrap: nowrap">
      {{ dialogData?.type == "medaxil" ? "Kassa Mədaxil" : "Kassa Məxaric" }}
    </span>
    <div class="clipText ml-auto" *ngIf="dialogData.data && !copyMode">
      <span class="transactionNo"
        ><span class="pcOnly" style="margin-right: -5px">Qaimə</span
        >{{ transactionNo }}</span
      >
    </div>
  </div>
  <div class="ui-titlecontrols" *ngIf="dialogData.data && !copyMode">
    <button class="ui-btn operations" [cdkMenuTriggerFor]="context_menu">
      <i
        class="material-symbols-outlined"
        style="color: white; font-weight: 500"
        >more_horiz</i
      >
    </button>
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<ng-template #context_menu>
  <div class="context-menu filterSettings" cdkMenu>
    <button
      (click)="editMode = true; copyMode = false"
      [disabled]="editMode && !copyMode"
      class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      style="
        box-shadow: unset;
        border-radius: 0px;
        border-bottom: 1px solid #ececec;
      "
      cdkMenuItem
    >
      <span class="material-symbols-outlined"> edit </span>
      <span class="w-100">Düzəliş et</span>
    </button>
    <button
      (click)="editMode = true; copyMode = true"
      [disabled]="editMode && copyMode"
      class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      style="
        box-shadow: unset;
        border-radius: 0px;
        border-bottom: 1px solid #ececec;
      "
      cdkMenuItem
    >
      <span class="material-symbols-outlined"> content_copy </span>
      <span class="w-100">Kopyala</span>
    </button>
    <button
      class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      (click)="deleteEntry()"
      style="box-shadow: unset; border-radius: 0px"
      cdkMenuItem
    >
      <span class="material-symbols-outlined"> delete </span>
      <span class="w-100">Sil</span>
    </button>
  </div>
</ng-template>

<div appFocusNextOnEnter [cdkContextMenuTriggerFor]="context_menu">
  <form #inv="ngForm">
    <div
      class="container"
      [ngClass]="{
        'pb-2':
          dialogData.data ||
          !(dialogData.data && dialogData.data.dateAdded == dateNow)
      }"
    >
      <div class="row px-3 py-3" style="background-color: #f6f6f6">
        <div class="col-md-8 col-sm-12">
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">receipt_long</i>
            </div>
            <ng-select
              [items]="bankGroupsByType"
              bindLabel="name"
              bindValue="id"
              [loading]="dataLoading"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="selectedBankGroupId"
              name="bankGroupId"
              class="form-control"
              [searchFn]="_dataService.customSearchFn"
              cdkFocusInitial
              required
              [disabled]="dialogData.data && !editMode"
              *ngIf="!dialogData.data?.trnRefNo"
            >
            </ng-select>
            <!-- ONLINE API UCUN-->
            <input
              type="text"
              placeholder="Köçürmə"
              class="form-control"
              autocomplete="off"
              value="Köçürmə"
              *ngIf="dialogData.data?.trnRefNo"
              disabled
            />
            <!-- END ONLINE API-->
            <div
              class="input-group-text inputGroupButtons"
              *ngIf="selectedBankGroupId && !dialogData?.data"
              style="cursor: pointer"
            >
              <a
                matTooltip="Ödəniş Tipi Haqqında"
                matTooltipClass="line-broken-tooltip"
                (click)="newBankGroupDialog(selectedBankGroupId)"
              >
                <mat-icon class="material-icons">info_outline</mat-icon>
              </a>
            </div>
            <div
              *ngIf="!dialogData?.data"
              class="input-group-text inputGroupButtons cardHover"
              style="cursor: pointer"
            >
              <a
                matTooltip="Yeni Ödəniş Tipi"
                matTooltipClass="line-broken-tooltip"
                (click)="newBankGroupDialog()"
              >
                <mat-icon class="material-icons">add</mat-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-smm-5">
          <div class="input-group">
            <div class="input-group-prepend" style="cursor: pointer">
              <div
                (click)="myDatepicker.open()"
                class="input-group-text cardHover"
                style="
                  padding: 5px 7px 2px;
                  border-bottom-right-radius: 0px;
                  border-top-right-radius: 0px;
                "
              >
                <i class="material-icons">calendar_month</i>
              </div>
            </div>
            <!-- <input [(ngModel)]="date.value" [value]="date.value" [matDatepicker]="myDatepicker"
                          class="form-control" id="date" ngModel name="date" (click)="myDatepicker.open()"> -->
            <input
              #dateInput
              [(ngModel)]="date.value"
              [matDatepicker]="myDatepicker"
              class="form-control"
              id="date"
              tabindex="-1"
              style="
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              "
              name="date"
              placeholder="{{
                _dataService.endDate.value
                  ? _dataService.endDate.value.format('DD/MM/YYYY') ?? ''
                  : _dataService.startDate.value.format('DD/MM/YYYY') ?? ''
              }}"
              (click)="dateInput.value = ''"
              (ngModelChange)="_dataService.formatDateValue(dateInput)"
              [disabled]="dialogData.data && !editMode"
            />
            <mat-datepicker #myDatepicker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="row px-3 py-1 mt-3">
        <div class="col-12">
          <label class="form-label" for="bankGroup">Kassa</label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">account_balance_wallet</i>
            </div>
            <ng-select
              class="form-control"
              [items]="_dataService.cashBox()"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="selectedCashboxId"
              tabindex="-1"
              [searchable]="false"
              [clearable]="false"
              name="cashboxId"
              [disabled]="dialogData.data && !editMode"
              *ngIf="!dialogData.data?.trnRefNo"
            >
            </ng-select>
            <input
              type="text"
              placeholder="Kassa"
              class="form-control"
              autocomplete="off"
              value="Kapital Bank"
              *ngIf="dialogData.data?.trnRefNo"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="row px-3 pt-2">
        <div class="col-md-8">
          <label class="form-label" for="name">Tərəf Müqabil</label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">person</i>
            </div>
            <ng-select
              [items]="_dataService.customers()"
              bindLabel="name"
              bindValue="id"
              [loading]="dataLoading"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="_customersService.selectedCustomerId"
              (change)="getUserBalance(_customersService.selectedCustomerId)"
              placeholder="Tərəf-Müqabil"
              name="customerId"
              class="form-control"
              [searchFn]="_dataService.customSearchFn"
              [disabled]="dialogData.data && !editMode"
              *ngIf="!dialogData.data?.trnRefNo"
              required
            >
            </ng-select>
            <input
              type="text"
              placeholder="Tərəf-Müqabil"
              class="form-control"
              autocomplete="off"
              value="{{ dialogData.data?.customerName }}"
              *ngIf="dialogData.data?.trnRefNo"
              disabled
            />
            <div
              *ngIf="_customersService.selectedCustomerId"
              matTooltip="Tərəf-Müqabil məlumatları"
              matTooltipClass="line-broken-tooltip roundedTooltip"
              matTooltipPosition="right"
              class="input-group-text inputGroupButtons cardHover"
              style="cursor: pointer"
            >
              <a
                (click)="
                  _customersService.customerDialog({
                    customerId: _customersService.selectedCustomerId
                  })
                "
              >
                <i class="material-icons">info_outline</i>
              </a>
            </div>
            <div
              *ngIf="!dialogData?.data"
              class="input-group-text inputGroupButtons cardHover"
              style="cursor: pointer"
            >
              <a
                matTooltip="Yeni Tərəf Müqabil"
                matTooltipClass="line-broken-tooltip roundedTooltip"
                (click)="customerDialog()"
              >
                <mat-icon class="material-icons">add</mat-icon>
              </a>
            </div>
          </div>
          <div
            *ngIf="customerBalance && _customersService.selectedCustomerId"
            style="
              font-size: 13px;
              margin-left: 50px;
              position: absolute;
              margin-top: -2px;
              font-family: 'Mulish';
            "
            [style.color]="
              customerBalance > 0
                ? '#d81c1c'
                : customerBalance < 0
                ? '#2c4c66'
                : null
            "
          >
            {{
              (customerBalance >= 0 ? customerBalance : -customerBalance) || ""
            }}AZN
            {{
              customerBalance < 0
                ? "Tərəf-Müqabilə borclusunuz"
                : customerBalance > 0
                ? "Tərəf-Müqabil sizə borcludur"
                : ""
            }}
          </div>
        </div>

        <div class="col-md-4 mt-smm-5">
          <label class="form-label" for="name">Məbləğ</label>
          <div class="input-group">
            <div class="input-group-text">
              <span class="manat" style="font-weight: 600">₼</span>
            </div>
            <input
              type="text"
              [(ngModel)]="bankAmount"
              (ngModelChange)="
                bankAmount = _dataService.fixInputValue(bankAmount)
              "
              (keypress)="_dataService.getNumericsOnly($event)"
              placeholder="Məbləğ"
              class="form-control"
              autocomplete="off"
              name="price"
              required
              [disabled]="dialogData.data && !editMode"
              (keydown.enter)="
                bankAmount = _dataService.calculateInput(bankAmount)
              "
              (focusout)="bankAmount = _dataService.calculateInput(bankAmount)"
            />
          </div>
        </div>
      </div>
      <div class="row px-3 py-3">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-text">
              <mat-icon>info_outline</mat-icon>
            </div>
            <textarea
              placeholder="Məlumat"
              class="form-control"
              id="details"
              rows="2"
              name="details"
              [(ngModel)]="details"
              [disabled]="dialogData.data && !editMode"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div
        class="row"
        style="
          background-color: #f6f6f6;
          border-top: 1px solid #ececec;
          padding: 0.75rem 1.25rem;
        "
      >
        <div class="d-flex">
          <button
            #addButton
            class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex justify-content-center align-items-center"
            id="addButton"
            [disabled]="!inv.valid"
            (click)="addBankOrder(inv.value, addButton)"
            *ngIf="!dialogData.data || (dialogData.data && editMode)"
          >
            {{ dialogData.data?.tId && !copyMode ? "Yenilə" : "Əlavə et" }}
          </button>
          @if(user && !editMode) {
          <span
            class="userName"
            style="
              margin: 0px 0px;
              color: #606060;
              position: absolute;
              right: 25px;
              bottom: 3px;
            "
            ><span class="material-symbols-outlined"> person </span
            >{{ user }}</span
          >
          } @if(refersTo && !editMode) {
          <span
            class="userName"
            (click)="getUneditedVer()"
            style="
              margin: 0px 0px;
              color: #606060;
              position: absolute;
              left: 25px;
              bottom: 3px;
              cursor: pointer;
            "
            >Redaktə
            {{
              ": " +
                (dialogData?.data?.dateAdded ??
                  dialogData?.data?.transaction.dateAdded ??
                  "")
            }}</span
          >
          }
        </div>
      </div>
    </div>
  </form>
</div>
