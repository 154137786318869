<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    {{ dialogData.name ? "Məhsul" : "Yeni Məhsul" }}
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<form #inv="ngForm">
  <div class="container">
    <div class="row px-3 py-3" style="background-color: #f6f6f6">
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-text">
            <i class="material-icons">book</i>
          </div>
          <input
            [(ngModel)]="dialogData.name"
            type="text"
            placeholder="Adı"
            class="form-control"
            autocomplete="off"
            name="name"
            required
          />
        </div>
      </div>

      <div class="col-3">
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <mat-icon>subtitles</mat-icon>
          </div>
          <input
            [(ngModel)]="dialogData.code"
            type="text"
            placeholder="Kodu"
            class="form-control"
            autocomplete="off"
            name="code"
          />
        </div>
      </div>

      <div class="col-3">
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <i class="material-icons">category</i>
          </div>
          <input
            [(ngModel)]="dialogData.category"
            type="text"
            placeholder="Kateqoriya"
            class="form-control"
            autocomplete="off"
            name="category"
          />
        </div>
      </div>
    </div>
    <mat-dialog-content>
      @if(!showOperations) {
      <div class="row px-3 mt-1">
        <div class="col">
          <label class="form-label" for="unit">Vahid</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">token</i>
            </div>
            <ng-select
              [items]="_dataService.units"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="dialogData.unit"
              name="unit"
              class="form-control"
              placeholder="Unit"
            >
            </ng-select>
          </div>
        </div>

        <div class="col">
          <label class="form-label" for="weight"
            >Çəkisi ({{ dialogData.unit == "kg" ? "" : "kg/"
            }}{{
              (dialogData.unit ? dialogData.unit : "ədəd") | slice : 0 : 2
            }})</label
          >
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span style="font-weight: 600; font-family: Avant">kg</span>
            </div>
            <input
              [(ngModel)]="dialogData.weight"
              type="number"
              placeholder="Çəkisi"
              class="form-control"
              autocomplete="off"
              name="weight"
            />
          </div>
        </div>

        <div
          *ngIf="dialogData.unit == 'm2' || selectedUzlemeGroupId"
          class="col"
        >
          <label class="form-label" for="width">En</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span style="font-weight: 600; font-family: Avant">m</span>
            </div>
            <input
              [(ngModel)]="dialogData.width"
              type="number"
              placeholder="En"
              class="form-control"
              autocomplete="off"
              name="width"
            />
          </div>
        </div>

        <div class="col">
          <label class="form-label" for="length">Uzunluq</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span style="font-weight: 600; font-family: Avant">m</span>
            </div>
            <input
              [(ngModel)]="dialogData.length"
              type="number"
              placeholder="Uzunluq"
              class="form-control"
              autocomplete="off"
              name="length"
            />
          </div>
        </div>
      </div>

      <div class="row px-3 mt-1">
        <div class="col">
          <label class="form-label" for="weight">Formul</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">science</i>
            </div>
            <ng-select
              [items]="formulaGroups"
              bindLabel="name"
              bindValue="id"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              appendTo="body"
              [(ngModel)]="selectedFormulaGroupId"
              (change)="
                formulaChanged = true;
                dialogData.formulaCost = getFormulaCost(selectedFormulaGroupId)
              "
              name="formulaGroupId"
              class="form-control"
              placeholder="Formul"
              [searchFn]="_dataService.customSearchFn"
            >
            </ng-select>
            <div
              class="input-group-text inputGroupButtons cardHover"
              *ngIf="inv.value.formulaGroupId"
              style="cursor: pointer"
            >
              <a
                matTooltip="Formul haqqında"
                matTooltipClass="line-broken-tooltip"
                (click)="newFormulaDialog(selectedFormulaGroupId)"
              >
                <mat-icon class="material-icons">info_outlined</mat-icon>
              </a>
            </div>

            <div
              class="input-group-text inputGroupButtons cardHover"
              style="cursor: pointer"
            >
              <a
                matTooltip="Yeni Formul yarat"
                matTooltipClass="line-broken-tooltip"
                (click)="newFormulaDialog()"
              >
                <mat-icon class="material-icons">add</mat-icon>
              </a>
            </div>
          </div>
        </div>

        <div class="col-4" [ngClass]="{ 'col-6': selectedUzlemeGroupId }">
          <label class="form-label" for="weight">Üzləmə</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <i class="material-icons">blinds_closed</i>
            </div>
            <ng-select
              [items]="uzlemeGroups"
              bindLabel="name"
              bindValue="id"
              loadingText="Yüklənir .."
              notFoundText="Tapılmadı"
              [(ngModel)]="selectedUzlemeGroupId"
              (change)="
                formulaChanged = true;
                dialogData.uzlemeCost = getFormulaCost(selectedUzlemeGroupId)
              "
              name="uzlemeGroupId"
              class="form-control"
              placeholder="Formul"
            >
            </ng-select>
            <div
              class="input-group-text inputGroupButtons"
              *ngIf="selectedUzlemeGroupId"
              style="cursor: pointer"
            >
              <a
                matTooltip="Formul haqqında"
                matTooltipClass="line-broken-tooltip"
                (click)="newFormulaDialog(selectedUzlemeGroupId)"
              >
                <mat-icon class="material-icons">info_outlined</mat-icon>
              </a>
            </div>

            <div
              class="input-group-text inputGroupButtons cardHover"
              style="cursor: pointer"
            >
              <a
                matTooltip="Yeni Formul yarat"
                matTooltipClass="line-broken-tooltip"
                (click)="newFormulaDialog(null, 'uzleme')"
              >
                <mat-icon class="material-icons">add</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row px-3 mt-1">
        <div class="col">
          <label class="form-label" for="name">Formul Maliyyəti</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span class="manat fw-500">₼</span>
            </div>
            <input
              [ngModel]="dialogData.formulaCost"
              type="number"
              placeholder="Maliyyət"
              class="form-control"
              autocomplete="off"
              name="formulMaliyyet"
              readonly
            />
          </div>
        </div>

        <div *ngIf="selectedUzlemeGroupId" class="col">
          <label class="form-label" for="name">Üzləmə Maliyyəti</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span class="manat fw-500">₼</span>
            </div>
            <input
              [ngModel]="dialogData.uzlemeCost * (dialogData.width ?? 1)"
              type="number"
              placeholder="Maliyyət"
              class="form-control"
              autocomplete="off"
              name="uzlemeMaliyyet"
              readonly
            />
          </div>
        </div>

        <div class="col">
          <label class="form-label" for="name">Digər Maliyyətlər</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span class="manat fw-500">₼</span>
            </div>
            <input
              [(ngModel)]="dialogData.uzleme"
              type="number"
              placeholder="Maliyyət"
              class="form-control"
              autocomplete="off"
              name="uzleme"
            />
          </div>
        </div>

        <div *ngIf="dialogData.weight && selectedFormulaGroupId" class="col">
          <label class="form-label" for="name">Toplam Maliyyət</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span class="manat fw-500">₼</span>
            </div>
            <input
              value="{{
                dialogData.width
                  ? ((getFormulaCost(selectedFormulaGroupId) *
                      dialogData.weight) /
                      dialogData.width +
                      dialogData.uzleme | number)
                  : (getFormulaCost(selectedFormulaGroupId) *
                      dialogData.weight +
                      dialogData.uzleme | number)
              }}"
              type="text"
              placeholder="Maliyyət"
              class="form-control"
              autocomplete="off"
              name="maliyyet"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="row px-3 pb-3 mt-1">
        <div class="col-6">
          <label class="form-label" for="name">Satış Qiyməti</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span class="manat fw-500">₼</span>
            </div>
            <input
              [(ngModel)]="dialogData.sellPrice"
              type="number"
              placeholder="Satış Qiyməti"
              class="form-control"
              autocomplete="off"
              name="sellPrice"
            />
          </div>
        </div>

        <div
          *ngIf="
            dialogData.weight && selectedFormulaGroupId && dialogData.sellPrice
          "
          class="col-6"
        >
          <label class="form-label" for="name">Qazanc</label>
          <div class="input-group">
            <div class="input-group-text pcOnly">
              <span class="manat fw-500">₼</span>
            </div>
            <input
              value="{{
                dialogData.width
                  ? (dialogData.sellPrice -
                      ((getFormulaCost(selectedFormulaGroupId) *
                        dialogData.weight) /
                        dialogData.width +
                        dialogData.uzleme) | number)
                  : (dialogData.sellPrice -
                      (getFormulaCost(selectedFormulaGroupId) *
                        dialogData.weight +
                        dialogData.uzleme) | number)
              }}"
              type="text"
              placeholder="Qazanc"
              class="form-control"
              autocomplete="off"
              name="maliyyet"
              disabled
            />
          </div>
        </div>
      </div>
      }

      <div
        *ngIf="showOperations"
        class="mat-elevation-z1 mt-3 px-0"
        style="border-radius: 6px"
      >
        <ag-table
          [colDefs]="columnDefs"
          [tableData]="agGridData$"
          [tableClass]="'ag-theme-alpine'"
          [tableHeight]="'287.5px'"
          [defaultSortModel]="[{ colId: 'date', sort: 'asc', sortIndex: 0 }]"
          (rowClicked)="
            _dataService.transactionDialog($event.data.transactionId)
          "
        ></ag-table>
      </div>
    </mat-dialog-content>
  </div>
</form>

<div
  class="py-2"
  style="
    background-color: #f6f6f6;
    border-top: 1px solid #ececec;
    padding-inline: 8px;
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div *ngIf="dialogData.id" class="col-6">
        <button
          #btnDetails
          class="btn btn-outline-secondary w-100 d-flex justify-content-center align-items-center"
          (click)="getTransactionsByProduct()"
        >
          <span style="font-size: 16px; line-height: 1">{{
            !showOperations ? "Əməliyyatlar" : "Bağla"
          }}</span>
          <mat-icon
            *ngIf="!dataLoading"
            style="font-size: 21px; vertical-align: inherit"
          >
            {{
              !showOperations
                ? "keyboard_double_arrow_up"
                : "keyboard_double_arrow_down"
            }}
          </mat-icon>
          <div *ngIf="dataLoading" class="lds-dual-ring"></div>
        </button>
      </div>
      @if(!showOperations) {
      <div class="col-6">
        <button
          class="btn btn-outline-secondary w-100"
          [disabled]="!inv.valid || !inv.dirty"
          style="
            padding-block: 10px;
            border: 1px solid gainsboro;
            font-size: 16px;
          "
          (click)="addProduct(inv.value, $event)"
        >
          {{ dialogData.id ? "Yenilə" : "Əlavə et" }}
        </button>
      </div>
      }
    </div>
  </div>
</div>
