import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren,
  computed,
  inject,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { forkJoin, lastValueFrom, of } from "rxjs";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { map, switchMap, tap } from "rxjs/operators";
import { NgForm, UntypedFormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NewProductDialogComponent } from "src/app/dialogs/product-dialog/product-dialog.component";
import { DataService } from "src/app/data.service";
import { NgSelectComponent } from "@ng-select/ng-select";

import * as _moment from "moment";
const moment = _moment;
import "lodash";
declare var _: any;
import { db } from "src/app/db.service";
import { PusherService } from "src/app/pusher.service";
import { ProductsService } from "src/app/products/products.service";
import { CustomersService } from "src/app/customers/customers.service";
import { StockService } from "src/app/stock/stock.service";
import { AuthService } from "src/app/auth.service";

@Component({
  selector: "app-sells-dialog",
  templateUrl: "./sells-dialog.component.html",
  styleUrls: ["./sells-dialog.component.css"],
})
export class SellsDialogComponent implements OnInit {
  _customersService = inject(CustomersService);
  _stockService = inject(StockService);
  _authService = inject(AuthService);
  selectedProductId: number;
  date;
  sum: number;
  priceReceived: number = 0;
  residue: number = 0;
  details: string;
  productsToSell: {
    id;
    quantity: number;
    length?: number;
    sellPrice: number;
    sellPricePlaceholder?: string;
    transactionId?: number;
    unit?: string;
    sum?;
    type?: string;
    uniqueId?: string;
    unedited?: boolean;
  }[] = [
    { id: null, quantity: null, length: null, sellPrice: null, sum: null },
  ];
  dataLoading;
  btnDataLoading;
  customerBalance: number;
  tId: number;
  groupSumData = this._dataService.getLS("productionCards") || [];
  stockSumData = this._dataService.getLS("stocksSumData") || [];
  customers;
  stockGroups = computed(() => {
    return [
      { id: 0, name: "Məhsul Anbarı" },
      ...this._dataService.stockGroups(),
    ];
  });
  selectedStockGroupId = 0;

  deleteTransaction = of({});
  response;
  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  @ViewChildren("input") inputs: QueryList<NgSelectComponent>;
  @ViewChild("inv") form: NgForm;
  @ViewChild("addButton") btnAdd: ElementRef;
  products = [];
  transactionNo: string = "";
  editMode: boolean = false;
  copyMode: boolean = false;
  showContextMenu: boolean = false;
  user: string;
  refersTo;

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
    if (event.shiftKey) {
      event.preventDefault();
      const focusedElement = document.activeElement as HTMLElement;
      if (
        (event.key === "n" || event.key === "N") &&
        focusedElement.tagName.toLowerCase() !== "input"
      ) {
        this.nextProduct();
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<SellsDialogComponent>,
    private http: HttpClient,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public _dataService: DataService,
    private _pusher: PusherService,
    private _productsService: ProductsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    console.log(this.dialogData);
    this.products = [
      ...this._dataService.products().map((product) => ({
        ...product,
        type: "product",
        uniqueId: `product_${product.id}`,
      })),
      ...this._dataService.materials().map((material) => ({
        ...material,
        type: "material",
        uniqueId: `material_${material.id}`,
        category: "Materiallar",
        sellPrice: material.price,
      })),
    ];

    this.date =
      this._dataService.startDate?.value.format("YYYY-MM-DD") ==
      this._dataService.endDate?.value.format("YYYY-MM-DD")
        ? new UntypedFormControl(
            moment(this._dataService.endDate?.value.format("YYYY-MM-DD"))
          )
        : new UntypedFormControl(moment());

    this.customers = computed(() => {
      return this._dataService.customers().filter(function (el) {
        return el.customerGroupId != 3;
      });
    });

    if (this.dialogData?.transactionId) {
      this.dataLoading = true;
      this.transactionNo = this.dialogData.transactionId;
      this._customersService.selectedCustomerId = this.dialogData.customerId;
      this.date = new UntypedFormControl(moment(this.dialogData.date));
      this.sum = !this.dialogData.sum
        ? null
        : this.dialogData.type == "Return"
        ? -parseFloat(this.dialogData.sum.toFixed(2))
        : parseFloat(this.dialogData.sum.toFixed(2));
      this.productsToSell = [];

      this.editMode = this.dialogData.editMode
        ? this.dialogData.editMode
        : false;
      this.copyMode = this.dialogData.copyMode
        ? this.dialogData.copyMode
        : false;

      if (this.dialogData.userId) {
        let userName = this._dataService
          .users()
          .find((u) => u.id == this.dialogData.userId);
        this.user = userName ? userName.fullname : null;
      }

      this.refersTo = this.dialogData.refersTo ?? null;

      this.getSells();
    } else {
      this._customersService.selectedCustomerId = null;
    }

    this.getMaterialSums();
  }

  addSell(data, btn) {
    this.btnDataLoading = true;
    btn.firstChild.innerText = "Gözləyin";
    btn.disabled = true;

    let products = Object.values(data.products) as {
      id: string;
      productId?: string;
      materialId?: string;
      quantity: number;
      price: number;
      type: string;
    }[];

    products.forEach((p) => {
      if (p.id[0] == "p") {
        p.productId = p.id.split("_")[1];
      }
      if (p.id[0] == "m") {
        p.materialId = p.id.split("_")[1];
      }
    });

    let postSells,
      postBank,
      postTransaction,
      putTransaction,
      putProductPriceUpdates,
      bankEntry;
    let sells = [];
    let today = new UntypedFormControl(moment());
    let result;
    const handleError = () => {
      this.btnDataLoading = false;
      btn.firstChild.innerText = "Əlavə et";
      btn.disabled = false;
      this.toastr.error("Yenidən cəhd edin", "Xəta!", { timeOut: 5000 });
    };

    var transaction: IApiResult = {
      type: "Sells",
      sum: this.sum,
      customerId: this._customersService.selectedCustomerId,
      date: this.date?.value.format("YYYY-MM-DD"),
      dateAdded: today?.value.format("YYYY-MM-DD HH:mm:ss"),
      details: data.details,
      userId: this._authService.loggedInUserValue.id ?? null,
      refersTo: this.dialogData?.transactionId ?? null,
    };

    if (this.dialogData?.type == "Return") {
      transaction.type = "Return";
    }

    putTransaction =
      this.dialogData.transactionId && this.copyMode == false
        ? this.http.put(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              this.dialogData.transactionId,
            { status: 2 }
          )
        : of(null);

    postTransaction = this.http.post(
      this._dataService.dbBase + "api.php/records/transactions",
      transaction
    );

    forkJoin(postTransaction, putTransaction)
      .pipe(
        map((transactionId) => {
          this.tId = transactionId[0];
          transaction.id = transactionId[0];

          this.deleteTransaction = this.http.delete(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              this.tId
          );

          console.log(this.selectedStockGroupId);
          transaction.stockGroupId =
            this.selectedStockGroupId == 0 ? null : this.selectedStockGroupId;

          let productIds = [];
          let productPriceUpdates: {}[] = [];
          let sellType: string = this.dialogData?.type;
          products.forEach((f) => {
            console.log(f);
            sells.push({
              productId: parseInt(f.productId) || null,
              materialId: parseInt(f.materialId) || null,
              transactionId: transactionId[0],
              customerId: transaction.customerId,
              quantity: sellType == "Sells" ? f.quantity : -f.quantity,
              price: f.price,
              stockGroupId: transaction.stockGroupId,
            });

            console.log(sells);

            productIds.push(f.productId);

            productPriceUpdates.push({
              sellPrice: f.price,
            });
          });

          postSells = this.http.post(
            this._dataService.dbBase + "api.php/records/sells/",
            sells
          );

          // BANK
          if (this.priceReceived != 0) {
            bankEntry = {
              transactionId: transactionId[0],
              income: parseFloat(this.priceReceived.toFixed(2)),
              bankGroupId: 0,
              cashboxId: this._dataService.cashBox()[0].id,
              customerId: transaction.customerId,
              outgoings: null,
              id: null,
            };
            console.log(bankEntry);
            postBank = this.http.post(
              this._dataService.dbBase + "api.php/records/bank/",
              bankEntry
            ); // FIX THIS!!
          } else {
            postBank = of(null);
          }

          // PRODUCT PRICE UPDATES
          // if (productIds.length) {
          //   putProductPriceUpdates = this.http.put(
          //     this._dataService.dbBase +
          //       "api.php/records/products/" +
          //       productIds,
          //     productPriceUpdates
          //   );
          // } else {
          //   putProductPriceUpdates = of(null);
          // }

          putProductPriceUpdates = of(null);

          // FOR ADDING DATA TO tableData without update over internet
          let selectedCustomer = this._dataService
            .customers()
            .find(
              (customer) =>
                customer.id === this._customersService.selectedCustomerId
            );
          let selectedCustomerName = selectedCustomer
            ? selectedCustomer.name
            : null;

          result = {
            ...transaction,
            customerName: selectedCustomerName,
            transactionId: transactionId[0],
          };

          result.remove =
            this.dialogData.transactionId && this.copyMode == false
              ? this.dialogData.transactionId
              : null;
        }),
        switchMap(() =>
          forkJoin({
            sells: postSells,
            bank: postBank,
            products: putProductPriceUpdates,
          })
        ),
        tap((res) => {
          this.response = res;
        })
      )
      .subscribe({
        error: (e) => {
          forkJoin({
            deleteTransaction: this.deleteTransaction,
            storeErrorInDatabase: this._dataService.storeErrorInDatabase(
              e,
              JSON.stringify(transaction),
              JSON.stringify(sells),
              JSON.stringify(data),
              "Sells"
            ),
          }).subscribe({
            complete: handleError,
            error: handleError,
          });
        },
        complete: () => {
          let dbSells = [];
          sells.forEach((el, i) => {
            dbSells.push({
              ...el,
              date: this.date?.value.format("YYYY-MM-DD"),
              id: this.response.sells[i],
              tId: transaction.id,
              transactionId: transaction,
            });
          });

          if (result.remove) {
            db.transactions.delete(result.remove);
            db.sells.where("tId").equals(result.remove).delete();
          }

          db.transactions.add(transaction, transaction.id);
          db.sells.bulkAdd(dbSells);

          let pushData = { transaction };
          this._pusher.postData(pushData).subscribe();

          this.dialogRef.close(result);
          this.toastr.success("", "Əlavə edildi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        },
      });
  }

  async getUneditedVer() {
    this.dataLoading = true;
    const r = await lastValueFrom(
      this.http.get<IApiResult>(
        `${this._dataService.dbBase}api.php/records/sells?join=transactions&filter=transactionId,eq,${this.refersTo}`
      )
    );

    console.log(r);

    r.records.forEach((el) => {
      this.productsToSell.push({
        id: el.productId
          ? `product_${el.productId}`
          : `material_${el.materialId}`,
        quantity:
          this.dialogData.type != "Return"
            ? parseFloat(el.quantity.toFixed(2))
            : -parseFloat(el.quantity.toFixed(2)),
        sellPrice: parseFloat(el.price.toFixed(3)),
        uniqueId: el.productId
          ? `product_${el.productId}`
          : el.materialId
          ? `material_${el.materialId}`
          : null,
        sum: el.quantity * el.price,
        transactionId: el.transactionId.id,
        unit: this.products.find((p) => p.id === el.productId)?.unit ?? null,
        unedited: true,
      });
      this.refersTo = el.transactionId?.refersTo ?? null;
    });
    this.copyMode = true; // to disable editing, bcos productsToSell is filled with old data
    this.dataLoading = false;
  }

  async getSells() {
    const result = await db.sells
      .where("tId")
      .equals(this.dialogData.transactionId)
      .toArray();

    if (result.length) {
      console.log(result);
      this.details = this.dialogData.details;
      result.forEach((el) => {
        this.selectedStockGroupId = el.stockGroupId || 0;
        this.productsToSell.push({
          id: el.productId
            ? `product_${el.productId}`
            : `material_${el.materialId}`,
          quantity:
            this.dialogData.type != "Return"
              ? parseFloat(el.quantity.toFixed(2))
              : -parseFloat(el.quantity.toFixed(2)),
          sellPrice: parseFloat(el.price.toFixed(3)),
          uniqueId: el.productId
            ? `product_${el.productId}`
            : el.materialId
            ? `material_${el.materialId}`
            : null,
          sum:
            this.dialogData.type != "Return"
              ? parseFloat((el.quantity * el.price).toFixed(2))
              : -parseFloat((el.quantity * el.price).toFixed(2)),
          transactionId: el.transactionId.id,
          unit: el.productId
            ? this.products.find(
                (p) => p.uniqueId === `product_${el.productId}`
              )?.unit
            : el.materialId
            ? this.products.find(
                (p) => p.uniqueId === `material_${el.materialId}`
              )?.unit
            : null,
        });
      });
    } else {
      const r = await lastValueFrom(
        this.http.get<IApiResult>(
          `${this._dataService.dbBase}api.php/records/sells?join=transactions&filter=transactionId,eq,${this.dialogData.transactionId}`
        )
      );

      this.details = this.dialogData.details;
      r.records.forEach((el) => {
        this.productsToSell.push({
          id: el.productId,
          quantity: el.quantity,
          sellPrice: el.price,
          sum: el.quantity * el.price,
          transactionId: el.transactionId.id,
          unit: this.products.find((p) => p.id === el.productId)?.unit ?? null,
        });
      });
    }

    this.dataLoading = false;
  }

  async getMaterialSums() {
    let stock = this.selectedStockGroupId == 0 ? 1 : this.selectedStockGroupId;
    this.groupSumData = await this._productsService.getProductionCards();
    this.stockSumData = await this._stockService.getStockCards(
      this._dataService.startDate?.value.format("YYYY-MM-DD"),
      this._dataService.endDate?.value.format("YYYY-MM-DD"),
      stock
    );
    this.groupSumData.forEach((material) => {
      if (
        this.products.find(
          (p) => p.id === material.productId && p.type == "product"
        )
      ) {
        this.products.find(
          (p) => p.id === material.productId && p.type == "product"
        ).totalQuantity = material.totalLength - material.sells;
      }
    });

    this.stockSumData.forEach((material) => {
      if (
        this.products.find(
          (p) => p.id === material.materialId && p.type == "material"
        )
      ) {
        this.products.find(
          (p) => p.id === material.materialId && p.type == "material"
        ).totalQuantity =
          material.totalQuantity + material.totalQuantityBeforeDate;
      }
    });
  }

  getProductDetails(idd, prod?) {
    const product = this.products.find((p) => p.uniqueId === idd);
    const sellProduct = this.productsToSell.find((p) => p.id === idd);
    const hasSellPrice = Boolean(product?.sellPrice);
    if (sellProduct.id) {
      sellProduct.sellPricePlaceholder = hasSellPrice
        ? product.sellPrice
        : null;
      sellProduct.unit = product?.unit ?? null;
      sellProduct.type = product.type;
    }
  }

  async getUserBalance(customerId: number) {
    if (customerId && typeof customerId === "number") {
      (
        await this._customersService.getCustomersBalanceById(customerId)
      ).subscribe((r) => {
        let customerBalaceObj = r.find((c) => c.customerId == customerId);
        this.customerBalance = customerBalaceObj.finalBalance;
      });
    }
  }

  nextProduct() {
    this.productsToSell.push({
      id: null,
      quantity: null,
      length: null,
      sellPrice: null,
    });
    this.changeDetectorRef.detectChanges();
    this.inputs.last.focus();
  }

  // DELETE FORMULA
  removeProduct(i, event) {
    if (event.key == "Enter") {
      return false;
    }
    this.productsToSell.splice(i, 1);
    // delete this.form.value.products[i];
    this.form.form.markAsDirty();
  }

  newProductDialog(productId?) {
    let dialogRef, data;
    if (productId) {
      data = this.products.find((x) => x.id == productId);
    }
    dialogRef = this.dialog.open(NewProductDialogComponent, {
      width: "950px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        //check if product already exists
        let existingProductIndex = this.products.findIndex(
          (product) => product.name === res.name
        );
        if (existingProductIndex !== -1) {
          this.products[existingProductIndex].name = res.name;
          this.products = [...this.products];
        } else {
          this.products = [...this.products, res];
        }

        localStorage.setItem("products", JSON.stringify(this.products));
      }
    });
  }

  calculateMaterialPrice(data, index, focusedInput?) {
    if (data.quantity && data.sum && focusedInput == "sum") {
      this.productsToSell[index].sellPrice = !isNaN(
        parseFloat((data.sum / data.quantity).toFixed(3))
      )
        ? parseFloat((data.sum / data.quantity).toFixed(3))
        : 0;
    } else if (data.quantity && data.sellPrice) {
      this.productsToSell[index].sum = !isNaN(
        parseFloat((data.quantity * data.sellPrice).toFixed(3))
      )
        ? parseFloat((data.quantity * data.sellPrice).toFixed(3))
        : 0;
    } else if (data.sum && data.quantity) {
      this.productsToSell[index].sellPrice = !isNaN(
        parseFloat((data.sum / data.quantity).toFixed(3))
      )
        ? parseFloat((data.sum / data.quantity).toFixed(3))
        : 0;
    }
    this.sum = this.productsToSell.reduce(function (total, o: any) {
      let value = o.useSum
        ? parseFloat(o.sum)
        : parseFloat(o.quantity) * parseFloat(o.sellPrice);
      return total + value;
    }, 0);

    this.sum = parseFloat(this.sum.toFixed(3));
    this.residue = this.sum - this.priceReceived;
  }
}
