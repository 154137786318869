import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  signal,
  effect,
  inject,
} from "@angular/core";
import { IProduct } from "../shared/models/product.interface";
import { ProductsService } from "../products/products.service";
import { MatDialog } from "@angular/material/dialog";
import { AddIstehsalDialogComponent } from "./add-istehsal-dialog/add-istehsal-dialog.component";
import { DataService } from "../data.service";
import { HttpClient } from "@angular/common/http";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import { AuthService } from "../auth.service";
import { ToastrService } from "ngx-toastr";
import { PusherService } from "../pusher.service";

import { db } from "src/app/db.service";
import { liveQuery } from "dexie";
import {
  from,
  Observable,
  map,
  of,
  Subject,
  switchMap,
  defer,
  merge,
  tap,
  takeUntil,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
} from "rxjs";
import { ColumnState, GridApi, GridReadyEvent } from "ag-grid-community";

import * as _moment from "moment";
const moment = _moment;
import * as _latinize from "latinize";
const latinize = _latinize;
import "lodash";
import { StockService } from "../stock/stock.service";
declare var _: any;

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit, OnDestroy {
  _stockService = inject(StockService);
  dataLoading = signal(true);
  public modalData: Object;
  public products: IProduct[];
  selectedElementId: number;
  actionType: string;
  public tableData: any;
  showColumnSettings: boolean = false;
  displayedColumns: string[] = [];
  defaultColumns: string[] = ["productId", "quantity", "date", "delete"];

  @ViewChild("card") card: ElementRef;
  @ViewChild("widgetsContent") widgetsContent: ElementRef;
  @ViewChild("searchInput") searchInput: ElementRef;
  totalLength: number;
  totalWeight: number;
  totalKG = 0;
  groupSumData = [];
  groupSumDataFiltered = [];
  xulaseTooltip: string = "çoxalt";
  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  showSummary: boolean = localStorage.hasOwnProperty(
    `${this._dataService.dbTable}showProductionSummary`
  )
    ? this._dataService.getLS("showProductionSummary")
    : true;

  navButtons = [
    {
      name: "Yeni İstehsal",
      icon: "add",
      function: () => this.openAddIstehsalDialog(),
    },
  ];

  columnDefs = [
    {
      headerName: "id",
      field: "tId",
      hide: true,
    },
    {
      headerName: "Məhsul",
      field: "productName",
      hide: false,
      flex: 2,
      minWidth: 100,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "productName"),
    },
    {
      headerName: "Miqdar, əd",
      field: "quantity",
      hide: false,
      sortable: true,
      flex: 2,
      minWidth: 120,
    },
    {
      headerName: "Ağırlıq, kg",
      field: "weight",
      sortable: true,
      hide: false,
      flex: 2,
      minWidth: 120,
    },
    {
      headerName: "En, m",
      field: "width",
      sortable: true,
      hide: false,
      flex: 2,
      minWidth: 120,
    },
    {
      headerName: "Uzunluq, m",
      field: "length",
      sortable: true,
      hide: false,
      flex: 2,
      minWidth: 120,
    },
    {
      headerName: "Toplam, m",
      field: "square",
      sortable: true,
      hide: false,
      flex: 2,
      minWidth: 120,
      cellRenderer: (params) => {
        return params.data.width
          ? (
              params.data.quantity *
              params.data.width *
              params.data.length
            ).toFixed(2)
          : (params.data.quantity * params.data.length).toFixed(2);
      },
    },
    {
      headerName: "Toplam, kg",
      field: "totalKG",
      sortable: true,
      hide: false,
      flex: 2,
      minWidth: 120,
      cellRenderer: (params) => {
        return (
          params.data.quantity *
          (params.data.width ?? 1) *
          params.data.length *
          params.data.weight
        ).toFixed(2);
      },
    },
    {
      headerName: "Tərəf Müqabil",
      field: "personnelId",
      sortable: true,
      hide: false,
      flex: 3,
      minWidth: 150,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "personnelId"),
    },
    {
      headerName: "Tarix",
      field: "date",
      hide: false,
      flex: 2,
      minWidth: 120,
      sortable: true,
      comparator: (dateA, dateB, nodeA, nodeB, isInverted) => {
        if (dateA === dateB) {
          // If dates are equal, sort by id (descending)
          const idA = nodeA.data.id;
          const idB = nodeB.data.id;
          return idA > idB ? -1 : idA < idB ? 1 : 0;
        } else {
          // Sort by date (descending)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      },
      cellRenderer: (params) => {
        return params.value.split("-").reverse().join("-");
      },
    },
    {
      headerName: "Sil",
      field: "delete",
      hide: false,
      flex: 1,
      maxWidth: 60,
      cellRenderer: (params) => {
        if (params.data.dateAdded.slice(0, 4) == this.dateNow.slice(0, 4)) {
          return "<i class='material-icons'>delete_outline</i>";
        } else {
          return "";
        }
      },
      onCellClicked: (params) => {
        this.deleteEntry(params.data);
      },
    },
  ];

  agGridData$: Observable<any[]>;
  protected gridApi!: GridApi;
  componentDestroyed$: Subject<boolean> = new Subject();

  dbUpdated: boolean = false;
  _dbUpdating$ = new BehaviorSubject(false);

  usedMaterials: {}[] = [];
  showUsedMaterials: boolean = false;

  constructor(
    protected _productService: ProductsService,
    public dialog: MatDialog,
    private http: HttpClient,
    public _dataService: DataService,
    public _authService: AuthService,
    private toastr: ToastrService,
    private _pusher: PusherService
  ) {
    effect(async () => {
      this._dataService.products();
      this.agGridData$ = this.getProductionTableData();
    });
  }

  ngOnInit() {
    this.agGridData$ = this.getProductionTableData();
    this.groupSumData = localStorage.hasOwnProperty(
      `${this._dataService.dbTable}productionCards`
    )
      ? this._dataService.getLS("productionCards")
      : [];
    this.groupSumDataFiltered = this.groupSumData;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getProductionTableData(): Observable<any[]> {
    const localData = this._dataService.getLS("productionTableData") || [];
    const localObservable = defer(() => {
      return of(localData);
    });

    const liveQueryObservable = combineLatest([
      this._dataService.dateChanged,
      this._dbUpdating$.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        this.dataLoading.set(true);
      }),
      switchMap(([dateChanged, dbUpdating]) =>
        from(
          liveQuery(() =>
            this.getTransactions(
              this._dataService.startDate.value.format("YYYY-MM-DD"),
              this._dataService.endDate.value.format("YYYY-MM-DD")
            )
          )
        ).pipe(
          map((things) =>
            things.map((thing) => {
              const customer = this._dataService
                .customers()
                .find((c) => c.id === thing.customerId);
              const material = this._dataService
                .products()
                .find((b) => b.id === thing.productId);
              return {
                ...thing,
                personnelId: customer
                  ? customer.hasOwnProperty("name")
                    ? customer.name
                    : ""
                  : thing.customerId === 0
                  ? "Pərakəndə"
                  : "",
                productName: material.hasOwnProperty("name")
                  ? material.name
                  : "",
                dateAdded: thing.transactionId.dateAdded,
              };
            })
          ),
          map((data) => {
            // params.data.quantity *
            //   params.data.width *
            //   params.data.length *
            //   params.data.weight
            this.totalKG = data
              .map((t) => t.quantity * t.length * t.weight * (t.width || 1))
              .filter((x) => x >= 0)
              .reduce((acc, value) => acc + value, 0);
            this._dataService.setLS("productionTableData", data.slice(-100));
            return data;
          }),
          tap(() => {
            setTimeout(() => {
              this.dataLoading.set(dbUpdating ? true : false);
              if (this.searchInput && this.searchInput.nativeElement.value) {
                this.applyFilter(this.searchInput.nativeElement.value);
              }
            }, 500);
          })
        )
      ),
      takeUntil(this.componentDestroyed$)
    );

    return merge(localObservable, liveQueryObservable);
  }

  async getTransactions(startDate: Date, endDate: Date, stockGroupId?) {
    const result = await db.production
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray();
    this.groupSumDataFiltered = await this._productService.getProductionCards(
      startDate,
      endDate
    );

    return result;
  }

  async getUsedMaterialsForProduction() {
    const result = await db.stock
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray()
      .then((transaction) =>
        transaction.filter((t) => t.tType == "Production")
      );

    // Grouping by materialId and calculating sum of quantities
    const groupedByMaterial: {
      [key: string]: { materialId: number; sum: number; [key: string]: any };
    } = result.reduce((result, transaction) => {
      const { materialId, quantity } = transaction;
      // Check if the materialId already exists in the result
      if (result[materialId]) {
        // If yes, add the quantity to the existing sum
        result[materialId].sum += quantity;
      } else {
        // If no, create a new entry for the materialId
        result[materialId] = {
          materialId: materialId,
          materialName:
            this._dataService.materials().find((m) => m.id == materialId)
              .name || "",
          sum: quantity,
        };
      }

      return result;
    }, {});

    // Convert the grouped object to an array
    const resultArray = Object.values(groupedByMaterial);
    resultArray.sort((a, b) => a.sum - b.sum);

    let mTotals = await this._stockService.getMaterialTotals(
      this._dataService.endDate.value.format("YYYY-MM-DD")
    );

    resultArray.map((m) => {
      let material = mTotals.find((mm) => mm.materialId == m.materialId);
      m.unitPrice = material.unitPrice;
      m.totalPrice = m.sum * m.unitPrice;
    });

    this.usedMaterials = resultArray;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    let cols = this._dataService.getLS("productionCols");
    cols?.forEach((col) => {
      this.columnDefs.find((cd) => cd.field == col.field).hide = col.hide;
    });
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "asc", sortIndex: 0 },
    ];
    // params.api.setDefaultColDef({
    //   // filter: true
    // });
    params.api.applyColumnState({ state: defaultSortModel });
  }

  updateCols(col?, val?) {
    this.gridApi.setGridOption("columnDefs", this.columnDefs);
    this._dataService.setLS("productionCols", this.columnDefs);
  }

  openAddIstehsalDialog(data?, event?) {
    if ((event && event.event.target.innerText != "delete_outline") || !event) {
      const dialogRef = this.dialog.open(AddIstehsalDialogComponent, {
        width: "850px",
        maxHeight: "700px",
        data: data,
        panelClass: "materialDialog",
        closeOnNavigation: true,
        disableClose: this._dataService.mobileDevice
          ? false
          : data
          ? false
          : true,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // this.dataRefresh$.next(res);
        }
      });
    }
  }

  // FILTER TABLE
  applyFilter(val) {
    this.groupSumDataFiltered = _.filter(this.groupSumData, function (o) {
      return (
        latinize(o.name.replace(/\s/g, "").toLowerCase()).indexOf(
          latinize(val.replace(/\s/g, "").toLowerCase())
        ) > -1
      );
    });

    this.gridApi.setGridOption("quickFilterText", val);
    this.totalKG = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this.totalKG +=
        node.data.quantity *
        (node.data.width ?? 1) *
        node.data.length *
        node.data.weight;
    });
  }

  filterDate(event: any) {
    if (this._dataService.endDate.value) {
      // this._dataService.dateChanged.emit(Date.now());
    }
  }

  onWheel(event: WheelEvent): void {
    if (
      !this.widgetsContent?.nativeElement?.className?.includes("expandedGraphs")
    ) {
      this.widgetsContent.nativeElement.scrollLeft += event.deltaY;
      event.preventDefault();
    }
  }

  toggleGraphs() {
    if (
      !this.widgetsContent?.nativeElement?.className?.includes("expandedGraphs")
    ) {
      this.widgetsContent.nativeElement.classList.add("expandedGraphs");
      this.xulaseTooltip = "azalt";
    } else {
      this.widgetsContent.nativeElement.classList.remove("expandedGraphs");
      this.xulaseTooltip = "çoxalt";
    }
  }

  deleteEntry(row) {
    let data = row.productName + ", " + row.quantity + " əd";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.dataLoading.set(true);
        this.http
          .put(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              row.tId,
            { status: 1 }
          )
          .subscribe(async () => {
            await db.transactions.delete(row.tId);
            await db.production.where("tId").equals(row.tId).delete();
            db.stock.where("tId").equals(row.tId).delete();
            this._pusher.postData("Production Entry Deleted").subscribe();
            this.toastr.success("", "Silindi!", {
              toastClass: "ngx-toastr customToast",
              progressBar: true,
              timeOut: 2500,
              progressAnimation: "increasing",
            });
            setTimeout(() => {
              this.dataLoading.set(false);
            }, 700);
          });
      }
    });
  }

  // deleteProduct(id: number) {
  //   this._productService.getProduct(id).subscribe(
  //     (res) => {
  //       this.modalData = res;
  //       this.ngxSmartModalService.setModalData(this.modalData, 'deleteProduct');
  //       this.ngxSmartModalService.getModal('deleteProduct').open();
  //     }
  //   )
  // }

  // editProduct(id: number) {
  //   this._productService.getProduct(id).subscribe(
  //     (res) => {
  //       this.modalData = res;
  //       this.ngxSmartModalService.setModalData(this.modalData, 'editProduct');
  //       this.ngxSmartModalService.getModal('editProduct').open();
  //     }
  //   )
  // }

  productAdded() {}

  // resetModal() {
  //   this.modalData = {
  //     elemId: null,
  //     actionType: null,
  //     title: 'Add item'
  //   };
  //   this.ngxSmartModalService.setModalData(this.modalData, 'myModal');
  // }
}
