<app-navbar
  [ngHeader]="ngHeader"
  [buttons]="navButtons"
  [extraButtons]="navExtraButtons"
  [dataLoading]="dataLoading()"
  (onChange)="
    _selectedStockGroupId$.next($event);
    selectedStockGroupId.set($event);
    _dataService.setLS('selectedStockGroupId', $event)
  "
></app-navbar>
<!-- CARDS -->
<div class="container-fluid px-3 noHammerSwipe mt-6">
  <div
    [@slideInOut]="showSummary ? 'in' : 'out'"
    [@toggleWrap]="isWrapped"
    *ngIf="showSummary"
    style="
      will-change: height, opacity;
      transition: all 0.3s ease;
      margin-top: -0.5rem !important;
    "
    #cardsContainer
    class="cardsContainer widgetsContent pb-2 pt-2"
    (wheel)="onWheel($event)"
  >
    <div
      (click)="
        showTotal = !showTotal; _dataService.setLS('showStockTotals', showTotal)
      "
      class="cards cardHover cardsSettings"
    >
      <span
        class="material-symbols-outlined"
        style="font-size: 50px; color: #e8e8e8; margin-top: -5px"
      >
        instant_mix
      </span>
      <span style="font-size: 14px; line-height: 1; text-align: center">{{
        showTotal ? "Anbar Qalığı" : "Dövr"
      }}</span>
    </div>
    <ng-container *ngFor="let sum of stockGraphs">
      <div
        (click)="materialDetailsDialog(sum.materialId)"
        #card
        class="cards cardHover"
        *ngIf="
          (sum.totalQuantity != 0 && !showTotal) ||
          (showTotal && sum.totalQuantityBeforeDate + sum.totalQuantity != 0)
        "
      >
        <!-- CARD HEADER -->
        <div
          class="d-flex justify-content-between"
          style="flex: 1; padding: 11px 15px"
        >
          <h4 style="min-width: 150px">{{ sum.materialName }}</h4>
          <span
            *ngIf="sum.unitPrice"
            class="materialPrice"
            (click)="
              $event.stopPropagation(); showStockInPrice = !showStockInPrice
            "
            matTooltip="{{
              showStockInPrice ? 'Orta alış qiyməti' : 'Orta satış qiyməti'
            }}"
            matTooltipClass="line-broken-tooltip"
            matTooltipPosition="right"
            >{{
              (showStockInPrice
                ? sum.stockInPrice / sum.stockInQuantity
                : sum.stockOutPrice / sum.stockOutQuantity) ||
                sum.unitPrice ||
                0
                | number : "1.2-2"
                | removeComma
            }}<span
              class="manat"
              style="font-size: 10px !important; text-align: center"
              >₼</span
            ></span
          >
        </div>
        <!-- REMAINING-->
        <div
          class="d-flex"
          [ngStyle]="
            showTotal
              ? { justifyContent: 'space-evenly' }
              : { justifyContent: 'start', paddingLeft: '20px' }
          "
          style="
            margin-top: -15px;
            font-size: 15px;
            font-family: Avant;
            line-height: 1;
            width: 100%;
            gap: 1rem;
            flex: 1;
            align-items: baseline;
            padding-inline: 1rem;
          "
        >
          <div
            class="d-flex align-items-center"
            [ngClass]="{ 'flex-column': showTotal }"
          >
            <span class="noWrap">{{
              summariesInKg
                ? (sum.totalQuantityBeforeDate + sum.totalQuantity
                  | number : "1.2-2"
                  | commaToSpace)
                : ((sum.totalQuantityBeforeDate + sum.totalQuantity) *
                    sum.unitPrice
                  | number : "1.2-2"
                  | commaToSpace)
            }}</span>
            <span
              class="d-flex align-items-center manat"
              style="
                padding-left: 4px;
                padding-top: 2px;
                font-size: 13px !important;
              "
              >{{
                summariesInKg ? (sum.unit | slice : 0 : 2) || "əd" : " ₼"
              }}</span
            >
          </div>

          <div *ngIf="showTotal" class="d-flex align-items-center flex-column">
            <span class="noWrap">{{
              !summariesInKg
                ? (sum.totalQuantityBeforeDate + sum.totalQuantity
                  | number : "1.2-2"
                  | commaToSpace)
                : ((sum.totalQuantityBeforeDate + sum.totalQuantity) *
                    sum.unitPrice
                  | number : "1.2-2"
                  | commaToSpace)
            }}</span>
            <span
              class="d-flex align-items-center manat"
              style="
                padding-left: 4px;
                padding-top: 2px;
                font-size: 13px !important;
              "
              >{{
                !summariesInKg ? (sum.unit | slice : 0 : 2) || "əd" : " ₼"
              }}</span
            >
          </div>
        </div>
        <!-- QUANTITIES-->
        <div
          *ngIf="!showTotal"
          class="d-flex"
          style="
            border-radius: 6px;
            font-family: Avant;
            font-size: 13px;
            flex-direction: column;
            line-height: 1.1;
            margin-top: 12px;
            padding: 3px 0px 3px 1.5rem;
            border-top: 1px solid #ececec;
            background: #f8f8f8;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          "
        >
          <!-- DÖVR ƏRZİNDƏ-->
          <div
            *ngIf="!showTotal"
            style="
              display: flex;
              gap: 1em;
              flex: 1;
              justify-content: space-around;
              text-align: center;
            "
          >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                !summariesInKg
                  ? (sum.stockInPrice | number : "1.1-1" | commaToSpace)
                  : (sum.stockInQuantity | number : "1.1-1" | commaToSpace)
              }}
              <span class="manat" style="font-size: 12px !important"
                >Gələn</span
              ></span
            >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                !summariesInKg
                  ? (sum.stockOutPrice | number : "1.1-1" | commaToSpace)
                  : (sum.stockOutQuantity | number : "1.1-1" | commaToSpace)
              }}
              <span class="manat" style="font-size: 12px !important"
                >Gedən</span
              ></span
            >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                !summariesInKg
                  ? (sum.totalQuantity * sum.unitPrice
                    | number : "1.1-1"
                    | commaToSpace)
                  : (sum.totalQuantity | number : "1.1-1" | commaToSpace)
              }}
              <span class="manat" style="font-size: 12px !important"
                >Fərq</span
              ></span
            >
            <span
              (click)="
                $event.stopPropagation();
                summariesInKg = !summariesInKg;
                _dataService.setLS('showStockSummariesInKG', summariesInKg)
              "
              class="d-flex align-items-center manat cardHover"
              style="font-size: 14px !important; padding-inline: 10px 15px"
              >{{
                summariesInKg ? (sum.unit | slice : 0 : 2) || "əd" : " ₼"
              }}</span
            >
            <!-- FIXME BURDA TOPLAM MIQDAR * SON BİRİM BIRIM FIYAT HESABLAMIŞAM -->
          </div>
          <!-- ANBAR QALIGI-->
          <div
            *ngIf="showTotal"
            style="
              display: flex;
              gap: 0.5em;
              flex: 1;
              justify-content: space-around;
              text-align: center;
            "
          >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                sum.totalQuantityBeforeDate + sum.totalQuantity
                  | number : "1.2-2"
                  | commaToSpace
              }}
              <span class="manat" style="font-size: 12px !important">{{
                sum.unit || "ədəd"
              }}</span></span
            >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                (sum.totalQuantityBeforeDate + sum.totalQuantity) *
                  sum.unitPrice
                  | number : "1.2-2"
                  | commaToSpace
              }}
              <span class="manat" style="font-size: 12px !important"
                >₼</span
              ></span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- SEARCH -->
<div class="container-fluid px-3" [ngClass]="{ 'mt-10': !showSummary }">
  <div class="row">
    <div id="targetTable" class="col-md-5 mt-2">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          #searchInput
          type="search"
          class="form-control cardHover"
          (keyup)="applyFilter(searchInput.value)"
          (search)="applyFilter(searchInput.value)"
          placeholder="Axtar"
          name="search"
        />
        <div class="input-group-append d-flex">
          <div
            *ngIf="showSummary"
            (click)="isWrapped = !isWrapped"
            matTooltip="Xülasələri {{ isWrapped ? 'azalt' : 'çoxalt' }}"
            matTooltipClass="line-broken-tooltip"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              style="
                font-size: 1.7rem;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              unfold_more
            </mat-icon>
          </div>
          <div
            (click)="
              showSummary = !showSummary;
              _dataService.setLS('showStockSummary', showSummary)
            "
            matTooltip="{{
              showSummary ? 'Xülasələri gizlə' : 'Xülasələri göstər'
            }}"
            matTooltipClass="line-broken-tooltip"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              mat-stroked-button
              style="
                font-size: 1.5rem;
                line-height: 1.2;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              toc
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 mt-2 mt-smm-6">
      <div class="summaryContainer cardHover">
        <span class="summaryElementContainer"
          ><span class="summaryType">Gələn</span>
          <span
            >{{ totalIn | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
        <span class="summaryElementContainer"
          ><span class="summaryType">Gedən</span>
          <span
            >{{ -totalOut | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
        <span class="summaryElementContainer"
          ><span class="summaryType">Fərq</span>
          <span
            >{{ totalIn + totalOut | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
        <span
          class="summaryElementContainer"
          matTooltip="Anbar Qalığı"
          matTooltipClass="line-broken-tooltip"
          matTooltipPosition="above"
          ><span class="summaryType">Yekun</span>
          <span
            >{{ totalRemaining | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
        <!-- <span
          ><span class="material-symbols-outlined"> timer </span>
          {{ performance }}
        </span> -->
      </div>
    </div>
  </div>
</div>

<!-- TABLE -->
<div class="container-fluid px-3">
  <ag-grid-angular
    style="width: 100%; border-radius: 6px; overflow: hidden; margin-top: 1rem"
    [ngStyle]="
      showSummary
        ? { height: 'calc(100vh - 260px)' }
        : { height: 'calc(100vh - 134px)' }
    "
    class="ag-theme-alpine cardHover"
    [rowData]="agGridData$ | async"
    (rowClicked)="openAddStockDialog($event.data, $event.data.tType, $event)"
    [columnDefs]="columnDefs"
    [localeText]="_dataService.agGridLocale"
    [animateRows]="false"
    (gridReady)="onGridReady($event)"
    [cacheQuickFilter]="true"
    (contextmenu)="onRightClick($event)"
    (cellMouseOver)="onCellMouseOver($event)"
  >
  </ag-grid-angular>
  <div
    class="context-menu filterSettings"
    *ngIf="_dataService.showContextMenu"
    [style.right.px]="contextMenuData.x ? auto : 20"
    [style.left.px]="
      !contextMenuData.x
        ? auto
        : _dataService.showSideNav
        ? contextMenuData.x - 170
        : contextMenuData.x - 50
    "
    [style.top.px]="contextMenuData.y"
  >
    <div
      class="px-3 py-1"
      style="
        background-color: #0000000a;
        display: flex;
        font-family: 'Mulish';
        font-weight: 600;
        font-size: 17px;
        justify-content: center;
      "
    >
      <span>Əməliyyatlar</span>
    </div>
    <div class="filterItems">
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="
          openAddStockDialog(
            contextMenuData.data,
            contextMenuData.data.tType,
            contextMenuData.data.event,
            true
          );
          _dataService.showContextMenu = false
        "
      >
        <span class="material-symbols-outlined"> edit </span>
        <span class="w-100">Düzəliş et</span>
      </button>
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="
          openAddStockDialog(
            contextMenuData.data,
            contextMenuData.data.tType,
            contextMenuData.data.event,
            true,
            true
          );
          _dataService.showContextMenu = false
        "
      >
        <span class="material-symbols-outlined"> content_copy </span>
        <span class="w-100">Kopyala</span>
      </button>
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="deleteEntry(contextMenuData.data)"
      >
        <span class="material-symbols-outlined"> delete </span>
        <span class="w-100">Sil</span>
      </button>
    </div>
  </div>
</div>
