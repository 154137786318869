import {
  Component,
  Inject,
  OnInit,
  Optional,
  inject,
  signal,
  effect,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/data.service";
import "lodash";
import { StockService } from "src/app/stock/stock.service";
declare var _: any;
import { ColumnState, GridReadyEvent } from "ag-grid-community";

@Component({
  selector: "app-material-dialog",
  templateUrl: "./material-dialog.component.html",
  styleUrls: ["./material-dialog.component.css"],
})
export class NewMaterialDialogComponent implements OnInit {
  _stockService = inject(StockService);
  name: string;
  price: number;
  unit: string;
  showOperations: boolean = false;
  dataLoading: boolean = false;
  selectedStockGroupId;
  selectedStockGroupIdSig = signal(0);
  showProduction = signal(false);

  agGridData$;
  columnDefs = [
    {
      headerName: "id",
      field: "tId",
      hide: true,
    },
    {
      headerName: "",
      field: "tType",
      flex: 1,
      minWidth: 100,
      cellRenderer: (params) => {
        switch (params.value) {
          case "Stock Entry":
            return '<span class="material-symbols-outlined">place_item</span>';
          case "Stock Out":
            return '<span class="material-symbols-outlined">move_item</span>';
          case "Stock Transfer":
            return '<span class="material-symbols-outlined">component_exchange</span>';
          case "Sells":
            return '<span  style="background-color: var(--primary-background), color: black" class="material-symbols-outlined">component_exchange</span>';
          case "Production":
            return '<span style="background-color: #0079ffeb" class="material-symbols-outlined">precision_manufacturing</span>';
          default:
            return '<span  style="background-color: var(--primary-background); color: white" class="material-symbols-outlined">sell</span>';
        }
      },
      cellClass: (params) => {
        switch (params.value) {
          case "Stock Entry":
            return "entryType stockEntry";
          case "Stock Out":
            return "entryType stockOut";
          case "Stock Transfer":
            return "entryType stockTransfer";
          default:
            return "entryType";
        }
      },
    },
    {
      headerName: "Miqdar",
      field: "quantity",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) =>
        params.value < 0 ? -params.value : params.value,
    },
    {
      headerName: "Qiymət",
      field: "price",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) =>
        params.value < 0 ? -params.value : params.value,
    },
    {
      headerName: "Tərəf-Müqabil",
      field: "customerId",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) =>
        params.value
          ? _.find(this._dataService.customers(), {
              id: params.value,
            }).name
          : "*",
      cellClass: (params) => {
        return "justify-content-start";
      },
    },
    {
      headerName: "Tarix",
      field: "date",
      flex: 3,
      minWidth: 100,
      comparator: (dateA, dateB, nodeA, nodeB, isInverted) => {
        if (dateA === dateB) {
          // If dates are equal, sort by id (descending)
          const idA = nodeA.data.id;
          const idB = nodeB.data.id;
          return idA > idB ? -1 : idA < idB ? 1 : 0;
        } else {
          // Sort by date (descending)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      },
    },
  ];
  gridApi: any;

  constructor(
    public dialogRef: MatDialogRef<NewMaterialDialogComponent>,
    private http: HttpClient,
    public _dataService: DataService,
    private toastr: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    effect(async () => {
      this.selectedStockGroupIdSig();
      this.showProduction();
      this.agGridData$ = await this._stockService.getMaterialOperations(
        this.dialogData.id,
        this.selectedStockGroupIdSig(),
        this.showProduction()
      );
    });
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.name = this.dialogData.name;
      this.price = this.dialogData.price;
      this.unit = this.dialogData.unit;
    }
  }

  addMaterial(formData, event) {
    event.srcElement.disabled = true;
    event.target.innerText = "Gözləyin ...";

    // CHECK IF DATA ALREADY EXISTS IN TABLE
    if (
      _.filter(this._dataService.materials(), (a) => {
        return a.name.toLowerCase() == formData.name.toLowerCase();
      }).length &&
      !this.dialogData
    ) {
      this.toastr.error("Bu adda məhsul mövcuddur");

      event.target.innerText = "Əlavə et";
      event.target.disabled = false;

      return;
    }

    let postMaterial;

    if (this.dialogData) {
      postMaterial = this.http.put(
        this._dataService.dbBase +
          "api.php/records/materials/" +
          this.dialogData.id,
        formData
      );
    } else {
      postMaterial = this.http.post(
        this._dataService.dbBase + "api.php/records/materials",
        formData
      );
    }

    return postMaterial.subscribe(
      (res) => {
        formData.id = this.dialogData?.id ? this.dialogData?.id : res;
        this.dialogRef.close(formData);

        if (this.dialogData?.id) {
          formData.id = this.dialogData.id;
          const index = this._dataService
            .materials()
            .findIndex((obj) => obj.id === this.dialogData.id);
          // TODO THIS WAS SIGNAL MUTATE I THINK ITS FIXED?
          this._dataService.materials()[index] = formData;
          this._dataService.materials.set([...this._dataService.materials()]);
          this.toastr.success("Material Yeniləndi");
        } else {
          formData.id = res;
          this._dataService.materials.set([
            ...this._dataService.materials(),
            formData,
          ]);
          this.toastr.success("Əlavə edildi!");
        }
        this.dialogRef.close(formData);
        this._dataService.setLS("materials", this._dataService.materials());
      },
      (err) => {
        console.log(err);
        event.srcElement.disabled = false;
        event.target.innerText = this.dialogData ? "Yenilə" : "Əlavə et";
        this.toastr.error("Xəta baş verdi, yenidən cəhd edin");
      }
    );
  }

  async materialOperations(event) {
    if (this.showOperations) {
      this.showOperations = false;
      return;
    }
    this.dataLoading = true;
    this.agGridData$ = await this._stockService.getMaterialOperations(
      this.dialogData.id,
      this.selectedStockGroupIdSig(),
      this.showProduction()
    );
    this.dataLoading = false;
    this.showOperations = true;
  }

  transactionDetails(data) {
    console.log(data);
    this._stockService.openStockDialog(data);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setIncludeHiddenColumnsInQuickFilter(true);
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "asc", sortIndex: 0 },
    ];
    this.gridApi.setGetRowClass((params) => {
      return params.node.data.justAdded == true ? "tableDataLoading" : "";
    });
    // params.api.setDefaultColDef({
    //   // filter: true
    // });
    params.api.applyColumnState({ state: defaultSortModel });
  }
}
