import { Injectable, inject, signal, OnDestroy } from "@angular/core";
import {
  from,
  Observable,
  map,
  of,
  Subject,
  switchMap,
  defer,
  merge,
  takeUntil,
  tap,
  combineLatest,
  BehaviorSubject,
  distinctUntilChanged,
  retryWhen,
  delay,
  take,
} from "rxjs";
import { DataService } from "../data.service";
import { db } from "src/app/db.service";
import { liveQuery } from "dexie";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IApiResult } from "../shared/models/apiresult.interface";
import moment from "moment";
import { BankDialogComponent } from "./bank-dialog/bank-dialog.component";
import { SalaryDialogComponent } from "./salary-dialog/salary-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BankService implements OnDestroy {
  _dataService = inject(DataService);
  dialog = inject(MatDialog);
  http = inject(HttpClient);
  componentDestroyed$: Subject<boolean> = new Subject();
  selectedCashboxId = this._dataService.getLS("selectedCashboxId")
    ? signal(this._dataService.getLS("selectedCashboxId"))
    : signal(0);
  _selectedCashboxId$ = new BehaviorSubject(this.selectedCashboxId());
  dbUpdated: boolean = false;
  _dbUpdating$ = new BehaviorSubject(true);
  dataLoading = signal(true);
  totalIn = this._dataService.getLS("bankTotalIn") || 0;
  totalOut = this._dataService.getLS("bankTotalOut") || 0;
  jwtToken = signal(this._dataService.getLS("kapitalToken") || "");
  kapitalBalance = 0;

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getBankTableData(): Observable<any[]> {
    const localData =
      this.selectedCashboxId() != 100
        ? this._dataService.getLS("bankTableData") || []
        : this._dataService.getLS("kapitalData") || [];
    const localObservable = defer(() => {
      return of(localData);
    });

    const liveQueryObservable = combineLatest([
      this._dataService.dateChanged,
      this._selectedCashboxId$.pipe(distinctUntilChanged()),
      this._dbUpdating$.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        setTimeout(() => {
          this.dataLoading.set(true);
        }, 100);
      }),
      switchMap(([dateChanged, selectedCashboxId, dbUpdating]) =>
        from(
          liveQuery(() =>
            this.getTransactions(
              this._dataService.startDate.value.format("YYYY-MM-DD"),
              this._dataService.endDate.value.format("YYYY-MM-DD"),
              selectedCashboxId
            )
          )
        ).pipe(
          map((things) => {
            const groupedThings = things.reduce((groups, thing) => {
              const key = thing.transactionId.id;
              const customer =
                this._dataService
                  .customers()
                  .find((c) => c.id === thing.customerId) ||
                this._dataService
                  .cashBox()
                  .find((c) => c.id === thing.cashboxId);
              const bankGroup = this._dataService
                .bankGroups()
                .find((b) => b.id === thing.bankGroupId);

              if (!groups[key]) {
                groups[key] = {
                  ...thing,
                  bankGroup: bankGroup ? bankGroup.name : " ",
                  customerName: customer ? customer?.name : " ",
                  dateAdded: thing.transactionId.dateAdded,
                  details: thing.transactionId.details,
                  transactionId: thing.transactionId,
                  transaction: thing.transactionId,
                };
              } else {
                groups[key].customerName += thing.customerId
                  ? ", " +
                    this._dataService
                      .customers()
                      .find((c) => c.id === thing.customerId)?.name
                  : " <> " +
                    this._dataService
                      .cashBox()
                      .find((c) => c.id === thing.cashboxId)?.name;
                groups[key].outgoings += thing.outgoings;
              }

              return groups;
            }, {});

            return Object.values(groupedThings);
          }),
          map((data) => {
            if (selectedCashboxId === 100) {
              return this.kapitalGetData().pipe(
                map(
                  (response) => response.responseData.operations.statementList
                ),
                map((data) => {
                  let result = [
                    ...data.map((d) => ({
                      ...d,
                      id: d.trnRefNo,
                      tId: d.trnRefNo,
                      details: d.purpose || "",
                      date: moment(new Date(d.trnDt)).format("YYYY-MM-DD"),
                      dateAdded: moment(new Date(d.trnDt)).format("YYYY-MM-DD"),
                      income: d.lcyAmount > 0 ? parseFloat(d.lcyAmount) : null,
                      outgoings: d.lcyAmount < 0 ? -d.lcyAmount : null,
                      bankGroup: 1,
                      customerName: d.contrAccount.split("/")[1] || "",
                    })),
                  ];
                  this._dataService.setLS("kapitalData", result.slice(-100));
                  console.log(result);
                  return result;
                })
              );
            } else {
              return of(data);
            }
          }),
          switchMap((resultData) => {
            return resultData.pipe();
          }),
          map(
            (
              data: { income: number; outgoings: number; [key: string]: any }[]
            ) => {
              this.totalIn = 0;
              this.totalOut = 0;
              this.totalIn =
                data.reduce(
                  (acc, value) =>
                    !selectedCashboxId && value.bankGroupId == 30
                      ? acc
                      : acc + value.income,
                  0
                ) ?? 0;
              this.totalOut =
                data.reduce(
                  (acc, value) =>
                    !selectedCashboxId && value.bankGroupId == 30
                      ? acc
                      : acc + value.outgoings,
                  0
                ) ?? 0;
              this.totalIn = this.totalIn || 0;
              this.totalOut = this.totalOut || 0;
              this._dataService.setLS("bankTableData", data.slice(-100));
              this._dataService.setLS("bankTotalIn", this.totalIn);
              this._dataService.setLS("bankTotalOut", this.totalOut);
              return data;
            }
          ),
          tap(() => {
            setTimeout(() => {
              this.dataLoading.set(false);
              // if (this.searchInput && this.searchInput.nativeElement.value) {
              //   this.applyFilter(this.searchInput.nativeElement.value);
              // }
            }, 500);
          }),
          retryWhen((errors) => {
            let retries = 0;
            return errors.pipe(
              switchMap((error) => {
                if (retries < 5) {
                  retries++;
                  return this.kapitalLogin().pipe(
                    tap((r) => {
                      this.jwtToken.set(r.responseData.jwttoken);
                      this._dataService.setLS("kapitalToken", this.jwtToken());
                    }),
                    delay(1000), // Add a delay before retrying
                    take(1) // Ensure that the login call is executed only once
                  );
                } else {
                  throw error; // If max retries reached, propagate the error
                }
              })
            );
          })
        )
      ),
      takeUntil(this.componentDestroyed$)
    );

    return merge(localObservable, liveQueryObservable);
  }

  getTotals(startDate: Date, endDate: Date, cashboxId?: number) {
    const localData = this._dataService.getLS("bankTotals");
    const localObservable = defer(() => of(localData));
    const dexieObservable = combineLatest([
      this._dataService.dateChanged,
      this._selectedCashboxId$.pipe(distinctUntilChanged()),
      this._dbUpdating$.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        setTimeout(() => {
          this.dataLoading.set(true);
        }, 100);
      }),
      switchMap(([dateChanged, selectedCashboxId, dbUpdating]) =>
        from(
          liveQuery(() =>
            this.getTotalsHelper(
              this._dataService.endDate.value.format("YYYY-MM-DD"),
              selectedCashboxId
            )
          )
        ).pipe(
          map((data) => {
            const result = {
              in: data.reduce(
                (acc, value) =>
                  value.date >= startDate ? acc + value.income : acc,
                0
              ),
              out: data.reduce(
                (acc, value) =>
                  value.date >= startDate ? acc + value.outgoings : acc,
                0
              ),
              residue: data.reduce(
                (acc, value) =>
                  value.date < startDate
                    ? acc + value.income - value.outgoings
                    : acc,
                0
              ),
              outBalance: data.reduce((acc, value) => {
                const bankGroup = this._dataService
                  .bankGroups()
                  .find(
                    (b) =>
                      b.id.toString() === (value.bankGroupId?.toString() || "")
                  );

                return value.date >= startDate &&
                  bankGroup &&
                  bankGroup.includeInBalance
                  ? acc + value.outgoings
                  : acc;
              }, 0),
              inBalance: data.reduce((acc, value) => {
                const bankGroup = this._dataService
                  .bankGroups()
                  .find(
                    (b) =>
                      b.id.toString() === (value.bankGroupId?.toString() || "")
                  );

                return value.date >= startDate &&
                  bankGroup &&
                  bankGroup.includeInBalance
                  ? acc + value.income
                  : acc;
              }, 0),
              residueBalance: data.reduce((acc, value) => {
                const bankGroup = this._dataService
                  .bankGroups()
                  .find(
                    (b) =>
                      b.id.toString() === (value.bankGroupId?.toString() || "")
                  );

                return value.date < startDate &&
                  bankGroup &&
                  bankGroup.includeInBalance
                  ? acc + value.income - value.outgoings
                  : acc;
              }, 0),
              sells: data.reduce(
                (acc, value) =>
                  value.date >= startDate && value.bankGroupId == 0 // FIXME bu id diger qruplarda (bant/karniz) ferqlidir (sells id)
                    ? acc + value.income
                    : acc,
                0
              ),
            };
            this._dataService.setLS("bankTotals", result);
            return result;
          })
        )
      )
    );
    return merge(localObservable, dexieObservable);
  }

  openBankDialog(
    type?: string,
    transactionId?: number,
    rowData?,
    event?,
    editMode?: boolean,
    copyMode?: boolean
  ) {
    if ((event && event.event.target.innerText != "more_horiz") || !event) {
      this._dataService.showContextMenu = false;

      if (!rowData?.bankGroup && transactionId) {
        // this.openTransferBankDialog(rowData);
        return;
      }
      if (rowData && rowData.transaction?.type == "Salary") {
        this.dialog.open(SalaryDialogComponent, {
          width: "800px",
          maxHeight: "700px",
          data: {
            cashboxId: this.selectedCashboxId(),
            transactionId: transactionId,
          },
          closeOnNavigation: true,
          panelClass: "materialDialog",
        });
        return;
      }
      let dialogData: {
        type?: string;
        data?: Object;
        cashboxId?: number;
        editMode?: boolean;
        copyMode?: boolean;
      } = {};
      dialogData.type = type;
      dialogData.cashboxId = this.selectedCashboxId();

      if (rowData) {
        rowData.bankGroupName = rowData.bankGroup;
        rowData.transactionId = rowData.tId;
        dialogData.type = rowData.income ? "medaxil" : "";
        dialogData.cashboxId = rowData.cashboxId || 1;
        dialogData.data = rowData;
        dialogData.editMode = editMode;
        dialogData.copyMode = copyMode;
      }

      this.dialog.open(BankDialogComponent, {
        width: "800px",
        maxHeight: "700px",
        data: dialogData,
        closeOnNavigation: true,
        disableClose: this._dataService.mobileDevice
          ? false
          : editMode || copyMode || !transactionId
          ? true
          : false,
        panelClass: "materialDialog",
      });
    }
  }

  openSalaryDialog(data?) {
    this._dataService.showContextMenu = false;
    let dialogData = data
      ? data
      : {
          cashboxId: this.selectedCashboxId(),
        };
    this.dialog.open(SalaryDialogComponent, {
      width: "800px",
      maxHeight: "700px",
      data: dialogData,
      panelClass: "materialDialog",
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : data
        ? false
        : true,
    });
    // dialogRef.afterClosed().subscribe((res) => {
    //   // this.getDxData();
    //   if (res) {
    //     // console.log(res);
    //     // db.bank.add(res, res.id);
    //   }
    // });
  }

  async getTotalsHelper(endDate, cashboxId?) {
    let result = await db.bank.where("date").belowOrEqual(endDate).toArray();
    if (cashboxId) {
      result = result.filter((b) => b.cashboxId == cashboxId);
    } else {
      result = result.filter((b) => b.bankGroupId != 30);
    }
    return result;
  }

  async getOutgoingsByCategory(startDate: Date, endDate: Date) {
    const banks = await db.bank
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray();

    const outGoings: { [key: string]: any } = banks.reduce((acc, bank) => {
      const { bankGroupId, outgoings } = bank;

      acc[bankGroupId] = acc[bankGroupId] || {
        name:
          this._dataService.bankGroups().find((x) => x.id == bankGroupId)
            ?.name ?? "",
        bankGroupId: bankGroupId,
        out: 0,
      };

      acc[bankGroupId].out += outgoings;
      return acc;
    }, {});

    const result = Object.values(outGoings).sort((a, b) => b.out - a.out);

    return result;
  }

  async calculateProfit() {
    const sells = await db.sells
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray();

    const result = sells.reduce((accumulator, sell) => {
      const { productId, materialId, quantity, price } = sell;
      let productionCost;
      let formulaCost;
      let uzlemeCost;
      let id;
      let name;
      if (productId) {
        id = productId;
        name =
          this._dataService
            .products()
            .find((product) => product.id === productId)?.name || "";
      } else if (materialId) {
        id = materialId;
        name =
          "material: " +
            this._dataService
              .materials()
              .find((material) => material.id === materialId)?.name || "";
      }

      if (id) {
        // TODO satilan materialdisa gelir duzgun hesablanmir
        // Check if the accumulator object already has an entry for the current id
        if (!accumulator[id]) {
          let product = this._dataService
            .products()
            .find((product) => product.id === id);

          let formula =
            this._dataService
              .formulaGroups()
              .find((formula) => formula.id === product?.formulaGroupId) ??
            null;

          formulaCost = formula?.cost ? formula?.cost : null;
          uzlemeCost = formula?.uzlemeCost ? formula?.uzlemeCost : null;

          if (formulaCost)
            productionCost = product?.width
              ? (formulaCost * product.weight) / product.width +
                (uzlemeCost ?? 0)
              : formulaCost * product.weight + (uzlemeCost ?? 0);

          // If not, create a new entry with id, sum initialized to 0, and name fetched from this._dataService.products or this._dataService.materials array
          accumulator[id] = {
            productId: id,
            productName: name,
            totalQuantity: 0,
            sellsSum: 0,
            revenue: 0,
            revenuePercentage: 0,
            formulaCost: productionCost ?? 0,
            costTotal: 0,
          };
        }

        // Increment the sum for the current id by adding quantity * price
        accumulator[id].sellsSum += quantity * price;
        accumulator[id].totalQuantity += quantity;
        accumulator[id].revenue += productionCost
          ? quantity * (price - productionCost)
          : null;

        // Limit the sum value to have a maximum of 2 decimal points
        accumulator[id].sellsSum = parseFloat(
          accumulator[id].sellsSum.toFixed(2)
        );

        accumulator[id].revenue = parseFloat(
          accumulator[id].revenue.toFixed(2)
        );

        accumulator[id].revenuePercentage = parseFloat(
          ((accumulator[id].revenue / accumulator[id].sellsSum) * 100).toFixed(
            2
          )
        );
      }

      // Return the updated accumulator object for the next iteration
      return accumulator;
    }, {});

    let sellsTotal = 0;
    let costTotal = 0;
    let administrativXercler = 0;

    const finalResult: any = Object.values(result);
    finalResult.map((item) => {
      sellsTotal += item.sellsSum;
      costTotal += item.totalQuantity * item.formulaCost;
    });

    let bankXercGroups = this._dataService
      .bankGroups()
      .filter((category) => category.bankGroupCategory == 2)
      .map((category) => category.id);

    let bank = await db.bank
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray()
      .then((transaction) =>
        transaction.filter(
          (t) => bankXercGroups.includes(t.bankGroupId) ?? null
        )
      );

    bank.map((t) => {
      administrativXercler += t.outgoings;
    });

    return { sellsTotal, costTotal, administrativXercler };
  }

  async getTransactions(startDate: Date, endDate: Date, selectedCashboxId?) {
    let result;
    if (selectedCashboxId) {
      result = await db.bank
        .where("date")
        .between(startDate, endDate, true, true)
        .toArray()
        .then((transaction) =>
          transaction.filter((t) => t.cashboxId == selectedCashboxId)
        );
    } else {
      result = await db.bank
        .where("date")
        .between(startDate, endDate, true, true)
        .toArray();
    }
    return result;
  }

  kapitalLogin() {
    return this.http.post<IApiResult>(
      "https://cb.kapitalbank.az/api/b2b/login",
      {
        username: environment.kapital.username,
        password: environment.kapital.pass,
      }
    );
  }

  kapitalGetData() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.jwtToken()}`,
    });
    return this.http.get<IApiResult>(
      `https://cb.kapitalbank.az/api/b2b/v2/statement/account?accountNumber=${
        environment.kapital.accountNo
      }&fromDate=${this._dataService.startDate.value.format(
        "DD-MM-YYYY"
      )}&toDate=${this._dataService.endDate.value.format("DD-MM-YYYY")}`,
      {
        headers,
      }
    );
  }
}
