import { Injectable, inject, signal } from "@angular/core";
import { db } from "src/app/db.service";
import { DataService } from "../data.service";
import {
  from,
  map,
  of,
  defer,
  merge,
  BehaviorSubject,
  switchMap,
  distinctUntilChanged,
  combineLatest,
} from "rxjs";
import { liveQuery } from "dexie";
import { NewMaterialDialogComponent } from "../dialogs/material-dialog/material-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AddStockDialogComponent } from "./stock-dialog/stock-dialog.component";

@Injectable({
  providedIn: "root",
})
export class StockService {
  _dataService = inject(DataService);
  dialog = inject(MatDialog);

  selectedStockGroupId = signal(0);
  _selectedStockGroupId$ = new BehaviorSubject(this.selectedStockGroupId());

  totalPrice: number = 0;

  getTotals(startDate: Date, endDate: Date) {
    const localData = this._dataService.getLS("stockTotals");
    const localObservable = defer(() => of(localData));
    const dexieObservable = combineLatest([
      this._dataService.dateChanged,
      this._selectedStockGroupId$.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([dateChanged, selectedStockGroupId]) =>
        from(
          liveQuery(() => this.getTotalsHelper(endDate, selectedStockGroupId))
        ).pipe(
          map((data) => {
            const result: {
              in;
              out;
              residue;
              inBalance;
              outBalance;
              residueBalance;
            } = {
              in: data.reduce(
                (acc, value) =>
                  value.date >= startDate && value.quantity > 0
                    ? acc + value.quantity * value.price
                    : acc,
                0
              ),
              out: data.reduce(
                (acc, value) =>
                  value.date >= startDate && value.quantity < 0
                    ? acc + value.quantity * value.price
                    : acc,
                0
              ),
              residue: data.reduce(
                (acc, value) =>
                  value.date < startDate
                    ? acc + value.quantity * value.price
                    : acc,
                0
              ),
              inBalance: data.reduce(
                (acc, value) =>
                  value.date >= startDate &&
                  value.quantity > 0 &&
                  value.customerId
                    ? acc + value.quantity * value.price
                    : acc,
                0
              ),
              outBalance: data.reduce(
                (acc, value) =>
                  value.date >= startDate &&
                  value.quantity < 0 &&
                  value.customerId
                    ? acc + value.quantity * value.price
                    : acc,
                0
              ),
              residueBalance: data.reduce(
                (acc, value) =>
                  value.date < startDate && value.customerId
                    ? acc + value.quantity * value.price
                    : acc,
                0
              ),
            };
            this._dataService.setLS("stockTotals", result);
            return result;
          })
        )
      )
    );
    return merge(localObservable, dexieObservable);
  }

  async getTotalsHelper(endDate, stockGroupId?) {
    let result = await db.stock.where("date").belowOrEqual(endDate).toArray();
    if (stockGroupId)
      result = result.filter((b) => b.stockGroupId == stockGroupId);
    return result;
  }

  async getStockCards(startDate, endDate, stockGroupId?) {
    // console.time("getStockCards");

    type ProductTotal = {
      materialId: number;
      materialName: string;
      totalQuantity?: number;
      totalPrice?: number;
      unit?: string;
      unitPrice?: number;
      sumBetweenDates?;
      sumBeforeStartDate?;
      totalQuantityBeforeDate?;
      sells?;
      stockInPrice?: number;
      stockOutPrice: number;
      stockInQuantity: number;
      stockOutQuantity: number;
      stockInBeforeQuantity?: number;
      stockInBeforePrice?: number;
      stockOutBeforeQuantity?: number;
      stockOutBeforePrice?: number;
      averagePrice?;
      [key: string]: any;
    };

    let stocks = await db.stock
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray();

    let stocksBefore = await db.stock.where("date").below(startDate).toArray();

    let sells = await db.sells
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray();

    let sellsBefore = await db.sells
      .where("date")
      .below(startDate)
      .toArray()
      .then((transaction) => transaction.filter((t) => t.materialId !== null));

    if (stockGroupId) {
      stocks =
        stocks.length > 0
          ? stocks.filter((t) => t.stockGroupId == stockGroupId)
          : stocks;
      stocksBefore =
        stocksBefore.length > 0
          ? stocksBefore.filter((t) => t.stockGroupId == stockGroupId)
          : stocksBefore;
      sells =
        sells.length > 0
          ? sells.filter((t) => t.stockGroupId == stockGroupId)
          : sells;
    }

    const productTotals: { [key: string]: ProductTotal } = stocks.reduce(
      (acc, stock) => {
        const { materialId, quantity, price, tType } = stock;
        const materialTotal = quantity * price;

        acc[materialId] = acc[materialId] || {
          materialId,
          materialName:
            this._dataService.materials().find((c) => c.id === materialId)
              ?.name || null,
          unit:
            this._dataService.materials().find((c) => c.id === materialId)
              ?.unit || null,
          sumBetweenDates: 0,
          sumBeforeStartDate: 0,
          unitPrice:
            this._dataService.materials().find((c) => c.id === materialId)
              ?.price || null,
          totalPrice: 0,
          totalQuantity: 0,
          totalQuantityBeforeDate: 0,
          sells: 0,
          stockInPrice: 0,
          stockOutPrice: 0,
          stockInQuantity: 0,
          stockOutQuantity: 0,
        };

        acc[materialId].sumBetweenDates += materialTotal;
        acc[materialId].totalPrice += quantity * price;
        acc[materialId].totalQuantity += quantity;
        acc[materialId].stockInQuantity +=
          tType == "Stock Entry" ? quantity : 0;
        acc[materialId].stockInPrice +=
          tType == "Stock Entry" ? quantity * price : 0;
        acc[materialId].stockOutQuantity -=
          tType == "Stock Out" || tType == "Production" ? quantity : 0;
        acc[materialId].stockOutPrice -=
          tType == "Stock Out" || tType == "Production" ? quantity * price : 0;
        return acc;
      },
      {}
    );

    stocksBefore.forEach((stock) => {
      const { materialId, quantity, price, tType } = stock;
      const materialTotal = quantity * price;

      productTotals[materialId] = productTotals[materialId] || {
        materialId,
        materialName:
          this._dataService.materials().find((c) => c.id === materialId)
            ?.name || null,
        unit:
          this._dataService.materials().find((c) => c.id === materialId)
            ?.unit || null,
        sumBetweenDates: 0,
        sumBeforeStartDate: 0,
        totalQuantity: 0,
        totalQuantityBeforeDate: 0,
        stockInPrice: 0,
        stockOutPrice: 0,
        stockInQuantity: 0,
        stockOutQuantity: 0,
        unitPrice:
          this._dataService.materials().find((c) => c.id === materialId)
            ?.price || null,
        totalPrice: 0,
      };

      productTotals[materialId].sumBeforeStartDate += materialTotal;
      productTotals[materialId].totalPrice += quantity * price;
      productTotals[materialId].totalQuantityBeforeDate += quantity;

      productTotals[materialId].stockInBeforeQuantity +=
        tType == "Stock Entry" ? quantity : 0;
      productTotals[materialId].stockOutBeforeQuantity +=
        tType == "Stock Out" ? quantity : 0;
      productTotals[materialId].stockInBeforePrice +=
        tType == "Stock Entry" ? quantity * price : 0;
      productTotals[materialId].stockOutBeforePrice +=
        tType == "Stock Out" ? quantity * price : 0;
    });

    sells.forEach((sell) => {
      const { materialId, quantity, price } = sell;
      productTotals[materialId].totalPrice += quantity * price;
      productTotals[materialId].totalQuantity -= quantity;
      productTotals[materialId].stockOutQuantity += quantity;
      productTotals[materialId].stockOutPrice += quantity * price;
    });

    sellsBefore.forEach((sell) => {
      const { materialId, quantity, price } = sell;
      productTotals[materialId] = productTotals[materialId] || {
        materialId,
        materialName:
          this._dataService.materials().find((c) => c.id === materialId)
            ?.name || null,
        unit:
          this._dataService.materials().find((c) => c.id === materialId)
            ?.unit || null,
        sumBetweenDates: 0,
        sumBeforeStartDate: 0,
        totalQuantity: 0,
        totalQuantityBeforeDate: 0,
        stockInPrice: 0,
        stockOutPrice: 0,
        stockInQuantity: 0,
        stockOutQuantity: 0,
        unitPrice:
          this._dataService.materials().find((c) => c.id === materialId)
            ?.price || null,
        totalPrice: 0,
      };
      productTotals[materialId].stockOutBeforeQuantity -= quantity;
      productTotals[materialId].stockOutBeforePrice -= quantity * price;
    });

    // console.log("sells", sells);

    const result = Object.values(productTotals).filter(
      (val) => val.materialId && val.materialName
    );

    // Sort the result array by sumBetweenDates in descending order
    result.sort((a, b) => a.sumBetweenDates - b.sumBetweenDates);

    this._dataService.setLS("stocksSumData", result);
    // console.timeEnd("getStockCards");
    // console.log("DEF STOCKS", result);
    return result;
  }

  async getStockCards1(stockGroupId?) {
    const [stocks, sells] = await Promise.all([
      db.stock.toArray(),
      db.sells
        .toArray()
        .then((transaction) =>
          transaction.filter((t) => t.materialId !== null)
        ),
    ]);

    let groupedData = [...stocks, ...sells].reduce(
      (combinedResult, transaction) => {
        const key = transaction.materialId;
        if (!combinedResult[key]) {
          combinedResult[key] = [];
        }

        combinedResult[key].push(
          transaction.tType
            ? transaction
            : { ...transaction, quantity: -transaction.quantity }
        );

        return combinedResult;
      },
      {}
    );

    const materials = this._dataService.materials();
    const materialMap = materials.reduce((map, material) => {
      map[material.id] = material.name;
      return map;
    }, {});

    let result = Object.keys(groupedData).reduce((result, key) => {
      const group = groupedData[key];
      group.forEach((item) => {
        if (!result[item.materialId]) {
          result[item.materialId] = {
            quantitySum: 0,
            priceSum: 0,
            alisKG: 0,
            alisUSD: 0,
          };
        }
        const quantityProduct = item.quantity * item.price;
        result[item.materialId].materialId = item.materialId;
        result[item.materialId].materialName =
          materialMap[item.materialId] || "";
        result[item.materialId].quantitySum += item.quantity;
        result[item.materialId].alisKG +=
          item.quantity > 0 && item.tType != "Stock Transfer"
            ? item.quantity
            : 0;
        result[item.materialId].alisUSD +=
          quantityProduct > 0 && item.tType != "Stock Transfer"
            ? quantityProduct
            : 0;
        result[item.materialId].priceSum += quantityProduct;
      });

      return result;
    }, {});

    result = Object.values(result).map((item: any) => ({
      ...item,
      unitPrice: item.alisUSD / item.alisKG,
    }));

    console.timeEnd("getStockCards1");

    return result;
  }

  // CALCULATES MATERIALS REMAINING QUANTITES, TOTAL PRICE AND UNIT PRICE
  async getMaterialTotals(endDate, stockGroupId?) {
    let [stocks, sells] = await Promise.all([
      db.stock.where("date").belowOrEqual(endDate).toArray(),
      db.sells
        .where("date")
        .belowOrEqual(endDate)
        .toArray()
        .then((transaction) =>
          transaction.filter((t) => t.materialId !== null)
        ),
    ]);

    if (stockGroupId != 100 && stockGroupId) {
      stocks = stocks.filter((st) => st.stockGroupId == stockGroupId);
      sells = sells.filter((st) => st.stockGroupId == stockGroupId);
    }

    this.totalPrice = 0;

    let groupedData = [...stocks, ...sells].reduce(
      (combinedResult, transaction) => {
        const key = transaction.materialId;
        if (!combinedResult[key]) {
          combinedResult[key] = [];
        }

        combinedResult[key].push(
          transaction.tType
            ? transaction
            : {
                ...transaction,
                quantity: -transaction.quantity,
                tType: "Sells",
              }
        );

        return combinedResult;
      },
      {}
    );

    for (let key in groupedData) {
      groupedData[key].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }

    const materials = this._dataService.materials();
    const materialMap = materials.reduce((map, material) => {
      map[material.id] = material.name;
      return map;
    }, {});

    let result: any = Object.keys(groupedData).reduce((result, key) => {
      const group = groupedData[key];
      let remainingKG = 0;
      let remainingTotalPrice = 0;
      let remainingUnitPrice = 0;
      group.forEach((item) => {
        if (!result[item.materialId]) {
          result[item.materialId] = {
            materialId: item.materialId,
            materialName: materialMap[item.materialId] || "",
            totalQuantity: 0,
            totalPrice: 0,
            unitPrice: 0,
          };
        }
        const transactionTotal = item.quantity * item.price;

        if (remainingKG > 0) {
          remainingUnitPrice =
            item.tType == "Stock Entry"
              ? (remainingTotalPrice + transactionTotal) /
                (remainingKG + item.quantity)
              : remainingUnitPrice;
        } else {
          remainingUnitPrice =
            item.tType == "Stock Entry" ? item.price : remainingUnitPrice;
        }

        remainingKG += item.quantity;
        remainingTotalPrice = remainingKG * remainingUnitPrice;

        result[item.materialId].totalQuantity = remainingKG;
        result[item.materialId].totalPrice = remainingTotalPrice;
        result[item.materialId].unitPrice = remainingUnitPrice;
      });

      return result;
    }, {});

    result = Object.values(result).filter(
      (item: any) =>
        Number.isInteger(item.materialId) && item.materialName.length > 0
    );

    result.forEach((r) => (this.totalPrice += r.totalPrice));
    return result;
  }

  async getUsedMaterialsForProduction() {
    const result = await db.stock
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray()
      .then((transaction) =>
        transaction.filter((t) => t.tType == "Production")
      );

    // Grouping by materialId and calculating sum of quantities
    const groupedByMaterial: {
      [key: string]: { materialId: number; sum: number; [key: string]: any };
    } = result.reduce((result, transaction) => {
      const { materialId, quantity } = transaction;
      // Check if the materialId already exists in the result
      if (result[materialId]) {
        // If yes, add the quantity to the existing sum
        result[materialId].sum += quantity;
      } else {
        // If no, create a new entry for the materialId
        result[materialId] = {
          materialId: materialId,
          materialName:
            this._dataService.materials().find((m) => m.id == materialId)
              .name || "",
          sum: quantity,
        };
      }

      return result;
    }, {});

    // Convert the grouped object to an array
    const resultArray = Object.values(groupedByMaterial);
    resultArray.sort((a, b) => a.sum - b.sum);
    return resultArray;
  }

  async openStockDialog(data?, stockEntryType?: string, event?) {
    let transactions = null;
    let tType = stockEntryType ? stockEntryType : data.tType;
    if (data?.tId) {
      transactions = await db.stock.where("tId").equals(data.tId).toArray();
      console.log(transactions);
    }
    this.dialog.open(AddStockDialogComponent, {
      width: "1000px",
      data: {
        data,
        tType,
        stockGroupId: data.stockGroupId || 1,
        transactions,
      },
      panelClass: "materialDialog",
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : data
        ? false
        : true,
    });
  }

  materialDialog(materialId?) {
    console.log(materialId);
    let dialogRef, data;
    if (materialId) {
      data = this._dataService.materials().find((x) => x.id == materialId);
    }
    dialogRef = this.dialog.open(NewMaterialDialogComponent, {
      width: materialId ? "750px" : "550px",
      data: data,
      panelClass: "materialDialog",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res?.id) {
        } else {
          // this.materials = [...this.materials, res];
        }
        // localStorage.setItem("groupSumData", JSON.stringify(this.groupSumData));
        // localStorage.setItem("materials", JSON.stringify(this.materials));
      }
    });
  }

  async getMaterialOperations(
    materialId,
    stockGroupId?,
    showProduction?: boolean
  ) {
    let stockQuery = db.stock.where("materialId").equals(materialId);

    if (stockGroupId) {
      stockQuery = stockQuery.filter((s) => s.stockGroupId === stockGroupId);
    }

    if (!showProduction) {
      stockQuery = stockQuery.filter((s) => s.tType !== "Production");
    }

    let stock = await stockQuery.toArray();
    let sells = await db.sells.where("materialId").equals(materialId).toArray();

    return stock.concat(sells);
  }
}
