import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  effect,
  inject,
  DestroyRef,
  HostListener,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DataService } from "../data.service";
import { HttpClient } from "@angular/common/http";
import {
  BehaviorSubject,
  combineLatest,
  defer,
  distinctUntilChanged,
  from,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { SellsDialogComponent } from "./sells-dialog/sells-dialog.component";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { db } from "src/app/db.service";
import { slideAnimation, toggleWrap } from "../shared/fade.animation";
import { liveQuery } from "dexie";
import {
  ColumnState,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import * as _moment from "moment";
const moment = _moment;
import * as _latinize from "latinize";
import { PusherService } from "../pusher.service";
import { ProductsService } from "../products/products.service";
import { StockService } from "../stock/stock.service";
const latinize = _latinize;

@Component({
  selector: "app-sells",
  templateUrl: "./sells.component.html",
  styleUrls: ["./sells.component.css"],
  animations: [slideAnimation, toggleWrap],
})
export class SellsComponent implements OnInit {
  _dataService = inject(DataService);
  _stockService = inject(StockService);
  destroyRef = inject(DestroyRef);

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this._dataService.showContextMenu = false;
    }
  }

  dataLoading: boolean = true;
  @ViewChild("cardsContainer") cardsContainer: ElementRef;
  @ViewChild("searchInput") searchInput: ElementRef;
  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  canDelete: boolean = false;
  componentDestroyed$ = new Subject<void>();
  showSummary: boolean = localStorage.hasOwnProperty(
    this._dataService.dbTable + "showSellsSummary"
  )
    ? this._dataService.getLS("showSellsSummary")
    : true;

  isWrapped: boolean = false;

  showTotal: boolean = false;

  navButtons = [
    {
      name: "Yeni Satış",
      icon: "add",
      function: () => this.openSellsDialog(),
    },
    {
      name: "Qayıtma",
      icon: "remove",
      function: () => this.openSellsDialog(null, "Return"),
    },
  ];

  dbUpdated: boolean;
  _dbUpdating$ = new BehaviorSubject(true);

  sellsSumDataUnfiltered: {}[] = this._dataService.getLS("sellsSumData") || [];
  sellsSumData = this._dataService.getLS("sellsSumData") || [];
  sellsSumTotal: number = this._dataService.getLS("sellsSumTotal") || 0;
  sellsReturnTotal: number = this._dataService.getLS("sellsReturnTotal") || 0;

  contextMenuData = { x: 0, y: 0, data: null };
  hoverData;

  protected gridApi!: GridApi;
  agGridData$: Observable<any[]>;
  columnDefs = [
    {
      headerName: "id",
      field: "id",
      hide: true,
    },
    {
      headerName: "Tərəf Müqabil",
      field: "customerName",
      sortable: true,
      flex: 3,
      minWidth: 150,
      filterValueGetter: (params) =>
        this._dataService.latinizeText(params, "customerName"),
      cellRenderer: (params) => {
        return params.data.refersTo
          ? `${params.value}<span>R</span>`
          : params.value;
      },
      cellClass: (params) => {
        return params.data.refersTo ? "editedTransaction" : "";
      },
    },
    {
      headerName: "Toplam, AZN",
      field: "sum",
      sortable: true,
      flex: 2,
      minWidth: 120,
      cellClass: (params) => {
        return params.value < 0 ? "minusValueRedColor" : "";
      },
      cellRenderer: (params) => {
        return typeof params.value === "number"
          ? params.value
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          : "";
      },
      cellStyle: { "word-spacing": "-2px !important", "white-space": "nowrap" },
    },
    {
      headerName: "Tarix",
      field: "date",
      flex: 2,
      minWidth: 120,
      sortable: true,
      comparator: (dateA, dateB, nodeA, nodeB, isInverted) => {
        if (dateA === dateB) {
          // If dates are equal, sort by id (descending)
          const idA = nodeA.data.id;
          const idB = nodeB.data.id;
          return idA > idB ? -1 : idA < idB ? 1 : 0;
        } else {
          // Sort by date (descending)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      },
      cellRenderer: (params) => {
        return params.value.split("-").reverse().join("-");
      },
    },
    { headerName: "Məlumat", field: "details", flex: 2, minWidth: 100 },
    {
      headerName: "",
      field: "delete",
      flex: 1,
      maxWidth: 50,
      cellRenderer: () => {
        return `<span class='d-flex align-items-center h-100'><i class='material-icons'>more_horiz</i></span>`;
      },
      onCellClicked: (params) => {
        this.contextMenu(params);
      },
    },
  ];
  public getRowId: GetRowIdFunc = (params) => params.data?.id;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private _pusher: PusherService,
    protected _productService: ProductsService
  ) {
    effect(async () => {
      this._dataService.products();
      this._dataService.materials();
      this._dataService.customers();
      this.agGridData$ = this.getSellsTableData();
    });
  }

  ngOnInit(): void {
    this.agGridData$ = this.getSellsTableData();
  }

  getSellsTableData() {
    const localData = this._dataService.getLS("sellsTable") || [];
    const localObservable = defer(() => {
      return of(localData);
    });
    const liveQueryObservable = combineLatest([
      this._dataService.dateChanged,
      this._dbUpdating$.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        this.dataLoading = true;
      }),
      switchMap(() =>
        from(
          liveQuery(() =>
            this.getTransactions(
              this._dataService.startDate.value.format("YYYY-MM-DD"),
              this._dataService.endDate.value.format("YYYY-MM-DD")
            )
          )
        ).pipe(
          map((things) =>
            things.map((thing) => {
              const customer = this._dataService
                .customers()
                .find((c) => c.id === thing.customerId);
              return {
                ...thing,
                sum: thing.type === "Sells" ? thing.sum : -thing.sum,
                customerName: customer
                  ? customer.hasOwnProperty("name")
                    ? customer.name
                    : ""
                  : thing.customerId === 0
                  ? "Pərakəndə"
                  : "",
              };
            })
          ),
          tap((data) => {
            this.sellsSumTotal = data.reduce(
              (acc, value) => (value.type == "Sells" ? acc + value.sum : acc),
              0
            );

            this.sellsReturnTotal = data.reduce(
              (acc, value) => (value.type != "Sells" ? acc + value.sum : acc),
              0
            );
            this._dataService.setLS("sellsSumTotal", this.sellsSumTotal);
            this._dataService.setLS("sellsReturnTotal", this.sellsReturnTotal);
            this._dataService.setLS("sellsTable", data.slice(-100));

            setTimeout(() => {
              this.dataLoading = false;
              if (this.searchInput && this.searchInput.nativeElement.value) {
                this.applyFilter(this.searchInput.nativeElement.value);
              }
            }, 500);
          })
        )
      ),
      takeUntilDestroyed(this.destroyRef)
    );

    return merge(localObservable, liveQueryObservable);
  }

  async getTransactions(startDate: Date, endDate: Date) {
    const sellsTransactions = await db.transactions
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray()
      .then((transaction) =>
        transaction.filter((t) => t.type == "Sells" || t.type == "Return")
      );

    this.getSellTotalsByProduct(startDate, endDate);
    return sellsTransactions;
  }

  async getSellTotalsByProduct(startDate, endDate) {
    type ProductTotal = {
      productId: number;
      materialId: number;
      productName: string;
      sumBetweenDates: number;
      sumBeforeStartDate: number;
      productPrice: number;
      productUnit?: string;
      totalQuantity: number;
      totalQuantityBeforeStartDate: number;
      type: string;
      formulaCost;
      revenue;
    };

    const sells = await db.sells
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray();

    const sellsBeforeStartDate = await db.sells
      .where("date")
      .below(startDate)
      .toArray();

    const productTotals: { [key: string]: ProductTotal } = sells.reduce(
      (acc, sell) => {
        const { productId, materialId, quantity, price } = sell;
        const productTotal = quantity * price;

        let product = this._dataService
          .products()
          .find((product) => product.id == productId);

        let formulaCost = product.width
          ? (this._dataService
              .formulaGroups()
              .find((formula) => formula.id == product.formulaGroupId)?.cost *
              product.weight) /
              product.width || 0
          : this._dataService
              .formulaGroups()
              .find((formula) => formula.id == product.formulaGroupId)?.cost *
              product.weight || 0;

        acc[productId] = acc[productId] || {
          productId,
          materialId,
          productName: productId
            ? this._dataService
                .products()
                .find((product) => product.id === productId)?.name
            : this._dataService
                .materials()
                .find((product) => product.id === materialId)?.name,
          productPrice: productId
            ? this._dataService
                .products()
                .find((product) => product.id === productId)?.sellPrice
            : this._dataService
                .materials()
                .find((product) => product.id === materialId)?.price,
          productUnit: productId
            ? this._dataService
                .products()
                .find((product) => product.id === productId)?.unit
            : this._dataService
                .materials()
                .find((product) => product.id === materialId)?.unit,
          sumBetweenDates: 0,
          sumBeforeStartDate: 0,
          totalQuantity: 0,
          totalQuantityBeforeStartDate: 0,
          type: productId ? "product" : "material",
          formulaCost,
        };

        acc[productId].revenue += productTotal - formulaCost * quantity;
        acc[productId].sumBetweenDates += productTotal;
        acc[productId].totalQuantity += quantity;

        if (materialId) {
          acc[materialId] = acc[materialId] || {
            productId,
            materialId,
            productName: productId
              ? this._dataService
                  .products()
                  .find((product) => product.id === productId)?.name
              : this._dataService
                  .materials()
                  .find((product) => product.id === materialId)?.name,
            productPrice: productId
              ? this._dataService
                  .products()
                  .find((product) => product.id === productId)?.sellPrice
              : this._dataService
                  .materials()
                  .find((product) => product.id === materialId)?.price,
            productUnit: productId
              ? this._dataService
                  .products()
                  .find((product) => product.id === productId)?.unit
              : this._dataService
                  .materials()
                  .find((product) => product.id === materialId)?.unit,
            sumBetweenDates: 0,
            sumBeforeStartDate: 0,
            totalQuantity: 0,
            totalQuantityBeforeStartDate: 0,
            type: productId ? "product" : "material",
            formulaCost: 0,
          };

          acc[materialId].sumBetweenDates += productTotal;
          acc[materialId].totalQuantity += quantity;
        }

        return acc;
      },
      {}
    );

    sellsBeforeStartDate.forEach((sell) => {
      const { productId, materialId, quantity, price } = sell;
      const productTotal = quantity * price;

      productTotals[productId] = productTotals[productId] || {
        productId,
        materialId,
        productName: productId
          ? this._dataService
              .products()
              .find((product) => product.id === productId)?.name
          : this._dataService
              .materials()
              .find((product) => product.id === materialId)?.name,
        productPrice: this._dataService
          .products()
          .find((product) => product.id === productId)?.sellPrice,
        productUnit: this._dataService
          .products()
          .find((product) => product.id === productId)?.unit,
        sumBetweenDates: 0,
        sumBeforeStartDate: 0,
        totalQuantity: 0,
        totalQuantityBeforeStartDate: 0,
        type: productId ? "product" : "material",
        formulaCost: 0,
        revenue: 0,
      };

      productTotals[productId].sumBeforeStartDate += productTotal;
      productTotals[productId].totalQuantityBeforeStartDate += quantity;
    });

    const result = Object.values(productTotals);

    // Sort the result array by sumBetweenDates in descending order
    result.sort((a, b) => b.sumBetweenDates - a.sumBetweenDates);

    // Filter out objects with sumBetweenDates equal to 0
    const filteredResult = result.filter((item) => item.sumBetweenDates !== 0);

    this.sellsSumData = filteredResult;
    this._dataService.setLS("sellsSumData", filteredResult);

    return filteredResult;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "asc", sortIndex: 0 },
    ];
    params.api.applyColumnState({ state: defaultSortModel });
  }

  applyFilter(val, input?) {
    if(!val && input) {
      input.value = '';
    }
    this.gridApi.setGridOption("quickFilterText", val);
    this.sellsSumTotal = 0;
    this.sellsReturnTotal = 0;
    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
      this.sellsSumTotal += node.data.sum >= 0 ? node.data.sum : 0;
      this.sellsReturnTotal += node.data.sum < 0 ? node.data.sum : 0;
    });
    this.sellsSumData = this.sellsSumDataUnfiltered.filter(function (o: any) {
      return (
        latinize(o.productName.replace(/\s/g, "").toLowerCase()).indexOf(
          latinize(val.replace(/\s/g, "").toLowerCase())
        ) > -1
      );
    });
  }

  onWheel(event: WheelEvent): void {
    if (
      !this.cardsContainer?.nativeElement?.className?.includes("expandedGraphs")
    ) {
      if (event.deltaMode === WheelEvent.DOM_DELTA_PIXEL) {
        this.cardsContainer.nativeElement.scrollLeft += event.deltaY;
        // event.preventDefault();
      }
    }
  }

  productDetailsDialog(product) {
    console.log(product);
    if (product.productId) {
      this._productService.ProductDetailsDialog(product.productId);
    }
    if (product.materialId) {
      this._stockService.materialDialog(product.materialId);
    }
  }

  deleteEntry(row) {
    this._dataService.showContextMenu = false;
    let data =
      row.customerName +
      ` - ` +
      row.sum.toFixed(3).replace(/\.(\d\d)\d?$/, ".$1") +
      " ₼";
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.dataLoading = true;
        this.http
          .put(
            this._dataService.dbBase + "api.php/records/transactions/" + row.id,
            { status: 1 }
          )
          .subscribe(async () => {
            await db.transactions.delete(row.id);
            await db.sells.where("tId").equals(row.id).delete();
            let gridRow = this.gridApi.getRowNode(row.id);
            if (gridRow) {
              this.gridApi.applyTransaction({
                remove: [gridRow],
              });
              this.gridApi.refreshCells();
            }
            this._pusher.postData("Sell Entry Deleted").subscribe();
            this.toastr.success("", "Silindi!", {
              toastClass: "ngx-toastr customToast",
              progressBar: true,
              timeOut: 2500,
              progressAnimation: "increasing",
            });
            setTimeout(() => {
              this.dataLoading = false;
            }, 700);
          });
      }
    });
  }

  openSellsDialog(
    transactionId?,
    transactionType?: string,
    rowData?,
    event?,
    editMode?: boolean,
    copyMode?: boolean
  ) {
    if ((event && event.event.target.innerText != "more_horiz") || !event) {
      this._dataService.showContextMenu = false;
      let data = null;
      if (transactionId) {
        data = rowData;
        data.transactionId = transactionId;
        data.editMode = editMode;
        data.copyMode = copyMode;
      } else {
        data = { type: transactionType || "Sells" };
      }
      const dialogRef = this.dialog.open(SellsDialogComponent, {
        width: transactionId ? "1000px" : "950px",
        maxHeight: "700px",
        data: data,
        closeOnNavigation: true,
        disableClose: this._dataService.mobileDevice
          ? false
          : editMode || copyMode || !transactionId
          ? true
          : false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          console.log(res);
          const startDate = new Date(
            this._dataService.startDate.value.format("YYYY-MM-DD")
          );
          const endDate = new Date(
            this._dataService.endDate.value.format("YYYY-MM-DD")
          );
          const targetDate = new Date(res.date);
          if (targetDate < startDate || targetDate > endDate) {
            this.gridApi.applyTransaction({
              add: [
                {
                  ...res,
                  // bankGroup: res.bankGroupName,
                  // transactionId: res.tId,
                  // transaction: res.transactionId,
                  // justAdded: true,
                },
              ],
            });
            if (res.remove) {
              let row = this.gridApi.getRowNode(res.remove);
              this.gridApi.applyTransaction({
                remove: [row],
              });
              this.gridApi.refreshCells();
            }
            // setTimeout(() => {
            //   let row = this.gridApi.getRowNode(res.id);
            //   row.data.justAdded = false;
            //   this.gridApi.applyTransaction({
            //     update: [row],
            //   });
            // }, 3000);
          }

          // let data = { ...res, bankGroupId: "loading" };
        }
      });
    }
  }

  contextMenu(data) {
    let lastOpened = this.contextMenuData.data
      ? this.contextMenuData.data.id
      : null;
    data.event.preventDefault();
    const targetElement = data.event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();

    this.contextMenuData = {
      x: null,
      y: rect.bottom + window.scrollY,
      data: data.data,
    };
    this._dataService.showContextMenu =
      lastOpened == this.contextMenuData.data.id
        ? !this._dataService.showContextMenu
        : true;
  }

  onCellMouseOver(params) {
    if (!this._dataService.showContextMenu)
      this.contextMenuData.data = params.data;
    this.hoverData = params.data;
  }

  onRightClick(event?) {
    event.preventDefault();
    if (this.contextMenuData.data != this.hoverData) {
      this.contextMenuData.data = this.hoverData;
    }
    const targetElement = event.target as HTMLElement;
    const rect = targetElement.getBoundingClientRect();

    this.contextMenuData.x = event.clientX;
    this.contextMenuData.y = rect.bottom + window.scrollY;

    this._dataService.showContextMenu = true;
  }
}
