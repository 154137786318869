<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
>
  <div
    *ngIf="dialogData.transactionId && dialogData.type != 'Return' && !copyMode"
    class="ui-titletext"
  >
    Satış Qaiməsi
    <span *ngIf="dialogData.transactionId && !copyMode" class="transactionNo"
      ><span class="pcOnly" style="margin-right: -5px">Qaimə</span
      >{{ transactionNo }}</span
    >
  </div>
  <div
    *ngIf="
      (!dialogData.transactionId && dialogData.type == 'Sells') || copyMode
    "
    class="ui-titletext"
  >
    Yeni Satış
  </div>
  <div *ngIf="dialogData.type == 'Return'" class="ui-titletext">
    Qayıtma
    <span *ngIf="dialogData.transactionId && !copyMode" class="transactionNo"
      ><span class="pcOnly" style="margin-right: -5px">Qaimə</span
      >{{ transactionNo }}</span
    >
  </div>
  <div class="ui-titlecontrols" *ngIf="dialogData.transactionId && !copyMode">
    <button
      class="ui-btn operations"
      (click)="showContextMenu = !showContextMenu"
    >
      <i
        class="material-symbols-outlined"
        style="color: white; font-weight: 500"
        >more_horiz</i
      >
    </button>
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>

  <div
    class="context-menu filterSettings"
    *ngIf="showContextMenu"
    [style.right.px]="45"
    [style.top.px]="36"
  >
    <div class="filterItems">
      <button
        (click)="editMode = true; copyMode = false; showContextMenu = false"
        [disabled]="editMode && !copyMode"
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      >
        <span class="material-symbols-outlined"> edit </span>
        <span class="w-100">Düzəliş et</span>
      </button>
      <button
        (click)="editMode = true; copyMode = true; showContextMenu = false"
        [disabled]="editMode && copyMode"
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      >
        <span class="material-symbols-outlined"> content_copy </span>
        <span class="w-100">Kopyala</span>
      </button>
    </div>
  </div>
</div>

<!-- TOP SECTION CUSTOMER/DATE-->
<div appFocusNextOnEnter (click)="showContextMenu = false">
  <div class="container">
    <div
      class="row py-3 px-3"
      style="background-color: #f6f6f6; margin-top: 0px"
    >
      <div
        class="col-md-3 col-sm-12"
        [ngClass]="{ 'col-md-4': !dialogData.transactionId || editMode }"
      >
        <div class="input-group">
          <div class="input-group-text">
            <i class="material-icons">person</i>
          </div>
          <ng-select
            #customerInput
            [items]="customers() ?? []"
            bindLabel="name"
            bindValue="id"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="_customersService.selectedCustomerId"
            placeholder="Müştəri seçin"
            name="customerId"
            class="form-control"
            [searchFn]="_dataService.customSearchFn"
            (change)="getUserBalance(_customersService.selectedCustomerId)"
            cdkFocusInitial
            [disabled]="dialogData.transactionId && !editMode"
            required
          >
          </ng-select>
          <div
            *ngIf="_customersService.selectedCustomerId"
            matTooltip="Tərəf-Müqabil məlumatları"
            matTooltipClass="line-broken-tooltip roundedTooltip"
            matTooltipPosition="right"
            class="input-group-text inputGroupButtons cardHover"
            style="cursor: pointer"
          >
            <a
              (click)="
                _customersService.customerDialog({
                  customerId: _customersService.selectedCustomerId
                })
              "
            >
              <mat-icon class="material-icons">info_outline</mat-icon>
            </a>
          </div>
          <div
            *ngIf="!dialogData.transactionId && dialogData?.type == 'Sells'"
            matTooltip="Yeni Tərəf-Müqabil"
            matTooltipClass="line-broken-tooltip"
            class="input-group-text inputGroupButtons cardHover"
            style="cursor: pointer"
          >
            <a (click)="_customersService.customerDialog()">
              <mat-icon class="material-icons">add</mat-icon>
            </a>
          </div>
        </div>

        <div
          *ngIf="customerBalance && _customersService.selectedCustomerId"
          style="
            font-size: 13px;
            margin-left: 50px;
            position: absolute;
            margin-top: -3px;
            font-family: 'Mulish';
          "
          [style.color]="
            customerBalance > 0
              ? '#d81c1c'
              : customerBalance < 0
              ? '#2c4c66'
              : null
          "
        >
          {{
            (customerBalance >= 0 ? customerBalance : -customerBalance) || ""
          }}AZN
          {{
            customerBalance < 0
              ? "Tərəf-Müqabilə borclusunuz"
              : customerBalance > 0
              ? "Tərəf-Müqabil sizə borcludur"
              : ""
          }}
        </div>
      </div>

      <div
        class="col-md-3 col-sm-12 mt-smm-5"
        [ngClass]="{ 'col-md-4': !dialogData.transactionId || editMode }"
      >
        <!-- <label class="form-label" for="contact">Anbar</label> -->
        <div class="input-group">
          <div class="input-group-text">
            <i class="material-icons">inventory</i>
          </div>
          <ng-select
            matTooltip="{{
              dialogData.type == 'Return' ? 'Giriş anbarı' : 'Çıxış anbarı'
            }}"
            matTooltipClass="line-broken-tooltip roundedTooltip"
            [items]="stockGroups()"
            bindLabel="name"
            bindValue="id"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="selectedStockGroupId"
            [disabled]="dialogData.transactionId && !editMode"
            name="stockGroupId"
            class="form-control"
            (change)="getMaterialSums()"
          >
          </ng-select>
          <!-- <div class="input-group-text inputGroupButtons" style="cursor: pointer;">
                  <a (click)="newCustomerDialog()" ><mat-icon class="material-icons">add</mat-icon></a>
              </div> -->
        </div>
      </div>

      <div
        class="col-md-3 col-sm-12 mt-smm-5"
        [ngClass]="{ 'col-md-4': !dialogData.transactionId || editMode }"
      >
        <div class="input-group">
          <div
            class="input-group-text cardHover"
            style="
              padding: 0px;
              width: 49px;
              height: 38px;
              display: flex;
              justify-content: center;
            "
          >
            <mat-datepicker-toggle [for]="myDatepicker" tabindex="-1">
              <mat-icon
                style="color: #0f4c7d; line-height: 21px"
                matDatepickerToggleIcon
                >calendar_month</mat-icon
              >
            </mat-datepicker-toggle>
          </div>
          <input
            #dateInput
            [(ngModel)]="date.value"
            [matDatepicker]="myDatepicker"
            class="form-control"
            id="date"
            name="date"
            placeholder="{{
              _dataService.endDate.value
                ? _dataService.endDate.value?.format('DD/MM/YYYY') ?? ''
                : _dataService.startDate.value?.format('DD/MM/YYYY') ?? ''
            }}"
            (click)="dateInput.value = ''"
            (ngModelChange)="_dataService.formatDateValue(dateInput)"
            tabindex="-1"
            [disabled]="dialogData.transactionId && !editMode"
            style="
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            "
          />
          <mat-datepicker #myDatepicker></mat-datepicker>
        </div>
      </div>

      <div
        *ngIf="dialogData.transactionId && !editMode"
        class="col-md-3 col-sm-12 mt-smm-5"
      >
        <div class="input-group">
          <div class="input-group-text">
            <span class="manat fw-500">₼</span>
          </div>
          <input
            type="text"
            placeholder="Toplam Satış Qiyməti"
            class="form-control"
            min="0"
            [(ngModel)]="sum"
            name="priceSum"
            tabindex="-1"
            disabled="true"
            readonly
          />
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <form #inv="ngForm" (keydown.enter)="$event.preventDefault()">
      <div class="container">
        <div *ngIf="dataLoading" class="row g-2 pb-3 py-2 px-2">
          <div
            *ngIf="dataLoading"
            class="container ms-1 d-flex justify-content-center"
            style="padding-top: 10px; margin-top: 2rem"
          >
            <div class="lds-dual-ring"></div>
          </div>
        </div>

        <!-- TODO TEMPORARY SOLUTION. ADD BUTTON ACTIVATES EVEN IF ABOVE CUSTOMER SELECTION ISNT SELECTED
        BECAUSE ITS OUTSIDE OF inv ngForm -->
        <input
          [(ngModel)]="_customersService.selectedCustomerId"
          type="text"
          placeholder="Miqdar"
          name="customerId"
          class="d-none"
          autocomplete="off"
          required
        />

        <!-- PRODUCTS -->
        <div class="row pb-3 pt-2 px-3">
          <div *ngIf="!dataLoading" class="col-12 d-flex flex-column">
            <div *ngIf="productsToSell?.length">
              <div ngModelGroup="products">
                <div
                  class="materialsTable mobileMaterialsList materialsTableHeader"
                  [ngClass]="{
                    materialsTableWithDel:
                      productsToSell.length > 1 &&
                      !_dataService.mobileDevice &&
                      (!dialogData.transactionId || editMode)
                  }"
                  *ngIf="!_dataService.mobileDevice"
                >
                  <div class="form-label">Məhsul</div>
                  <div class="form-label">Miqdar</div>
                  <div class="form-label">Qiymət</div>
                  <div class="form-label">Toplam Məbləğ</div>
                </div>

                <!-- PRODUCTS -->
                <div
                  ngModelGroup="{{ i }}"
                  *ngFor="
                    let product of productsToSell;
                    let i = index;
                    trackBy: _dataService.trackByFn
                  "
                  class="mt-2 materialsTable mobileMaterialsList"
                  [ngClass]="{
                    materialsTableWithDel:
                      productsToSell.length > 1 &&
                      !_dataService.mobileDevice &&
                      (!dialogData.transactionId || editMode)
                  }"
                >
                  <div class="1">
                    <div class="input-group">
                      <div class="input-group-text fw-500 pcOnly">
                        <span *ngIf="productsToSell.length > 1">
                          @if(product.unedited) {
                          <span
                            style="display: flex"
                            class="material-symbols-outlined"
                          >
                            edit_note
                          </span>
                          } @else {
                          {{ i + 1 }}
                          }</span
                        >
                        <mat-icon *ngIf="productsToSell.length == 1"
                          >sell</mat-icon
                        >
                      </div>
                      <ng-select
                        #input
                        [items]="products"
                        bindLabel="name"
                        bindValue="uniqueId"
                        [loading]="dataLoading"
                        appendTo="body"
                        loadingText="Yüklənir .."
                        notFoundText="Tapılmadı"
                        placeholder="Məhsul seçin"
                        [(ngModel)]="product.id"
                        groupBy="category"
                        name="id"
                        class="form-control customNg"
                        required
                        [searchFn]="_dataService.customSearchFn"
                        (change)="getProductDetails(product.id, product)"
                        [disabled]="dialogData.transactionId && !editMode"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <div
                            [ngClass]="
                              !item.totalQuantity || item.totalQuantity <= 0
                                ? 'bg-red'
                                : 'option-padding'
                            "
                          >
                            <div>
                              <span style="font-weight: 600; font-size: 17px">{{
                                item.name
                              }}</span>
                            </div>
                            <small
                              ><span style="font-weight: 500">Qalıq:</span>
                              {{
                                item.totalQuantity
                                  ? (item.totalQuantity | number | removeComma)
                                  : 0
                              }}
                              {{ item.unit || "ədəd" }}</small
                            >
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        [(ngModel)]="product.quantity"
                        (ngModelChange)="
                          product.quantity = _dataService.fixInputValue(
                            product.quantity
                          )
                        "
                        (keypress)="_dataService.getNumericsOnly($event)"
                        (focus)="product.quantity = null"
                        (focusout)="
                          product.quantity = _dataService.calculateInput(
                            product.quantity
                          );
                          calculateMaterialPrice(product, i)
                        "
                        type="text"
                        placeholder="Miqdar"
                        class="form-control"
                        autocomplete="off"
                        name="quantity"
                        required
                        [disabled]="dialogData.transactionId && !editMode"
                      />
                      <div
                        class="input-group-text"
                        style="
                          width: 43px;
                          display: flex;
                          justify-content: center;
                        "
                      >
                        <span style="font-weight: 500">{{
                          product.unit ? (product.unit | slice : 0 : 2) : "əd"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="input-group">
                      <input
                        [(ngModel)]="product.sellPrice"
                        (ngModelChange)="
                          product.sellPrice = _dataService.fixInputValue(
                            product.sellPrice
                          )
                        "
                        (keypress)="_dataService.getNumericsOnly($event)"
                        (focus)="product.sellPrice = null"
                        (focusout)="
                          product.sellPrice = _dataService.calculateInput(
                            product.sellPrice
                          );
                          calculateMaterialPrice(product, i)
                        "
                        type="text"
                        placeholder="{{
                          product.sellPricePlaceholder
                            ? product.sellPricePlaceholder
                            : 'Qiymət'
                        }}"
                        class="form-control"
                        autocomplete="off"
                        name="price"
                        required
                        [disabled]="dialogData.transactionId && !editMode"
                      />
                      <div class="input-group-text">
                        <span class="manat fw-500">₼</span>
                      </div>
                    </div>
                  </div>
                  <div class="priceTotal">
                    <div class="input-group">
                      <input
                        [(ngModel)]="product.sum"
                        (ngModelChange)="
                          product.sum = _dataService.fixInputValue(product.sum)
                        "
                        (keypress)="_dataService.getNumericsOnly($event)"
                        (focus)="product.sum = null"
                        (focusout)="
                          product.sum = _dataService.calculateInput(
                            product.sum
                          );
                          calculateMaterialPrice(product, i, 'sum')
                        "
                        type="text"
                        placeholder="Məbləğ"
                        class="form-control"
                        tabindex="-1"
                        name="mebleg"
                        [disabled]="dialogData.transactionId && !editMode"
                      />
                      <div class="input-group-text">
                        <span class="manat fw-500">₼</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="btnDeleteContainer"
                    *ngIf="
                      productsToSell.length > 1 &&
                      (!dialogData.transactionId || editMode)
                    "
                  >
                    <button
                      class="btn btn-outline-primary btnDelete"
                      (click)="
                        removeProduct(i, $event);
                        calculateMaterialPrice(inv.value, i)
                      "
                      matTooltip="Məhsulu sil"
                      [matTooltipPosition]="'right'"
                      matTooltipClass="line-broken-tooltip roundedTooltip"
                      tabindex="-1"
                    >
                      <mat-icon class="material-icons">clear</mat-icon>
                    </button>
                  </div>
                  @if(i!=productsToSell.length-1) {
                  <hr class="mobileOnly w-100 grid-span-2-mobile" />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- PRICE STUFF -->
        <div
          class="row px-3"
          style="padding: 0.5rem 0rem 1rem; border-top: 1px solid #ececec"
        >
          <div
            class="col-md-1 d-flex align-items-end"
            *ngIf="!dialogData.transactionId || editMode"
          >
            <button
              class="btn btn-outline-secondary"
              style="
                background: #e9ecef;
                border: 1px solid #cecece !important;
                color: rgb(44, 76, 102);
                width: 50px;
                height: 90%;
                min-height: 37px;
              "
              (click)="nextProduct()"
              matTooltip="Yeni Məhsul 
                        Qısayol: Shift + N"
              matTooltipClass="line-broken-tooltip"
              [matTooltipPosition]="'right'"
              tabindex="-1"
            >
              <mat-icon class="material-icons">add</mat-icon>
            </button>
          </div>
          <div
            *ngIf="!dialogData.transactionId || editMode"
            class="col"
            style="padding-left: 0px !important"
          >
            <label class="form-label" for="priceSum"
              >Toplam Satış Qiyməti</label
            >
            <div class="input-group">
              <div class="input-group-text manat fw-500">₼</div>
              <input
                id="priceSum"
                type="number"
                placeholder="Toplam Satış Qiyməti"
                class="form-control"
                min="0"
                [(ngModel)]="sum"
                name="priceSum"
                tabindex="-1"
                readonly
              />
            </div>
          </div>

          <div
            class="col"
            *ngIf="
              (!dialogData.transactionId && dialogData?.type == 'Sells') ||
              editMode
            "
          >
            <label class="form-label" for="priceReceived">Alınan Məbləğ</label>
            <div class="input-group">
              <div class="input-group-text manat fw-500">₼</div>
              <input
                type="text"
                id="priceReceived"
                placeholder="Alınan Məbləğ"
                class="form-control"
                min="0"
                [(ngModel)]="priceReceived"
                (ngModelChange)="residue = sum - priceReceived"
                (keypress)="_dataService.getNumericsOnly($event)"
                (focus)="priceReceived ? priceReceived : (priceReceived = '')"
                (focusout)="
                  priceReceived.length > 0
                    ? priceReceived
                    : (priceReceived = 0);
                  priceReceived != 0
                    ? (priceReceived =
                        _dataService.calculateInput(priceReceived))
                    : (priceReceived = 0);
                  residue = sum - priceReceived
                "
                name="priceReceived"
                required
                [disabled]="dialogData.transactionId && !canUpdate"
              />
            </div>
          </div>

          <div
            class="col"
            *ngIf="
              ((!dialogData.transactionId && dialogData?.type == 'Sells') ||
                editMode) &&
              priceReceived
            "
          >
            <label class="form-label" for="residue">Qalıq</label>
            <div class="input-group">
              <div class="input-group-text manat fw-500">₼</div>
              <input
                type="number"
                id="residue"
                placeholder="Qalıq"
                class="form-control"
                min="0"
                [(ngModel)]="residue"
                name="residue"
                tabindex="-1"
                readonly
              />
            </div>
          </div>
        </div>

        <!-- DETAILS -->
        <div
          class="row px-3 pb-3"
          [ngClass]="{ 'pb-4': dialogData.transactionId }"
          style="margin-top: 0px"
        >
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-text">
                <mat-icon>info_outline</mat-icon>
              </div>
              <textarea
                placeholder="Məlumat"
                class="form-control"
                id="details"
                rows="2"
                name="details"
                [(ngModel)]="details"
                [disabled]="dialogData.transactionId && !editMode"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
<div
  class="d-grid"
  style="
    background-color: #f6f6f6;
    border-top: 1px solid #ececec;
    padding: 0.75rem 1.25rem;
  "
>
  <button
    #btnAdd
    class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex justify-content-center align-items-center"
    (click)="addSell(inv.value, btnAdd)"
    [disabled]="!inv.valid"
    *ngIf="!dialogData?.transactionId || editMode"
  >
    <span style="font-size: 1.1rem">{{
      dialogData?.transactionId && !copyMode ? "Yenilə" : "Əlavə et"
    }}</span>
    <div *ngIf="btnDataLoading" class="lds-dual-ring"></div>
  </button>
  @if(user && !editMode) {
  <span
    class="userName"
    style="
      margin: 0px 0px;
      color: #606060;
      position: absolute;
      right: 25px;
      bottom: 3px;
    "
    ><span class="material-symbols-outlined"> person </span>{{ user }}</span
  >
  } @if(refersTo && !editMode) {
  <span
    class="userName"
    (click)="getUneditedVer()"
    style="
      margin: 0px 0px;
      color: #606060;
      position: absolute;
      left: 25px;
      bottom: 3px;
      cursor: pointer;
    "
    >Redaktə{{ ": " + (dialogData?.dateAdded ?? "") }}</span
  >
  }
</div>
