import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/data.service";
import { NgForm, UntypedFormControl } from "@angular/forms";
import { map, switchMap } from "rxjs/operators";
import "lodash";
declare var _: any;
import * as _moment from "moment";
import { StockService } from "src/app/stock/stock.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { db } from "src/app/db.service";
import { AuthService } from "src/app/auth.service";
const moment = _moment;

@Component({
  selector: "app-stock-transfer",
  templateUrl: "./stock-transfer.component.html",
  styleUrls: ["./stock-transfer.component.css"],
})
export class StockTransferComponent {
  focusables = ["input", "select", "textarea"];
  @ViewChildren("input") inputs: QueryList<NgSelectComponent>;
  @ViewChild("inv") form: NgForm;
  materials;
  cashBox = this._dataService.stockGroups();
  stockGroupIdFrom: number;
  stockGroupIdTo: number;
  date;
  details: string;
  transactionNo: string = "";
  materialsToAdd: {
    id: number;
    quantity: number;
    length?: number;
    sum?: number;
    unit?: string;
  }[] = [
    {
      id: null,
      quantity: null,
    },
  ];
  materialSums;
  sum;
  priceReceived;
  residue;
  selectedStockGroupId;
  user: string;

  constructor(
    public dialogRef: MatDialogRef<StockTransferComponent>,
    private http: HttpClient,
    private toastr: ToastrService,
    public _dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    protected _stockService: StockService,
    private _authService: AuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.date = new UntypedFormControl(moment());

    console.log(this.dialogData);

    if (this.dialogData) {
      this.stockGroupIdFrom = this.dialogData?.selectedStockGroupId;
    }
    if (this.dialogData.data) {
      let data;
      this.details = this.dialogData.data.details;
      this.date = new UntypedFormControl(moment(this.dialogData.data.date));
      this.transactionNo = this.dialogData.data.tId;
      data = await db.stock
        .where("tId")
        .equals(this.dialogData.data.tId)
        .toArray();
      this.stockGroupIdFrom = data.find((d) => d.quantity < 0).stockGroupId;
      this.stockGroupIdTo = data.find((d) => d.quantity >= 0).stockGroupId;
      this.materialsToAdd = [];
      let dialogMaterial = data.filter((m) => m.quantity >= 0);
      dialogMaterial.forEach((m) => {
        let material = _.find(this._dataService.materials(), {
          id: m.materialId,
        });
        this.materialsToAdd.push({
          id: m.materialId,
          quantity: m.quantity,
          unit: material.unit || "əd",
        });
      });

      if (
        this.dialogData?.data?.userId ||
        this.dialogData?.data?.transactionId?.userId
      ) {
        let userName =
          this._dataService
            .users()
            .find((u) => u.id == this.dialogData?.data?.userId) ??
          this._dataService
            .users()
            .find(
              (u) => u.id == this.dialogData?.data?.transactionId?.userId
            ) ??
          null;
        this.user = userName ? userName.fullname : null;
      }
    } else {
      this.stockGroupIdFrom =
        this._dataService.stockGroups().find((st) => st.type == 1)?.id ?? null;
      this.stockGroupIdTo =
        this._dataService.stockGroups().find((st) => st.type == 2)?.id ?? null;
    }
    this.getMaterialSums();
  }

  addTransferOrder(data, event) {
    event.srcElement.disabled = true;
    event.target.innerText = "Gözləyin ...";
    let products: IApiResult = Object.values(data.products);
    let stockEntry = [];
    let today = new UntypedFormControl(moment());

    var transaction: IApiResult = {
      type: "Stock Transfer",
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD HH:mm:ss"),
      details: data.details,
      userId: this._authService.loggedInUserValue.id ?? null,
    };

    this.http
      .post(
        this._dataService.dbBase + "api.php/records/transactions/",
        transaction
      )
      .pipe(
        map((transactionId) => {
          transaction.id = transactionId;
          products.forEach((f) => {
            stockEntry.push({
              materialId: f.materialId,
              transactionId: transactionId,
              stockGroupId: this.stockGroupIdFrom,
              quantity: -f.quantity,
              price: 0,
            });
            stockEntry.push({
              materialId: f.materialId,
              transactionId: transactionId,
              stockGroupId: this.stockGroupIdTo,
              quantity: f.quantity,
              price: 0,
            });
          });
        }),
        switchMap(() =>
          this.http.post(
            this._dataService.dbBase + "api.php/records/stock/",
            stockEntry
          )
        )
      )
      .subscribe(
        (res) => {
          this.dialogRef.close(res);
          this.toastr.success("Əlavə edildi!");
        },
        (err) => {
          this.toastr.error(err);
        }
      );
  }

  getMaterialDetails(materialId, index) {
    this.materialsToAdd[index].unit = _.find(this._dataService.materials(), {
      id: materialId,
    })?.unit
      ? _.find(this._dataService.materials(), { id: materialId })?.unit
      : null;
  }

  async getMaterialSums() {
    this._dataService.materials().forEach((material) => {
      material.totalQuantity = 0;
    });
    this.materialSums = await this._stockService.getStockCards(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD"),
      this.stockGroupIdFrom
    );

    this.materialSums.forEach((material) => {
      if (
        this._dataService.materials().find((p) => p.id === material.materialId)
      ) {
        this._dataService
          .materials()
          .find((p) => p.id === material.materialId).totalQuantity = parseFloat(
          (material.totalQuantity + material.totalQuantityBeforeDate).toFixed(3)
        );
      }
    });
  }

  nextProduct() {
    this.materialsToAdd.push({
      id: null,
      quantity: null,
    });
    console.log(this.materialsToAdd);
    this.materialsToAdd = this.materialsToAdd;
    this.changeDetectorRef.detectChanges();
    this.inputs.last.focus();
  }

  // DELETE FORMULA
  removeProduct(i, event?) {
    if (event.key == "Enter") {
      return false;
    }
    this.materialsToAdd.splice(i, 1);
    this.form.form.markAsDirty();
  }
}
