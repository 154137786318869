import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { DataService } from "./data.service";
declare const Pusher: any;

@Injectable()
export class PusherService {
  http = inject(HttpClient);
  _dataService = inject(DataService);
  constructor() {
    this.pusher = new Pusher("8c6f66a8d1541d7de81d", {
      cluster: "eu",
      encrypted: true,
      // channelAuthorization: { endpoint: "https://tekoplast.az/jjapi/pusher/auth"}
    });
  }
  pusher;

  public init(channel) {
    return this.pusher.subscribe(channel);
  }

  postData(data: any): Observable<any> {
    const pusherUrl = "https://tekoplast.az/jjapi/pusher/";

    let pushData = {
      ch: this._dataService.dbTable,
      ev: "general",
      data: data,
      uuid: this._dataService.uuid,
    };

    return this.http.post(pusherUrl, pushData).pipe(
      catchError((error) => {
        console.error("Error sending Pusher data", error);
        return throwError(error);
      })
    );
  }
}
