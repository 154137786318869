import packageJson from "../../package.json";

export const environment = {
  version: packageJson.version,
  firebase: {
    projectId: "ncaccount-cavidnebi",
    appId: "1:248897311185:web:2f9fc3e01e6478b3a310f0",
    databaseURL: "https://ncaccount-cavidnebi.firebaseio.com",
    storageBucket: "ncaccount-cavidnebi.appspot.com",
    locationId: "europe-west",
    apiKey: "AIzaSyD3jQDYRSeXsEanX4fuqn7Sd-vtr8Hh40I",
    authDomain: "ncaccount-cavidnebi.firebaseapp.com",
    messagingSenderId: "248897311185",
  },
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyD3jQDYRSeXsEanX4fuqn7Sd-vtr8Hh40I",
    authDomain: "ncaccount-cavidnebi.firebaseapp.com",
    databaseURL: "https://ncaccount-cavidnebi.firebaseio.com",
    projectId: "ncaccount-cavidnebi",
    storageBucket: "ncaccount-cavidnebi.appspot.com",
    messagingSenderId: "248897311185",
    appId: "1:248897311185:web:2f9fc3e01e6478b3a310f0",
  },
  kapital: {
    username: "0436957T",
    pass: "Teko2023@",
    accountNo: "40040019440436957114",
  },
};
