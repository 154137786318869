import { Component, OnInit } from "@angular/core";
import { GoogleGenerativeAI } from "@google/generative-ai";

@Component({
  selector: "app-g-ai",
  templateUrl: "./g-ai.component.html",
  styleUrl: "./g-ai.component.css",
})
export class GAIComponent implements OnInit {
  genAI = new GoogleGenerativeAI("AIzaSyA3wUYDiqz15jPlZQeoEYKai4SJthbEP5U");
  model = this.genAI.getGenerativeModel({ model: "gemini-pro" });
  prompt: string = "";
  chatData: { prompt; response }[] = [];
  dataLoading: boolean = false;

  async ngOnInit() {}

  async sendPrompt(prompt) {
    this.dataLoading = true;
    const result = await this.model.generateContent(prompt);
    const response = await result.response.text();

    let regex = /\*\*(.*?)\*\*/g;
    let modifiedResponse = response
      .replace(regex, "<span class='header'>$1</span>")
      .replace(/```([\s\S]*?)```/, "<span class='code'>$1</span>");

    this.chatData.unshift({ prompt, response: modifiedResponse });
    this.dataLoading = false;
    this.prompt = "";
  }
}
