import { HttpClient } from "@angular/common/http";
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { forkJoin, of } from "rxjs";
import { DataService } from "src/app/data.service";
import { map, switchMap, tap } from "rxjs/operators";
import { NgForm, UntypedFormControl } from "@angular/forms";
import { db } from "src/app/db.service";
import { ToastrService } from "ngx-toastr";
import { NewBankGroupDialogComponent } from "src/app/dialogs/new-bank-group-dialog/new-bank-group-dialog.component";
import _ from "lodash";
import * as _moment from "moment";
import { PusherService } from "src/app/pusher.service";
import { CustomersService } from "src/app/customers/customers.service";
import { AuthService } from "src/app/auth.service";
import { ConfirmationDialogComponent } from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
const moment = _moment;

@Component({
  selector: "app-bank-dialog",
  templateUrl: "./bank-dialog.component.html",
  styleUrls: ["./bank-dialog.component.css"],
})
export class BankDialogComponent implements OnInit {
  @ViewChild("inv") form: NgForm;
  dataLoading: boolean = false;
  date;
  selectedBankGroupId: number;
  selectedCashboxId: number = this._dataService.cashBox()[0].id
    ? this._dataService.cashBox()[0].id
    : 1;
  bankAmount;
  details;
  bankGroupsByType = [];
  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  transactionNo: string = "";
  bankEntry;
  deleteTransaction = of({});
  customerBalance;
  editMode: boolean = false;
  copyMode: boolean = false;
  user: string;
  refersTo;

  @HostListener("keydown.enter", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    var addButton = document.querySelector("#addButton") as HTMLElement;
    if (this.form.valid) {
      this.addBankOrder(this.form.value, addButton);
    }
  }

  constructor(
    public dialogRef: MatDialogRef<BankDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private http: HttpClient,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public _dataService: DataService,
    private _pusher: PusherService,
    private _authService: AuthService,
    protected _customersService: CustomersService
  ) {}

  ngOnInit(): void {
    this.date =
      this._dataService.startDate.value.format("YYYY-MM-DD") ==
      this._dataService.endDate.value.format("YYYY-MM-DD")
        ? new UntypedFormControl(
            moment(this._dataService.endDate.value.format("YYYY-MM-DD"))
          )
        : new UntypedFormControl(moment());
    this.sortBankGroups();
    if (this.dialogData?.data) {
      this.selectedBankGroupId = this.dialogData.data.bankGroupId;
      this._customersService.selectedCustomerId =
        this.dialogData.data.customerId;
      this.details = this.dialogData.data.details;
      this.date = new UntypedFormControl(moment(this.dialogData.data.date));
      this.bankAmount = this.dialogData.data.income
        ? this.dialogData.data.income
        : this.dialogData.data.outgoings;
      this.selectedCashboxId = this.dialogData.cashboxId;
      this.transactionNo = this.dialogData.data.transactionId;
      this.editMode = this.dialogData.editMode
        ? this.dialogData.editMode
        : false;
      this.copyMode = this.dialogData.copyMode
        ? this.dialogData.copyMode
        : false;
      if (this.dialogData?.data?.transaction?.userId) {
        let userName = this._dataService
          .users()
          .find((u) => u.id == this.dialogData?.data?.transaction?.userId);
        this.user = userName ? userName.fullname : null;
      }
      this.refersTo = this.dialogData?.data?.transaction?.refersTo ?? null;
    } else {
      this._customersService.selectedCustomerId = null;
    }

    if (this.dialogData?.cashboxId) {
      this.selectedCashboxId = this.dialogData.cashboxId;
    }
  }

  addBankOrder(data, btn) {
    this.dataLoading = true;
    btn.firstChild.innerText = "Gözləyin";
    btn.disabled = true;
    btn.innerText = "Gözləyin";

    let postBank, removeTransaction;
    let today = new UntypedFormControl(moment());

    const handleError = () => {
      this.dataLoading = false;
      btn.firstChild.innerText = "Əlavə et";
      btn.disabled = false;
      this.toastr.error("", "Xəta baş verdi, yenidən cəhd edin", {
        toastClass: "ngx-toastr",
        progressBar: true,
        timeOut: 2500,
        progressAnimation: "increasing",
      });
    };

    var transaction = {
      id: null,
      type: "Bank",
      sum: this.dialogData.type == "medaxil" ? data.price : -data.price,
      customerId: data.customerId,
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD HH:mm:ss"),
      details: data.details,
      userId: this._authService.loggedInUserValue.id ?? null,
      refersTo: this.dialogData?.data?.tId ?? null,
    };

    if (this.dialogData.data && this.copyMode == false) {
      removeTransaction = this.http.put(
        this._dataService.dbBase +
          "api.php/records/transactions/" +
          this.dialogData.data.tId,
        { status: 2 }
      );
    } else {
      removeTransaction = of(null);
    }

    let selectedCustomerName = _.find(this._dataService.customers(), {
      id: this._customersService.selectedCustomerId,
    }).name;

    this.bankEntry = {
      bankGroupId: data.bankGroupId,
      cashboxId: this.selectedCashboxId,
      income: this.dialogData.type == "medaxil" ? data.price : null,
      outgoings: this.dialogData.type == "medaxil" ? null : data.price,
      customerId: data.customerId,
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD"),
      details: data.details,
      customerName: selectedCustomerName,
      bankGroupName: this._dataService
        .bankGroups()
        .find((b) => b.id == data.bankGroupId)
        ? this._dataService.bankGroups().find((b) => b.id == data.bankGroupId)
            .name
        : "Loading",
      type: this.dialogData.type == "medaxil" ? "Bank In" : "Bank Out",
    };

    forkJoin({
      post: this.http.post(
        this._dataService.dbBase + "api.php/records/transactions/",
        transaction
      ),
      remove: removeTransaction,
    })
      .pipe(
        map((transactionId) => {
          transaction.id = transactionId.post;
          this.bankEntry = {
            ...this.bankEntry,
            transactionId: transactionId.post,
            cashboxId: this.selectedCashboxId,
          };

          this.deleteTransaction = this.http.delete(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              transactionId.post
          );

          postBank = this.http.post(
            this._dataService.dbBase + "api.php/records/bank/",
            this.bankEntry
          );
        }),
        switchMap(() => postBank),
        tap((res) => {
          this.bankEntry.id = res;
        })
      )
      .subscribe({
        error: (e) => {
          forkJoin({
            deleteTransaction: this.deleteTransaction,
            storeErrorInDatabase: this._dataService.storeErrorInDatabase(
              e,
              JSON.stringify(transaction),
              JSON.stringify(this.bankEntry),
              JSON.stringify(data),
              "Bank"
            ),
          }).subscribe({
            complete: handleError,
            error: handleError,
          });
        },
        complete: () => {
          let remove =
            this.dialogData.data && this.copyMode == false
              ? this.dialogData.data?.tId
              : null;

          if (remove) {
            db.transactions.delete(remove);
            db.bank.where("tId").equals(remove).delete();
          }

          this.bankEntry = {
            ...this.bankEntry,
            transactionId: transaction,
            tId: transaction.id,
            dateAdded: today.value.format("YYYY-MM-DD"),
            details: transaction.details,
          };

          db.transactions.add(transaction, transaction.id);
          db.bank.add(this.bankEntry, this.bankEntry.id);

          let pushData = { transaction, bank: this.bankEntry };
          this._pusher.postData(pushData).subscribe();

          this.dialogRef.close({
            ...this.bankEntry,
            remove: this.dialogData.data ? this.dialogData.data?.id : null,
          });
          this.toastr.success("", "Əlavə edildi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        },
      });
  }

  newBankGroupDialog(selectedBankGroupId?: number) {
    let dialogRef;
    let data: { type; data? } = { type: this.dialogData?.type };

    if (selectedBankGroupId) {
      data.data = _.find(this._dataService.bankGroups(), {
        id: selectedBankGroupId,
      });
    }

    dialogRef = this.dialog.open(NewBankGroupDialogComponent, {
      width: "465px",
      data: data,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((dialogReturnData) => {
      if (dialogReturnData) {
        this._dataService.getBankGroups().subscribe((bankGroups) => {
          this._dataService.bankGroups.set(bankGroups);
          this.sortBankGroups();
          this._dataService.setLS("bankGroups", this._dataService.bankGroups());
          this.bankGroupsByType = [...this.bankGroupsByType];
          this.selectedBankGroupId = dialogReturnData.id
            ? dialogReturnData.id
            : dialogReturnData;
        });
      }
    });
  }

  sortBankGroups() {
    if (this.dialogData?.type == "medaxil") {
      this._dataService.bankGroups().forEach((element) => {
        if (element.bankEntryType == null || element.bankEntryType == 1) {
          this.bankGroupsByType.push(element);
        }
      });
      this.selectedBankGroupId = this.bankGroupsByType[0]
        ? this.bankGroupsByType[0].id
        : null;
    } else {
      this._dataService.bankGroups().forEach((element) => {
        if (element.bankEntryType == null || element.bankEntryType == 2) {
          this.bankGroupsByType.push(element);
        }
      });
      this.selectedBankGroupId = this.bankGroupsByType[0]
        ? this.bankGroupsByType[0].id
        : null;
    }
    this.bankGroupsByType.push({ id: 0, name: "Satış" });
  }

  async getUserBalance(customerId) {
    if (customerId) {
      (
        await this._customersService.getCustomersBalanceById(customerId)
      ).subscribe((r) => {
        let customerBalaceObj = r.find((c) => c.customerId == customerId);
        this.customerBalance = customerBalaceObj.finalBalance;
      });
    }
  }

  // GETS ORIGINAL VERSION OF TRANSACTIONS IF ITS EDITED
  getUneditedVer() {
    this.dataLoading = true;
    this.http
      .get(
        `https://tekoplast.az/jjapi/api.php/records/transactions/${this.refersTo}/?join=bank`
      )
      .subscribe((r: any) => {
        let dialogData = {
          bankGroupId: r.bank[0].bankGroupId,
          bankGroupName: r.bank[0].bankGroupId,
          transactionId: r.bank[0].transactionId,
          type: r.bank.income ? "medaxil" : "",
          cashboxId: r.bank.cashboxId || 1,
          editMode: false,
          copyMode: true,
          data: { ...r.bank[0], transaction: r },
        };

        const dialogRef = this.dialog.open(BankDialogComponent, {
          width: "700px",
          maxHeight: "700px",
          data: dialogData,
          closeOnNavigation: true,
          disableClose: this._dataService.mobileDevice ? false : true,
          panelClass: "materialDialog",
          hasBackdrop: false,
        });
        this.dataLoading = false;
      });
  }

  deleteEntry() {
    let data = `${this.dialogData.data.customerName} - ${
      this.dialogData.data.income ?? this.dialogData.data.outgoings
    } ₼`;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data,
    });
    console.log(this.dialogData);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.http
          .put(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              this.dialogData?.data?.tId,
            { status: 1 }
          )
          .subscribe(async () => {
            await db.transactions.delete(this.dialogData?.data?.tId);
            await db.bank
              .where("tId")
              .equals(this.dialogData?.data?.tId)
              .delete();
            this._pusher.postData("Bank Entry Deleted").subscribe();
            this.toastr.success("", "Silindi!", {
              toastClass: "ngx-toastr customToast",
              progressBar: true,
              timeOut: 2500,
              progressAnimation: "increasing",
            });
            this.dialogRef.close();
          });
      }
    });
  }

  customerDialog() {
    let data = null;
    // ANBAR GİRİŞİ OLDUQDA YENİ MÜŞTƏRİ TİPİ TƏCHİZADÇI, ƏKS HALDA MÜŞTƏRİ
    if (this.dialogData.type == "medaxil") {
      data = { customerGroupId: 1 };
    } else {
      data = { customerGroupId: 2 };
    }
    this._customersService.customerDialog(data);
  }
}
