import { Injectable, inject, signal } from "@angular/core";
import { DataService } from "./data.service";
import { BehaviorSubject, firstValueFrom, forkJoin } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IApiResult } from "./shared/models/apiresult.interface";
import { db } from "src/app/db.service";
import * as _moment from "moment";
const moment = _moment;

@Injectable({
  providedIn: "root",
})
export class SyncService {
  _dataService = inject(DataService);
  http = inject(HttpClient);

  loadingProgress = 0;
  dbSyncing = false;
  showSyncLine = true;
  syncDate =
    this._dataService.getLS("syncDate") ||
    moment(new Date()).format("HH:mm DD/MM/YY");
  newDataSynced: BehaviorSubject<string> = new BehaviorSubject(this.syncDate);

  lastUpdateTimeLocal: {
    bank?: Date;
    bankGroups?: Date;
    cashBox?: Date;
    customerGroups?: Date;
    customers?: Date;
    formulaGroups?: Date;
    formulas?: Date;
    materials?: Date;
    personnel?: Date;
    production?: Date;
    products?: Date;
    sells?: Date;
    stock?: Date;
    stockGroups?: Date;
    transactionDeletions?: Date;
    transactions?: Date;
    users?: Date;
  } = this._dataService.getLS("lastUpdateTimeLocal") || {
    bank: new Date(),
    bankGroups: new Date(),
    cashBox: new Date(),
    customerGroups: new Date(),
    customers: new Date(),
    formulaGroups: new Date(),
    formulas: new Date(),
    materials: new Date(),
    personnel: new Date(),
    production: new Date(),
    products: new Date(),
    sells: new Date(),
    stock: new Date(),
    stockGroups: new Date(),
    transactionDeletions: new Date(),
    transactions: new Date(),
    users: new Date(),
  };

  async syncDB() {
    if (this.dbSyncing) {
      console.log("Sync in progress");
      return;
    }
    let newData = false;
    try {
      let progress = 10;
      this.dbSyncing = true;
      this.loadingProgress = progress;
      const lastId = {
        transaction: this._dataService.getLS("lastTransactionId") || 0,
        delete: this._dataService.getLS("lastTransactionDeletion") || 0,
        bank: this._dataService.getLS("lastBankId") || 0,
        sells: this._dataService.getLS("lastSellId") || 0,
        stock: this._dataService.getLS("lastStockId") || 0,
        production: this._dataService.getLS("lastProductionId") || 0,
      };

      const [
        transactionsResult,
        transactionDeletionsResult,
        bankResult,
        sellsResult,
        stocksResult,
        productionResult,
      ] = await Promise.all([
        firstValueFrom(
          this.http.get<IApiResult>(
            `https://tekoplast.az/jjapi/api.php/records/transactions?filter=id,gt,${lastId.transaction}`
          )
        ),
        firstValueFrom(
          this.http.get<IApiResult>(
            `https://tekoplast.az/jjapi/api.php/records/transactionDeletions?filter=id,gt,${lastId.delete}`
          )
        ),
        firstValueFrom(
          this.http.get<IApiResult>(
            `https://tekoplast.az/jjapi/api.php/records/bank?join=transactions&filter=id,gt,${lastId.bank}`
          )
        ),
        firstValueFrom(
          this.http.get<IApiResult>(
            `https://tekoplast.az/jjapi/api.php/records/sells?join=transactions&filter=id,gt,${lastId.sells}`
          )
        ),
        firstValueFrom(
          this.http.get<IApiResult>(
            `https://tekoplast.az/jjapi/api.php/records/stock?join=transactions&filter=id,gt,${lastId.stock}`
          )
        ),
        firstValueFrom(
          this.http.get<IApiResult>(
            `https://tekoplast.az/jjapi/api.php/records/production?join=transactions&filter=id,gt,${lastId.production}`
          )
        ),
      ]);

      this.loadingProgress += progress;

      const transactions = transactionsResult.records;
      const bank = bankResult.records;
      const transactionDeletions = transactionDeletionsResult.records;
      const sells = sellsResult.records;
      const stocks = stocksResult.records;
      const production = productionResult.records;

      const lastIdSrv = {
        transaction: Math.max(...transactions.map((item) => item.id)),
        delete: Math.max(...transactionDeletions.map((item) => item.id)),
        bank: Math.max(...bank.map((item) => item.id)),
        sells: Math.max(...sells.map((item) => item.id)),
        stock: Math.max(...stocks.map((item) => item.id)),
        production: Math.max(...production.map((item) => item.id)),
      };
      this.loadingProgress += progress;

      // ADD NEW TRANSACTIONS
      if (transactions.length) {
        // Insert new transactions into the Dexie table
        if (lastIdSrv.transaction != lastId.transaction) {
          await db.transactions.bulkPut(transactions);
          console.log(`added ${transactions.length} transactions`);
          this._dataService.setLS("lastTransactionId", lastIdSrv.transaction);
          this.lastUpdateTimeLocal.transactions = new Date();
        }
        this.loadingProgress += progress;

        // Insert new bank into the Dexie table
        if (bank.length && lastIdSrv.bank != lastId.bank) {
          const newBankRecords = bank.map((bank) => ({
            ...bank,
            tId: bank.transactionId.id,
            date: bank.transactionId.date,
            customerId: bank.customerId
              ? bank.customerId
              : bank.transactionId.customerId,
          }));
          await db.bank.bulkPut(newBankRecords);
          console.log(`added ${newBankRecords.length} bank`);
          this._dataService.setLS("lastBankId", lastIdSrv.bank);
        }
        this.loadingProgress += progress;

        // Insert new sells into the Dexie table
        if (sells.length && lastIdSrv.sells != lastId.sells) {
          const newSells = sells.map((sell) => ({
            ...sell,
            tId: sell.transactionId.id,
            date: sell.transactionId.date,
          }));
          await db.sells.bulkPut(newSells);
          console.log(`added ${newSells.length} sells`);
          this._dataService.setLS("lastSellId", lastIdSrv.sells);
        }
        this.loadingProgress += progress;

        // Insert new production into the Dexie table
        if (production.length && lastIdSrv.production != lastId.production) {
          const newProduction = production.map((pr) => ({
            ...pr,
            tId: pr.transactionId.id,
            date: pr.transactionId.date,
          }));
          await db.production.bulkPut(newProduction);
          console.log(`added ${newProduction.length} production`);
          this._dataService.setLS("lastProductionId", lastIdSrv.production);
        }
        this.loadingProgress += progress;

        // Insert new stocks into the Dexie table
        if (stocks.length && lastIdSrv.stock != lastId.stock) {
          const newStocks = stocks.map((stock) => ({
            ...stock,
            tId: stock.transactionId.id,
            date: stock.transactionId.date,
            customerId: stock.transactionId.customerId,
            tType: stock.transactionId.type,
          }));
          await db.stock.bulkPut(newStocks);
          console.log(`added ${newStocks.length} stocks`);
          this._dataService.setLS("lastStockId", lastIdSrv.stock);
        }
        newData = true;
      }
      this.loadingProgress += progress;

      // HANDLE DELETIONS
      if (transactionDeletions.length && lastIdSrv.delete != lastId.delete) {
        const deletedTransactionIds = transactionDeletions.map(
          (td) => td.transactionId
        );
        await db.transactions.bulkDelete(deletedTransactionIds);

        if (deletedTransactionIds.length > 0) {
          const [
            deletedSellIds,
            deletedBankIds,
            deletedStockIds,
            deletedProductionIds,
          ] = [
            await db.sells
              .where("tId")
              .anyOf(deletedTransactionIds)
              .primaryKeys(),
            await db.bank
              .where("tId")
              .anyOf(deletedTransactionIds)
              .primaryKeys(),
            await db.stock
              .where("tId")
              .anyOf(deletedTransactionIds)
              .primaryKeys(),
            await db.production
              .where("tId")
              .anyOf(deletedTransactionIds)
              .primaryKeys(),
          ];

          this.loadingProgress += progress;

          await this.deleteItems(deletedSellIds, "sells");
          await this.deleteItems(deletedBankIds, "bank");
          await this.deleteItems(deletedStockIds, "stock");
          await this.deleteItems(deletedProductionIds, "production");

          this.loadingProgress += progress;
        }

        this._dataService.setLS(
          "lastUpdateTimeLocal",
          this.lastUpdateTimeLocal
        );
        this._dataService.setLS("lastTransactionDeletion", lastIdSrv.delete);
        newData = true;
      }
      this.loadingProgress =
        this.loadingProgress < 90 ? 90 : this.loadingProgress;
      setTimeout(() => {
        this.dbSyncing = false;
        this.showSyncLine = false;
        this.loadingProgress = 0;
      }, 500);

      this.syncDate = moment(new Date()).format("HH:mm DD/MM/YY");
      this._dataService.setLS("syncDate", this.syncDate);
      if (newData) {
        this.newDataSynced.next(this.syncDate);
      }
      console.log(`Sync Completed: ${this.syncDate}`);
    } catch (error) {
      console.error("Sync Error:", error);
      this.dbSyncing = false;
    }
  }

  async deleteItems(ids, collectionName) {
    if (ids.length > 0) {
      console.log(`deleted${collectionName}: ${ids.length}`);
      await db[collectionName].bulkDelete(ids);
    }
  }

  reset() {
    this.showSyncLine = true;
    db.transactions.clear();
    db.bank.clear();
    db.sells.clear();
    db.stock.clear();
    db.production.clear();

    localStorage.removeItem(this._dataService.dbTable + "lastTransactionId");
    localStorage.removeItem(`${this._dataService.dbTable}lastBankId`);
    localStorage.removeItem(`${this._dataService.dbTable}lastSellId`);
    localStorage.removeItem(`${this._dataService.dbTable}lastStockId`);
    localStorage.removeItem(`${this._dataService.dbTable}lastProductionId`);
    localStorage.removeItem(
      `${this._dataService.dbTable}lastTransactionDeletion`
    );
    this.syncDB();
  }

  getLSData() {
    this._dataService.customers.set(this._dataService.getLS("customers") || []);
    this._dataService.customerGroups.set(
      this._dataService.getLS("customerGroups") || []
    );
    this._dataService.products.set(this._dataService.getLS("products") || []);
    this._dataService.bankGroups.set(
      this._dataService.getLS("bankGroups") || []
    );
    this._dataService.materials.set(this._dataService.getLS("materials") || []);
    this._dataService.stockGroups.set(
      this._dataService.getLS("stockGroups") || []
    );
    this._dataService.formulaGroups.set(
      this._dataService.getLS("formulaGroups") || []
    );
    this._dataService.cashBox.set(this._dataService.getLS("cashBox") || []);
  }

  getSettings() {
    forkJoin([
      this._dataService.getFormulaGroups(),
      this._dataService.getCustomers(),
      this._dataService.getMaterials(),
      this._dataService.getStockGroups(),
      this._dataService.getProducts(),
      this._dataService.getBankGroups(),
      this._dataService.getCashBox(),
      this._dataService.getCustomerGroups(),
      this._dataService.getTodos(),
      this._dataService.getUsers(),
      // this._dataService.getErrors(),
    ]).subscribe({
      next: (r) => {
        localStorage.setItem("formulaGroups", JSON.stringify(r[0]));
        this._dataService.setLS("formulaGroups", r[0]);
        this._dataService.formulaGroups.set(r[0]);

        localStorage.setItem("customers", JSON.stringify(r[1]));
        this._dataService.setLS("customers", r[1]);
        this._dataService.customers.set(r[1]);

        localStorage.setItem("customerGroups", JSON.stringify(r[1]));
        this._dataService.setLS("customerGroups", r[7]);
        this._dataService.customerGroups.set(r[7]);

        localStorage.setItem("materials", JSON.stringify(r[2]));
        this._dataService.setLS("materials", r[2]);
        this._dataService.materials.set(r[2]);

        localStorage.setItem("stockGroups", JSON.stringify(r[3]));
        this._dataService.setLS("stockGroups", r[3]);
        this._dataService.stockGroups.set(r[3]);

        localStorage.setItem("products", JSON.stringify(r[4]));
        this._dataService.setLS("products", r[4]);
        this._dataService.products.set(r[4]);

        localStorage.setItem("bankGroups", JSON.stringify(r[5]));
        this._dataService.setLS("bankGroups", r[5]);
        this._dataService.bankGroups.set(r[5]);

        localStorage.setItem("cashBox", JSON.stringify(r[6]));
        this._dataService.setLS("cashBox", r[6]);
        this._dataService.cashBox.set(r[6]);

        this._dataService.setLS("todos", r[8]);
        this._dataService.todos.set(r[8]);

        this._dataService.setLS("users", r[9]);
        this._dataService.users.set(r[9]);

        // this._dataService.setLS("errors", r[10]);
        // this._dataService.errors.set(r[10]);

        db.formulaGroups.bulkPut(r[0]);
        db.customers.bulkPut(r[1]);
        db.customerGroups.bulkPut(r[7]);
        this._dataService.customerGroups.set(r[7]);

        console.log("Settings Updated Sync Service");
      },
      error: () => {
        this.offlineSettings();
      },
    });
  }

  async offlineSettings() {
    let formulaGroups = await db.formulaGroups.toArray();
    this._dataService.formulaGroups.set(formulaGroups);

    let customers = await db.customers.toArray();
    this._dataService.customers.set(customers);

    let customerGroups = await db.customerGroups.toArray();
    this._dataService.customerGroups.set(customerGroups);
  }
}
