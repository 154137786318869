import {
  Component,
  Input,
  OnInit,
  WritableSignal,
  effect,
  inject,
  signal,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { BankService } from "src/app/bank/bank.service";
import { DataService } from "src/app/data.service";
import { db } from "src/app/db.service";

@Component({
  selector: "app-cash-distribution",
  templateUrl: "./cash-distribution.component.html",
  styleUrl: "./cash-distribution.component.css",
})
export class CashDistributionComponent implements OnInit {
  _dataService = inject(DataService);
  _bankService = inject(BankService);
  route = inject(ActivatedRoute);
  expandRows: boolean[] = [];
  expandRowsInit: boolean[] = [];
  expandTable: boolean[] = [];
  totalIn: number = 0;
  totalOut: number = 0;
  totals;
  totalSells: number = 0;
  editMode: boolean = false;
  excludedBankGroups = signal(
    this._dataService.getLS("excludedBankGroups") ?? []
  );
  @Input() selectedChip?: WritableSignal<string> = signal("Outgoings");
  tableData: { id?; data? }[] = [];
  defaultSortModel = [{ colId: "date", sort: "desc", sortIndex: 0 }];
  dataLoading: boolean = false;
  columnDefs = [];
  private subscription: Subscription;

  constructor() {
    effect(() => {
      if (this.selectedChip()) {
        this.getData();
      }
      this._dataService.dateChangedSig();
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params["selectedChip"]) {
        this.selectedChip.set(params["selectedChip"]);
      }
    });
  }

  async getData() {
    this.totalOut = 0;
    this.totalIn = 0;
    this.columnDefs = [
      { headerName: "Tərəf-Müqabil", field: "customerName", flex: 2 },
      {
        headerName: "Alınan, ₼",
        field: "income",
        flex: 2,
      },
      {
        headerName: "Ödənən, ₼",
        field: "outgoings",
        flex: 2,
      },
      {
        headerName: "Tarix",
        field: "date",
        flex: 1,
        minWidth: 120,
        sortable: true,
      },
      {
        headerName: "Məlumat",
        field: "details",
        flex: 1,
        minWidth: 100,
        cellClass: (params) => {
          return "justify-content-start clipText";
        },
      },
    ];
    switch (this.selectedChip()) {
      case "Outgoings":
        return (this.totals = await this.getOutgoingsByCategory(
          this._dataService.startDate.value.format("YYYY-MM-DD"),
          this._dataService.endDate.value.format("YYYY-MM-DD")
        ));
      case "Income":
        return (this.totals = await this.getIncomeByCategory(
          this._dataService.startDate.value.format("YYYY-MM-DD"),
          this._dataService.endDate.value.format("YYYY-MM-DD")
        ));
      default:
        return (this.totals = await this.getOutgoingsByCategory(
          this._dataService.startDate.value.format("YYYY-MM-DD"),
          this._dataService.endDate.value.format("YYYY-MM-DD")
        ));
    }
  }

  async getOutgoingsByCategory(startDate: Date, endDate: Date) {
    this.dataLoading = true;
    this.totalSells = 0;
    const banks = await db.bank
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray()
      .then((bank) => bank.filter((b) => b.bankGroupId != 30));

    const outGoings: { [key: string]: any } = banks.reduce((acc, bank) => {
      const { bankGroupId, outgoings, income } = bank;

      this.totalIn += income;
      this.totalOut += outgoings;

      acc[bankGroupId] = acc[bankGroupId] || {
        name:
          this._dataService.bankGroups().find((x) => x.id == bankGroupId)
            ?.name ?? "",
        bankGroupId: bankGroupId,
        bankGroupCategory:
          this._dataService.bankGroups().find((x) => x.id == bankGroupId)
            ?.bankGroupCategory ?? 0,
        out: 0,
        in: 0,
      };

      acc[bankGroupId].out += outgoings;
      acc[bankGroupId].in += income;

      this.totalSells += bankGroupId == 0 ? income - outgoings : 0;

      this.expandTable.push(false);
      return acc;
    }, {});

    // let result = Object.values(outGoings).filter((item) => item.out > 0);
    let result = Object.values(outGoings);

    // Group the data by bankGroupCategory
    const groupedData = result.reduce((result, item) => {
      const category = item.bankGroupCategory;

      if (!result[category]) {
        result[category] = [];
      }

      result[category].push(item);
      this.expandRows.push(true);
      this.expandRowsInit.push(true);
      return result;
    }, {});

    this.tableData = [];
    this.dataLoading = false;

    const sortArrays = (data) => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          data[key] = data[key].sort((a, b) => b.out - a.out);
        }
      }
    };
    sortArrays(groupedData);
    return groupedData;
  }

  async getIncomeByCategory(startDate: Date, endDate: Date) {
    this.dataLoading = true;
    const banks = await db.bank
      .where("date")
      .between(startDate, endDate, true, true)
      .toArray()
      .then((bank) => bank.filter((b) => b.bankGroupId != 30));

    const outGoings: { [key: string]: any } = banks.reduce((acc, bank) => {
      const { bankGroupId, outgoings, income } = bank;

      this.totalIn += income;
      this.totalOut += outgoings;

      acc[bankGroupId] = acc[bankGroupId] || {
        name:
          this._dataService.bankGroups().find((x) => x.id == bankGroupId)
            ?.name ?? "",
        bankGroupId: bankGroupId,
        bankGroupCategory:
          this._dataService.bankGroups().find((x) => x.id == bankGroupId)
            ?.bankGroupCategory ?? 0,
        out: 0,
      };

      acc[bankGroupId].out += income;

      this.expandTable.push(false);
      return acc;
    }, {});

    let result = Object.values(outGoings).filter((item) => item.out > 0);

    // Group the data by bankGroupCategory
    const groupedData = result.reduce((result, item) => {
      const category = item.bankGroupCategory;

      if (!result[category]) {
        result[category] = [];
      }

      result[category].push(item);
      this.expandRows.push(true);
      this.expandRowsInit.push(true);
      return result;
    }, {});

    this.tableData = [];
    this.dataLoading = false;

    const sortArrays = (data) => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          data[key] = data[key].sort((a, b) => b.out - a.out);
        }
      }
    };
    sortArrays(groupedData);
    return groupedData;
  }

  getBankCategoryName(id) {
    return (
      this._dataService.bankGroupCategories().find((b) => b.id == id)?.name ??
      ""
    );
  }

  totalOutgoings(category): number {
    return this.totals[category].reduce((sum, item) => {
      if (!this.excludedBankGroups().includes(item.bankGroupId)) {
        sum += item.out;
      }
      return sum;
    }, 0);
  }

  totalIncome(category): number {
    return this.totals[category].reduce((sum, item) => {
      if (!this.excludedBankGroups().includes(item.bankGroupId)) {
        sum += item.in;
      }
      return sum;
    }, 0);
  }

  expandRowsFn() {
    this.tableData = [];
    this.expandRows = [];
    this.expandRowsInit.forEach(() => this.expandRows.push(true));
  }

  async setTableData(bgId) {
    if (!this.tableData.find((t) => t.id == bgId)) {
      this.dataLoading = true;
      let result = await db.bank
        .where("date")
        .between(
          this._dataService.startDate.value.format("YYYY-MM-DD"),
          this._dataService.endDate.value.format("YYYY-MM-DD"),
          true,
          true
        )
        .toArray()
        .then((transaction) =>
          transaction.filter((t) => {
            return t.bankGroupId == bgId;
          })
        );

      result = result.map((transaction) => {
        const customerId = transaction.customerId;
        const customer = this._dataService
          .customers()
          .find((cust) => cust.id === customerId);

        if (customer) {
          return {
            ...transaction,
            customerName: customer.name,
            details: transaction.transactionId?.details ?? "",
          };
        } else {
          // Handle the case where the customer is not found
          return transaction;
        }
      });

      this.tableData = [];
      this.tableData.push({ id: bgId, data: result });
      this.dataLoading = false;
    } else {
      this.tableData = [];
    }
  }

  toggleBankGroup(item) {
    let indexToRemove = this.excludedBankGroups().indexOf(item);
    if (indexToRemove !== -1) {
      this.excludedBankGroups().splice(indexToRemove, 1);
    } else {
      this.excludedBankGroups.set([...this.excludedBankGroups(), item]);
    }
  }

  calculateSum(categoryKey, income?: boolean) {
    const data = this.totals[categoryKey];
    if (data) {
      const sum = income
        ? data.reduce((acc, item) => acc + item.in, 0)
        : data.reduce((acc, item) => acc + item.out, 0);
      return sum;
    } else {
      return 0;
    }
  }
}
