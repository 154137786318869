<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    {{ dialogData?.data ? "Tərəf-Müqabil" : "Yeni Tərəf-Müqabil" }}
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<form #inv="ngForm">
  <div style="background-color: #f6f6f6; margin-top: 10px">
    <div class="container">
      <div class="row g-2 pb-3 pt-2 px-2">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-text">
              <i class="material-icons">person</i>
            </div>
            <input
              [(ngModel)]="customerName"
              type="text"
              placeholder="Adı"
              class="form-control"
              autocomplete="off"
              name="name"
              required
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row g-2 pb-3 pt-2 px-2">
      <div class="col-12" *ngIf="!showOperations">
        <label class="form-label" for="bankGroup">Tipi</label>
        <div class="input-group">
          <div class="input-group-text">
            <i class="material-icons">receipt_long</i>
          </div>
          <ng-select
            [items]="_dataService.customerGroups()"
            bindLabel="name"
            bindValue="id"
            loadingText="Yüklənir .."
            notFoundText="Tapılmadı"
            [(ngModel)]="selectedCustomerGroupId"
            name="customerGroupId"
            class="form-control"
            required
          >
          </ng-select>
        </div>
      </div>

      <div class="col-12" *ngIf="!showOperations">
        <label class="form-label" for="details">Məlumat</label>
        <div class="input-group">
          <div class="input-group-text">
            <mat-icon>info_outline</mat-icon>
          </div>
          <textarea
            placeholder="Məlumat"
            class="form-control"
            id="details"
            rows="2"
            name="details"
            [(ngModel)]="details"
          ></textarea>
        </div>
      </div>

      <div class="col-12" *ngIf="dialogData?.data">
        <label
          class="form-label"
          for="customerBalance"
          *ngIf="!showOperations && customerBalance != 0"
          >Balans</label
        >
        <div
          class="d-flex gap-2"
          [ngClass]="{ 'mt-2': showOperations }"
          *ngIf="customerBalance != 0"
        >
          <div class="input-group" style="max-width: 40%">
            <div class="input-group-text">
              <span class="manat">₼</span>
            </div>
            <input
              #addBalanceInput
              type="number"
              placeholder="Balans"
              class="form-control"
              autocomplete="off"
              name="customerBalance"
              min="0"
              [ngModel]="
                customerBalanceEdited >= 0
                  ? customerBalanceEdited
                  : -customerBalanceEdited
              "
              (ngModelChange)="customerBalanceEdited = $event"
              style="background-color: white"
              [disabled]="
                _authService.loggedInUserValue.email_address !=
                'cavidnebi@gmail.com'
              "
            />
            <div
              *ngIf="
                customerBalance != customerBalanceEdited &&
                customerBalanceEdited != 0
              "
              style="cursor: pointer"
              class="input-group-text"
              (click)="customerBalanceEdited = customerBalance"
            >
              <span class="material-symbols-outlined">
                settings_backup_restore
              </span>
            </div>
          </div>

          <div
            class="d-flex justify-content-center align-items-center w-100 balanceStatus"
            *ngIf="customerBalance && customerBalance == customerBalanceEdited"
            [style.color]="
              customerBalance > 0
                ? '#2c4c66'
                : customerBalance < 0
                ? 'red'
                : null
            "
            [style.background-color]="customerBalance > 0 ? 'aliceblue' : ''"
          >
            {{
              customerBalance < 0
                ? "Tərəf-Müqabilə borclusunuz"
                : customerBalance > 0
                ? "Tərəf-Müqabil sizə borcludur"
                : ""
            }}
          </div>
          <div
            class="d-flex justify-content-center align-items-center w-100 gap-2"
            *ngIf="
              customerBalance &&
              customerBalance != customerBalanceEdited &&
              _authService.loggedInUserValue.email_address ==
                'cavidnebi@gmail.com'
            "
          >
            <button
              #btnBorc
              class="btn btn-outline-primary btnDelete padding-block-5 w-100"
              (click)="setInitialBalance('alacaq', btnBorc, addBalanceInput)"
              [disabled]="
                !addBalanceInput?.value || addBalanceInput?.value == 0
              "
            >
              Tərəf Borculudur
            </button>
            <button
              #btnAlacaq
              class="btn btn-outline-primary btnDelete padding-block-5 w-100"
              (click)="setInitialBalance('borc', btnAlacaq, addBalanceInput)"
              [disabled]="!addBalanceInput.value || addBalanceInput.value == 0"
            >
              Tərəf Alacaqlıdır
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="col-12" *ngIf="dialogData?.data && !showOperations">
        <label
          class="form-label"
          for="initialBalance"
          style="width: 100%; margin-bottom: 0"
          >Borc / Alacaq əlavə et</label
        >
        <div class="balanceRow">
          <div class="input-group" style="min-width: 160px">
            <div class="input-group-text">
              <span class="manat">₼</span>
            </div>
            <input
              [(ngModel)]="addBalance"
              type="text"
              placeholder="Miqdar"
              class="form-control"
              autocomplete="off"
              name="addBalance"
              (change)="addBalance = _dataService.calculateInput(addBalance)"
              #addBalanceInput
            />
          </div>
          <button
            #btnBorc
            class="btn btn-outline-primary btnDelete padding-block-5"
            (click)="setInitialBalance('borc', btnBorc, addBalanceInput)"
            [disabled]="!addBalanceInput.value || addBalanceInput.value == 0"
          >
            Borc əlavə et
          </button>
          <button
            #btnAlacaq
            class="btn btn-outline-primary btnDelete padding-block-5"
            (click)="setInitialBalance('alacaq', btnAlacaq, addBalanceInput)"
            [disabled]="!addBalanceInput.value || addBalanceInput.value == 0"
          >
            Alacaq əlavə et
          </button>
        </div>
      </div> -->
    </div>
  </div>
  <!-- OPERATIONS -->
  <div *ngIf="showOperations" class="d-grid pb-2">
    <div class="container">
      <div class="row g-2 px-2">
        <ag-grid-angular
          style="
            width: 100%;
            height: 274px;
            border-radius: 6px;
            overflow: hidden;
            margin-top: 0.5rem;
          "
          class="ag-theme-alpine"
          [rowData]="agGridData$"
          [columnDefs]="columnDefs"
          [localeText]="_dataService.agGridLocale"
          (gridReady)="onGridReady($event)"
          [getRowId]="getRowId"
          [cacheQuickFilter]="true"
          (rowClicked)="_dataService.transactionDialog($event.data)"
        >
        </ag-grid-angular>
        <!-- <button
          style="
            right: 7px;
            position: absolute;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            border: 1px solid var(--primary-background);
            margin-top: 2px;
            color: var(--primary-background);
            background-color: white;
          "
          (click)="showOperations = false"
        >
          <mat-icon>close</mat-icon>
        </button> -->
      </div>
    </div>
  </div>
  <!-- END OPERATIONS -->
  <div class="d-grid mt-2 py-3" style="background-color: #f6f6f6">
    <div class="container">
      <div class="row g-2 px-2">
        <div class="col" *ngIf="dialogData?.data">
          <button
            *ngIf="dialogData?.data"
            class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex align-items-center justify-content-center"
            (click)="customerOperations($event)"
          >
            {{ showOperations ? "Bağla" : "Əməliyyatlar" }}
            <mat-icon
              *ngIf="!dataLoading"
              style="font-size: 21px; vertical-align: inherit"
            >
              {{
                showOperations
                  ? "keyboard_double_arrow_down"
                  : "keyboard_double_arrow_up"
              }}
            </mat-icon>
            <div *ngIf="dataLoading" class="lds-dual-ring"></div>
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-outline-primary btnDelete padding-block-5 w-100"
            [disabled]="!inv.valid || !inv.dirty"
            (click)="addCustomer(inv.value, $event)"
          >
            {{ dialogData?.data ? "Yenilə" : "Əlavə et" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
