<app-navbar
  [headerText]="'Kassa Bölgüsü'"
  [dataLoading]="dataLoading"
></app-navbar>

<div class="container-fluid px-3 mt-6 pb-4">
  <div class="row justify-content-center">
    <div class="col d-flex flex-column">
      <!-- SUMS -->
      <div class="d-flex gap-2">
        <!-- <button
          class="btn btn-outline-secondary d-flex align-items-center w-50"
          style="min-width: 150px"
          (click)="
            this.selectedChip() == 'Outgoings'
              ? this.selectedChip.set('Income')
              : this.selectedChip.set('Outgoings')
          "
        >
          <span class="material-symbols-outlined">
            {{
              this.selectedChip() == "Outgoings"
                ? "switch_right"
                : "switch_left"
            }}
          </span>
          <span class="mx-auto">{{
            this.selectedChip() == "Outgoings" ? "Ödənişlər" : "Alışlar"
          }}</span>
        </button>
        <div
          class="summaryContainer cardHover w-100"
          style="position: relative; cursor: pointer"
        >
          <span class="summaryElementContainer">
            <span class="summaryType">Toplam Alınan</span>
            <span
              >{{ totalIn | number : "1.1-1" | commaToSpace }}
              <span class="manat">₼</span></span
            ></span
          >
        </div>
        <div
          class="summaryContainer cardHover w-100"
          style="position: relative; cursor: pointer"
        >
          <span class="summaryElementContainer">
            <span class="summaryType">Toplam Ödənən</span>
            <span
              >{{ totalOut | number : "1.1-1" | commaToSpace }}
              <span class="manat">₼</span></span
            ></span
          >
        </div>
        <div
          class="summaryContainer cardHover w-100"
          style="position: relative; cursor: pointer"
        >
          <span class="summaryElementContainer">
            <span class="summaryType">Fərq</span>
            <span
              >{{ totalIn - totalOut | number : "1.1-1" | commaToSpace }}
              <span class="manat">₼</span></span
            ></span
          >
        </div> -->
        <!-- <button
          class="btn btn-outline-secondary d-flex align-items-center w-50"
          style="max-width: 150px"
          (click)="
            expandRowsFn();
            editMode = !editMode;
            _dataService.setLS('excludedBankGroups', excludedBankGroups())
          "
        >
          <span class="material-symbols-outlined">
            {{ editMode ? "check" : "edit" }}
          </span>
          <span class="mx-auto">{{ editMode ? "Təsdiqlə" : "Düzəliş" }}</span>
        </button> -->
        <button
          class="btn btn-outline-secondary d-flex align-items-center w-50"
          style="max-width: 250px"
          (click)="expandRows.length ? (expandRows = []) : expandRowsFn()"
        >
          <span class="material-symbols-outlined">
            {{ expandRows.length ? "expand_less" : "expand_more" }}
          </span>
          <span class="mx-auto">{{
            expandRows.length ? "Azalt" : "Çoxalt"
          }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="table-responsive">
    <table class="table table-bordered text-center">
      <thead style="border: 0px">
        <tr style="border-top: 0; border-bottom: 1px solid #0000002b">
          <th class="headerCol" scope="col" style="width: 25%">Kateqoriya</th>
          <th class="headerCol" scope="col">Alınan</th>
          <th class="headerCol" scope="col">Ödənən</th>
          <th class="headerCol" scope="col" style="border-right: 0">Fərq</th>
        </tr>
      </thead>
      <tbody class="cursor-pointer">
        <tr class="sums">
          <td>Toplam</td>
          <td>
            {{ totalIn | number : "1.1-1" | commaToSpace
            }}<span class="manat" style="line-height: 20px; margin-left: 2px"
              >₼</span
            >
          </td>
          <td>
            {{ totalOut | number : "1.1-1" | commaToSpace
            }}<span class="manat" style="line-height: 20px; margin-left: 2px"
              >₼</span
            >
          </td>
          <td>
            {{ totalIn - totalOut | number : "1.1-1" | commaToSpace
            }}<span class="manat" style="line-height: 20px; margin-left: 2px"
              >₼</span
            >
          </td>
        </tr>

        <!-- ANA KATEGORILER -->
        @for(category of totals | keyvalue; track category.key; let i = $index){
        <tr
          class="categoryRow"
          (click)="expandRows[i] = !expandRows[i]; tableData = []"
        >
          <td class="text-left d-flex align-items-center">
            <span class="material-symbols-outlined">
              {{ expandRows[i] ? "arrow_drop_up" : "arrow_drop_down" }}
            </span>
            <span>{{ getBankCategoryName(category.key) || "Digər" }}</span>
          </td>
          <td style="position: relative">
            <div>
              {{ totalIncome(category.key) | number : "1.1-1" | commaToSpace }}
              <span class="manat" style="line-height: 20px; margin-left: 2px"
                >₼</span
              >
            </div>

            <!-- ƏMSAL -->
            <span
              class="pcOnly"
              style="
                padding-block: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 10px;
                color: #4b4b4b;
                font-size: 14px;
                top: 4px;
              "
            >
              {{
                totalIn != 0
                  ? ((calculateSum(category.key, true) / totalIn) * 100
                    | number : "1.1-1"
                    | commaToSpace)
                  : "0.0"
              }}
              %</span
            >
          </td>

          <td style="position: relative">
            <div>
              {{
                totalOutgoings(category.key) | number : "1.1-1" | commaToSpace
              }}
              <span class="manat" style="line-height: 20px; margin-left: 2px"
                >₼</span
              >
            </div>

            <!-- ƏMSAL -->
            <span
              class="pcOnly"
              style="
                padding-block: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 10px;
                color: #4b4b4b;
                font-size: 14px;
                top: 4px;
              "
            >
              {{
                totalSells != 0
                  ? ((calculateSum(category.key) / totalSells) * 100
                    | number : "1.1-1"
                    | commaToSpace)
                  : "0.0"
              }}%</span
            >
          </td>

          <td>
            {{
              totalIncome(category.key) - totalOutgoings(category.key)
                | number : "1.1-1"
                | commaToSpace
            }}
            <span class="manat" style="line-height: 20px; margin-left: 2px"
              >₼</span
            >
          </td>
        </tr>

        <!-- ALT KATEGORILER -->
        @if(expandRows[i]){
        <ng-container>
          @for(item of category.value; track item.id; let ind = $index){
          @if(editMode || (!editMode &&
          !excludedBankGroups().includes(item.bankGroupId))){
          <tr
            (click)="$event.stopPropagation(); setTableData(item.bankGroupId)"
            [ngStyle]="
              excludedBankGroups().includes(item.bankGroupId)
                ? { backgroundColor: '#e4e4e4' }
                : {}
            "
            class="tableRow"
          >
            <td
              class="text-left"
              style="padding-left: 2.5rem"
              [ngStyle]="
                _dataService.findObjectById(item.bankGroupId, tableData)
                  ? { fontWeight: '600' }
                  : {}
              "
            >
              @if(editMode) {
              <div
                class="box"
                (click)="
                  $event.stopPropagation(); toggleBankGroup(item.bankGroupId)
                "
              >
                @if(!excludedBankGroups().includes(item.bankGroupId)){
                <mat-icon
                  style="
                    color: var(--primary-background);
                    font-size: 20px;
                    line-height: 25px;
                  "
                  >check</mat-icon
                >
                }
              </div>
              }

              {{ item.name }}
            </td>
            <td style="position: relative">
              {{ item.in | number : "1.1-1" | commaToSpace }}
              <span class="manat" style="line-height: 20px; margin-left: 2px"
                >₼</span
              >
              <!-- ƏMSAL -->
              <span class="emsal"
                >{{
                  totalIn != 0
                    ? ((item.in / totalIn) * 100
                      | number : "1.1-1"
                      | commaToSpace)
                    : "0.0"
                }}
                %</span
              >
            </td>
            <td style="position: relative">
              {{ item.out | number : "1.1-1" | commaToSpace }}
              <span class="manat" style="line-height: 20px; margin-left: 2px"
                >₼</span
              >
              <!-- ƏMSAL -->
              <span class="emsal"
                >{{
                  totalSells != 0
                    ? ((item.out / totalSells) * 100
                      | number : "1.1-1"
                      | commaToSpace)
                    : "0.0"
                }}
                %</span
              >
            </td>
            <td
              [ngClass]="{
                    'bg-red':
                      item.in - item.out < 0,
                      'bg-blue':
                      item.in - item.out >= 0,
                  }"
            >
              {{ item.in - item.out | number : "1.1-1" | commaToSpace
              }}<span class="manat" style="line-height: 20px; margin-left: 2px"
                >₼</span
              >
            </td>
          </tr>
          @if( _dataService.findObjectById(item.bankGroupId, tableData)) {
          <tr class="p-3 pt-1" (click)="$event.stopPropagation()">
            <td colspan="4">
              <ag-table
                [colDefs]="columnDefs"
                [tableData]="
                  _dataService.findObjectById(item.bankGroupId, tableData).data
                "
                [tableHeight]="'250px'"
                [tableClass]="'distTable'"
                [defaultSortModel]="defaultSortModel"
                (rowClicked)="
                  _dataService.transactionDialog($event.data.transactionId)
                "
              ></ag-table>
            </td>
          </tr>
          } } }
        </ng-container>
        } }
      </tbody>
    </table>
  </div>
</div>
