import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeComma'
})
export class RemoveCommaPipe implements PipeTransform {
  
  isNotComma(charecter: string): boolean {
    return charecter !== '.'
  }

  transform(value?: string | null): string {
    if(value) {
      return [...value!].filter(this.isNotComma).join("").replace(/,/g, '.');
    } else {
      return null;
    }
  }

}