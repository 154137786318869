<ag-grid-angular
  style="
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    transition: 200ms;
  "
  [style.height]="tableHeight"
  class="ag-theme-alpine {{tableClass}} cardHover"
  [rowData]="tableData"
  (rowClicked)="rowClicked.next($event)"
  (gridReady)="onGridReady($event)"
  [columnDefs]="colDefs"
  [localeText]="_dataService.agGridLocale"
  [cacheQuickFilter]="true"
>
</ag-grid-angular>

<!-- (rowClicked)="_dataService.transactionDialog($event.data)" -->
