<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div *ngIf="!dataLoading" class="ui-titletext">
    {{
      formulaName
        ? formulaName
        : !formulaName && dialogData.uzleme
        ? "Yeni Üzləmə Formulu"
        : "Yeni Formul"
    }}
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<mat-dialog-content>
  <form #inv="ngForm">
    <div class="container">
      <div class="row g-2 pb-3 pt-2 px-2">
        <div class="col-12">
          <label class="form-label" for="name">Formul Adı</label>
          <div class="input-group" style="box-shadow: 0px 0px 2px #8f8f8f5e">
            <div class="input-group-text pcOnly">
              <i style="color: #2c4c66" class="material-icons">book</i>
            </div>
            <input
              [(ngModel)]="formulaName"
              type="text"
              placeholder="Adı"
              class="form-control"
              autocomplete="off"
              name="name"
              required
            />
          </div>
        </div>

        <div>
          <div
            *ngIf="dataLoading"
            class="container ms-1 d-flex justify-content-center"
          >
            <div class="lds-dual-ring"></div>
          </div>
        </div>

        <div class="col-12" *ngIf="!dataLoading">
          <div class="row formulaHeaders">
            <div class="col-1">Növ</div>
            <div class="col-6">Məhsul</div>
            <div class="col-4">Miqdar</div>
            <!-- <div class="col-2" style="margin-inline: 6px">Qiymət</div>
            <div class="col-2">Məbləğ</div> -->
            <div class="col-1"></div>
          </div>
          <div
            class="row mt-1 d-flex"
            ngModelGroup="{{ i }}"
            *ngFor="
              let formula of formulas;
              let i = index;
              trackBy: _dataService.trackByFn
            "
          >
            <div
              class="col-md-5 col-sm-12 formulaColumn materialSelector"
              style="flex: 2"
            >
              <div class="input-group">
                <div class="input-group-text pcOnly formulaType">
                  <span>{{ formula.type ? formula.type : t }}</span>
                </div>
                <!-- bindLabel="name"
                  bindValue="id" -->
                <ng-select
                  [items]="selectData"
                  bindLabel="name"
                  bindValue="id"
                  appendTo="body"
                  loadingText="Yüklənir .."
                  notFoundText="Tapılmadı"
                  [(ngModel)]="formula.id"
                  [ngClass]="{ panelUp: i > 1 }"
                  name="materialId"
                  class="form-control"
                  required
                  (change)="
                    getMaterialDetails(formula.id, i, formula);
                    calculateTotalPrice(i)
                  "
                  [searchFn]="_dataService.customSearchFn"
                  groupBy="type"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div>
                      <span>{{ item.name }}</span>
                    </div>
                    <small
                      ><b>Qalıq:</b>
                      {{ item.totalQuantity ? item.totalQuantity : 0 }}
                      {{ item.unit ? item.unit : "ədəd" }}</small
                    >
                  </ng-template>
                </ng-select>
                <div
                  class="input-group-text inputGroupButtons"
                  *ngIf="formula.type"
                  style="cursor: pointer"
                >
                  <a
                    matTooltip="Ətraflı"
                    matTooltipClass="line-broken-tooltip roundedTooltip"
                    (click)="newFormulaDialog(formula)"
                  >
                    <mat-icon class="material-icons">info_outlined</mat-icon>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-md-2 col-4 formulaColumn mtForMobile"
              style="flex: 1"
            >
              <div class="input-group">
                <input
                  [(ngModel)]="formula.quantity"
                  type="number"
                  placeholder="Miqdar"
                  class="form-control"
                  autocomplete="off"
                  name="quantity"
                  min="0"
                  (ngModelChange)="calculateTotalPrice(i)"
                  required
                />
                <div
                  class="input-group-text inputGroupButtons"
                  style="cursor: pointer"
                >
                  <span>{{
                    formula.unit ? (formula.unit | slice : 0 : 2) : "əd"
                  }}</span>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-2 col-4 formulaColumn mtForMobile">
              <div class="input-group">
                <input
                  [(ngModel)]="formula.price"
                  type="number"
                  placeholder="Qiymət"
                  min="0"
                  class="form-control"
                  autocomplete="off"
                  name="price"
                  (ngModelChange)="calculateTotalPrice(i)"
                  required
                />
                <div
                  class="input-group-text inputGroupButtons"
                  style="cursor: pointer"
                >
                  <a
                    style="
                      line-height: 1;
                      font-size: 20px;
                      padding: 0px 8px !important;
                      min-width: 31px;
                    "
                    (click)="manatConverter(i); calculateTotalPrice(i)"
                  >
                    <span class="manat fw-500">{{ formula.currency }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-3 formulaColumn mtForMobile">
              <input
                [(ngModel)]="formula.sum"
                type="number"
                placeholder="Məbləğ"
                class="form-control"
                autocomplete="off"
                name="sum"
                readonly
              />
            </div> -->
            @if(formulas.length>1) {
            <div class="col-1 formulaColumn mtForMobile">
              <button
                class="btn btn-outline-primary btnDelete"
                (click)="removeFormula(i, formula); calculateTotalPrice()"
              >
                <mat-icon class="material-icons">clear</mat-icon>
              </button>
            </div>
            }

            <hr class="mobileOnly" />
          </div>

          <div style="display: flex">
            <button
              class="btn btn-outline-secondary mt-3"
              style="background: #285579; color: white; width: 85px"
              matTooltipClass="line-broken-tooltip roundedTooltip"
              matTooltip="Digər xammaddə əlavə et"
              [matTooltipPosition]="'right'"
              (click)="nextFormula()"
            >
              <mat-icon class="material-icons">add</mat-icon>
            </button>
            <span
              *ngIf="formulaCost"
              style="
                margin-left: auto;
                font-size: 1.1rem;
                padding-top: 1rem;
                font-family: 'Avant';
              "
              >Toplam çəki: {{ formulaWeight | number | removeComma }} kg
            </span>
            <span
              *ngIf="formulaCost"
              style="
                margin-left: auto;
                font-size: 1.1rem;
                padding-top: 1rem;
                font-family: 'Avant';
              "
              >Maliyyət: {{ formulaCost | number | removeComma }}
              <span class="manat">₼</span>/kg
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div
  class="px-4 pb-3"
  style="background-color: #f6f6f6; border-top: 1px solid #ececec"
>
  <div class="d-grid mt-3">
    <button
      class="btn btn-outline-secondary"
      [disabled]="!inv.valid"
      (click)="addFormula(inv.value, $event)"
      style="padding-block: 10px; border: 1px solid gainsboro; font-size: 17px"
    >
      {{ dialogData.formulaId ? "Yenilə" : "Əlavə et" }}
    </button>
  </div>
</div>
