import { Component, Inject, OnInit, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/data.service";
import "lodash";
declare var _: any;
import * as _latinize from "latinize";
const latinize = _latinize;

@Component({
  selector: "app-new-bank-group-dialog",
  templateUrl: "./new-bank-group-dialog.component.html",
  styleUrls: ["./new-bank-group-dialog.component.css"],
})
export class NewBankGroupDialogComponent implements OnInit {
  bankGroups = JSON.parse(localStorage.getItem("bankGroups"))
    ? JSON.parse(localStorage.getItem("bankGroups"))
    : this._dataService.getBankGroups();
  medaxil: boolean = false;
  mexaric: boolean = false;
  bankGroupName: string;
  selectedBankGroupCategoryNo: number;
  includeInBalance: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<NewBankGroupDialogComponent>,
    private http: HttpClient,
    private toastr: ToastrService,
    public _dataService: DataService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit(): void {
    console.log(this.dialogData);
    if (this.dialogData?.type && this.dialogData?.type == "medaxil") {
      this.medaxil = true;
    } else {
      this.mexaric = true;
    }

    if (this.dialogData.data) {
      this.bankGroupName = this.dialogData.data.name;
      this.medaxil =
        this.dialogData.data.bankEntryType == 1 ||
        this.dialogData.data.bankEntryType == null
          ? true
          : false;
      this.mexaric =
        this.dialogData.data.bankEntryType == 2 ||
        this.dialogData.data.bankEntryType == null
          ? true
          : false;
      this.includeInBalance = this.dialogData.data.includeInBalance;
      this.selectedBankGroupCategoryNo = parseInt(
        this.dialogData.data.bankGroupCategory
      );
    }
  }

  console() {
    console.log(this.selectedBankGroupCategoryNo);
  }

  addBankGroup(data, event) {
    event.srcElement.disabled = true;
    event.target.innerText = "Gözləyin ...";

    let postBankGroup;

    // CHECK IF DATA ALREADY EXISTS IN TABLE
    if (
      !this.dialogData.data &&
      _.filter(this.bankGroups, (a) => {
        return (
          latinize(a.name.toLowerCase()) == latinize(data.name.toLowerCase())
        );
      }).length
    ) {
      this.toastr.error("Bu adda kateqoriya mövcuddur");

      event.target.innerText = "Əlavə et";
      event.target.disabled = false;

      return;
    }

    let bankGroupData: {
      id?: number;
      name: string;
      bankEntryType: number;
      includeInBalance: number;
      bankGroupCategory;
    } = {
      name: data.name,
      bankEntryType: null,
      includeInBalance: data.includeInBalance,
      bankGroupCategory: this.selectedBankGroupCategoryNo,
    };

    if (data.medaxil && !data.mexaric) {
      bankGroupData.bankEntryType = 1;
    }
    if (!data.medaxil && data.mexaric) {
      bankGroupData.bankEntryType = 2;
    }

    postBankGroup = this.http.post(
      this._dataService.dbBase + "api.php/records/bankGroups",
      bankGroupData
    );

    if (this.dialogData.data) {
      postBankGroup = this.http.put(
        this._dataService.dbBase +
          "api.php/records/bankGroups/" +
          this.dialogData.data.id,
        bankGroupData
      );
    }

    console.log(bankGroupData);

    return postBankGroup.subscribe(
      (res) => {
        if (this.dialogData.data) {
          bankGroupData.id = this.dialogData.data.id;
          this.dialogRef.close(bankGroupData);
          this.toastr.success("Yeniləndi!");
        } else {
          bankGroupData.id = res;
          this.dialogRef.close(bankGroupData);
          this.toastr.success("Əlavə edildi!");
        }
      },
      (err) => {
        console.log(err);
        this.dialogRef.close();
        this.toastr.error("Xəta baş verdi");
      }
    );
  }

  deleteBankGroup(event) {
    event.srcElement.disabled = true;
    event.srcElement.innerText = "...";

    this.http
      .delete(
        this._dataService.dbBase +
          "api.php/records/bankGroups/" +
          this.dialogData.data.id
      )
      .subscribe(
        (res) => {
          this.dialogRef.close({ id: res, type: "delete" });
          this.toastr.success("Əlavə edildi!");
        },
        (err) => {
          this.toastr.error("Bu kateqoriyada əməliyyat mövcuddur");
          event.srcElement.innerText = "delete_outlined";
        }
      );
  }
}
