import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  inject,
  OnChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDateRangeInput } from "@angular/material/datepicker";
import { DataService } from "src/app/data.service";
import { SyncService } from "src/app/sync.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnChanges {
  _dataService = inject(DataService);
  _syncService = inject(SyncService);
  @Input({required: false}) headerText!: string;
  @Input() buttons?: { name?: string; icon?: string; function?: Function }[] =
    [];
  @Input() extraButtons?: {
    name?: string;
    icon?: string;
    function?: Function;
  }[] = [];
  @Input() ngHeader;

  @ViewChild("picker") picker;
  @ViewChild(MatDateRangeInput) dateRangeInput;
  @ViewChild("startDate") startDateInput: ElementRef;

  @Output() onChange = new EventEmitter<any>(); // for ng select changes
  items: [];
  selectedItem: number;

  showDropdown = false;

  @Input() loadingProgress: number;
  @Input() dataLoading: boolean;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.showDropdown = window.innerWidth >= 992 && !this.isMobile();
  }

  ngOnInit() {
    this.showDropdown = window.innerWidth >= 992 && !this.isMobile();

    this.items = this.ngHeader ? this.ngHeader.items : [];
    this.selectedItem = this.ngHeader ? this.ngHeader.selectedItem : 0;
  }

  ngOnChanges() {
    if (this.dataLoading) {
      document.body.classList.add("cursorWait");
    } else {
      document.body.classList.remove("cursorWait");
    }
  }

  isMobile() {
    const mobileWidth = 768;
    return window.innerWidth < mobileWidth;
  }

  datePickerOnEnter(event: any, picker: any, endDateInput: any, startDateInput?: any) {
    if (
      (event.target.value.length == 10 || event.target.value.length == 8) &&
      startDateInput
    ) {
      console.log("this");
      endDateInput.value = "";
      endDateInput.focus();
    }
    // if (event.target.value === "" && !startDateInput) {
    //   endDateInput.value =
    //     this._dataService.startDate.value.format('DD/MM/YYYY');
    //   this._dataService.dateChanged.next(
    //     this._dataService.startDate.value +
    //       this._dataService.endDate.value +
    //       Math.floor(Math.random() * 1000)
    //   );
    // }
    else {
      endDateInput.blur();
      picker.close();
    }
  }

  showButtons() {
    const buttons = document.querySelectorAll(".hidden");
    buttons.forEach((button) => {
      button.classList.toggle("hidden");
    });
  }

  hideButtons() {
    const buttons = document.querySelectorAll(".btnTopBar");
    buttons.forEach((button, index) => {
      if (index > 0) {
        button.classList.add("hidden");
      }
    });
  }

  filterDate(event) {
    if (event.value) {
      const startDateStr = this._dataService.startDate.value.format('YYYY-MM-DD');
      const endDateStr = this._dataService.endDate.value.format('YYYY-MM-DD');
  
      this._dataService.dateChanged.next(startDateStr + endDateStr);
      this._dataService.dateChangedSig.set(startDateStr + endDateStr);
      
      this._dataService.setLS("startDate", startDateStr);
      this._dataService.setLS("endDate", endDateStr);
    }
  }
  

  datePickerClicked(event?) {
    console.log(event);
    this.picker.open();
    setTimeout(() => {
      console.log(this.startDateInput);
      event.target.focus();
    });
  }
}
