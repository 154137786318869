import { Injectable } from "@angular/core";
import Dexie, { Table } from "dexie";

@Injectable({
  providedIn: "root",
})
export class AppDB extends Dexie {
  bank: Table;
  bankGroups: Table;
  bankTable: Table;
  customerGroups: Table;
  customers: Table;
  formulaGroups: Table;
  formulas: Table;
  materials: Table;
  production: Table;
  products: Table;
  sells: Table;
  stock: Table;
  stockGroups: Table;
  transactions: Table;
  transactionDeletions: Table;
  users: Table;

  constructor(tableName: string) {
    super(tableName);
    this.version(17).stores({
      bank: "++id, transactionId, bankGroupId, income, outgoings, cashboxId, tId, date, customerId, tType",
      bankGroups:
        "++id, name, bankEntryType, includeInBalance, bankGroupCategory",
      customerGroups: "++id, name",
      customers: "++id, name, details, customerGroupId",
      formulaGroups: "++id, name, cost, type, formula",
      formulas: "++id, formulaGroupId, materialId, quantity",
      initialStockBalance:
        "++id, materialId, stockGroupId, quantity, price, year",
      materials: "++id, name, price, unitType, currency",
      production:
        "++id, transactionId, productId, quantity, weight, length, productionType, customerId, machineNo, date, dateAdded, formulaGroupId, formulaTotalWeight, tId",
      products:
        "++id, name, code, category, formulaGroupId, weight, uzleme, sellPrice",
      sells:
        "++id, productId, materialId, transactionId, customerId, quantity, price, tId, date, stockGroupId",
      stock:
        "++id, transactionId, stockGroupId, materialId, quantity, price, tId, date, customerId",
      stockGroups: "++id, name",
      transactions:
        "++id, sum, type, customerId, date, dateAdded, details, status, userId, refersTo",
      transactionDeletions: "++id, transactionId, type",
      users: "++id, username, password, fullName, email, role",
    });
  }
}

export let db = new AppDB(JSON.parse(localStorage.getItem("dbTable")));

export function switchDatabase(newDatabaseName: string) {
  db.close();
  db = new AppDB(newDatabaseName);
}
