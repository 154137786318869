import { Injectable, WritableSignal, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
@Injectable()
export class AuthService {
  public baseUrl = "https://tekoplast.az/hesabat/api/?login";
  private loggedUserSubject: BehaviorSubject<any>;
  public loggedInUser: Observable<any>;
  public userSignal: WritableSignal<{ [key: string]: any }> = signal({});
  getLoggedUser;

  constructor(private http: HttpClient, private _router: Router) {
    this.getLoggedUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.userSignal.set(this.getLoggedUser ?? {});
    this.loggedUserSubject = new BehaviorSubject(this.getLoggedUser);
    this.loggedInUser = this.loggedUserSubject.asObservable();
  }

  loginUser(emailAddress: string, password: string) {
    return this.http
      .post<any>(`${this.baseUrl}`, { emailAddress, password })
      .pipe(
        map((response) => {
          localStorage.setItem("loggedInUser", JSON.stringify(response));
          this.loggedUserSubject.next(response);
          this.userSignal.set(response);
          return response;
        })
      );
  }

  logoutUser() {
    localStorage.removeItem("loggedInUser");
    this.loggedUserSubject.next(null);
  }

  canActivate(state: ActivatedRouteSnapshot): boolean {
    if (this.loggedInUserValue == null || !this.loggedInUserValue?.id) {
      this._router.navigate(["Login"]);
      localStorage.removeItem("loggedInUser");
      this.loggedUserSubject.next(null);
      return false;
    }
    if (this.loggedInUserValue && this.loggedInUserValue?.role == 2) {
      if (state.url[0]?.path == "Stock") {
        return true;
      } else {
        this._router.navigate(["Stock"]);
        return false;
      }
    }
    if (this.loggedInUserValue && this.loggedInUserValue?.role == 3) {
      if (state.url[0]?.path == "Products") {
        return true;
      } else {
        this._router.navigate(["Products"]);
        return false;
      }
    }
    return true;
  }

  public get loggedInUserValue() {
    return this.loggedUserSubject.value;
  }
}
