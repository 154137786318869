import { HttpClient } from "@angular/common/http";
import {
  Component,
  Inject,
  OnInit,
  Optional,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ViewChild,
  computed,
} from "@angular/core";
import { NgForm, UntypedFormControl } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { db } from "src/app/db.service";
import { DataService } from "src/app/data.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { NewCustomerDialogComponent } from "src/app/dialogs/customer-dialog/customer-dialog.component";
import { forkJoin, map, of, switchMap } from "rxjs";
import * as _moment from "moment";
import { AuthService } from "src/app/auth.service";
const moment = _moment;

@Component({
  selector: "app-salary-dialog",
  templateUrl: "./salary-dialog.component.html",
  styleUrls: ["./salary-dialog.component.css"],
})
export class SalaryDialogComponent implements OnInit {
  details;
  date;
  sum: number;
  personnel;
  selectedCashboxId: number = this._dataService.cashBox()[0]
    ? this._dataService.cashBox()[0].id
    : 1;
  personnelToPay: { id: number; outgoings: number }[] = [];
  editMode: boolean = false;
  copyMode: boolean = false;
  @ViewChildren("input") inputs: QueryList<NgSelectComponent>;
  @ViewChild("inv") form: NgForm;

  constructor(
    public dialogRef: MatDialogRef<SalaryDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private http: HttpClient,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public _dataService: DataService,
    private _authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    console.log(this.dialogData);
    this.date = new UntypedFormControl(moment());

    this.personnel = computed(() => {
      return this._dataService.customers().filter(function (el) {
        return el.customerGroupId == 3;
      });
    });

    if (this.dialogData.transactionId) {
      let tt = await db.transactions.get(this.dialogData.transactionId);
      this.date = new UntypedFormControl(moment(tt.date));

      let dxData = await db.bank
        .where("tId")
        .equals(this.dialogData.transactionId)
        .toArray();
      dxData.forEach((el) => {
        this.personnelToPay.push({
          id: el.customerId,
          outgoings: el.outgoings,
        });
      });
      this.calculateSum();
    } else {
      this.personnelToPay = [{ id: null, outgoings: null }];
    }

    this.selectedCashboxId = this.dialogData?.cashboxId
      ? this.dialogData?.cashboxId
      : this.selectedCashboxId;

    this.editMode = this.dialogData.editMode ? this.dialogData.editMode : false;
    this.copyMode = this.dialogData.copyMode ? this.dialogData.copyMode : false;
  }

  addSalary(data, event) {
    event.target.innerText = "Gözləyin ...";
    event.target.disabled = true;

    let today = new UntypedFormControl(moment());

    var postBankData: {}[] = [];
    let removeTransaction;

    var transaction = {
      id: null,
      type: "Salary",
      sum: this.sum,
      customerId: null,
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD HH:mm:ss"),
      details: data.details,
      userId: this._authService.loggedInUserValue.id ?? null,
    };

    // ! FIX THIS FOR UPDATES

    if (this.dialogData.transactionId && this.copyMode == false) {
      removeTransaction = this.http.put(
        this._dataService.dbBase +
          "api.php/records/transactions/" +
          this.dialogData.transactionId,
        { status: 2 }
      );
    } else {
      removeTransaction = of(null);
    }
    let postData = this.http.post(
      this._dataService.dbBase + "api.php/records/transactions",
      transaction
    );

    forkJoin({
      post: postData,
      remove: removeTransaction,
    })
      .pipe(
        map((r) => {
          transaction.id = r.post;
          this.personnelToPay.forEach((e) => {
            postBankData.push({
              transactionId: transaction.id,
              cashboxId: this.selectedCashboxId,
              bankGroupId: 4,
              outgoings: e.outgoings,
              customerId: e.id,
            });
          });
        }),
        switchMap(() =>
          this.http.post(
            this._dataService.dbBase + "api.php/records/bank",
            postBankData
          )
        )
      )
      .subscribe((res) => {
        let dbBank = [];
        this.personnelToPay.forEach((el, i) => {
          dbBank.push({
            tType: "Salary",
            id: res[i],
            transactionId: transaction,
            tId: transaction.id,
            date: this.date.value.format("YYYY-MM-DD"),
            customerId: el.id,
            cashboxId: this.selectedCashboxId,
            bankGroupId: 4,
            income: null,
            outgoings: el.outgoings,
          });
        });
        db.transactions.add(transaction, transaction.id);
        db.bank.bulkAdd(dbBank);

        let remove =
          this.dialogData?.transactionId && this.copyMode == false
            ? this.dialogData.transactionId
            : null;

        if (remove) {
          db.transactions.delete(remove);
          db.bank.where("tId").equals(remove).delete();
        }
        
        this.dialogRef.close(res);
        this.toastr.success("Əlavə edildi!");
      });
  }

  nextPerson() {
    this.personnelToPay.push({ id: null, outgoings: null });
    this.changeDetectorRef.detectChanges();
    this.inputs.last.focus();
  }

  // DELETE FORMULA
  removePerson(i) {
    this.personnelToPay.splice(i, 1);
  }

  focusNextInputOnEnter(event) {
    if (event.config) {
      event.element.parentNode.parentNode.nextSibling.firstChild.lastChild.focus();
    } else if (event.target.value && this.form.valid) {
      this.nextPerson();
    }
  }

  calculationOnInput(data, index) {
    data.value = data.value.replace(/,/g, ".");
    if (data.value && data.value[0] == "=") {
      this.personnelToPay[index].outgoings = Number(
        eval(data.value.substring(1)).toFixed(3)
      );
      data.value = Number(eval(data.value.substring(1)).toFixed(3));
    }
    this.personnelToPay[index].outgoings =
      Number(data.value.replace(/[^\d.-]/g, "")) != 0
        ? Number(data.value.replace(/[^\d.-]/g, ""))
        : null;
    data.value =
      Number(data.value.replace(/[^\d.-]/g, "")) != 0
        ? Number(data.value.replace(/[^\d.-]/g, ""))
        : null;
  }

  calculateSum() {
    this.sum = 0;
    this.personnelToPay.forEach((e) => {
      this.sum += Number(e.outgoings) ? Number(e.outgoings) : 0;
    });
    this.sum = Number(this.sum.toFixed(3));
  }

  newCustomerDialog() {
    let dialogRef,
      data = { CustomerGroupId: 3 };
    dialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: "500px",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        console.log(this.personnel);
        this.personnel = [...this.personnel, res];
        localStorage.setItem("customers", JSON.stringify(this.personnel));
      }
    });
  }
}
