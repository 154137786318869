import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "commaToSpace",
})
export class CommatospacePipe implements PipeTransform {
  isNotComma(charecter: string): boolean {
    return charecter !== ".";
  }

  transform(value?: string | null): string {
    if (value) {
      return value.toString().replace(/\./g, " ").replace(/,/g, '.');
    } else {
      return null;
    }
  }
}
