<app-navbar
  [headerText]="'Mənfəət və Zərər'"
  [buttons]="navButtons"
  [dataLoading]="dataLoading"
></app-navbar>

<div class="container-fluid px-3">
  <div class="row mt-6 justify-content-center">
    <div class="col-md-6">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          #searchInput
          type="search"
          class="form-control"
          (keyup)="applyFilter(searchInput.value)"
          (search)="applyFilter(searchInput.value)"
          placeholder="Axtar"
        />
        <!-- <div class="input-group-append d-flex">
            <div
              matTooltip="Filter"
              matTooltipClass="line-broken-tooltip roundedTooltip"
              matTooltipPosition="right"
              style="cursor: pointer"
              class="input-group-text"
              (click)="showFilterOptions = !showFilterOptions"
            >
              <span class="material-symbols-outlined"> filter_alt </span>
            </div>
          </div> -->
      </div>
      <div class="revenueTable mt-3">
        <span>Satışlar</span>
        <span>{{ sellsTotal | number }}</span>
        <span class="sub">Material dəyəri</span>
        <span class="sub">{{ costTotal | number }}</span>
        <span class="sub">Xərclər</span>
        <span class="sub">{{ administrativXercler | number }}</span>
        <span>Gəlir</span>
        <span>{{
          sellsTotal - costTotal - administrativXercler | number
        }}</span>
        <!-- <span class="sub">Amortizasiya xərcləri</span>
        <span class="sub">*</span>
        <span class="sub">Faiz xərclər</span>
        <span class="sub">*</span>
        <span>Vergidən öncəki mənfəət</span>
        <span>Filan qeder</span>
        <span class="sub">Vergi</span>
        <span class="sub">*</span>
        <span>Xalis mənfəət</span>
        <span>*</span> -->
      </div>
    </div>
    <!-- <div class="col-md-6">
      <ag-grid-angular
        style="width: 100%; height: 100%; border-radius: 6px; overflow: hidden"
        class="ag-theme-alpine"
        [rowData]="agGridData$"
        [columnDefs]="columnDefs"
        [localeText]="_dataService.agGridLocale"
        (gridReady)="onGridReady($event)"
        (rowClicked)="
          _productService.ProductDetailsDialog($event.data.productId)
        "
        [cacheQuickFilter]="true"
      >
      </ag-grid-angular>
    </div> -->
  </div>
</div>

<div class="container-fluid px-3 mt-3">
  <div class="row">
    <div id="targetTable" class="col-md-6" style="position: relative">
      <!-- <section
          class="filterSettings"
          *ngIf="showFilterOptions"
          [@slideInOut]="showFilterOptions ? 'in' : 'out'"
          [formGroup]="selectedCustomerGroups"
        >
          <div
            class="px-3 py-1"
            style="
              background-color: #0000000a;
              display: flex;
              font-family: 'Mulish';
              font-weight: 600;
              font-size: 18px;
            "
          >
            <span style="padding-left: 23px">Filter</span>
          </div>
          <div
            *ngFor="let item of _dataService.customerGroups()"
            class="filterItems"
          >
            <input
              class="form-check-input"
              type="checkbox"
              value="{{ item.name }}"
              id="{{ item.id }}"
              formControlName="{{ item.id }}"
            />
            <label
              style="cursor: pointer"
              class="form-check-label"
              for="{{ item.id }}"
              >{{ item.name }}</label
            >
          </div>
          <div class="px-3 py-2" style="background-color: #0000000a">
            <button
              class="btn btnDelete w-100"
              style="padding: 4px !important"
              (click)="showFilterOptions = false"
            >
              Bağla
            </button>
          </div>
        </section> -->
    </div>
    <!-- <div class="col-md-6 mt-smm-6">
        <mat-chip-listbox aria-label="Customer Types">
          <mat-chip-option
            selected
            (click)="setChipSelected($event.target.innerText)"
            >Tərəf-Müqabillər
            <span class="balanceSums">{{
              _customersService.sumDebit + _customersService.sumKredit
                | number
                
            }}</span>
            <span
              class="manat"
              style="font-size: 10px !important; text-align: center"
              >₼</span
            ></mat-chip-option
          >
          <mat-chip-option (click)="setChipSelected('Debitorlar')"
            >Debitorlar
            <span class="balanceSums">{{
              _customersService.sumDebit | number
            }}</span>
            <span
              class="manat"
              style="font-size: 10px !important; text-align: center"
              >₼</span
            ></mat-chip-option
          >
          <mat-chip-option (click)="setChipSelected('Kreditorlar')"
            >Kreditorlar
            <span class="balanceSums">{{
              (-_customersService.sumKredit | number) || 0
            }}</span>
            <span
              class="manat"
              style="font-size: 10px !important; text-align: center"
              >₼</span
            ></mat-chip-option
          >
        </mat-chip-listbox>
        <!-- <div class="summary">
          <span>Alınan {{ _bankService.totalIn | number }} <span class="manat">₼</span></span>
          <span>Ödənən {{ _bankService.totalOut | number }} <span class="manat">₼</span></span>
          <span>Fərq {{ _bankService.totalIn - _bankService.totalOut | number }} <span class="manat">₼</span></span>
        </div>
      </div> -->
  </div>
</div>
