import {
  Component,
  OnInit,
  Inject,
  Optional,
  HostListener,
  ElementRef,
  ViewChild,
  computed,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef as MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgForm, UntypedFormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { defer, forkJoin, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { DataService } from "src/app/data.service";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "src/app/auth.service";
import { db } from "src/app/db.service";
import { IProduction } from "src/app/shared/models/production.interface";

import * as _moment from "moment";
const moment = _moment;
import "lodash";
import { ITransaction } from "src/app/shared/models/transaction.interface";
import { NewFormulaDialogComponent } from "src/app/dialogs/formula-dialog/formula-dialog.component";
import { PusherService } from "src/app/pusher.service";
import { ProductsService } from "../products.service";
declare var _: any;

@Component({
  selector: "app-add-istehsal-dialog",
  templateUrl: "./add-istehsal-dialog.component.html",
  styleUrls: ["./add-istehsal-dialog.component.css"],
})
export class AddIstehsalDialogComponent implements OnInit {
  @ViewChild("inv") form: NgForm;
  date;
  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  personnel;
  selectedPersonnelId: number;
  stock: {}[] = [];
  selectedFormulaGroupId;
  selectedStockGroupId: number =
    this._dataService.stockGroups().find((s) => s.type == 2)?.id || 1;
  machineNo;
  length;
  lengthPlaceholder;
  weight;
  weightPlaceholder;
  quantity;
  width;
  widthPlaceholder;
  showWidth: boolean = false;
  formulaTableData;
  formulaDisplayedColumns: string[] = ["material", "quantity", "unit"];
  formulaTotalQuantity;
  deleteTransaction = of({});
  selectedFormulaName: string = null;
  dataLoading: boolean = false;
  usedMaterials: { [key: string]: any }[] = [];
  usedProducts: {}[] = [];
  formulaTotalWeight: number = 0;
  response;
  user: string;

  constructor(
    public dialogRef: MatDialogRef<AddIstehsalDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialog: MatDialog,
    private http: HttpClient,
    private toastr: ToastrService,
    public _dataService: DataService,
    public _authService: AuthService,
    protected _productService: ProductsService,
    private _pusher: PusherService
  ) {}

  @HostListener("keydown.enter", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.form.valid && this.form.controls.machineNo.value) {
      this.addNewIstehsal(this.form.value, event);
    }
  }

  ngOnInit(): void {
    this.date = new UntypedFormControl(moment());
    this.formulaTableData = new MatTableDataSource();

    this.personnel = computed(() => {
      return this._dataService.customers().filter(function (el) {
        return el.customerGroupId == 3;
      });
    });

    if (this.dialogData) {
      this.date = new UntypedFormControl(moment(this.dialogData.date));
      this.selectedPersonnelId = this.dialogData.customerId;
      this.quantity = this.dialogData.quantity;
      this.weight = this.dialogData.weight;
      this.length = this.dialogData.length;
      this.width = this.dialogData.width;
      this.machineNo = this.dialogData.machineNo;
      this.selectedFormulaGroupId = this.dialogData.formulaGroupId;
      this._productService.selectedProductId = this.dialogData.productId;
      this.selectedFormulaName = this.dialogData.formulaGroupId
        ? _.find(this._dataService.formulaGroups(), {
            id: this.dialogData.formulaGroupId,
          }).name
        : null;
      this.showWidth =
        this._dataService
          .products()
          .filter((t) => t.id == this._productService.selectedProductId)[0]
          ?.unit == "m2"
          ? true
          : false;

      if (this.dialogData?.transactionId?.userId) {
        let userName = this._dataService
          .users()
          .find((u) => u.id == this.dialogData?.transactionId?.userId);
        console.log(userName);
        this.user = userName ? userName.fullname : null;
      }
    } else {
      this._productService.selectedProductId = null;
    }

    if (this._productService.selectedProductId) {
      this.lengthPlaceholder = _.find(this._dataService.products(), {
        id: this._productService.selectedProductId,
      }).length;
      this.weightPlaceholder = _.find(this._dataService.products(), {
        id: this._productService.selectedProductId,
      }).weight;
      this.widthPlaceholder = _.find(this._dataService.products(), {
        id: this._productService.selectedProductId,
      }).width;
    }

    if (!this.dialogData?.formulaGroupId) {
      this.formulaDisplayedColumns.splice(2, 1);
    }
  }

  getProducts(): Observable<any> {
    return this.http
      .get<IApiResult>(this._dataService.dbBase + "api.php/records/products")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  async addNewIstehsal(data, event, remove?) {
    event.target.innerText = "Gözləyin ...";
    event.target.disabled = true;
    this.date = new UntypedFormControl(moment());
    let today = new UntypedFormControl(moment());
    this.date.value = data.date;
    let newIstehsal: IProduction;

    const handleError = () => {
      event.target.innerText = "Əlavə et";
      event.target.disabled = false;
      this.toastr.error("", "Xəta baş verdi, yenidən cəhd edin", {
        toastClass: "ngx-toastr",
        progressBar: true,
        timeOut: 2500,
        progressAnimation: "increasing",
      });
    };

    let formulaGroupId = this.selectedFormulaGroupId;

    let getFormulaGroup = formulaGroupId
      ? this.http.get<IApiResult>(
          `${this._dataService.dbBase}api.php/records/formulaGroups/${formulaGroupId}?join=formulas&join=materials`
        )
      : of(null);

    //   let formulaGroupp = await this.getFormulaGroup(formulaGroupId);
    //   console.log(formulaGroupp);

    //   let res = await this.getUsedMaterials(formulaGroupp.formulas);
    //   console.log(res);

    let deleteOldFormula = remove
      ? this.http.delete(
          this._dataService.dbBase +
            "api.php/records/transactions/" +
            this.dialogData.tId
        )
      : of(null);

    let transaction: ITransaction = {
      type: "Production",
      customerId: data.personnelId,
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD HH:mm:ss"),
      details: "Istehsalat", // add this
      userId: this._authService.loggedInUserValue.id ?? null,
    };

    newIstehsal = {
      transactionId: null,
      productId: data.productId,
      quantity: data.quantity,
      weight: data.weight,
      length: data.length,
      width: data.width,
      productionType: "İstehsalat",
      customerId: data.personnelId,
      machineNo: data.machineNo,
      formulaGroupId: formulaGroupId,
      formulaTotalWeight: this.formulaTotalWeight,
    };

    // getFormulaGroup.subscribe(async (r) => {
    //   console.log("yo");
    //   this.getUsedMaterials(r.formulas).then((ff) => console.log(ff));
    // });

    forkJoin([
      getFormulaGroup,
      this.http.post<number>(
        this._dataService.dbBase + "api.php/records/transactions",
        transaction
      ),
    ])
      .pipe(
        switchMap((r) =>
          defer(() =>
            r[0] ? this.getUsedMaterials(r[0].formulas) : of(null)
          ).pipe(
            map(() => {
              transaction.id = r[1];
              newIstehsal.transactionId = r[1];

              this.deleteTransaction = this.http.delete(
                this._dataService.dbBase +
                  "api.php/records/transactions/" +
                  r[1]
              );

              this.usedMaterials?.forEach((element) => {
                let usedMaterialQuantity = null;

                if (element.materialId) {
                  switch (
                    _.find(this._dataService.materials(), {
                      id: element.materialId,
                    }).unit
                  ) {
                    case "ədəd": {
                      usedMaterialQuantity = -parseFloat(
                        newIstehsal.quantity.toFixed(5)
                      );
                      break;
                    }
                    case "m": {
                      usedMaterialQuantity = -parseFloat(
                        (newIstehsal.quantity / data.length).toFixed(5)
                      ); // CHECK
                      break;
                    }
                    case "m2": {
                      usedMaterialQuantity = -parseFloat(
                        (newIstehsal.quantity / data.length).toFixed(5)
                      ); // CHECK
                      break;
                    }
                    // kg
                    default: {
                      if (data.width) {
                        usedMaterialQuantity = -parseFloat(
                          (
                            (element.quantity / this.formulaTotalWeight) *
                            data.weight *
                            data.quantity *
                            data.length *
                            data.width
                          ).toFixed(5)
                        );
                      } else {
                        usedMaterialQuantity = -parseFloat(
                          (
                            (element.quantity / this.formulaTotalWeight) *
                            data.weight *
                            data.quantity *
                            data.length
                          ).toFixed(5)
                        );
                      }
                      break;
                    }
                  }

                  this.stock.push({
                    transactionId: r[1],
                    materialId: element.materialId ? element.materialId : null, // fix
                    quantity: usedMaterialQuantity,
                    price: element.materialId
                      ? _.find(this._dataService.materials(), {
                          id: element.materialId,
                        }).price
                      : null,
                    stockGroupId: this.selectedStockGroupId,
                  });
                }
              });
            })
          )
        ),
        switchMap(() =>
          forkJoin({
            production: this.http.post(
              this._dataService.dbBase + "api.php/records/production",
              newIstehsal
            ),
            stock: this.http.post(
              this._dataService.dbBase + "api.php/records/stock",
              this.stock
            ),
            productUpdate: this.http.put(
              this._dataService.dbBase +
                "api.php/records/products/" +
                data.productId,
              {
                weight: data.weight,
                length: data.length,
                width: data.width,
                formulaGroupId: this.selectedFormulaGroupId,
              }
            ),
            deleteOldFormula: deleteOldFormula,
          })
        ),
        tap((res) => {
          this.response = res;
        })
      )
      .subscribe({
        error: (e) => {
          forkJoin({
            deleteTransaction: this.deleteTransaction,
            storeErrorInDatabase: this._dataService.storeErrorInDatabase(
              e,
              JSON.stringify(transaction),
              JSON.stringify(newIstehsal),
              JSON.stringify(data),
              "Istehsal"
            ),
          }).subscribe({
            complete: handleError,
            error: handleError,
          });
        },
        complete: () => {
          let dbProduction = {
            ...newIstehsal,
            id: this.response.production,
            tId: transaction.id,
            date: transaction.date,
            transactionId: transaction,
          };
          let dbStock = [];
          this.stock?.forEach((el, i) => {
            dbStock.push({
              ...el,
              id: this.response.stock[i],
              tId: transaction.id,
              transactionId: transaction,
              date: transaction.date,
              customerId: transaction.customerId,
              tType: transaction.type,
            });
          });
          if (remove) {
            db.transactions.delete(this.dialogData.tId);
            db.production.where("tId").equals(this.dialogData.tId).delete();
            db.stock.where("tId").equals(this.dialogData.tId).delete();
          }
          db.transactions.add(transaction, transaction.id);
          db.production.add(dbProduction, dbProduction.id);
          db.stock.bulkAdd(dbStock);
          this._pusher.postData("New Production").subscribe();
          this.dialogRef.close("reload");
          this.toastr.success("", "Əlavə edildi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        },
      });
  }

  getUsedMaterials = async (obj) => {
    for (const f of obj) {
      if (f.materialId != null) {
        this.usedMaterials.push(f);
        if (
          _.find(this._dataService.materials(), { id: f.materialId }).unit ==
          "kg"
        ) {
          this.formulaTotalWeight = this.formulaTotalWeight + f.quantity;
        }
      }
      if (f.productId != null) {
        this.usedProducts.push(f);
      }
      if (f.includedFormulaGroupId != null) {
        let result = await this.getFormulaGroup(f.includedFormulaGroupId);
        let totalQuantity = 0;
        result.formulas?.forEach((element) => {
          totalQuantity += element.quantity;
        });
        result.formulas?.forEach((element) => {
          element.quantity = element.quantity / totalQuantity;
        });
        console.log(result);
        this.getUsedMaterials(result.formulas);
      }
    }
    return this.usedMaterials;
  };

  // getUsedMaterials = async (obj) => {
  //   for (const f of obj) {
  //     if (f.materialId != null) {
  //       this.usedMaterials.push(f);
  //       if (
  //         _.find(this._dataService.materials(), { id: f.materialId }).unit ==
  //         "kg"
  //       ) {
  //         this.formulaTotalWeight = this.formulaTotalWeight + f.quantity;
  //       }
  //     }
  //     if (f.productId != null) {
  //       this.usedProducts.push(f);
  //     }
  //     if (f.includedFormulaGroupId != null) {
  //       let result = await this.getFormulaGroup(f.includedFormulaGroupId);
  //       let totalQuantity = 0;
  //       result.formulas.forEach((element) => {
  //         totalQuantity += element.quantity;
  //       });
  //       result.formulas.forEach((element) => {
  //         element.quantity = element.quantity / totalQuantity;
  //       });
  //       console.log(result);
  //       this.getUsedMaterials(result.formulas);
  //     }
  //   }
  //   return this.usedMaterials;
  // };

  getProductionFormula(btn) {
    this.dataLoading = true;
    btn.firstChild.innerText = "Gözləyin";
    btn.disabled = true;

    forkJoin([
      this.http.get<IApiResult>(
        this._dataService.dbBase +
          "api.php/records/stock?join=materials&filter=transactionId,eq," +
          this.dialogData.tId
      ),
      this.http.get<IApiResult>(
        this._dataService.dbBase +
          "api.php/records/formulas?filter=formulaGroupId,eq," +
          this.dialogData.formulaGroupId
      ),
      this.http.get<IApiResult>(
        this._dataService.dbBase +
          "api.php/records/production?filter=transactionId,eq," +
          this.dialogData.tId
      ),
    ]).subscribe((r) => {
      this.formulaTableData.data = r[0].records;
      this.formulaTotalQuantity = r[2].records[0].formulaTotalWeight
        ? r[2].records[0].formulaTotalWeight
        : r[1].records
            .map((t) => t.quantity)
            .reduce((acc, value) => acc + value, 0);
      this.dataLoading = false;
    });
  }

  exportInvoice(data) {
    console.log(data);
  }

  async getFormulaGroup(id: number) {
    let result = await db.formulaGroups.get(id);
    return result;
  }

  productSelected() {
    const selectedProduct = _.find(this._dataService.products(), {
      id: this._productService.selectedProductId,
    });
    this.selectedFormulaGroupId = this._productService.selectedProductId
      ? selectedProduct.formulaGroupId
      : null;
    this.length = this._productService.selectedProductId
      ? selectedProduct.length
      : null;
    this.lengthPlaceholder = this._productService.selectedProductId
      ? selectedProduct.length
      : null;
    this.weightPlaceholder = this._productService.selectedProductId
      ? selectedProduct.weight
      : null;
    this.widthPlaceholder = this._productService.selectedProductId
      ? selectedProduct.width
      : null;
    this.width = this._productService.selectedProductId
      ? selectedProduct.width
      : null;
    this.showWidth =
      this._productService.selectedProductId && selectedProduct.unit === "m2";
    this.weight = this._productService.selectedProductId
      ? selectedProduct.weight
      : null;
  }

  newFormulaDialog(formulaId?: number, uzleme?: string) {
    let dialogRef;
    let data = { formulaId: formulaId, uzleme: uzleme };
    dialogRef = this.dialog.open(NewFormulaDialogComponent, {
      width: "900px",
      data: data,
      panelClass: "materialDialog",
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : formulaId
        ? false
        : true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // this._dataService.getFormulaGroups().subscribe((r) => {
        //   this.formulaGroups = r;
        //   localStorage.setItem("formulaGroups", JSON.stringify(r));
        // });
      }
    });
  }
}
