<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    {{ dialogData?.data ? "Ödəniş Tipi" : "Yeni Ödəniş Tipi" }}
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<form #inv="ngForm">
  <div class="row px-3 py-3" style="background-color: #f6f6f6">
    <div class="col-12">
      <div class="input-group">
        <div class="input-group-text pcOnly">
          <i class="material-icons">receipt_long</i>
        </div>
        <input
          [(ngModel)]="bankGroupName"
          type="text"
          placeholder="Adı"
          class="form-control"
          autocomplete="off"
          name="name"
          required
        />
      </div>
    </div>
  </div>

  <div class="row px-3 py-1 mt-2">
    <div class="col-12">
      <label class="form-label" for="name">Kateqoriya</label>
      <div class="input-group">
        <div class="input-group-text pcOnly">
          <i class="material-icons">receipt_long</i>
        </div>
        <ng-select
          class="customSelect"
          [items]="_dataService.bankGroupCategories()"
          name="bankGroupCategory"
          bindLabel="name"
          bindValue="id"
          [clearable]="false"
          [searchable]="false"
          (change)="console()"
          [(ngModel)]="selectedBankGroupCategoryNo"
          style="
            font-size: 1.4rem;
            font-family: 'Avant';
            cursor: pointer;
            border: 1px solid lightgray;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-left: 8px;
            background-color: white;
            width: 350px;
          "
          required
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row px-3 py-1 mt-2">
    <div class="col-12" style="font-size: 1.1rem">
      <span class="form-label d-block" for="name">Əməliyyatlar</span>

      <div>
        <div class="form-check form-check-inline">
          <input
            [(ngModel)]="medaxil"
            name="medaxil"
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            value="option1"
          />
          <label
            style="cursor: pointer"
            class="form-check-label"
            for="inlineCheckbox1"
            >Kassa Mədaxil</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            [(ngModel)]="mexaric"
            name="mexaric"
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox2"
            value="option2"
          />
          <label
            style="cursor: pointer"
            class="form-check-label"
            for="inlineCheckbox2"
            >Kassa Məxaric</label
          >
        </div>
        <div class="form-check form-check-inline" style="position: relative">
          <input
            [(ngModel)]="includeInBalance"
            name="includeInBalance"
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox3"
            value="option3"
          />
          <label
            style="cursor: pointer"
            class="form-check-label"
            for="inlineCheckbox3"
            >Balans</label
          >
          <div
            style="
              background: var(--primary-background);
              color: white;
              border-radius: 100%;
              width: 15px;
              height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 5px;
              cursor: help;
              position: absolute;
              right: -20px;
              top: 2px;
            "
            matTooltip="Seçilən ödəniş tipi balans hesabatında görünsün?"
            matTooltipClass="line-broken-tooltip"
            matTooltipPosition="right"
          >
            <span class="material-symbols-outlined" style="font-size: 10px">
              question_mark
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="d-grid mt-2"
    style="
      background-color: #f6f6f6;
      border-top: 1px solid #ececec;
      padding: 0.75rem 1.25rem;
    "
  >
    <div class="btn-group">
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100"
        [disabled]="!inv.valid || !inv.dirty"
        (click)="addBankGroup(inv.value, $event)"
      >
        {{ dialogData.data ? "Yenilə" : "Əlavə et" }}
      </button>
      <button
        *ngIf="dialogData.data"
        class="btn btn-outline-primary btnDelete padding-block-5"
        style="
          background: #e6e6e6;
          border: 0px;
          color: #2c4c66;
          width: 55px;
          line-height: 10px;
          z-index: 99;
        "
        [disabled]="!inv.valid"
        (click)="deleteBankGroup($event)"
      >
        <mat-icon style="color: #ff0000; cursor: pointer"
          >delete_outlined</mat-icon
        >
      </button>
    </div>
  </div>
</form>
