<app-navbar
  [ngHeader]="ngHeader"
  [buttons]="navButtons"
  [dataLoading]="dataLoading"
  (onChange)="selectedStockGroupId.set($event)"
></app-navbar>

<div class="container-fluid px-3">
  <div class="row mt-6">
    <div id="targetTable" class="col-md-6" style="position: relative">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          #searchInput
          type="search"
          class="form-control"
          placeholder="Axtar"
        />
      </div>
    </div>

    <div class="col-md-6 mt-smm-6" style="position: relative">
      <div
        class="summaryContainer cardHover"
        style="position: relative; cursor: pointer"
      >
        <span class="summaryElementContainer"
          ><span class="summaryType">Toplam</span>
          <span
            >{{ _stockService.totalPrice | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
      </div>
    </div>

    <ag-table
      [colDefs]="columnDefs"
      [defaultSortModel]="[{ colId: 'priceSum', sort: 'desc', sortIndex: 0 }]"
      [tableData]="agGridData$ | async"
      [tableClass]="'mt-3'"
      [tableHeight]="'calc(100vh - 130px)'"
      [filterVal]="searchInput.value"
      (rowClicked)="_stockService.materialDialog($event.data.materialId)"
    ></ag-table>
  </div>
</div>
