import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appFocusNextOnEnter]",
})
export class FocusNextOnEnterDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keydown.enter", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    const form = this.el.nativeElement.querySelector("form");

    // console.log(form.classList.contains("ng-valid"));

    if (form === null) {
      console.error("Error: form element not found");
      return;
    }

    const focusableElements = form.querySelectorAll(
      "input, select, textarea, ng-select, number, button"
    );
    const currentIndex = Array.from(focusableElements).indexOf(
      event.target as HTMLElement
    );

    if (currentIndex !== focusableElements.length - 1) {
      const nextIndex = this.findNextElementIndex(
        focusableElements,
        currentIndex
      );

      if (
        this.shouldFocusNextElement(focusableElements[nextIndex]) &&
        this.isCurrentValueValid(event.target as HTMLInputElement)
      ) {
        const nextElement = focusableElements[nextIndex] as HTMLElement;
        if (nextElement.tagName === "NG-SELECT") {
          const nextInput = this.getNextInputElement(
            focusableElements,
            nextIndex
          );
          if (nextInput) {
            nextInput.focus();
          }
        } else {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }
  }

  private getNextInputElement(
    elements: HTMLElement[],
    currentIndex: number
  ): HTMLElement | null {
    for (let i = currentIndex + 1; i < elements.length; i++) {
      const element = elements[i];
      if (element.tagName === "INPUT" || element.tagName === "TEXTAREA") {
        return element as HTMLElement;
      }
    }
    return null;
  }

  private isCurrentValueValid(inputElement: HTMLInputElement): boolean {
    const ariaActiveDescendant = inputElement.getAttribute(
      "aria-activedescendant"
    );
    if (inputElement.getAttribute("aria-autocomplete") == "list") {
      return inputElement.value.trim() !== "" || ariaActiveDescendant == null;
    }

    return inputElement.value.trim() !== "";
  }

  private findNextElementIndex(
    elements: HTMLElement[],
    currentIndex: number
  ): number {
    for (let i = currentIndex + 1; i < elements.length; i++) {
      if (this.shouldFocusNextElement(elements[i])) {
        return i;
      }
    }
    return currentIndex; // If no next element found, return the current index
  }

  private shouldFocusNextElement(element: HTMLElement): boolean {
    const tagName = element.tagName.toLowerCase();
    if (element.tabIndex == -1) {
      return false;
    } else if (tagName === "input") {
      const inputElement = element as HTMLInputElement;
      return !inputElement.readOnly;
    } else if (tagName === "select") {
      return (element as HTMLSelectElement).value !== "";
    } else if (tagName === "textarea") {
      return true;
    } else if (tagName === "button") {
      const inputElement = element as HTMLButtonElement;
      if (inputElement.tabIndex != -1) {
        return true;
      }
      return false;
    }
    return false; // For other elements like ng-select
  }
}
