import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { ColumnState, GridApi, GridReadyEvent } from "ag-grid-community";
import { DataService } from "src/app/data.service";

@Component({
  selector: "ag-table",
  templateUrl: "./ag-table.component.html",
  styleUrl: "./ag-table.component.css",
})
export class AgTableComponent implements OnChanges {
  _dataService = inject(DataService);
  @Input() tableData?: {}[] = [];
  @Input() colDefs?: {}[] = [];
  @Input() tableClass?: string = "";
  @Input() tableHeight?: string = "200px";
  @Input() defaultSortModel?: ColumnState[];
  @Input() filterVal? = "";
  @Output() rowClicked = new EventEmitter<any>();
  protected gridApi!: GridApi;

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    if (this.defaultSortModel)
      params.api.applyColumnState({ state: this.defaultSortModel });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // SEARCH
    if (this.gridApi)
      this.gridApi.setGridOption(
        "quickFilterText",
        changes?.filterVal?.currentValue
      );
  }
}
