import {
  trigger,
  animate,
  transition,
  style,
  query,
  state,
} from "@angular/animations";

// ROUTE CHANGE ANIMATION
export const fadeAnimation = trigger("fadeAnimation", [
  transition(":increment", [
    query(":enter", [style({ opacity: 0 })], { optional: true }),

    query(
      ":leave",
      [
        style({ transform: "translateY(0px)", opacity: 1 }),
        animate("0.12s", style({ transform: "translateY(-12px)", opacity: 0 })),
      ],
      { optional: true }
    ),

    query(
      ":enter",
      [
        style({ transform: "translateY(6px)" }),
        animate(
          "0.25s ease-out",
          style({ transform: "translateY(0px)", opacity: 1 })
        ),
      ],
      { optional: true }
    ),
  ]),
  transition(":decrement", [
    query(":enter", [style({ opacity: 0 })], { optional: true }),

    query(
      ":leave",
      [
        style({ transform: "translateY(0px)", opacity: 1 }),
        animate("0.12s", style({ transform: "translateY(12px)", opacity: 0 })),
      ],
      { optional: true }
    ),

    query(
      ":enter",
      [
        style({ transform: "translateY(-6px)" }),
        animate(
          "0.25s ease-out",
          style({ transform: "translateY(0px)", opacity: 1 })
        ),
      ],
      { optional: true }
    ),
  ]),
]);

// SHOW HIDE CARDS
export const slideAnimation = [
  [
    trigger("slideInOut", [
      transition(":enter", [
        style({ maxHeight: "0", opacity: 0 }),
        animate("100ms ease-out", style({ maxHeight: "130px", opacity: 1 })),
      ]),
      transition(":leave", [
        animate("100ms ease-out", style({ maxHeight: "0", opacity: 0 })),
      ]),
    ]),
  ],
];

// EXPAND AND COLLAPSE CARDS
export const toggleWrap = [
  [
    trigger("toggleWrap", [
      state("false", style({ flexWrap: "nowrap", maxHeight: "130px" })), // Initial state
      state(
        "true",
        style({ flexWrap: "wrap", maxHeight: "360px", overflowY: "scroll" })
      ), // Wrapped state
      transition("true => false", animate("200ms ease-out")),
      transition("false => true", animate("400ms ease-out")),
    ]),
  ],
];

export const slideUpDown = [
  [
    trigger("slideUpDown", [
      state(
        "1",
        style({
          height: "111px",
          opacity: 1,
          transform: "translateY(-108px)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.13)",
        })
      ),
      state(
        "0",
        style({
          height: "0px",
          opacity: 0,
          transform: "translateY(0)",
          borderBottom: "0px",
        })
      ),
      transition(":enter", animate("100ms ease-in-out")),
      transition("* => *", animate("100ms ease-in-out")),
    ]),
  ],
];

export const flip = [
  [
    trigger("flip", [
      state("default", style({ transform: "rotateY(0)" })),
      state("flipped", style({ transform: "rotateY(-180deg)" })),
      transition("default <=> flipped", animate("600ms ease")),
    ]),
  ],
];

export const valueChange = [
  [
    trigger("valueChange", [
      transition("* => *", [
        style({ opacity: 0, transform: "translateY(5px)" }),
        animate("200ms", style({ opacity: 1, transform: "translateY(0px)" })),
      ]),
    ]),
  ],
];
