import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../data.service";
@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  constructor(private _dataService: DataService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        "dbTable": this._dataService.dbTable,
      },
    });
    return next.handle(authReq);
  }
}
