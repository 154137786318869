import {
  NgModule,
  LOCALE_ID,
  APP_INITIALIZER,
  ErrorHandler,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ToastrModule } from "ngx-toastr";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";

// FIRESTORE
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { environment } from "../environments/environment";
import { provideAuth, getAuth } from "@angular/fire/auth";

// LANG STUFF
import { registerLocaleData, APP_BASE_HREF } from "@angular/common";
import localeAz from "@angular/common/locales/az-Latn";

// Material Modules
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  CdkMenuTrigger,
  CdkContextMenuTrigger,
  CdkMenuItem,
  CdkMenu,
} from "@angular/cdk/menu";
import {
  MatTooltipModule,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatNativeDateModule } from "@angular/material/core";
import { MatListModule } from "@angular/material/list";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";

// import * as Hammer from 'hammerjs';
// import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { AppComponent } from "./app.component";
import { BankComponent } from "./bank/bank.component";
import { ProductsComponent } from "./products/products.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProductsService } from "./products/products.service";
// import { ProductEditComponent } from './products/product-edit/product-edit.component';
// import { SearchComponent } from './search/search.component';
// import { InventoryComponent } from './inventory/inventory.component';
// import { InventoryDetailsComponent } from './inventory/inventory-details/inventory-details.component';
import { DevComponent } from "./products/dev/dev.component";
// import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ConfirmationDialogComponent } from "./shared/confirmation-dialog/confirmation-dialog.component";
import { NegativeNumberToPositivePipe } from "./shared/negative-number-to-positive.pipe";
// import { SingleProductDialogComponent } from './products/single-product-dialog/single-product-dialog.component';
import { SingleInventoryDialogComponent } from "./inventory/single-inventory-dialog/single-inventory-dialog.component";
import { CustomersComponent } from "./customers/customers.component";
import { SingleCustomerDialogComponent } from "./customers/single-customer-dialog/single-customer-dialog.component";
import { CustomersFullComponent } from "./customers/customers-full/customers-full.component";
// import { TestComponent } from './test/test.component';
import { ServiceWorkerModule } from "@angular/service-worker";
// import { SellsComponent } from './test/sells/sells.component';
import { AddIstehsalDialogComponent } from "./products/add-istehsal-dialog/add-istehsal-dialog.component";
import { LayoutModule } from "@angular/cdk/layout";
import { StockComponent } from "./stock/stock.component";
import { AddStockDialogComponent } from "./stock/stock-dialog/stock-dialog.component";
import { NewMaterialDialogComponent } from "./dialogs/material-dialog/material-dialog.component";
import { NewProductDialogComponent } from "./dialogs/product-dialog/product-dialog.component";
import { MaterialComponent } from "./materials/material.component";

import { NoCacheHeadersInterceptor } from "./shared/NoCacheHeadersInterceptor";
import { LoginComponent } from "./login/login.component";
import { SellsComponent } from "./sells/sells.component";
import { SellsDialogComponent } from "./sells/sells-dialog/sells-dialog.component";
import { AuthService } from "./auth.service";
import { BankDialogComponent } from "./bank/bank-dialog/bank-dialog.component";
import { NewCustomerDialogComponent } from "./dialogs/customer-dialog/customer-dialog.component";
import { NewFormulaDialogComponent } from "./dialogs/formula-dialog/formula-dialog.component";
import { NewBankGroupDialogComponent } from "./dialogs/new-bank-group-dialog/new-bank-group-dialog.component";
import { TransferBankDialogComponent } from "./dialogs/transfer-bank-dialog/transfer-bank-dialog.component";
import { RemoveCommaPipe } from "./shared/remove-comma.pipe";
import { SalaryDialogComponent } from "./bank/salary-dialog/salary-dialog.component";
import { AppRoutingModule } from "./app-routing.module";
import { NgChartsModule } from "ng2-charts";
import { FocusNextOnEnterDirective } from "./shared/focus-next-on-enter-directive.directive";
import { FocusInitialDirective } from "./shared/focus-initial.directive";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { AgTableComponent } from "./shared/ag-table/ag-table.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { PusherService } from "./pusher.service";
import { ErrorHandlerService } from "./error-handler.service";
import { StockTransferComponent } from "./dialogs/stock-transfer/stock-transfer.component";
import { ProfitComponent } from "./reports/profit/profit.component";
import { CashDistributionComponent } from "./reports/cash-distribution/cash-distribution.component";
import { CommatospacePipe } from "./shared/commatospace.pipe";
import { GAIComponent } from "./shared/g-ai/g-ai.component";

// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
//     pan: { direction: Hammer.DIRECTION_RIGHT }
//   };
// }

export function appVersion() {
  return () =>
    new Promise<void>((resolve) => {
      document.getElementById("appVersion").innerText = environment.version;
      console.log("startup");
      resolve();
    });
}

export const DateFormats = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

registerLocaleData(localeAz, "az-Latn");

@NgModule({ declarations: [
        AppComponent,
        ProductsComponent,
        DashboardComponent,
        // ProductEditComponent,
        // SearchComponent,
        // InventoryComponent,
        // InventoryDetailsComponent,
        DevComponent,
        // SpinnerComponent,
        ConfirmationDialogComponent,
        NegativeNumberToPositivePipe,
        // SingleProductDialogComponent,
        SingleInventoryDialogComponent,
        CustomersComponent,
        SingleCustomerDialogComponent,
        CustomersFullComponent,
        // TestComponent,
        // SellsComponent,
        AddIstehsalDialogComponent,
        StockComponent,
        AddStockDialogComponent,
        NewMaterialDialogComponent,
        NewProductDialogComponent,
        LoginComponent,
        SellsComponent,
        SellsDialogComponent,
        MaterialComponent,
        BankComponent,
        BankDialogComponent,
        NewCustomerDialogComponent,
        NewFormulaDialogComponent,
        NewBankGroupDialogComponent,
        TransferBankDialogComponent,
        RemoveCommaPipe,
        SalaryDialogComponent,
        FocusNextOnEnterDirective,
        FocusInitialDirective,
        NavbarComponent,
        AgTableComponent,
        StockTransferComponent,
        ProfitComponent,
        CashDistributionComponent,
        CommatospacePipe,
        GAIComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        // NgxSmartModalModule.forChild(),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        // HammerModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatDatepickerModule,
        CdkContextMenuTrigger,
        CdkMenuTrigger,
        CdkMenuItem,
        CdkMenu,
        MatTooltipModule,
        MatMenuModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSidenavModule,
        MatNativeDateModule,
        DragDropModule,
        NgSelectModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
            registrationStrategy: "registerWhenStable:30000",
        }),
        LayoutModule,
        MatListModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        AppRoutingModule,
        NgChartsModule,
        ScrollingModule,
        AgGridModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appVersion,
            multi: true,
        },
        PusherService,
        ProductsService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: APP_BASE_HREF, useValue: "/" },
        { provide: LOCALE_ID, useValue: "az-Latn" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHeadersInterceptor,
            multi: true,
        },
        {
            provide: "agGridLocaleText",
            useValue: {
                noRowsToShow: "Custom no rows message",
                loadingOoo: "Custom loading message",
            },
        },
        // {
        //   provide: HAMMER_GESTURE_CONFIG,
        //   useClass: MyHammerConfig,
        // },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: DateFormats },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                showDelay: 0,
                hideDelay: 0,
                touchGestures: "auto",
                position: "before",
                touchendHideDelay: 0,
                disableTooltipInteractivity: true,
            },
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
