import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  signinForm: UntypedFormGroup;
  dataLoading: boolean = false;
  emptyValue: string = "";
  appVersion = environment.version;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private _router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.signinForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  get form() {
    return this.signinForm.controls;
  }

  onSubmit() {
    this.dataLoading = true;
    this.authService
      .loginUser(this.form.email.value, this.form.password.value)
      .subscribe(
        (data) => {
          if (data != null) {
            this.dataLoading = false;
            this._router.navigate(["Home"]);
          } else {
            this.dataLoading = false;
            this.toastr.error("", "Xəta", {
              toastClass: "ngx-toastr",
              progressBar: true,
              timeOut: 2500,
              progressAnimation: "increasing",
            });
          }
        },
        (error) => {
          this.dataLoading = false;
          this.toastr.error("", "Xəta", {
            toastClass: "ngx-toastr",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
          console.log(error);
        }
      );
  }
}
