<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <!-- <div class="ui-titletext">{{dialogData?.type == 'Sells' ? 'Yeni Satış' : 'Satış Qaiməsi'}}</div> -->
  <div class="ui-titletext">
    Maaş
    <span *ngIf="dialogData?.transactionId && !copyMode"> Qaiməsi</span>
    <span
      class="transactionNo"
      *ngIf="dialogData?.transactionId && !copyMode"
      >{{ "Qaimə " + dialogData?.transactionId }}</span
    >
  </div>
  <div class="ui-titlecontrols" *ngIf="dialogData.transactionId && !copyMode">
    <button class="ui-btn operations" [cdkMenuTriggerFor]="context_menu">
      <i
        class="material-symbols-outlined"
        style="color: white; font-weight: 500"
        >more_horiz</i
      >
    </button>
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<ng-template #context_menu>
  <div class="context-menu filterSettings" cdkMenu>
    <button
      (click)="editMode = true; copyMode = false"
      [disabled]="editMode && !copyMode"
      class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      style="
        box-shadow: unset;
        border-radius: 0px;
        border-bottom: 1px solid #ececec;
      "
      cdkMenuItem
    >
      <span class="material-symbols-outlined"> edit </span>
      <span class="w-100">Düzəliş et</span>
    </button>
    <button
      (click)="editMode = true; copyMode = true"
      [disabled]="editMode && copyMode"
      class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
      style="
        box-shadow: unset;
        border-radius: 0px;
        border-bottom: 1px solid #ececec;
      "
      cdkMenuItem
    >
      <span class="material-symbols-outlined"> content_copy </span>
      <span class="w-100">Kopyala</span>
    </button>
  </div>
</ng-template>

<div class="container">
  <div class="row p-3" style="background-color: #f6f6f6">
    <div class="col-md-4">
      <div class="input-group">
        <div class="input-group-text">
          <i class="material-icons">account_balance_wallet</i>
        </div>
        <ng-select
          class="form-control"
          [items]="_dataService.cashBox()"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="selectedCashboxId"
          [searchable]="false"
          [clearable]="false"
          name="cashboxId"
          [disabled]="dialogData.transactionId && !editMode"
        >
        </ng-select>
      </div>
    </div>

    <div class="col-md-4 mt-smm-5">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            padding: 0px;
            width: 51px;
            height: 38px;
            display: flex;
            justify-content: center;
          "
        >
          <mat-datepicker-toggle [for]="myDatepicker">
            <mat-icon
              style="color: #0f4c7d; line-height: 21px"
              matDatepickerToggleIcon
              >calendar_month</mat-icon
            >
          </mat-datepicker-toggle>
        </div>
        <input
          #dateInput
          [(ngModel)]="date.value"
          [matDatepicker]="myDatepicker"
          class="form-control"
          placeholder="{{
            _dataService.endDate.value
              ? _dataService.endDate.value.format('DD/MM/YYYY')
              : _dataService.startDate.value.format('DD/MM/YYYY')
          }}"
          (click)="dateInput.value = ''"
          (ngModelChange)="_dataService.formatDateValue(dateInput)"
          id="date"
          name="date"
          style="border-top-right-radius: 6px; border-bottom-right-radius: 6px"
          [disabled]="dialogData.transactionId && !editMode"
        />

        <mat-datepicker #myDatepicker></mat-datepicker>
      </div>
    </div>

    <div class="col-md-4 mt-smm-5">
      <div class="input-group">
        <div class="input-group-text">
          <span class="manat" style="font-weight: 500">₼</span>
        </div>
        <input
          type="number"
          placeholder="Toplam"
          class="form-control"
          min="0"
          [(ngModel)]="sum"
          name="priceSum"
          tabindex="-1"
          readonly
        />
      </div>
    </div>
  </div>
</div>

<form #inv="ngForm" (keydown.enter)="$event.preventDefault()">
  <div class="container">
    <mat-dialog-content>
      <div class="row g-3 pb-3 px-3">
        <div class="col-12 d-flex flex-column gap-2">
          <table>
            <div ngModelGroup="personnel" style="margin-top: -10px">
              <div
                ngModelGroup="{{ i }}"
                class="mt-2 materialsTable gap-mobile"
                *ngFor="
                  let person of personnelToPay;
                  let i = index;
                  trackBy: _dataService.trackByFn
                "
                [ngClass]="{
                  materialsTableWithDel:
                    personnelToPay.length > 1 &&
                    !_dataService.mobileDevice &&
                    (!dialogData.transactionId || editMode)
                }"
              >
                <div>
                  <div class="input-group">
                    <div class="input-group-text" style="min-width: 35px">
                      <span style="font-weight: 600">{{ i + 1 }}</span>
                    </div>
                    <ng-select
                      #input
                      [items]="personnel()"
                      bindLabel="name"
                      bindValue="id"
                      loadingText="Yüklənir .."
                      notFoundText="Tapılmadı"
                      [(ngModel)]="person.id"
                      name="personnelId"
                      class="form-control"
                      required
                      [searchFn]="_dataService.customSearchFn"
                      placeholder="İşçi seçin"
                      appendTo="body"
                      cdkFocusInitial
                      required
                      (change)="focusNextInputOnEnter(input)"
                      [disabled]="dialogData.transactionId && !editMode"
                    >
                    </ng-select>
                    <div
                      class="input-group-text inputGroupButtons"
                      style="cursor: pointer"
                      *ngIf="!dialogData?.transactionId"
                    >
                      <a
                        matTooltip="Yeni İşçi"
                        matTooltipClass="line-broken-tooltip roundedTooltip"
                        (click)="newCustomerDialog()"
                      >
                        <mat-icon class="material-icons">add</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="grid-span-2-mobile">
                  <div class="input-group">
                    <div class="input-group-text">
                      <span class="manat" style="font-weight: 500">₼</span>
                    </div>
                    <input
                      #quantityInput
                      [ngModel]="person.outgoings"
                      (ngModelChange)="person.outgoings = $event"
                      (keyup.enter)="
                        calculationOnInput(quantityInput, i);
                        calculateSum();
                        focusNextInputOnEnter($event)
                      "
                      (change)="
                        calculationOnInput(quantityInput, i); calculateSum()
                      "
                      type="text"
                      placeholder="Məbləğ"
                      class="form-control"
                      autocomplete="off"
                      name="mebleg"
                      required
                      [disabled]="dialogData.transactionId && !editMode"
                    />
                  </div>
                </div>
                <div
                  class="deleteColumn"
                  *ngIf="!dialogData?.transactionId || editMode"
                >
                  <button
                    *ngIf="personnelToPay.length > 1"
                    class="btn btn-outline-primary btnDelete"
                    matTooltip="İşçini sil"
                    [matTooltipPosition]="'right'"
                    matTooltipClass="line-broken-tooltip roundedTooltip"
                    (click)="removePerson(i)"
                  >
                    <mat-icon class="material-icons">clear</mat-icon>
                  </button>
                </div>
                @if(i!=personnelToPay.length-1) {
                <hr
                  class="mobileOnly w-100 grid-span-2-mobile mt-0"
                  style="margin-bottom: 5px !important"
                />
                }
              </div>
            </div>
          </table>
          <button
            matTooltip="Növbəti İşçi"
            matTooltipClass="line-broken-tooltip roundedTooltip"
            matTooltipPosition="right"
            class="btn btn-outline-secondary mt-2"
            style="width: 75px"
            (click)="nextPerson()"
            *ngIf="!dialogData?.transactionId || editMode"
          >
            <mat-icon class="material-icons">add</mat-icon>
          </button>
        </div>

        <div class="col-12 mt-3">
          <div class="input-group">
            <div class="input-group-text">
              <mat-icon>info_outline</mat-icon>
            </div>
            <textarea
              placeholder="Məlumat"
              class="form-control"
              id="details"
              rows="2"
              name="details"
              [(ngModel)]="details"
              [disabled]="dialogData.transactionId && !editMode"
            ></textarea>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <div
      class="row gap-2"
      style="background-color: #f6f6f6; border-top: 1px solid #0000001c"
    >
      <div class="d-grid mt-3 pb-3">
        <button
          class="btn btn-outline-primary btnDelete padding-block-5"
          (click)="addSalary(inv.value, $event)"
          [disabled]="!inv.valid"
          *ngIf="!dialogData?.transactionId || editMode"
        >
          {{ dialogData?.transactionId && !copyMode ? "Yenilə" : "Əlavə et" }}
        </button>
      </div>
    </div>
  </div>
</form>
