import { Component, OnDestroy, OnInit, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataService } from "src/app/data.service";
import { NewMaterialDialogComponent } from "src/app/dialogs/material-dialog/material-dialog.component";
import { NewProductDialogComponent } from "src/app/dialogs/product-dialog/product-dialog.component";
import { HttpClient } from "@angular/common/http";
import { IApiResult } from "../../shared/models/apiresult.interface";
import { NewCustomerDialogComponent } from "src/app/dialogs/customer-dialog/customer-dialog.component";
import { NewFormulaDialogComponent } from "src/app/dialogs/formula-dialog/formula-dialog.component";
import { db } from "src/app/db.service";
import {
  Observable,
  map,
  Subject,
  tap,
  of,
  defer,
  merge,
  switchMap,
} from "rxjs";
import { ConfirmationDialogComponent } from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { ProfitComponent } from "src/app/reports/profit/profit.component";

@Component({
  selector: "app-material",
  templateUrl: "./dev.component.html",
  styleUrls: ["./dev.component.css"],
})
export class DevComponent implements OnDestroy, OnInit {
  dataLoading = signal(false);
  performance: number = 0;
  navButtons = [
    { name: "Push", icon: "transition_push", function: () => this.push() },
    {
      name: "Throw Error",
      icon: "error",
      function: () => this.triggerError(),
    },
    { name: "Clear Errors", icon: "save", function: () => this.clearErrors() },
    // { name: "Clear Table", icon: "save", function: () => this.clearTable() },
  ];
  navExtraButtons = [
    {
      name: "Mənfəət",
      icon: "save",
      function: () => this.profitDialog(),
    },
    {
      name: "Yeni Xammal",
      icon: "save",
      function: () => this.newMaterialDialog(event),
    },
    {
      name: "Yeni Məhsul",
      icon: "save",
      function: () => this.newProductDialog(event),
    },
    {
      name: "Yeni Müştəri",
      icon: "save",
      function: () => this.newCustomerDialog(event),
    },
    {
      name: "Yeni Formul",
      icon: "save",
      function: () => this.newFormulaDialog(event),
    },
  ];
  errors$: Observable<any[]>;
  fullErrorList = [];
  componentDestroyed$: Subject<boolean> = new Subject();

  checked = true;

  constructor(
    public _dataService: DataService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.errors$ = this.getData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getData() {
    this.dataLoading.set(true);
    const localData = this._dataService.getLS("errors") || [];
    const localObservable = defer(() => {
      return of(localData);
    });
    const liveQueryObservable = this.http
      .get<IApiResult>("https://tekoplast.az/jjapi/api.php/records/errors")
      .pipe(
        map((response) => response.records),
        map((records) => {
          this.fullErrorList = records;
          let result = records.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          const messageCount = new Map();
          result = Array.from(new Set(result.map((a) => a.message))).map(
            (message) => {
              const duplicates = result.filter((a) => a.message === message);
              const count = duplicates.length;
              const uniqueObj = { ...duplicates[0], duplicateNumber: count };
              messageCount.set(message, count);
              return uniqueObj;
            }
          );
          return result;
        }),
        tap((res) => {
          this._dataService.setLS("errors", res);
          this.dataLoading.set(false);
        })
      );
    return merge(localObservable, liveQueryObservable);
  }

  push(error?: string) {
    this.http
      .get(
        "https://tekoplast.az/docktr/api/?getTokens&uid=1TgHpEOspfZmDhanm8m1XLgm29u1"
      )
      .pipe(
        switchMap((res) => {
          let tokens = res[0].fcmToken;
          let requestData = {
            tokens: JSON.stringify(tokens),
            title: "JJ Accounting Error",
            body: error || null,
            url: "https://jj.tekoplast.az",
          };
          return this.http.post(
            "https://tekoplast.az/docktr/api/?pushNotification",
            requestData
          );
        })
      )
      .subscribe();
  }

  triggerError() {
    throw new Error("Test error for error handling");
  }

  clearErrors() {
    let ids = this.fullErrorList.map((err) => err.id);
    this.http
      .delete(`https://tekoplast.az/jjapi/api.php/records/errors/${ids}`)
      .subscribe(() => (this.errors$ = this.getData()));
  }

  clearTable() {
    db.transactions.clear();
    db.bank.clear();
    db.sells.clear();
    db.stock.clear();
    db.production.clear();
    // db.transactionDeletions.clear();

    localStorage.removeItem(this._dataService.dbTable + "lastTransactionId");
    localStorage.removeItem(`${this._dataService.dbTable}lastBankId`);
    localStorage.removeItem(`${this._dataService.dbTable}lastSellId`);
    localStorage.removeItem(`${this._dataService.dbTable}lastStockId`);
    localStorage.removeItem(`${this._dataService.dbTable}lastProductionId`);
    localStorage.removeItem(
      `${this._dataService.dbTable}lastTransactionDeletion`
    );
    console.log("cleared");
  }

  deleteEntry(error) {
    let ids;
    ids = this.fullErrorList
      .filter((err) => err.message == error.message)
      .map((err) => err.id);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: error.message,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.http
          .delete(this._dataService.dbBase + "api.php/records/errors/" + ids)
          .subscribe(async () => {
            this.errors$ = this.getData();
          });
      }
    });
  }

  profitDialog() {
    const dialogRef = this.dialog.open(ProfitComponent, {
      width: "520px",
      // You can customize other properties like height, data, etc.
    });
  }

  newMaterialDialog(event?) {
    this.dialog.open(NewMaterialDialogComponent, {
      width: "520px",
    });
  }

  newProductDialog(event) {
    this.dialog.open(NewProductDialogComponent, {
      width: "900px",
      data: this._dataService.products().find((x) => x.id == 142),
    });
  }

  newCustomerDialog(event) {
    this.dialog.open(NewCustomerDialogComponent, {
      width: "500px",
      data: event,
    });
  }

  newFormulaDialog(event) {
    this.dialog.open(NewFormulaDialogComponent, {
      width: "800px",
      data: { formulaId: 27 },
      panelClass: "materialDialog",
    });
  }
}
