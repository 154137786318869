<app-navbar
  [headerText]="'Developer'"
  [buttons]="navButtons"
  [extraButtons]="navExtraButtons"
  [dataLoading]="dataLoading()"
></app-navbar>

<div class="container-fluid mt-6">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <app-g-ai></app-g-ai>
    </div>
  </div>
</div>

<div class="container-fluid px-3 mt-3">
  <div class="row justify-content-center">
    <div class="col-md-8">
      @if(errors$ | async; as errors){ @if(errors.length) {
      <div
        *ngFor="let error of errors"
        class="error-card cardHover"
        style="position: relative"
      >
        @if(error.message){
        <div class="error-message">{{ error.message }}</div>
        } @if(error.duplicateNumber) {
        <div class="duplicateNo">
          {{ error.duplicateNumber }}
        </div>
        } @if(error.stackTrace) {
        <h6>Stack</h6>
        <div class="stack-trace">{{ error.stackTrace }}</div>
        } @if(error.transaction) {
        <h6>Transaction</h6>
        <div class="transaction">{{ error.transaction }}</div>
        } @if(error.formData) {
        <h6>Form Data</h6>
        <div class="stack-trace">{{ error.formData }}</div>
        }

        <div class="date">{{ error.date }}</div>
        <div class="user">
          User: {{ error.user }}
          <mat-icon
            style="
              background: #f4f4f4;
              border: 1px solid #ececec;
              padding: 6px 30px 30px 8px;
              border-radius: 10px;
            "
            class="delete-icon"
            (click)="deleteEntry(error)"
            >delete</mat-icon
          >
        </div>
      </div>

      } @else {
      <div
        class="d-flex flex-column w-100 align-items-center justify-content-center unselectable"
        style="font-size: 1.5rem; font-weight: 600; height: calc(100dvh - 200px); color: var(--primary-background)"
      >
        <span style="font-size: 3rem" class="material-symbols-outlined">
          sentiment_satisfied
        </span>
        <span>No Errors</span>
      </div>
      } }
    </div>
  </div>
</div>

<!-- <div
  class="container-fluid px-3 noHammerSwipe mt-6"
  *ngIf="stockGraphs.length && stockGraphs.length > 0"
>
  <div #cardsContainer class="cardsContainer widgetsContent mt-3 pb-2">
    <div
      (click)="showTotal = !showTotal"
      class="cards cardsSettings"
    >
      <span class="material-symbols-outlined" style="font-size: 50px; color: #e8e8e8"> instant_mix </span>
      <span style="font-size: 14px">{{ showTotal ? "Toplam" : "Dövr" }}</span>
    </div>
    <ng-container *ngFor="let sum of stockGraphs">
      <div #card class="cards" *ngIf="sum.sumBetweenDates != 0">
        <div class="d-flex justify-content-between" style="flex: 1">
          <h4>{{ sum.materialName }}</h4>
          <span class="materialPrice"
            >{{
              sum.totalPrice / (sum.totalQuantityBeforeDate + sum.totalQuantity)
                | number : "1.2-2"
                | removeComma
            }}<span
              class="manat"
              style="font-size: 10px !important; text-align: center"
              >₼</span
            ></span
          >
        </div>
        <div
          class="d-flex"
          style="
            border-radius: 6px;
            font-family: Avant;
            font-size: 14px;
            flex-direction: column;
          "
        >
          <div
            *ngIf="!showTotal"
            style="
              display: flex;
              gap: 0.5em;
              flex: 1;
              justify-content: space-around;
              text-align: center;
            "
          >
            <span
              >{{ sum.totalQuantity | number : "1.2-2" | removeComma }}
              <span class="manat" style="font-size: 12px !important">{{
                sum.unit || "ədəd"
              }}</span></span
            >
            <span
              >{{ sum.sumBetweenDates | number : "1.2-2" | removeComma }}
              <span class="manat" style="font-size: 12px !important"
                >₼</span
              ></span
            >
          </div>
          <div
            *ngIf="showTotal"
            style="
              display: flex;
              gap: 0.5em;
              flex: 1;
              justify-content: space-around;
              text-align: center;
            "
          >
            <span
              >{{
                sum.totalQuantityBeforeDate + sum.totalQuantity
                  | number : "1.2-2"
                  | removeComma
              }}
              <span class="manat" style="font-size: 12px !important">{{
                sum.unit || "ədəd"
              }}</span></span
            >
            <span
              >{{
                sum.sumBeforeStartDate + sum.sumBetweenDates
                  | number : "1.2-2"
                  | removeComma
              }}
              <span class="manat" style="font-size: 12px !important"
                >₼</span
              ></span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="container-fluid px-3">
  <div class="row">
    <div id="targetTable" class="col-md-6 mt-2">
      <div class="input-group">
        <input
          #searchInput
          type="search"
          class="form-control"
          (keyup)="applyFilter(searchInput.value)"
          (search)="applyFilter(searchInput.value)"
          placeholder="Axtar"
        />
      </div>
    </div>
    <div class="col-md-6 mt-2 mt-smm-6">
      <div class="summary">
        <span>Alınan {{ totalIn | number }} <span class="manat">₼</span></span>
        <span
          >Ödənən {{ -totalOut | number }} <span class="manat">₼</span></span
        >
        <span
          >Fərq {{ totalIn + totalOut | number }}
          <span class="manat">₼</span></span
        >
        <!-- <span
          ><span class="material-symbols-outlined"> timer </span>
          {{ performance }}
        </span> 
      </div>
    </div>
  </div>

  <ag-grid-angular
    style="
      width: 100%;
      height: calc(100vh - 260px);
      border-radius: 6px;
      overflow: hidden;
      margin-top: 1rem;
    "
    class="ag-theme-alpine"
    [rowData]="agGridData$ | async"
    [columnDefs]="columnDefs"
    [localeText]="_dataService.agGridLocale"
    (gridReady)="onGridReady($event)"
    [cacheQuickFilter]="true"
  >
  </ag-grid-angular>
</div> -->
