import { Component, OnInit, inject, effect } from "@angular/core";
import { ColumnState, GridApi, GridReadyEvent } from "ag-grid-community";
import { DataService } from "src/app/data.service";
import { db } from "src/app/db.service";
import { ProductsService } from "src/app/products/products.service";
import { StockService } from "src/app/stock/stock.service";

@Component({
  selector: "app-profit",
  templateUrl: "./profit.component.html",
  styleUrls: ["./profit.component.css"],
})
export class ProfitComponent implements OnInit {
  _dataService = inject(DataService);
  _productService = inject(ProductsService);
  _stockService = inject(StockService);
  dataLoading: boolean = true;
  navButtons = [
    {
      name: "",
      icon: "",
      function: () => null,
    },
  ];
  columnDefs = [
    {
      headerName: "id",
      field: "productId",
      maxWidth: 60,
      hide: true,
    },
    {
      headerName: "Məhsul",
      field: "productName",
      flex: 2,
    },
    {
      headerName: "Toplam Satış, AZN",
      field: "sellsSum",
      sortable: true,
      flex: 3,
      minWidth: 100,
    },
    {
      headerName: "Mənfəət, AZN",
      field: "revenue",
      sortable: true,
      flex: 3,
      minWidth: 100,
    },
    {
      headerName: "%",
      field: "revenuePercentage",
      sortable: true,
      flex: 1,
    },
  ];
  protected gridApi!: GridApi;
  agGridData$;
  sellsTotal: number = 0;
  costTotal: number = 0;
  administrativXercler: number = 0;
  materials;

  constructor() {
    effect(() => {
      if (this._dataService.dateChangedSig()) {
        this.getData();
      }
    });
  }

  async ngOnInit() {}

  async getData() {
    this.dataLoading = true;
    this.sellsTotal = 0;
    this.costTotal = 0;
    this.administrativXercler = 0;
    const sells = await db.sells
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray();

    const result = sells.reduce((accumulator, sell) => {
      const { productId, materialId, quantity, price } = sell;
      let productionCost;
      let formulaCost;
      let id;
      let name;
      if (productId) {
        id = productId;
        name =
          this._dataService
            .products()
            .find((product) => product.id === productId)?.name || "";
      } else if (materialId) {
        id = materialId;
        name =
          "material: " +
            this._dataService
              .materials()
              .find((material) => material.id === materialId)?.name || "";
      }

      if (id) {
        // Check if the accumulator object already has an entry for the current id
        if (!accumulator[id]) {
          let product = this._dataService
            .products()
            .find((product) => product.id === id);

          let formula = product?.formulaGroupId
            ? this._dataService
                .formulaGroups()
                .find((formula) => formula.id === product?.formulaGroupId)
            : null;

          formulaCost = formula?.cost ? formula?.cost : null;

          productionCost = product?.width
            ? (formulaCost * product.weight) / product.width
            : formulaCost * product?.weight || 0;

          // If not, create a new entry with id, sum initialized to 0, and name fetched from this._dataService.products or this._dataService.materials array
          accumulator[id] = {
            productId: id,
            productName: name,
            totalQuantity: 0,
            sellsSum: 0,
            revenue: 0,
            revenuePercentage: 0,
            formulaCost: productionCost,
            costTotal: 0,
          };
        }

        // Increment the sum for the current id by adding quantity * price
        accumulator[id].sellsSum += quantity * price;
        accumulator[id].totalQuantity += quantity;
        accumulator[id].revenue += productionCost
          ? quantity * (price - productionCost)
          : null;

        // Limit the sum value to have a maximum of 2 decimal points
        accumulator[id].sellsSum = parseFloat(
          accumulator[id].sellsSum.toFixed(2)
        );

        accumulator[id].revenue = parseFloat(
          accumulator[id].revenue.toFixed(2)
        );

        accumulator[id].revenuePercentage = parseFloat(
          ((accumulator[id].revenue / accumulator[id].sellsSum) * 100).toFixed(
            2
          )
        );
      }

      // Return the updated accumulator object for the next iteration
      return accumulator;
    }, {});

    const finalResult: any = Object.values(result);
    finalResult.map((item) => {
      this.sellsTotal += item.sellsSum;
      this.costTotal += item.totalQuantity * item.formulaCost;
    });
    this.agGridData$ = finalResult;

    let bankXercGroups = this._dataService
      .bankGroups()
      .filter((category) => category.bankGroupCategory == 2)
      .map((category) => category.id);

    let bank = await db.bank
      .where("date")
      .between(
        this._dataService.startDate.value.format("YYYY-MM-DD"),
        this._dataService.endDate.value.format("YYYY-MM-DD"),
        true,
        true
      )
      .toArray()
      .then((transaction) =>
        transaction.filter(
          (t) => bankXercGroups.includes(t.bankGroupId) ?? null
        )
      );

    bank.map((t) => {
      this.administrativXercler += t.outgoings;
    });

    this.dataLoading = false;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setGridOption("includeHiddenColumnsInQuickFilter", true);
    // this.gridApi.setIsExternalFilterPresent(
    //   () => filterTable !== false || chipSelected.length > 0
    // );
    // this.gridApi.setDoesExternalFilterPass(this.filterTableFn);
    this.gridApi.onFilterChanged();
    var defaultSortModel: ColumnState[] = [
      { colId: "sellsSum", sort: "desc", sortIndex: 0 },
    ];
    params.api.applyColumnState({ state: defaultSortModel });
  }

  applyFilter(val) {
    // filterTable = true;
    this.gridApi.setGridOption("quickFilterText", val);
    // this._customersService.sumDebit = 0;
    // this._customersService.sumKredit = 0;
    // this.gridApi.forEachNodeAfterFilterAndSort((node) => {
    //   this._customersService.sumDebit +=
    //     node.data.finalBalance >= 0 ? node.data.finalBalance : 0;
    //   this._customersService.sumKredit +=
    //     node.data.finalBalance < 0 ? node.data.finalBalance : 0;
    // });
  }
}
