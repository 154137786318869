<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid px-3 homeTitleContainer">
    <div class="mobileOnly" style="width: 50px"></div>
    <h3
      *ngIf="headerText"
      class="mb-0 unselectable"
      (click)="_dataService.showNav = !_dataService.showNav"
    >
      {{ headerText }}
    </h3>
    <ng-select
      *ngIf="ngHeader"
      (change)="onChange.emit(selectedItem === 0 ? 0 : selectedItem)"
      class="titleSelector"
      [items]="items"
      bindLabel="name"
      bindValue="id"
      [(ngModel)]="selectedItem"
      [clearable]="false"
      [searchFn]="_dataService.customSearchFn"
      style="
        font-size: 1.4rem;
        font-family: 'Avant';
        cursor: pointer;
        border: 1px solid lightgray;
        border-radius: 4px;
        padding-left: 8px;
        background-color: white;
      "
    >
    </ng-select>
    <mat-icon
      class="matArrowDown"
      *ngIf="ngHeader"
      style="margin-left: -30px; z-index: 9; pointer-events: none"
      >expand_more</mat-icon
    >
    <div
      class="lds-dual-ring"
      *ngIf="
        dataLoading && !_syncService.dbSyncing && _dataService.mobileDevice
      "
    ></div>
    <span
      class="loader"
      *ngIf="
        dataLoading && !_syncService.dbSyncing && !_dataService.mobileDevice
      "
    ></span>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="_dataService.showNav = !_dataService.showNav"
    >
      <i class="material-icons" style="line-height: 30px">manage_search</i>
    </button>
  </div>

  <div
    class="collapse navbar-collapse"
    [ngClass]="{ show: _dataService.showNav }"
    id="navbarText"
  >
    <div class="container gx-3">
      <div class="d-flex-mobile navButtonsContainer">
        <ul
          *ngIf="extraButtons.length > 0"
          class="navbar-nav ml-auto btnExtra"
          style="right: calc(100% + 200px)"
        >
          <li>
            <button
              mat-stroked-button
              class="btn px-4 h-100 btn-outline-secondary w-sm-100 mt-smm-5 h-100"
              style="
                width: 100%;
                min-height: 38px !important;
                min-width: 200px !important;
                transition: width 1s ease-in;
                background-color: white !important;
                white-space: nowrap;
                font-family: Avant;
              "
              type="button"
              (click)="extraButtons[0].function(); _dataService.showNav = false"
              *ngIf="extraButtons[0].name"
            >
              <i class="material-symbols-outlined">
                {{ extraButtons[0].icon }}
              </i>
              <span style="margin-left: auto; margin-right: auto">{{
                extraButtons[0].name
              }}</span>
            </button>
          </li>
          <li *ngFor="let button of extraButtons.slice(1); let i = index">
            <button
              mat-stroked-button
              class="btn px-4 h-100 btn-outline-secondary w-sm-100 mt-smm-5 h-100 btnTopBar1 hidden"
              type="button"
              (click)="extraButtons.function(); _dataService.showNav = false"
            >
              <i class="material-symbols-outlined">
                {{ button.icon }}
              </i>
              <span style="margin-left: auto; margin-right: auto">{{
                button.name || 'test'
              }}</span>
            </button>
          </li>
        </ul>
        <ul
          *ngIf="buttons.length > 0"
          class="navbar-nav ml-auto navButtons"
          (mouseover)="showButtons()"
          (mouseout)="hideButtons()"
          [ngClass]="{ 'navbar-navHoverForMultiple': buttons.length > 1 }"
        >
          <li>
            <button
              mat-stroked-button
              class="btn px-4 h-100 btn-outline-secondary w-sm-100 mt-smm-5 h-100 btnTopBar"
              type="button"
              (click)="buttons[0].function(); _dataService.showNav = false"
              *ngIf="buttons[0].name"
            >
              <i class="material-symbols-outlined">
                {{ buttons[0].icon }}
              </i>
              <span style="margin-left: auto; margin-right: auto">{{
                buttons[0].name
              }}</span>
            </button>
          </li>
          <li *ngFor="let button of buttons.slice(1); let i = index">
            <button
              mat-stroked-button
              class="btn px-4 h-100 btn-outline-secondary w-sm-100 mt-smm-5 h-100 btnTopBar hidden"
              type="button"
              (click)="button.function(); _dataService.showNav = false"
            >
              <i class="material-symbols-outlined">
                {{ button.icon }}
              </i>
              <span style="margin-left: auto; margin-right: auto">{{
                button.name
              }}</span>
            </button>
          </li>
        </ul>

        <!-- <div class="dateInputs">
          <input
            #startDatee
            placeholder="{{
              _dataService.startDate.value
                ? _dataService.startDate.value.format('DD/MM/YYYY')
                : 'Tarix'
            }}"
            value="{{
              _dataService.startDate.value
                ? _dataService.startDate.value.format('DD/MM/YYYY')
                : 'Tarix'
            }}"
            (click)="
              startDate.value = ''; picker.open(); datePickerClicked($event)
            "
            (ngModelChange)="_dataService.formatDateValue(startDate)"
          />
          <input
            #endDate
            value="{{
              _dataService.endDate.value
                ? _dataService.endDate.value.format('DD/MM/YYYY')
                : 'Tarix'
            }}"
            placeholder="{{
              _dataService.endDate.value
                ? _dataService.endDate.value.format('DD/MM/YYYY')
                : 'Tarix'
            }}"
            (dateChange)="filterDate($event)"
            (click)="endDate.value = ''"
            (ngModelChange)="
              _dataService.formatDateValue(endDate);
              _dataService.showNav = false
            "
          />
        </div> -->
        <mat-form-field
          appearance="fill"
          class="w-sm-100 mt-smm-5 datePicker"
          style="z-index: 999"
        >
          <mat-date-range-input [rangePicker]="picker">
            <input
              #startDate
              name="startDate"
              matStartDate
              placeholder="{{
                _dataService.startDate.value
                  ? _dataService.startDate.value.format('DD/MM/YYYY')
                  : 'Tarix'
              }}"
              [formControl]="_dataService.startDate"
              (click)="startDate.value = ''"
              (ngModelChange)="_dataService.formatDateValue(startDate)"
              (keydown.enter)="
                datePickerOnEnter($event, picker, endDate, startDate)
              "
            />
            <input
              #endDate
              name="endDate"
              matEndDate
              placeholder="_dataService.endDate.value
              ? _dataService.endDate.value.format('DD/MM/YYYY')
              : 'Tarix'"
              [formControl]="_dataService.endDate"
              (dateChange)="filterDate($event)"
              (click)="endDate.value = ''"
              (ngModelChange)="
                _dataService.formatDateValue(endDate);
                _dataService.showNav = false
              "
              (keydown.enter)="datePickerOnEnter($event, picker, endDate)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div
    class="loading-line"
    [style.width.%]="_syncService.loadingProgress"
    [class.visible]="_syncService.dbSyncing"
    *ngIf="_syncService.showSyncLine"
  ></div>
</nav>
