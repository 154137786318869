import { Component, Inject, OnInit, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/data.service";
import { UntypedFormControl } from "@angular/forms";
import { map, switchMap } from "rxjs/operators";
import "lodash";
declare var _: any;
import * as _moment from "moment";
const moment = _moment;
import { db } from "src/app/db.service";
import { AuthService } from "src/app/auth.service";
import { PusherService } from "src/app/pusher.service";

@Component({
  selector: "app-transfer-bank-dialog",
  templateUrl: "./transfer-bank-dialog.component.html",
  styleUrls: ["./transfer-bank-dialog.component.css"],
})
export class TransferBankDialogComponent implements OnInit {
  materials;
  cashBox = JSON.parse(localStorage.getItem("cashBox"))
    ? JSON.parse(localStorage.getItem("cashBox"))
    : this._dataService.getCashBox();
  cashboxIdFrom: number;
  cashboxIdTo: number;
  amount: number;
  date;
  details: string;
  transactionNo: string = "";
  user: string;

  constructor(
    public dialogRef: MatDialogRef<TransferBankDialogComponent>,
    private http: HttpClient,
    private toastr: ToastrService,
    public _dataService: DataService,
    private _authService: AuthService,
    private _pusher: PusherService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.date = new UntypedFormControl(moment());
    if (this.dialogData) {
      if (this.dialogData.cashboxId == 0) {
        this.cashboxIdFrom = this.cashBox[0].id;
        this.cashboxIdTo = this.cashBox[1].id;
      } else {
        this.cashboxIdFrom = this.dialogData.cashboxId;
        let box = this.cashBox.find((c) => c.id != this.dialogData.cashboxId);
        this.cashboxIdTo = box.id;
      }
    }
    if (this.dialogData.data) {
      this.details = this.dialogData.data.details;
      this.date = new UntypedFormControl(moment(this.dialogData.data.date));
      this.amount = this.dialogData.data.income
        ? this.dialogData.data.income
        : this.dialogData.data.outgoings;
      this.transactionNo = this.dialogData.data.tId;

      let data = await db.bank
        .where("tId")
        .equals(this.dialogData.data.tId)
        .toArray();
      console.log(data);
      this.cashboxIdFrom = data.find((d) => d.outgoings > 0).cashboxId;
      this.cashboxIdTo = data.find((d) => d.income > 0).cashboxId;
      // this.cashboxIdFrom = this.dialogData.data.outgoings ? this.dialogData.cashboxId : null;
      // this.cashboxIdTo = this.dialogData.data.income ? this.dialogData.cashboxId : null;

      if (this.dialogData?.data?.transaction?.userId) {
        let userName = this._dataService
          .users()
          .find((u) => u.id == this.dialogData?.data?.transaction?.userId);
        this.user = userName ? userName.fullname : null;
      }
    }
  }

  addTransferOrder(data, event) {
    event.srcElement.disabled = true;
    event.target.innerText = "Gözləyin ...";

    if (data.cashboxIdFrom == data.cashboxIdTo) {
      this.toastr.error("Eyni kassa arasında köçürmə edə bilməzsiniz");
      event.srcElement.disabled = false;
      event.target.innerText = "Əlavə et";
      return;
    }

    let bankEntryIn, bankEntryOut;
    let today = new UntypedFormControl(moment());

    var transaction = {
      id: null,
      type: "Cashbox Transfer",
      sum: data.amount,
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD HH:mm:ss"),
      details: data.details,
      userId: this._authService.loggedInUserValue.id ?? null,
    };

    this.http
      .post(
        this._dataService.dbBase + "api.php/records/transactions/",
        transaction
      )
      .pipe(
        map((transactionId) => {
          transaction.id = transactionId;
          bankEntryIn = {
            transactionId: transactionId,
            cashboxId: data.cashboxIdTo,
            income: data.amount,
            bankGroupId: 30, //TODO FIX
          };

          bankEntryOut = {
            transactionId: transactionId,
            cashboxId: data.cashboxIdFrom,
            outgoings: data.amount,
            bankGroupId: 30, //FIX
          };
        }),
        switchMap(() =>
          this.http.post(this._dataService.dbBase + "api.php/records/bank/", [
            bankEntryIn,
            bankEntryOut,
          ])
        )
      )
      .subscribe({
        next: (res) => {
          console.log(res);
          let dbBank = [
            {
              id: res[0],
              ...bankEntryIn,
              date: transaction.date,
              tId: transaction.id,
              transactionId: transaction,
            },
            {
              id: res[1],
              ...bankEntryOut,
              date: transaction.date,
              tId: transaction.id,
              transactionId: transaction,
            },
            ,
          ];
          db.transactions.add(transaction, transaction.id);
          db.bank.bulkAdd(dbBank);

          let pushData = { transaction, bank: dbBank };
          this._pusher.postData(pushData).subscribe();

          this.dialogRef.close(pushData);
          this.toastr.success("Əlavə edildi!");
        },
        error: (err) => {
          console.log(err);
          this.toastr.error("Xəta");
        },
      });
  }

  async getBankData(tId) {
    let result = await db.bank.where("tId").equals(tId).toArray();
    return result;
  }
}
