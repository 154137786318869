<div
  style="
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Avant;
    position: relative;
    background: #2e425a;
    background: linear-gradient(
      0deg,
      rgb(75 109 147) 0%,
      rgba(46, 66, 90, 1) 100%
    );
  "
>
  <div class="container-fluid">
    <div class="row justify-content-center" style="position: relative">
      <div
        style="
          display: flex;
          justify-content: center;
          margin-bottom: 1em;
          color: white;
          user-select: none;
        "
      >
        <span
          style="
            font-size: 80px;
                font-family: 'Titan One', fantasy, Tahoma;
                line-height: 1;
                font-style: italic;
          "
        >
          JJ
        </span>

        <div
          style="
            display: flex;
            flex-direction: column;
            font-family: 'Avant';
            font-size: 11px;
            line-height: 1;
            justify-content: end;
            margin-left: 3px;
            margin-bottom: 5px;
          "
        >
          <span style="padding-left: 5px">v{{appVersion}}</span>
          <span>ACCG</span>
        </div>
      </div>

      <form
        #inv="ngForm"
        [formGroup]="signinForm"
        class="d-flex justify-content-center"
        style="z-index: 1;user-select: none;"
      >
        <div
          class="col-lg-4 col-12 px-4"
          style="
            background: #46637fe0;
            background: linear-gradient(0deg, #46637fe0 0%, #385570e0 100%);
            border-radius: 8px;
            box-shadow: 0px 0px 5px #00000038;
            font-family: 'Kodchasan';
          "
        >
          <h5
            style="
              display: flex;
              justify-content: center;
              align-items: baseline;
              color: #ffffff;
              font-family: Kodchasan;
              padding-block: 20px;
              font-weight: 100;
            "
          >
            <mat-icon style="align-self: flex-end; margin-right: 5px"
              >person</mat-icon
            >
            İstifadəçi Girişi
          </h5>
          <div class="mb-2">
            <label for="email" class="form-label">
              <mat-icon>email</mat-icon> Email
            </label>
            <input
              type="email"
              class="form-control"
              id="email"
              formControlName="email"
              autofocus
            />
          </div>

          <div>
            <label for="password" class="form-label mt-2">
              <mat-icon>vpn_key</mat-icon> Şifrə
            </label>
            <input
              type="password"
              class="form-control"
              formControlName="password"
              id="password"
            />
          </div>

          <div class="d-grid gap-2">
            <button
              class="btn btn-outline-secondary py-2"
              style="border: 0px!important"
              [disabled]="inv.invalid && inv.touched"
              (click)="onSubmit()"
            >
              Daxil Et
            </button>
          </div>
        </div>
      </form>
      <div
        style="position: absolute; bottom: -60px"
        class="lds-dual-ring mt-3 mr-3"
        *ngIf="dataLoading"
      ></div>
    </div>
  </div>

  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</div>
