@if(dataLoading) {
<div
  style="z-index: 999999; position: absolute; left: 0; top: 98px"
  class="container-fluid d-flex justify-content-center"
>
  <span class="loader"></span>
</div>
}

<textarea
  placeholder="Sualınız?"
  class="form-control"
  rows="2"
  name="prompt"
  [(ngModel)]="prompt"
  (keydown.enter)="sendPrompt($event.target.value)"
  [disabled]="dataLoading"
></textarea>

@if(chatData.length>0){ @for(item of chatData; track item.prompt) {
<div class="container mt-3" style="font-family: Mulish">
  <div class="row" style="border-bottom: 1px solid #ececec">
    <span style="font-style: italic">{{ item.prompt }}</span>
  </div>
  <div class="row">
    <span class="responseText" [innerHTML]="item.response"></span>
  </div>
</div>
} }
