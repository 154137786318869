import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthService } from "./auth.service";
import { BankComponent } from "./bank/bank.component";
import { CustomersFullComponent } from "./customers/customers-full/customers-full.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { DevComponent } from "./products/dev/dev.component";
import { ProductsComponent } from "./products/products.component";
import { SellsComponent } from "./sells/sells.component";
import { StockComponent } from "./stock/stock.component";
import { ProfitComponent } from "./reports/profit/profit.component";
import { MaterialComponent } from "./materials/material.component";
import { CashDistributionComponent } from "./reports/cash-distribution/cash-distribution.component";

const routes: Routes = [
  { path: "", redirectTo: "Home", pathMatch: "full" },
  {
    path: "Home",
    component: DashboardComponent,
    canActivate: [AuthService],
    data: { tab: 0 },
  },
  {
    path: "Stock",
    component: StockComponent,
    canActivate: [AuthService],
    data: { tab: 1 },
  },
  {
    path: "Products",
    component: ProductsComponent,
    canActivate: [AuthService],
    data: { tab: 2 },
  },
  {
    path: "Sells",
    component: SellsComponent,
    canActivate: [AuthService],
    data: { tab: 3 },
  },
  {
    path: "Bank",
    component: BankComponent,
    canActivate: [AuthService],
    data: { tab: 4 },
  },
  {
    path: "Customers",
    component: CustomersFullComponent,
    canActivate: [AuthService],
    data: { tab: 5 },
  },
  {
    path: "Profit",
    component: ProfitComponent,
    canActivate: [AuthService],
    data: { tab: 7 },
  },
  {
    path: "CashDist",
    component: CashDistributionComponent,
    canActivate: [AuthService],
    data: { tab: 9 },
  },
  {
    path: "Materials",
    component: MaterialComponent,
    canActivate: [AuthService],
    data: { tab: 8 },
  },
  {
    path: "Dev",
    component: DevComponent,
    canActivate: [AuthService],
    data: { tab: 6 },
  },
  { path: "Login", component: LoginComponent },
  { path: "404", component: DashboardComponent },
  { path: "**", redirectTo: "Home" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
