<div
  class="ui-titlebar"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <div class="ui-titletext">
    <span style="text-wrap: nowrap"> Kassalar Arası Köçürmə </span>
    <div class="clipText ml-auto" *ngIf="dialogData.data">
      <span class="transactionNo"
        ><span class="pcOnly" style="margin-right: -5px">Qaimə</span
        >{{ transactionNo }}</span
      >
    </div>
  </div>
  <div class="ui-titlecontrols">
    <button class="ui-btn close" (click)="this.dialogRef.close()">
      <svg viewBox="0 0 10 10">
        <polygon
          points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"
        />
      </svg>
    </button>
  </div>
</div>

<form #inv="ngForm">
  <div class="container">
    <div class="row g-2 pb-3 pt-2 px-2">
      <div class="col-6">
        <label class="form-label" for="bankGroup">Kassadan</label>
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <i class="material-icons">account_balance_wallet</i>
          </div>
          <ng-select
            class="form-control"
            [items]="cashBox"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="cashboxIdFrom"
            [searchable]="false"
            [clearable]="false"
            name="cashboxIdFrom"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-6">
        <label class="form-label" for="bankGroup">Kassaya</label>
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <i class="material-icons">account_balance_wallet</i>
          </div>
          <ng-select
            class="form-control"
            [items]="cashBox"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="cashboxIdTo"
            [searchable]="false"
            [clearable]="false"
            name="cashboxIdTo"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-6">
        <label class="form-label" for="name"
          >Məbləğ, <span class="manat">₼</span></label
        >
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <span class="manat" style="font-weight: 600">₼</span>
          </div>
          <input
            [(ngModel)]="amount"
            type="number"
            placeholder="Məbləğ"
            class="form-control"
            autocomplete="off"
            name="amount"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <label class="form-label" for="date">Tarix</label>
        <div class="input-group">
          <div
            class="input-group-prepend"
            (click)="myDatepicker.open()"
            style="cursor: pointer"
          >
            <div
              class="input-group-text pcOnly"
              style="
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              "
            >
              <i class="material-icons">calendar_month</i>
            </div>
          </div>
          <input
            #startDate
            [(ngModel)]="date.value"
            [matDatepicker]="myDatepicker"
            placeholder="{{
              _dataService.startDate.value
                ? _dataService.startDate.value.format('DD/MM/YYYY')
                : 'Tarix'
            }}"
            (click)="startDate.value = ''"
            (ngModelChange)="_dataService.formatDateValue(startDate)"
            class="form-control"
            id="date"
            name="date"
          />
          <mat-datepicker #myDatepicker></mat-datepicker>
        </div>
      </div>

      <div class="col-12">
        <label class="form-label" for="details">Məlumat</label>
        <div class="input-group">
          <div class="input-group-text pcOnly">
            <mat-icon>info_outline</mat-icon>
          </div>
          <textarea
            placeholder="Məlumat"
            class="form-control"
            id="details"
            rows="2"
            name="details"
            [(ngModel)]="details"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</form>

<div
  class="d-grid mt-2"
  style="
    background-color: #f6f6f6;
    border-top: 1px solid #ececec;
    padding: 0.75rem 1.25rem;
  "
>
  <div class="btn-group" *ngIf="!transactionNo">
    <button
      class="btn btn-outline-primary btnDelete padding-block-5 w-100"
      [disabled]="!inv.valid"
      (click)="addTransferOrder(inv.value, $event)"
    >
      Əlavə et
    </button>
  </div>
  @if(user) {
  <span
    class="userName"
    style="
      margin: 0px 0px;
      color: #606060;
      position: absolute;
      right: 25px;
      bottom: 3px;
    "
    ><span class="material-symbols-outlined"> person </span>{{ user }}</span
  >
  }
</div>
