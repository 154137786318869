import {
  Component,
  OnInit,
  Inject,
  Optional,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgForm, UntypedFormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { forkJoin, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { NewMaterialDialogComponent } from "src/app/dialogs/material-dialog/material-dialog.component";
import * as _moment from "moment";
const moment = _moment;
import "lodash";
declare var _: any;
import { DataService } from "src/app/data.service";
import { AuthService } from "src/app/auth.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { db } from "src/app/db.service";
import { PusherService } from "src/app/pusher.service";
import { StockService } from "../stock.service";
import { CustomersService } from "src/app/customers/customers.service";

@Component({
  selector: "app-stock-dialog",
  templateUrl: "./stock-dialog.component.html",
  styleUrls: ["./stock-dialog.component.css"],
})
export class AddStockDialogComponent implements OnInit {
  focusables = ["input", "select", "textarea"];
  @ViewChildren("input") inputs: QueryList<NgSelectComponent>;
  @ViewChild("inv") form: NgForm;
  date;
  dateNow = moment(Date.now()).format("YYYY-MM-DD");
  dataLoading: boolean = false;
  user: string;
  unitType: string;
  residue: number = 0;
  priceReceived: number = 0;
  sum: number = 0;
  materialsToAdd: {
    id: number;
    quantity: number;
    length?: number;
    sellPrice: number;
    transactionId?: number;
    sum?: number;
    unit?: string;
    useSum: boolean;
  }[] = [
    {
      id: null,
      quantity: null,
      length: null,
      sellPrice: null,
      transactionId: null,
      sum: null,
      useSum: false,
    },
  ];
  products$;
  selectedStockGroupId: number = 1;
  materialSums;
  transactionDetails;
  transactionNo: string = "";
  deleteTransaction = of({});
  response;
  editMode: boolean = false;
  copyMode: boolean = false;
  showContextMenu: boolean = false;

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
    if (event.shiftKey) {
      event.preventDefault();

      if (event.key === "n" || event.key === "N") {
        this.nextProduct();
      }
      // if (event.key === 'M' || event.key === 'm') {
      //   if(this.form.valid && !this.dialogData?.transactionId) {
      //     this.btnAdd.nativeElement.disabled = true;
      //     this.btnAdd.nativeElement.innerText = "Gözləyin ...";
      //     this.addSell(this.form.value, event);
      //   }
      // }
    }
  }

  // stockEntries: { transactionId: number, stockGroupId: number, materialId: number, quantity: number, price: number}

  constructor(
    public dialogRef: MatDialogRef<AddStockDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialog: MatDialog,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    public _dataService: DataService,
    public _authService: AuthService,
    private _pusher: PusherService,
    private _stockService: StockService,
    protected _customersService: CustomersService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log(this.dialogData);
    this.date =
      this._dataService.startDate.value.format("YYYY-MM-DD") ==
      this._dataService.endDate.value.format("YYYY-MM-DD")
        ? new UntypedFormControl(
            moment(this._dataService.endDate.value.format("YYYY-MM-DD"))
          )
        : new UntypedFormControl(moment());
    this.selectedStockGroupId = this.dialogData.stockGroupId;

    if (this.dialogData.data) {
      this.transactionNo = this.dialogData.data.tId;
      this._customersService.selectedCustomerId = this.dialogData.data
        .customerId
        ? this.dialogData.data.customerId
        : this.dialogData.data.customerName
        ? _.find(this._dataService.customers(), {
            name: this.dialogData.data.customerName,
          })?.id
        : null;
      this.date = new UntypedFormControl(moment(this.dialogData.data.date));
      this.selectedStockGroupId = this.dialogData.data.stockGroupId;
      this.transactionDetails = this.dialogData.data.details;

      if (
        this.dialogData?.data?.userId ||
        this.dialogData?.data?.transactionId?.userId
      ) {
        let userName = this.dialogData?.data?.transactionId?.userId
          ? this._dataService
              .users()
              .find((u) => u.id == this.dialogData?.data?.transactionId?.userId)
          : this._dataService
              .users()
              .find((u) => u.id == this.dialogData?.data?.userId);
        this.user = userName ? userName.fullname : null;
      }

      this.editMode = this.dialogData.data?.editMode
        ? this.dialogData.data?.editMode
        : false;
      this.copyMode = this.dialogData.data?.copyMode
        ? this.dialogData.data?.copyMode
        : false;

      this.materialsToAdd = [];
      this.dialogData.transactions.forEach((el) => {
        this.materialsToAdd.push({
          id: el.materialId,
          quantity:
            this.dialogData.stockEntryType == "Stock Entry"
              ? parseFloat(el.quantity.toFixed(2))
              : -parseFloat(el.quantity.toFixed(2)),
          sellPrice: parseFloat(el.price.toFixed(3)),
          unit: this._dataService.materials().find((x) => x.id == el.materialId)
            .unit,
          transactionId: el.tId,
          sum:
            this.dialogData.stockEntryType == "Stock Entry"
              ? parseFloat((el.quantity * el.price).toFixed(2))
              : -parseFloat((el.quantity * el.price).toFixed(2)),
          useSum: false,
        });
        this.sum +=
          this.dialogData.stockEntryType == "Stock Entry"
            ? parseFloat((el.quantity * el.price).toFixed(2))
            : -parseFloat((el.quantity * el.price).toFixed(2));
      });
    } else {
      this._customersService.selectedCustomerId = null;
    }

    this.getMaterialSums();

    this.dataLoading = false;
  }

  addNewIstehsal(data, btn) {
    this.dataLoading = true;
    btn.firstChild.innerText = "Gözləyin";
    btn.disabled = true;

    let postStockEntries,
      postTransaction,
      putTransaction,
      postBank,
      putMaterialPriceUpdates;
    let result, remove, dxBank;
    let stockEntryFinal: {}[] = [];

    const handleError = () => {
      this.dataLoading = false;
      btn.firstChild.innerText = "Əlavə et";
      btn.disabled = false;
      this.toastr.error("", "Xəta baş verdi, yenidən cəhd edin", {
        toastClass: "ngx-toastr",
        progressBar: true,
        timeOut: 2500,
        progressAnimation: "increasing",
      });
    };

    let stockEntries = _.values(data.products); // STOCK ENTRIES

    // this.date = new UntypedFormControl(moment());
    let today = new UntypedFormControl(moment());
    // this.date.value = data.date;

    var transaction: IApiResult = {
      type: this.dialogData.stockEntryType,
      sum: data.priceSum,
      customerId: this._customersService.selectedCustomerId,
      date: this.date.value.format("YYYY-MM-DD"),
      dateAdded: today.value.format("YYYY-MM-DD HH:mm:ss"),
      details: data.details,
      userId: this._authService.loggedInUserValue.id ?? null,
      refersTo: this.dialogData?.data?.tId ?? null,
    };

    if (this.dialogData.data) {
      putTransaction = this.http.put(
        this._dataService.dbBase +
          "api.php/records/transactions/" +
          this.dialogData.data.tId,
        { status: 2 }
      );
    } else {
      putTransaction = of(null);
    }

    postTransaction = this.http.post(
      this._dataService.dbBase + "api.php/records/transactions",
      transaction
    );

    forkJoin(postTransaction, putTransaction)
      .pipe(
        map((transactionId) => {
          let materialIds = [];
          let materialPrices: {}[] = [];
          transaction.id = transactionId[0];
          this.deleteTransaction = this.http.delete(
            this._dataService.dbBase +
              "api.php/records/transactions/" +
              transactionId[0]
          );
          stockEntries.forEach((f) => {
            if (this.dialogData.stockEntryType == "Stock Out") {
              stockEntryFinal.push({
                materialId: f.materialId,
                transactionId: transactionId[0],
                stockGroupId: this.selectedStockGroupId,
                quantity: -f.quantity,
                price: f.price,
              });
            } else {
              stockEntryFinal.push({
                materialId: f.materialId,
                transactionId: transactionId[0],
                stockGroupId: this.selectedStockGroupId,
                quantity: f.quantity,
                price: f.price,
              });
            }
            materialIds.push(f.materialId);
            materialPrices.push({
              price: f.price,
            });
          });

          putMaterialPriceUpdates = this.http.put(
            this._dataService.dbBase +
              "api.php/records/materials/" +
              materialIds.toString(),
            materialPrices
          );
          postStockEntries = this.http.post(
            this._dataService.dbBase + "api.php/records/stock/",
            stockEntryFinal
          );

          let bankEntry = {
            transactionId: transactionId[0],
            income: null,
            outgoings: null,
            bankGroupId: 1,
            cashboxId: 1, // TODO FIX THIS!! bu ve yuxaridaki secile bilmelidir
          };

          // BANK
          if (
            this.priceReceived != 0 &&
            this.dialogData.stockEntryType == "Stock Entry"
          ) {
            bankEntry.outgoings = this.priceReceived;
            postBank = this.http.post(
              this._dataService.dbBase + "api.php/records/bank/",
              bankEntry
            );
          } else if (
            this.priceReceived != 0 &&
            this.dialogData.stockEntryType == "Stock Out"
          ) {
            bankEntry.income = this.priceReceived;
            postBank = this.http.post(
              this._dataService.dbBase + "api.php/records/bank/",
              bankEntry
            );
          } else {
            bankEntry = null;
            postBank = of(null);
          }

          console.log(bankEntry);

          if (bankEntry) {
            dxBank = {
              ...bankEntry,
              id: null,
              transactionId: transaction,
              tId: transactionId[0],
              dateAdded: today.value.format("YYYY-MM-DD"),
              details: transaction.details,
            };
          }

          // FOR ADDING DATA TO tableData without update over internet
          let selectedCustomer = this._dataService
            .customers()
            .find(
              (customer) =>
                customer.id === this._customersService.selectedCustomerId
            );
          let selectedCustomerName = selectedCustomer
            ? selectedCustomer.name
            : null;

          result = {
            ...transaction,
            customerName: selectedCustomerName,
            transactionId: transactionId[0],
          };

          result.remove = this.dialogData?.data?.tId || null;
        }),
        switchMap(() =>
          forkJoin({
            stock: postStockEntries,
            bank: postBank,
            materialUpdates: putMaterialPriceUpdates,
          })
        ),
        tap((res) => {
          this.response = res;
        })
      )
      .subscribe({
        error: (e) => {
          console.log(e);
          forkJoin({
            deleteTransaction: this.deleteTransaction,
            storeErrorInDatabase: this._dataService.storeErrorInDatabase(
              e,
              JSON.stringify(transaction),
              JSON.stringify(stockEntryFinal),
              JSON.stringify(data),
              "Stock"
            ),
          }).subscribe({
            complete: handleError,
            error: (err) => {
              console.log(err);
              handleError;
            },
          });
        },
        complete: () => {
          let dbStock = [];
          stockEntryFinal.forEach((el, i) => {
            dbStock.push({
              ...el,
              id: this.response.stock[i],
              tId: transaction.id,
              transactionId: transaction,
              date: transaction.date,
              customerId: transaction.customerId,
              tType: transaction.type,
            });
          });

          if (result.remove) {
            db.transactions.delete(result.remove);
            db.stock.where("tId").equals(result.remove).delete();
          }

          db.transactions.add(transaction, transaction.id);
          db.stock.bulkAdd(dbStock);
          if (dxBank) {
            dxBank.id = this.response.bank;
            db.bank.add(dxBank, dxBank.id);
          }

          console.log(dxBank);

          this._pusher.postData("New Stock Entry").subscribe();

          this.dialogRef.close({ data: result, remove: remove });
          this.toastr.success("", "Əlavə edildi!", {
            toastClass: "ngx-toastr customToast",
            progressBar: true,
            timeOut: 2500,
            progressAnimation: "increasing",
          });
        },
      });
  }

  newMaterialDialog(index) {
    const dialogRef = this.dialog.open(NewMaterialDialogComponent, {
      width: "520px",
      closeOnNavigation: true,
      panelClass: "materialDialog",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.materialsToAdd[index].id = res.id;
      }
    });
  }

  // GET MATERIAL PRICE
  getMaterialDetails(materialId, index) {
    this.materialsToAdd[index].sellPrice = _.find(
      this._dataService.materials(),
      {
        id: materialId,
      }
    )?.price
      ? _.find(this._dataService.materials(), { id: materialId })?.price
      : null;

    this.materialsToAdd[index].unit = _.find(this._dataService.materials(), {
      id: materialId,
    })?.unit
      ? _.find(this._dataService.materials(), { id: materialId })?.unit
      : null;

    this.calculateMaterialPrice(this.materialsToAdd[index], index);
  }

  calculateMaterialPrice(data?, index?, focusedInput?) {
    if (data.quantity && data.sum && focusedInput == "sum") {
      this.materialsToAdd[index].sellPrice = !isNaN(
        parseFloat((data.sum / data.quantity).toFixed(5))
      )
        ? parseFloat((data.sum / data.quantity).toFixed(5))
        : 0;
      this.materialsToAdd[index].useSum = true;
    } else if (data.quantity && data.sellPrice) {
      this.materialsToAdd[index].sum = parseFloat(
        (data.quantity * data.sellPrice).toFixed(3)
      );
      this.materialsToAdd[index].useSum = false;
    } else if (data.sum && data.quantity) {
      this.materialsToAdd[index].sellPrice = !isNaN(
        parseFloat((data.sum / data.quantity).toFixed(3))
      )
        ? parseFloat((data.sum / data.quantity).toFixed(3))
        : null;
      this.materialsToAdd[index].useSum = true;
    }
    this.sum = this.materialsToAdd.reduce(function (total, o: any) {
      let value = o.useSum
        ? parseFloat(o.sum)
        : parseFloat(o.quantity) * parseFloat(o.sellPrice);
      return total + value;
    }, 0);

    this.sum = parseFloat(this.sum.toFixed(3));
    this.residue = this.sum - this.priceReceived;
  }

  async getMaterialSums() {
    this._dataService.materials().forEach((material) => {
      material.totalQuantity = 0;
    });
    this.materialSums = await this._stockService.getStockCards(
      this._dataService.startDate.value.format("YYYY-MM-DD"),
      this._dataService.endDate.value.format("YYYY-MM-DD"),
      this.selectedStockGroupId
    );

    this.materialSums.forEach((material) => {
      if (
        this._dataService.materials().find((p) => p.id === material.materialId)
      ) {
        this._dataService
          .materials()
          .find((p) => p.id === material.materialId).totalQuantity = parseFloat(
          (material.totalQuantity + material.totalQuantityBeforeDate).toFixed(3)
        );
      }
    });
  }

  nextProduct() {
    this.materialsToAdd.push({
      id: null,
      quantity: null,
      length: null,
      sellPrice: null,
      sum: null,
      useSum: false,
    });
    console.log(this.materialsToAdd);
    this.materialsToAdd = this.materialsToAdd;
    this.changeDetectorRef.detectChanges();
    this.inputs.last.focus();
  }

  // DELETE FORMULA
  removeProduct(i, event?) {
    if (event.key == "Enter") {
      return false;
    }
    this.materialsToAdd.splice(i, 1);
    this.form.form.markAsDirty();
  }

  newCustomerDialog() {
    let data = null;
    // ANBAR GİRİŞİ OLDUQDA YENİ MÜŞTƏRİ TİPİ TƏCHİZADÇI, ƏKS HALDA MÜŞTƏRİ
    if (this.dialogData.stockEntryType == "Stock Entry") {
      data = { customerGroupId: 2 };
    } else {
      data = { customerGroupId: 1 };
    }
    this._customersService.customerDialog(data);
  }
}
