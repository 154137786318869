import { Component, Inject, OnInit, Optional, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { NewFormulaDialogComponent } from "../formula-dialog/formula-dialog.component";
import { DataService } from "src/app/data.service";
import { SellsDialogComponent } from "src/app/sells/sells-dialog/sells-dialog.component";
import { ProductsService } from "src/app/products/products.service";
import { db } from "src/app/db.service";

@Component({
  selector: "app-new-product-dialog",
  templateUrl: "./product-dialog.component.html",
  styleUrls: ["./product-dialog.component.css"],
})
export class NewProductDialogComponent implements OnInit {
  _productsService = inject(ProductsService);
  dataLoading: boolean = false;
  allFormulaGroups;
  formulaGroups;
  uzlemeGroups;
  formulMaliyyet: number;
  products;
  selectedFormulaGroupId: number;
  selectedUzlemeGroupId: number;
  selectedUnit;
  formulaChanged: boolean = false;
  showOperations: boolean = false;

  agGridData$;
  columnDefs = [
    {
      headerName: "id",
      field: "tId",
      hide: true,
    },
    // TRANSACTION TYPE
    {
      headerName: "",
      field: "transactionId.type",
      flex: 1,
      minWidth: 100,
      cellRenderer: (params) => {
        switch (params.value) {
          case "Stock Entry":
            return '<span class="material-symbols-outlined">place_item</span>';
          case "Stock Out":
            return '<span class="material-symbols-outlined">move_item</span>';
          case "Stock Transfer":
            return '<span class="material-symbols-outlined">sell</span>';
          case "Sells":
            return '<span  style="background-color: aliceblue, color: black" class="material-symbols-outlined">sell</span>';
          case "Production":
            return '<span style="background-color: #ff00009e" class="material-symbols-outlined">precision_manufacturing</span>';
          default:
            return '<span  style="background-color: aliceblue; color: black" class="material-symbols-outlined">sell</span>';
        }
      },
      cellClass: (params) => {
        switch (params.value) {
          case "Stock Entry":
            return "entryType stockEntry";
          case "Stock Out":
            return "entryType stockOut";
          case "Stock Transfer":
            return "entryType stockTransfer";
          default:
            return "entryType";
        }
      },
    },
    {
      headerName: "Miqdar",
      field: "quantity",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) =>
        params.data.transactionId.type == "Production"
          ? (
              params.data.quantity *
              params.data.length *
              (params.data.width ?? 1)
            )
              .toFixed(2)
              .replace(/[.,]00$/, "")
          : params.value < 0
          ? -params.value
          : params.value,
    },
    {
      headerName: "Qiymət",
      field: "price",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) => params.value ?? "*",
    },
    {
      headerName: "Tərəf-Müqabil",
      field: "customerId",
      flex: 3,
      minWidth: 100,
      cellRenderer: (params) =>
        params.value
          ? this._dataService.customers().find((c) => c.id == params.value).name
          : "*",
      cellClass: () => {
        return "justify-content-start";
      },
    },
    {
      headerName: "Tarix",
      field: "date",
      flex: 3,
      minWidth: 100,
      comparator: (dateA, dateB, nodeA, nodeB, isInverted) => {
        if (dateA === dateB) {
          // If dates are equal, sort by id (descending)
          const idA = nodeA.data.id;
          const idB = nodeB.data.id;
          return idA > idB ? -1 : idA < idB ? 1 : 0;
        } else {
          // Sort by date (descending)
          return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        }
      },
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<NewProductDialogComponent>,
    private http: HttpClient,
    private toastr: ToastrService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: IApiResult,
    public _dataService: DataService
  ) {}

  async ngOnInit(): Promise<void> {
    this.products = JSON.parse(localStorage.getItem("products"));
    this.allFormulaGroups =
      this._dataService.formulaGroups() ?? (await db.formulaGroups.toArray());
    if (!this.dialogData?.id) {
      this.dialogData = {
        code: "",
        name: "",
        category: "",
        weight: "",
        uzleme: "",
      };
    }

    this.selectedFormulaGroupId = this.dialogData.formulaGroupId
      ? this.dialogData.formulaGroupId
      : null;
    this.selectedUzlemeGroupId = this.dialogData.uzlemeGroupId
      ? this.dialogData.uzlemeGroupId
      : null;

    this.dialogData.formulaCost = this.selectedFormulaGroupId
      ? this.getFormulaCost(this.selectedFormulaGroupId)
      : null;
    this.dialogData.uzlemeCost = this.selectedUzlemeGroupId
      ? this.getFormulaCost(this.selectedUzlemeGroupId)
      : null;

    this.sortFormulaGroups();
  }

  addProduct(formData, event) {
    if (!formData.uzleme) formData.uzleme = 0;
    if (!formData.weight) formData.weight = 0;
    console.log(formData);
    event.target.innerText = "Gözləyin ...";
    event.target.disabled = true;
    let postProduct;

    // CHECK IF DATA ALREADY EXISTS IN TABLE
    if (
      this.products.find(
        (a) => a.name.toLowerCase() == formData.name.toLowerCase()
      ) &&
      !this.dialogData.id
    ) {
      this.toastr.error("Bu adda məhsul mövcuddur");

      event.target.innerText = "Əlavə et";
      event.target.disabled = false;
      return;
    }

    if (this.dialogData.id) {
      postProduct = this.http.put(
        this._dataService.dbBase +
          "api.php/records/products/" +
          this.dialogData.id,
        formData
      );
    } else {
      postProduct = this.http.post(
        this._dataService.dbBase + "api.php/records/products/",
        formData
      );
    }

    return postProduct.subscribe(
      (r) => {
        if (this.dialogData.id) {
          formData.id = this.dialogData.id;
          const index = this._dataService
            .products()
            .findIndex((obj) => obj.id === this.dialogData.id);
          // TODO THIS WAS SIGNAL MUTATE
          this._dataService.products()[index] = formData;
          this._dataService.products.set([...this._dataService.products()]);
          this.dialogRef.close(formData);
          this.toastr.success("Məhsul Yeniləndi");
        } else {
          formData.id = r;
          this._dataService.products.set([
            ...this._dataService.products(),
            formData,
          ]);
          this.dialogRef.close(formData);
          this.toastr.success("Əlavə edildi!");
        }
        this._dataService.setLS("products", this._dataService.products());
      },
      (err) => console.log(err)
    );
  }

  newFormulaDialog(formulaId?: number, uzleme?: string) {
    let dialogRef;
    let data = { formulaId: formulaId, uzleme: uzleme };
    dialogRef = this.dialog.open(NewFormulaDialogComponent, {
      width: "700px",
      data: data,
      closeOnNavigation: true,
      panelClass: "materialDialog",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this._dataService.getFormulaGroups().subscribe((r) => {
          this.allFormulaGroups = r;
          localStorage.setItem("formulaGroups", JSON.stringify(r));
          this.sortFormulaGroups();
        });
        // this.formulaGroups = [...this.formulaGroups, res];
        // this.data.formulaGroupId = res.id;
      }
    });
  }

  async getTransactionsByProduct() {
    this.showOperations = !this.showOperations;
    this.agGridData$ = this.showOperations
      ? await this._productsService.getProductOperations(this.dialogData.id)
      : null;
  }

  openTransactionDetailsDialog(rowData) {
    let data = null;
    if (rowData.type == "Sells" || rowData.type == "Return") {
      data = {
        id: rowData.transactionId,
        type: rowData.type,
        transactionId: rowData.transactionId,
        customerName: rowData.name,
        date: rowData.date,
      };

      this.dialog.open(SellsDialogComponent, {
        width: "1000px",
        maxHeight: "700px",
        data: data,
        closeOnNavigation: true,
      });
    }
  }

  getFormulaCost(id: number) {
    let f = this.allFormulaGroups.find((f) => f.id == id);
    let result = f?.cost;
    return result;
  }

  sortFormulaGroups() {
    this.uzlemeGroups = this.allFormulaGroups.filter((el) => el.type == 1);

    this.formulaGroups = this.allFormulaGroups.filter((el) => el.type != 1);
  }
}
