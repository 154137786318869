<app-navbar
  [headerText]="'Satışlar'"
  [buttons]="navButtons"
  [dataLoading]="dataLoading"
></app-navbar>

<!-- CARDS -->
<div class="container-fluid px-3 noHammerSwipe mt-6">
  <div
    #cardsContainer
    class="cardsContainer widgetsContent pt-2 pb-2"
    [@slideInOut]="showSummary ? 'in' : 'out'"
    [@toggleWrap]="isWrapped"
    *ngIf="showSummary"
    style="will-change: height, opacity; margin-top: -0.5rem !important"
    (wheel)="onWheel($event)"
  >
    <div (click)="showTotal = !showTotal" class="cards cardHover cardsSettings">
      <span
        class="material-symbols-outlined"
        style="font-size: 50px; color: #e8e8e8"
      >
        instant_mix
      </span>
      <span style="font-size: 14px">{{ showTotal ? "Toplam" : "Dövr" }}</span>
    </div>
    <ng-container *ngFor="let sum of sellsSumData">
      <div
        #card
        class="cards cardHover"
        *ngIf="sum.sumBetweenDates != 0"
        (click)="productDetailsDialog(sum)"
      >
        <h4>{{ sum.productName }}</h4>
        <span
          style="word-spacing: -3px !important; white-space: nowrap"
          *ngIf="sum.productPrice"
          class="materialPrice"
          >{{ sum.productPrice | number : "1.2-2" | commaToSpace
          }}<span
            class="manat"
            style="font-size: 10px !important; text-align: center"
            >₼</span
          ></span
        >
        <div
          class="d-flex gap-1 py-1"
          style="border-radius: 6px; font-family: Avant; font-size: 0.95rem"
        >
          <!-- <div
            style="
              display: flex;
              gap: 0.5em;
              text-align: center;
              border-right: 1px solid #ececec;
              flex: 1;
              justify-content: center;
            "
          >
            <span
              >{{ sum.sumBeforeStartDate | number : "1.2-2" | removeComma }}
              <span class="manat" style="font-size: 12px !important"
                >₼</span
              ></span
            >
          </div> -->
          <div
            style="
              display: flex;
              gap: 0.5em;
              flex: 1;
              justify-content: space-around;
              text-align: center;
            "
          >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                (showTotal
                  ? sum.totalQuantityBeforeStartDate + sum.totalQuantity
                  : sum.totalQuantity
                )
                  | number : "1.2-2"
                  | commaToSpace
              }}
              <span class="manat" style="font-size: 12px !important">{{
                sum.productUnit || "ədəd"
              }}</span></span
            >
            <span style="word-spacing: -3px !important; white-space: nowrap"
              >{{
                (showTotal
                  ? sum.sumBeforeStartDate + sum.sumBetweenDates
                  : sum.sumBetweenDates
                )
                  | number : "1.2-2"
                  | commaToSpace
              }}
              <span class="manat" style="font-size: 12px !important"
                >₼</span
              ></span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- SEARCH -->
<div class="container-fluid px-3" [ngClass]="{ 'mt-10': !showSummary }">
  <div class="row">
    <div id="targetTable" class="col-md-5 mt-2">
      <div class="input-group">
        <div
          class="input-group-text"
          style="
            background-color: var(--primary-background);
            color: white;
            padding-inline: 20px;
            font-family: 'Mulish';
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            cursor: pointer;
            position: relative;
          "
          (click)="searchInput.focus()"
        >
          <span class="material-symbols-outlined"> search </span>
        </div>
        <input
          #searchInput
          type="text"
          name="search"
          class="form-control cardHover"
          (input)="applyFilter(searchInput.value)"
          (keydown)="
            $event.key === 'Escape' ? applyFilter('', searchInput) : null
          "
          placeholder="Axtar"
        />

        @if(searchInput.value.length){
        <div
          class="input-group-append"
          style="
            position: absolute;
            right: 60px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99;
            cursor: pointer;
            color: #2f6194;
            padding: 2px;
          "
          (click)="searchInput.value = ''; applyFilter('')"
        >
          <span style="font-size: 16px" class="material-symbols-outlined">
            close
          </span>
        </div>
        }

        <div class="input-group-append d-flex">
          <div
            *ngIf="showSummary"
            (click)="isWrapped = !isWrapped"
            matTooltip="Xülasələri {{ isWrapped ? 'azalt' : 'çoxalt' }}"
            matTooltipClass="line-broken-tooltip"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              style="
                font-size: 1.7rem;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              unfold_more
            </mat-icon>
          </div>
          <div
            (click)="
              showSummary = !showSummary;
              _dataService.setLS('showSellsSummary', showSummary)
            "
            matTooltip="{{
              showSummary ? 'Xülasələri gizlə' : 'Xülasələri göstər'
            }}"
            matTooltipClass="line-broken-tooltip"
            style="cursor: pointer"
            class="input-group-text"
          >
            <mat-icon
              mat-stroked-button
              style="
                font-size: 1.5rem;
                line-height: 1.2;
                color: var(--primary-background);
                cursor: pointer;
              "
            >
              toc
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 mt-2">
      <div class="summaryContainer cardHover">
        <span class="summaryElementContainer"
          ><span class="summaryType">Satışlar</span>
          <span
            >{{ sellsSumTotal | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
        <span class="summaryElementContainer"
          ><span class="summaryType">Qayıtma</span>
          <span
            >{{ -sellsReturnTotal | number : "1.0-0" | commaToSpace }}
            <span class="manat">₼</span></span
          ></span
        >
        <span class="summaryElementContainer"
          ><span class="summaryType">Fərq</span>
          <span
            >{{
              sellsSumTotal + sellsReturnTotal | number : "1.0-0" | commaToSpace
            }}
            <span class="manat">₼</span></span
          ></span
        >
      </div>
    </div>
  </div>
</div>

<!-- TABLE -->
<div class="container-fluid px-3">
  <ag-grid-angular
    style="
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      margin-top: 1rem;
      transition: 200ms;
    "
    [ngStyle]="
      showSummary
        ? { height: 'calc(100vh - 260px)' }
        : { height: 'calc(100vh - 134px)' }
    "
    class="ag-theme-alpine transition-div cardHover"
    [rowData]="agGridData$ | async"
    [columnDefs]="columnDefs"
    [localeText]="_dataService.agGridLocale"
    [animateRows]="false"
    (rowClicked)="
      openSellsDialog($event.data.id, $event.data.type, $event.data, $event)
    "
    (gridReady)="onGridReady($event)"
    [getRowId]="getRowId"
    (contextmenu)="onRightClick($event)"
    (cellMouseOver)="onCellMouseOver($event)"
    [cacheQuickFilter]="true"
  >
  </ag-grid-angular>
  <div
    class="context-menu filterSettings"
    *ngIf="_dataService.showContextMenu"
    [style.right.px]="contextMenuData.x ? auto : 20"
    [style.left.px]="
      !contextMenuData.x
        ? auto
        : _dataService.showSideNav
        ? contextMenuData.x - 170
        : contextMenuData.x - 50
    "
    [style.top.px]="contextMenuData.y"
  >
    <div
      class="px-3 py-1"
      style="
        background-color: #0000000a;
        display: flex;
        font-family: 'Mulish';
        font-weight: 600;
        font-size: 17px;
        justify-content: center;
      "
    >
      <span>Əməliyyatlar</span>
    </div>
    <div class="filterItems">
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="
          openSellsDialog(
            contextMenuData.data.id,
            contextMenuData.data.type,
            contextMenuData.data,
            contextMenuData.data.event,
            true
          );
          _dataService.showContextMenu = false
        "
      >
        <span class="material-symbols-outlined"> edit </span>
        <span class="w-100">Düzəliş et</span>
      </button>
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="
          openSellsDialog(
            contextMenuData.data.id,
            contextMenuData.data.type,
            contextMenuData.data,
            contextMenuData.data.event,
            true,
            true
          );
          _dataService.showContextMenu = false
        "
      >
        <span class="material-symbols-outlined"> content_copy </span>
        <span class="w-100">Kopyala</span>
      </button>
      <button
        class="btn btn-outline-primary btnDelete padding-block-5 w-100 d-flex"
        (click)="deleteEntry(contextMenuData.data)"
      >
        <span class="material-symbols-outlined"> delete </span>
        <span class="w-100">Sil</span>
      </button>
    </div>
  </div>
</div>
