import { Injectable, EventEmitter, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IProduct } from "../shared/models/product.interface";
import { Observable } from "rxjs";
import { IInventory } from "../shared/models/inventory.interface";
import { IApiResult } from "src/app/shared/models/apiresult.interface";
import { DataService } from "../data.service";
import { db } from "src/app/db.service";
import "lodash";
import { MatDialog } from "@angular/material/dialog";
import { NewProductDialogComponent } from "../dialogs/product-dialog/product-dialog.component";
import { ToastrService } from "ngx-toastr";
declare var _: any;

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  _dataService = inject(DataService);
  dialog = inject(MatDialog);
  toastr = inject(ToastrService);

  // baseUrl: string = "https://localhost:44326/api/";

  baseUrl: string = "https://localhost:5001/api/";

  categorySelected = new EventEmitter<any>();
  categorySelectedOnAdd = new EventEmitter<any>();
  productEdited = new EventEmitter<any>(); // ON PRODUCT, INVENTORY ADD DELETE
  selectedProductId: null;

  constructor(private http: HttpClient) {}

  async getProductionCards(startDate?, endDate?) {
    let production, sells;
    if (startDate && endDate) {
      production = await db.production
        .where("date")
        .between(startDate, endDate, true, true)
        .toArray();

      sells = await db.sells
        .where("date")
        .between(startDate, endDate, true, true)
        .toArray();
    } else {
      production = await db.production.toArray();
      sells = await db.sells.toArray();
    }

    const productTotals: { [key: string]: any } = production.reduce(
      (acc, stock) => {
        const { productId, quantity, length, weight, width } = stock;

        acc[productId] = acc[productId] || {
          productId,
          name: _.find(this._dataService.products(), {
            id: productId,
          })?.name,
          unit: _.find(this._dataService.products(), { id: productId })?.unit,
          unitPrice: _.find(this._dataService.products(), { id: productId })
            ?.sellPrice,
          totalLength: 0,
          totalWeight: 0,
          sells: 0,
        };

        acc[productId].totalLength += width
          ? quantity * length * width
          : quantity * length;
        acc[productId].totalWeight += quantity * length * weight;
        return acc;
      },
      {}
    );

    sells.forEach((sell) => {
      const { productId, quantity } = sell;

      if (productTotals[productId]) {
        productTotals[productId].sells += quantity;
      }
    });

    const result = Object.values(productTotals);

    // Sort the result array by sumBetweenDates in descending order
    result.sort((a, b) => b.totalLength - a.totalLength);
    this._dataService.setLS("productionCards", result);
    return result;
  }

  ProductDetailsDialog(productId?) {
    let dialogRef,
      data = null;
    if (productId) {
      data = this._dataService.products().find((x) => x.id == productId);
    }
    dialogRef = this.dialog.open(NewProductDialogComponent, {
      width: "950px",
      maxHeight: "700px",
      data: data,
      panelClass: "materialDialog",
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : productId
        ? false
        : true,
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.selectedProductId = res.id;
        }
      },
      () => {
        this.toastr.error("Xəta baş verdi. Zəhmət olmasa yenidən cəhd edin");
      }
    );
  }

  async getProductOperations(productId) {
    let stock = await db.production
      .where("productId")
      .equals(productId)
      .toArray();
    let sells = await db.sells.where("productId").equals(productId).toArray();

    return stock.concat(sells);
  }

  addIstehsal(data: object) {
    return this.http.post(
      "https://tekoplast.az/hesabat/api.php/records/production",
      data
    );
  }

  GetProducts(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(this.baseUrl);
  }

  getProduction(): Observable<IApiResult> {
    return this.http.get<IApiResult>(
      "https://tekoplast.az/hesabat/api.php/records/production?join=personnel&join=products&order=date,desc&order=id,desc"
    );
  }

  // GET PRODUCTS OF A CATEGORY
  getProduct(productId: number) {
    return this.http.get<object>(this.baseUrl + "products/" + productId);
  }

  // GET PRODUCTS OF A CATEGORY BY STOCK ID
  getProductByStock(productId: number, stockId: number) {
    return this.http.get<object>(
      this.baseUrl + "products/" + productId + "/" + stockId
    );
  }

  // INVENTORY DETAILS BY DATE
  getInventoryDetailsByDate(categoryId: number, date1: Date, date2: Date) {
    return this.http.get<object>(
      this.baseUrl + `products/date/${categoryId}/${date1}/${date2}`
    );
  }

  // GET PRODUCTS BY DATE AND STOCK
  getProductsByDate(date1: Date, date2: Date, stockId?: number) {
    return this.http.get<object>(
      this.baseUrl + `productcategories/date/${date1}/${date2}/${stockId}`
    );
  }

  // GET PRODUCT DETAILS BY PRODUCT NAME
  getProductDetailsByName(CatId: Number, ProductName: string) {
    return this.http.get<object>(
      this.baseUrl + "getproductdetailsbyname/" + CatId + "/" + ProductName
    );
  }

  // PRODUCT CATEGORIES GET
  getProductCategories() {
    return this.http.get<object>(this.baseUrl + "productcategories");
  }

  // GET PRODUCT CATEGORIES BY STOCK ID
  getProductCategoriesByStock(stockId: number) {
    return this.http.get<object>(
      this.baseUrl + `productcategories/stock/${stockId}/1`
    );
  }

  addProductCategory(productCategory: object) {
    return this.http.post(this.baseUrl + "productcategories/", productCategory);
  }

  deleteProductCategory(Id: number) {
    return this.http.delete(this.baseUrl + "productcategories/" + Id);
  }

  // ADD INVENTORY
  addInventory(inventory: object) {
    return this.http.post(this.baseUrl + "inventories/", inventory);
  }

  getInventoryById(id: number) {
    return this.http.get<IInventory>(this.baseUrl + "inventories/" + id);
  }

  addProduct(product: IProduct) {
    return this.http.post(this.baseUrl + "products1", product);
  }

  editProduct(productId: number, product: IProduct) {
    return this.http.put(this.baseUrl + "products/" + productId, product);
  }

  deleteProduct(productId: number) {
    return this.http.delete(this.baseUrl + "products/" + productId);
  }

  searchProduct(text: string) {
    return this.http.get<IProduct[]>(this.baseUrl + "search/" + text);
  }

  // GET STOCKS LIST
  getStocks() {
    return this.http.get<object>(this.baseUrl + "stocks");
  }

  getStockByID(id: number) {
    return this.http.get<object>(this.baseUrl + "stocks" + id);
  }

  // GET UNITS
  getUnits() {
    return this.http.get<Object>(this.baseUrl + "units");
  }
}
