<app-navbar
  [headerText]="'Ana Səhifə'"
  [buttons]="navButtons"
  [dataLoading]="dataLoading"
></app-navbar>

<div class="container-fluid px-3 mt-6 dashboardCards">
  <!-- XAMMAL ANBARI -->
  <div routerLink="../Stock" class="mt-2 cards cardHover summary custom-grid">
    <h5 class="px-2 text-center custom-header" style="background: #174f87">
      <mat-icon class="custom-icon">local_mall</mat-icon>
    </h5>
    <div
      class="d-flex justify-content-end"
      style="overflow: auto; position: relative"
    >
      <p
        class="custom-text"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        {{ selectedStockGroupName() }}
      </p>
      <span
        (click)="$event.stopPropagation(); getNextStockGroupId()"
        class="material-symbols-outlined cardHover switchSelection"
        style="font-size: 27px"
      >
        multiple_stop
      </span>
    </div>
    <ng-container *ngIf="stockTotals | async as stocks">
      <div class="custom-grid-container">
        <div class="custom-grid-item">
          <!-- <div
          class="custom-grid-info"
        >
          <span class="custom-grid-label">Əvvələ:</span>
          <span class="custom-grid-value" [@valueChange]="stocks.residue">{{
            stocks.residue | number : "1.0-0" | removeComma
          }}</span>
        </div> -->
          <div class="custom-grid-info">
            <span class="custom-grid-label">Gələn:</span>
            <span class="custom-grid-value" [@valueChange]="stocks.in">{{
              stocks.in | number : "1.0-0" | removeComma
            }}</span>
          </div>
          <div class="custom-grid-info">
            <span class="custom-grid-label">Gedən:</span>
            <span class="custom-grid-value" [@valueChange]="stocks.out">{{
              -stocks.out | number : "1.0-0" | removeComma
            }}</span>
          </div>
          <div class="custom-grid-info">
            <span class="custom-grid-label">Fərq:</span>
            <span class="custom-grid-value" [@valueChange]="stocks.out">{{
              stocks.in + stocks.out | number : "1.0-0" | removeComma
            }}</span>
          </div>
        </div>
      </div>
      <div class="custom-value">
        <span class="manat">₼</span>
        <span
          class="custom-value-text"
          [@valueChange]="stocks.in + stocks.out + stocks.residue"
          >{{
            stocks.in + stocks.out + stocks.residue
              | number : "1.0-0"
              | commaToSpace
          }}</span
        >
      </div>
    </ng-container>
  </div>

  <!-- İstehsal -->
  <div
    routerLink="../Products"
    class="mt-2 cards cardHover summary custom-grid"
  >
    <h5 class="px-2 text-center custom-header" style="background: #1f60a2">
      <mat-icon class="custom-icon">precision_manufacturing </mat-icon>
    </h5>
    <div
      class="d-flex justify-content-end"
      style="overflow: auto; position: relative"
    >
      <p
        class="custom-text"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        İstehsal
      </p>
    </div>
    <ng-container *ngIf="stockTotals | async as stocks">
      <div class="custom-grid-container">
        <div class="custom-grid-item">
          <div class="custom-grid-info">
            <span class="custom-grid-label">Kg:</span>
            <span class="custom-grid-value" [@valueChange]="totalProduction">{{
              -totalProduction.totalQuantity | number : "1.0-0" | removeComma
            }}</span>
          </div>
          <div class="custom-grid-info">
            
          </div>
          <div class="custom-grid-info">
            
          </div>
        </div>
      </div>
      <div class="custom-value">
        <span class="manat">₼</span>
        <span class="custom-value-text" [@valueChange]="totalProduction">{{
          -totalProduction.totalPrice | number : "1.0-0" | commaToSpace
        }}</span>
      </div>
    </ng-container>
  </div>

  <!-- SATIŞLAR -->
  <div routerLink="../Sells" class="mt-2 cards cardHover summary custom-grid">
    <h5 class="px-2 text-center custom-header" style="background: #1566b9">
      <mat-icon class="custom-icon">shopping_cart_checkout</mat-icon>
    </h5>
    <p class="custom-text">Satışlar</p>
    <ng-container *ngIf="sellsTotals | async as sells">
      <ng-container *ngIf="bankTotals | async as bank">
        <div class="custom-grid-container">
          <div class="custom-grid-item">
            <div class="custom-grid-info">
              <span class="custom-grid-label">Satış:</span>
              <span class="custom-grid-value" [@valueChange]="sells.sells">{{
                sells.sells | number : "1.0-0" | removeComma
              }}</span>
            </div>
            <div class="custom-grid-info">
              <span class="custom-grid-label">Qaytarma:</span>
              <span class="custom-grid-value" [@valueChange]="sells.returns">{{
                -sells.returns | number : "1.0-0" | removeComma
              }}</span>
            </div>
            <div class="custom-grid-info">
              <span class="custom-grid-label">Alınan:</span>
              <span class="custom-grid-value" [@valueChange]="sells.residue">{{
                bank.sells | number : "1.0-0" | removeComma
              }}</span>
            </div>
          </div>
        </div>
        <div class="custom-value">
          <span class="manat">₼</span>
          <span
            class="custom-value-text"
            [@valueChange]="sells.sells + sells.returns"
            >{{
              sells.sells + sells.returns | number : "1.0-0" | commaToSpace
            }}</span
          >
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- KASSA -->
  <div routerLink="../Bank" class="mt-2 cards summary custom-grid cardHover">
    <h5
      class="px-2 text-center custom-header"
      style="background-color: #1f60a2"
    >
      <mat-icon class="custom-icon">account_balance</mat-icon>
    </h5>
    <div
      class="d-flex justify-content-end"
      style="overflow: auto; position: relative"
    >
      <p
        class="custom-text"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        {{ selectedCashboxName() }}
      </p>
      <span
        (click)="$event.stopPropagation(); getNextCashboxId()"
        class="material-symbols-outlined cardHover switchSelection"
        style="font-size: 27px"
      >
        multiple_stop
      </span>
    </div>
    <ng-container *ngIf="bankTotals | async as bank">
      <div class="custom-grid-container">
        <div class="custom-grid-item">
          <div class="custom-grid-info">
            <span class="custom-grid-label">Alınan:</span>
            <span class="custom-grid-value" [@valueChange]="bank.in">{{
              bank.in | number : "1.0-0" | removeComma
            }}</span>
          </div>
          <div class="custom-grid-info">
            <span class="custom-grid-label">Ödənən:</span>
            <span class="custom-grid-value" [@valueChange]="bank.out">{{
              bank.out | number : "1.0-0" | removeComma
            }}</span>
          </div>
          <div class="custom-grid-info">
            <span class="custom-grid-label">Fərq:</span>
            <span class="custom-grid-value" [@valueChange]="bank.out">{{
              bank.in - bank.out | number : "1.0-0" | removeComma
            }}</span>
          </div>
        </div>
      </div>
      <div class="custom-value">
        <span class="manat">₼</span>
        <span
          class="custom-value-text"
          [@valueChange]="bank.in - bank.out + bank.residue"
          >{{
            bank.in - bank.out + bank.residue | number : "1.0-0" | commaToSpace
          }}</span
        >
      </div>
    </ng-container>
  </div>

  <!-- DEBIT KREDIT -->
  <div
    routerLink="../Customers"
    class="mt-2 cards cardHover summary custom-grid"
  >
    <h5 class="px-2 text-center custom-header" style="background: #1566b9">
      <mat-icon class="custom-icon">currency_exchange</mat-icon>
    </h5>
    <p class="custom-text">Debit/Kredit</p>
    <div class="custom-grid-container">
      <div class="custom-grid-item">
        <div class="custom-grid-info">
          <span class="custom-grid-label">Debit:</span>
          <span class="custom-grid-value minWordSpace">{{
            _customersService.sumDebitSig() | number : "1.0-0" | commaToSpace
          }}</span>
        </div>
        <div class="custom-grid-info">
          <span class="custom-grid-label">Kredit:</span>
          <span class="custom-grid-value minWordSpace">{{
            -_customersService.sumKreditSig() | number : "1.0-0" | commaToSpace
          }}</span>
        </div>
      </div>
    </div>
    <div class="custom-value">
      <span class="manat">₼</span>
      <span class="custom-value-text">{{
        _customersService.sumDebitSig() + _customersService.sumKreditSig()
          | number : "1.0-0"
          | commaToSpace
      }}</span>
    </div>
  </div>

  <!-- Gəlir -->
  <div routerLink="../Profit" class="mt-2 cards cardHover summary custom-grid">
    <h5 class="px-2 text-center custom-header" style="background: #2b74be">
      <mat-icon class="custom-icon">price_check</mat-icon>
    </h5>
    <p class="custom-text">Gəlir</p>

    <div class="custom-grid-container">
      <div class="custom-grid-item">
        <div class="custom-grid-info">
          <span class="custom-grid-label">Satış:</span>
          <span class="custom-grid-value" [@valueChange]="profit.sellsTotal">{{
            profit.sellsTotal | number : "1.0-0" | removeComma
          }}</span>
        </div>
        <div class="custom-grid-info">
          <span class="custom-grid-label">Maya:</span>
          <span class="custom-grid-value" [@valueChange]="profit.costTotal">{{
            profit.costTotal | number : "1.0-0" | removeComma
          }}</span>
        </div>
        <div class="custom-grid-info">
          <span class="custom-grid-label">Xərclər:</span>
          <span
            class="custom-grid-value"
            [@valueChange]="profit.administrativXercler"
            >{{
              profit.administrativXercler | number : "1.0-0" | removeComma
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="custom-value">
      <span class="manat">₼</span>
      <span
        class="custom-value-text"
        [@valueChange]="
          profit.sellsTotal - profit.costTotal - profit.administrativXercler
        "
        >{{
          profit.sellsTotal - profit.costTotal - profit.administrativXercler
            | number : "1.0-0"
            | commaToSpace
        }}</span
      >
    </div>
  </div>
</div>

<div
  class="container-fluid px-3"
  style="
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  "
>
  <!-- SATIŞ GRAFİKİ-->
  <div
    class="cardHover"
    style="
      border: 1px solid #d3d3d3;
      border-radius: 8px;
      background-color: white;
    "
  >
    <h5
      class="px-2 text-center"
      style="
        background: #2f4e6d;
        padding: 10px 0px 9px;
        color: white;
        margin-top: 0px !important;
        font-size: 17px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      "
    >
      Satış grafiki
    </h5>
    <canvas
      baseChart
      class="chart"
      [data]="lineChartData"
      [options]="lineChartOptions"
      style="padding: 10px; max-height: 230px"
    >
    </canvas>
  </div>

  <!-- DÖVR ÜZRƏ ÖDƏMƏLƏR -->
  <div
    class="cardHover"
    style="
      border: 1px solid #d3d3d3;
      border-radius: 8px;
      background-color: white;
      position: relative;
      cursor: pointer;
    "
  >
    <h5
      (click)="_router.navigate(['/CashDist'])"
      class="px-2 text-center"
      style="
        background: #2f4e6d;
        padding: 10px 0px 9px;
        color: white;
        margin-top: 0px !important;
        font-size: 17px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      "
    >
      Dövr üzrə ödəmələr
    </h5>
    <canvas
      (click)="_router.navigate(['/CashDist'])"
      baseChart
      class="chart payChart"
      [data]="pieChartData"
      [options]="pieChartOptions"
      type="pie"
      #pieChart
      style="max-height: 230px"
    >
    </canvas>
    <ng-container *ngIf="!pieChartData?.datasets[0]?.data.length">
      <span
        style="
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%);
        "
        >Seçilən tarixdə ödəmə yoxdur</span
      >
    </ng-container>
  </div>

  <!-- SON ƏMƏLIYYATLAR -->
  <div>
    <div
      class="cardHover"
      style="
        border: 1px solid #d3d3d3;
        border-radius: 8px;
        background-color: white;
        position: relative;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
      "
    >
      <h5
        class="px-2 text-center"
        style="
          background: #2f4e6d;
          padding: 10px 0px 9px;
          color: white;
          margin: 0px !important;
          font-size: 17px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        "
      >
        Son Əməliyyatlar
      </h5>

      <ag-table
        [colDefs]="columnDefs"
        [tableData]="lastTransactions"
        [tableClass]="'ag-theme-alpine transition-div lastTransactionTable'"
        [tableHeight]="'100%'"
        (rowClicked)="_dataService.transactionDialog($event.data)"
      ></ag-table>
    </div>
  </div>
</div>

<div class="container-fluid px-3">
  <div class="row">
    <div class="col-md-12">
      <div class="mat-elevation-z1 my-3 cardHover" style="border-radius: 6px">
        <table
          mat-table
          [dataSource]="accountsOverall"
          class="w-100 px-5 accountsOverallTable"
        >
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>
              Tarix
              <span class="accountsOverallTotals">Cəm</span>
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.yr + "/" + element.mon | date : "yyyy MMMM" | titlecase
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sells">
            <th mat-header-cell *matHeaderCellDef>
              Satışlar
              <span class="accountsOverallTotals minWordSpace"
                >{{
                  accountsOverallTotals.sells | number : "1.0-0" | commaToSpace
                }}
                <span class="manat">₼</span></span
              >
            </th>
            <td mat-cell *matCellDef="let element" class="minWordSpace">
              {{ element.sells || 0 | number : "1.0-0" | commaToSpace }}
            </td>
          </ng-container>

          <ng-container matColumnDef="income">
            <th mat-header-cell *matHeaderCellDef>
              Gələn
              <span class="accountsOverallTotals minWordSpace"
                >{{
                  accountsOverallTotals.income | number : "1.0-0" | commaToSpace
                }}
                <span class="manat">₼</span></span
              >
            </th>
            <td mat-cell *matCellDef="let element" class="minWordSpace">
              {{ element.income || 0 | number : "1.0-0" | commaToSpace }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sellsResidue">
            <th mat-header-cell *matHeaderCellDef>
              Fərq
              <span class="accountsOverallTotals minWordSpace"
                >{{
                  accountsOverallTotals.sells - accountsOverallTotals.income
                    | number : "1.0-0"
                    | commaToSpace
                }}
                <span class="manat">₼</span></span
              >
            </th>
            <td mat-cell *matCellDef="let element" class="minWordSpace">
              {{
                (element.sells || 0) - (element.income || 0)
                  | number : "1.0-0"
                  | commaToSpace
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef>
              Xammal Alışı
              <span class="accountsOverallTotals minWordSpace"
                >{{
                  accountsOverallTotals.buy | number : "1.0-0" | commaToSpace
                }}
                <span class="manat">₼</span></span
              >
            </th>
            <td mat-cell *matCellDef="let element" class="minWordSpace">
              {{ element.stock || 0 | number : "1.0-0" | commaToSpace }}
            </td>
          </ng-container>

          <ng-container matColumnDef="outgoings">
            <th mat-header-cell *matHeaderCellDef>
              Ödənən
              <span class="accountsOverallTotals minWordSpace"
                >{{
                  accountsOverallTotals.outgoings
                    | number : "1.0-0"
                    | commaToSpace
                }}
                <span class="manat">₼</span></span
              >
            </th>
            <td mat-cell *matCellDef="let element" class="minWordSpace">
              {{ element.stockOut || 0 | number : "1.0-0" | commaToSpace }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stockResidue">
            <th mat-header-cell *matHeaderCellDef>
              Fərq
              <span class="accountsOverallTotals minWordSpace"
                >{{
                  accountsOverallTotals.buy - accountsOverallTotals.outgoings
                    | number : "1.0-0"
                    | commaToSpace
                }}
                <span class="manat">₼</span></span
              >
            </th>
            <td mat-cell *matCellDef="let element" class="minWordSpace">
              {{
                (element.stock || 0) - (element.stockOut || 0)
                  | number : "1.0-0"
                  | commaToSpace
              }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            style="cursor: pointer"
            (click)="changeSelectedDate(row)"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid px-3">
  <div class="row mt-3 pb-4">
    <div class="col-12">
      <div class="row mb-3">
        <div class="col-md-7">
          <div class="input-group">
            <div
              class="input-group-text"
              style="
                background-color: var(--primary-background);
                color: white;
                padding-inline: 40px;
                font-family: 'Mulish';
              "
            >
              Notlar
            </div>
            <input
              #newToDoInput
              type="text"
              name="addTodo"
              class="form-control cardHover"
              placeholder="Yeni not"
              (keyup.enter)="
                addToDo(newToDoInput.value); newToDoInput.value = ''
              "
            />
            <div
              class="input-group-text inputGroupButtons toDoAddButton cardHover"
              style="cursor: pointer"
            >
              <a (click)="addToDo(newToDoInput.value); newToDoInput.value = ''">
                <mat-icon class="material-icons">add</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="notes">
            @for(todo of _dataService.todos().reverse(); track todo.id; let i =
            $index) { @if(!todo.status) {
            <div class="cardHover">
              <span class="todoText">{{ todo.todo }}</span>
              <span
                class="toDoItemButton cardHover"
                (click)="doneToDo(todo.id)"
              >
                <mat-icon>done</mat-icon>
              </span>
            </div>
            } }
          </div>
        </div>
        <div class="col-sm-12 col-md-4 mt-smm-6">
          <div class="notes notesDone">
            @for(todo of _dataService.todos().reverse(); track todo.id; let i =
            $index) { @if(todo.status) {
            <div class="cardHover">
              {{ todo.todo }}&nbsp;<mat-icon>done</mat-icon>
              <span
                class="toDoItemButton cardHover"
                (click)="removeToDo(todo.id)"
              >
                <mat-icon>delete_outline</mat-icon>
              </span>
            </div>
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
